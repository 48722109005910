"use client";

import React, { ReactNode } from "react";
import { ThemeProvider as Provider } from "next-themes";

type ThemeProviderType = {
  children: ReactNode;
  attribute: string;
  defaultTheme: string;
  storageKey: string;
  themes: string[];
};

function ThemeProvider({
  children,
  attribute,
  defaultTheme,
  storageKey,
  themes,
}: ThemeProviderType) {
  return (
    <Provider
      attribute={attribute}
      themes={themes}
      defaultTheme={defaultTheme}
      storageKey={storageKey}
    >
      {children}
    </Provider>
  );
}

export default ThemeProvider;

import { storageKeys } from "@/config/general";
import { DEFAULT_FALLBACK_THEME } from "@/config/theme";
import { capitalizeFirstLetter } from "@/fe-core/helpers/general";
import Storage from "@/fe-core/helpers/storage";
import {
  themeActions,
  themeState,
  themeActionTypes,
} from "@/fe-core/meta/types/theme";

const initialState: themeState = {
  theme: capitalizeFirstLetter(
    (Storage.get(storageKeys.THEME) as string) ||
      process.env.NEXT_PUBLIC_DEFAULT_THEME ||
      DEFAULT_FALLBACK_THEME
  ),
};

const themeReducer = (
  state: themeState = initialState,
  action: themeActions
): themeState => {
  switch (action.type) {
    case themeActionTypes.SET_CURRENT_THEME:
      return {
        ...state,
        theme: capitalizeFirstLetter(action.payload.theme),
      };
    default:
      return {
        ...state,
      };
  }
};

export default themeReducer;

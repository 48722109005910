import { ICMSRecentWinnerViewModel, IGetRecentWinnerRequestPayload } from '../interfaces/recentWinners'
import { IErrorPayload, IStatus } from '../interfaces/root'

export enum recentWinnersActionTypes {
  GET_RECENT_WINNERS_REQUEST = 'GET_RECENT_WINNERS_REQUEST',
  GET_RECENT_WINNERS_SUCCESS = 'GET_RECENT_WINNERS_SUCCESS',
  GET_RECENT_WINNERS_FAILURE = 'GET_RECENT_WINNERS_FAILURE',
}

export type GetRecentWinnersRequestAction = {
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_REQUEST,
  payload: IGetRecentWinnerRequestPayload
}

export type GetRecentWinnersSuccessAction = {
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_SUCCESS
  payload: ICMSRecentWinnerViewModel[]
}

export type GetRecentWinnersFailureAction = {
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_FAILURE
  payload: IErrorPayload
}

export type RecentWinnersActions =
  | GetRecentWinnersRequestAction
  | GetRecentWinnersSuccessAction
  | GetRecentWinnersFailureAction

export type RecentWinnersState = {
  recentWinners: ICMSRecentWinnerViewModel[]
  pending: boolean
  status: IStatus | null
}

export enum winnerRequestPeriodTypes {
  DAILY = "daily",
  HOURLY = "hourly",
  MONTHLY = "monthly",
}
import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";

import signupEmailSaga from "./signupEmailSaga";
import signupSaga from "./_signupSaga_nwa";
import signupEmailConfirmationSaga from "./signupEmailConfirmationSaga";
import signupSmsSaga from "./signupSmsSaga";
import validateSaga from "./validateSaga";
import userConsentsSaga from "./_userConsentsSaga_nwa";
import currenciesSaga from "./_currenciesSaga_nwa";
import getValidCountriesSaga from "./_validCountriesSaga_nwa";

function* registerSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    fork(signupSaga),
    fork(signupEmailSaga),
    fork(getValidCountriesSaga),
    fork(signupSmsSaga),
    fork(signupEmailConfirmationSaga),
    fork(validateSaga),
    fork(userConsentsSaga),
    fork(currenciesSaga),
  ]);
}

export default registerSaga;

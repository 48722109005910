import {
  RewardsDrawerActions,
  rewardsDrawerActionTypes,
  RewardsDrawerState,
} from "@/fe-core/meta/types/rewardsDrawer";

const initialState: RewardsDrawerState = {
  isOpen: false,
};

const rewardsDrawerReducer = (
  state: RewardsDrawerState = initialState,
  action: RewardsDrawerActions
): RewardsDrawerState => {
  switch (action.type) {
    case rewardsDrawerActionTypes.OPEN_REWARDS_DRAWER:
      return {
        ...state,
        isOpen: true,
      };
    case rewardsDrawerActionTypes.CLOSE_REWARDS_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default rewardsDrawerReducer;

import {
  SessionLimitFailureAction,
  SessionLimitRequestAction,
  SessionLimitSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { ISessionLimitPayload } from "@/fe-core/meta/interfaces/user";

export const sessionLimitRequest = (
  payload: ISessionLimitPayload
): SessionLimitRequestAction => ({
  type: userActionTypes.SESSION_LIMIT_REQUEST,
  payload,
});

export const sessionLimitSuccess = (): SessionLimitSuccessAction => ({
  type: userActionTypes.SESSION_LIMIT_SUCCESS,
});

export const sessionLimitFailure = (
  payload: IErrorPayload
): SessionLimitFailureAction => ({
  type: userActionTypes.SESSION_LIMIT_FAILURE,
  payload,
});

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  activateEmailActionTypes,
  ActivateEmailRequestAction,
} from "@/fe-core/meta/types/activateEmail";
import {
  activateEmailFailure,
  activateEmailSuccess,
} from "@/fe-core/_redux/actions/activateEmailActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { IActivateEmailViewModel } from "@/fe-core/meta/interfaces/activateEmail";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import { headerKeys } from "@/config/general";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* activateEmailRequestSaga({ payload }: ActivateEmailRequestAction) {
  try {
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IActivateEmailViewModel | IErrorPayload> =
      yield fetch("/api/user/signup/activateEmail", <RequestInit>{
        method: methodTypes.POST,
        body: JSON.stringify({ hashKey: payload.verificationCode }),
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const activateEmailData: IActivateEmailViewModel = yield response.json();
      yield put(activateEmailSuccess(activateEmailData));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(activateEmailFailure(errorPayload));
    }
  } catch (error) {
    const { message } = error as Error;

    yield put(activateEmailFailure(parseFrontendError(message)));
  }
}

function* activateEmailSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      activateEmailActionTypes.ACTIVATE_EMAIL_REQUEST,
      activateEmailRequestSaga
    ),
  ]);
}

export default activateEmailSaga;

import {
  PopularCompetitionsActions,
  popularCompetitionsActionTypes,
  PopularCompetitionsState,
} from "@/fe-core/meta/types/popularCompetitions";

const initialState: PopularCompetitionsState = {
  popularCompetitions: [],
  pending: false,
  status: null,
};

const popularCompetitionsReducer = (
  state: PopularCompetitionsState = initialState,
  action: PopularCompetitionsActions
): PopularCompetitionsState => {
  switch (action.type) {
    case popularCompetitionsActionTypes.POPULAR_COMPETITIONS_REQUEST:
      return {
        ...state,
        pending: true,
        popularCompetitions: [],
        status: null,
      };
    case popularCompetitionsActionTypes.POPULAR_COMPETITIONS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        popularCompetitions: payload,
        status: {
          success: true,
        },
      };
    }
    case popularCompetitionsActionTypes.POPULAR_COMPETITIONS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        popularCompetitions: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default popularCompetitionsReducer;

import { I18N_NAMESPACE } from "@/config/i18n";
import { useTranslations } from "next-intl";
import { FC, useEffect, useState } from "react";

interface Props {
  onResend: () => void;
}

const ResendTimer: FC<Props> = ({ onResend }) => {
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);

  const intervals = [180, 300]; // Seconds: 1 minute, 3 minutes, 5 minutes
  const [attempts, setAttempts] = useState(0);
  const [timer, setTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && isButtonDisabled) {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer, isButtonDisabled]);

  const handleResend = () => {
    if (!isButtonDisabled) {
      onResend();

      // Calculate the next wait time
      const nextInterval =
        intervals[attempts] || intervals[intervals.length - 1];
      setTimer(nextInterval);
      setIsButtonDisabled(true);

      // Increment the number of attempts
      setAttempts((prevAttempts) => prevAttempts + 1);
    }
  };

  return (
    <p className="text-xs dark:text-white text-center">
      {t("not-receive-password-mail")} <br />
      <a
        onClick={handleResend}
        className="underline text-pink cursor-pointer mt-2 text-primary-500"
      >
        {isButtonDisabled
          ? `${registerT("resend-in")} ${timer}s`
          : registerT("resend-code")}
      </a>
    </p>
  );
};

export default ResendTimer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  ICMSJackpotWinnerViewModel,
  IGBJackpotWinnerViewModel,
  IJackpotWinnerViewModel,
} from "@/fe-core/meta/interfaces/jackpotWinners";
import {
  jackpotWinnersActionTypes,
  GetJackpotWinnersRequestAction,
} from "@/fe-core/meta/types/jackpotWinners";
import {
  getJackpotWinnersFailure,
  getJackpotWinnersSuccess,
} from "@/fe-core/_redux/actions/jackpotWinnersActions";

import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";

function* getJackpotWinnersSaga({}: GetJackpotWinnersRequestAction) {
  try {
    const response: IJsonResponse<IJackpotWinnerViewModel[] | IErrorPayload> =
      yield fetch(`/api/jackpotWinners`, {
        method: methodTypes.GET,
      });

    if (response.status == 200) {
      const winners: IJackpotWinnerViewModel[] = yield response.json();

      const cmsWinners: ICMSJackpotWinnerViewModel[] = winners.map((item) => {
        const n = item.gameModel.launchUrl.lastIndexOf("/");
        const slug = item.gameModel.launchUrl.substring(n + 1);

        return {
          username: item.username,
          amount: item.amount,
          gameId: item.gameId,
          gameCode: item.gameId,
          gameName: item.gameName,
          gameSlug: slug,
          gameDesktopThumbnail: item.gameModel.icons["114"],
          gameMobileThumbnail: item.gameModel.icons["114"],
          gameHasFunMode: item.gameModel.hasFunMode,
          timestamp: new Date().toISOString(),
        };
      });

      yield put(getJackpotWinnersSuccess(cmsWinners));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getJackpotWinnersFailure(errorPayload));
    }
  } catch (error) {
    yield put(getJackpotWinnersFailure(parseFrontendError(error)));
  }
}

// function* getGBJackpotWinnersSaga({}: GetJackpotWinnersRequestAction) {
//   try {
//     const response: IJsonResponse<IGBJackpotWinnerViewModel[] | IErrorPayload> =
//       yield fetch(`/api/gbJackpotWinners`, {
//         method: methodTypes.GET,
//       });

//     if (response.status == 200) {
//       const winners: IGBJackpotWinnerViewModel[] = yield response.json();

//       const cmsWinners: ICMSJackpotWinnerViewModel[] = winners.map((item) => {
//         return {
//           username: item.username,
//           amount: item.amount,
//           gameId: item.gameCode,
//           gameCode: item.gameCode,
//           gameName: item.gameName,
//           gameSlug: item.slug,
//           gameDesktopThumbnail: item.desktopThumbnail,
//           gameMobileThumbnail: item.mobileThumbnail,
//           gameHasFunMode: false,
//           timestamp: item.timestamp,
//         };
//       });

//       yield put(getJackpotWinnersSuccess(cmsWinners));
//     } else {
//       const errorPayload: IErrorPayload = yield response.json();
//       yield put(getJackpotWinnersFailure(errorPayload));
//     }
//   } catch (error) {
//     yield put(getJackpotWinnersFailure(parseFrontendError(error)));
//   }
// }

// function* getCMSJackpotWinnersSaga({}: GetJackpotWinnersRequestAction) {
//   try {
//     const response: IJsonResponse<ICMSJackpotWinnerViewModel[] | IErrorPayload> =
//       yield fetch(`/api/cmsJackpotWinners`, {
//         method: methodTypes.GET,
//       });

//     if (response.status == 200) {
//       const winners: ICMSJackpotWinnerViewModel[] = yield response.json();

//       yield put(getJackpotWinnersSuccess(winners));
//     } else {
//       const errorPayload: IErrorPayload = yield response.json();
//       yield put(getJackpotWinnersFailure(errorPayload));
//     }
//   } catch (error) {
//     yield put(getJackpotWinnersFailure(parseFrontendError(error)));
//   }
// }

function* jackpotWinnerSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_REQUEST,
      getJackpotWinnersSaga
    ),
  ]);
}

export default jackpotWinnerSaga;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  TransactionHistoryState,
  userActionTypes,
  TransactionHistoryActions,
} from "@/fe-core/meta/types/user";

const initialState: TransactionHistoryState = {
  pending: false,
  data: null,
  status: null,
};

const transactionHistoryReducer = (
  state: TransactionHistoryState = initialState,
  action: TransactionHistoryActions | RootActions
): TransactionHistoryState => {
  switch (action.type) {
    case userActionTypes.TRANSACTION_HISTORY_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.TRANSACTION_HISTORY_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.TRANSACTION_HISTORY_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CLEAR_TRANSACTION_HISTORY:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default transactionHistoryReducer;

import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import SVGIcon from "../../SVGIcon";
import Image from "next/image";
import { useLocale } from "next-intl";
import { usePathname } from "../../../navigation";
import classNames from "classnames";

const LanguageSwitcher = () => {
  const [showLanguage, setShowLanguage] = useState(false);
  const locale = useLocale();

  const availableLanguages = [
    { locale: "en", img: "/img/flags/globe.svg", name: "English" },
    { locale: "en-IN", img: "/img/flags/countries-flag/in.svg", name: "English (India)" },
    { locale: "en-MY", img: "/img/flags/countries-flag/my.svg", name: "English (Malaysia)" },
    
    // { locale: "hi-IN", img: "/img/flags/countries-flag/in.svg", name: "Hindi" },
    // { locale: "th-TH", img: "/img/flags/countries-flag/th.svg", name: "Thai" },
    // { locale: "id-ID", img: "/img/flags/countries-flag/id.svg", name: "Indonesian" },
    // { locale: "vi-VN", img: "/img/flags/countries-flag/vi.svg", name: "Vietnamese" },
    // { locale: "ja-JP", img: "/img/flags/countries-flag/jp.svg", name: "日本語" },

    // { locale: "pt-br", img: "/img/flags/br.svg", name: "Brasil" },
    // { locale: "fi", img: "/img/flags/fi.svg", name: "Finnish" },
    // { locale: "nb", img: "/img/flags/no.svg", name: "Norway" },
    // { locale: "de", img: "/img/flags/de.svg", name: "Germany" },
    // { locale: "zh", img: "/img/flags/ch.svg", name: "Chinese" },
  ];
  const currentLocal = availableLanguages?.find(
    (item) => item?.locale === locale
  );
  const pathname = usePathname();

  return (
    <div
      className={`relative z-1 leading-none flex items-center justify-center lg:block w-max mx-auto lg:w-auto`}
    >
      <Menu>
        <Menu.Button
          onClick={() => setShowLanguage(!showLanguage)}
          className="btn border border-white/15 w-full !justify-between !py-1.5 !px-2 !pr-3.5 lg:!py-3 lg:!px-4 lg:!pr-6 !rounded-full !capitalize lg:min-w-60 !outline-none !shadow-none !ring-0"
        >
          <div className="flex items-center gap-2.5 lg:gap-5">
            <span className="relative block w-4.5 h-4.5 overflow-hidden lg:w-7 lg:h-7 rounded-full">
              <Image
                src={currentLocal ? currentLocal?.img : "/img/flags/globe.svg"}
                alt={currentLocal?.name || ""}
                fill
                objectFit="cover"
                loading="lazy"
              />
            </span>
            <span className="text-xs sm:text-base font-medium text-primary/50">
              {currentLocal?.name ? currentLocal?.name : "English"}
            </span>
          </div>
          <SVGIcon
            icon="angleArrow"
            className="h-3 w-2 lg:h-4 lg:w-4 text-tertiary-300 rotate-90"
          />
        </Menu.Button>

        <Menu.Items className="absolute top-full left-0 mt-2 w-max lg:w-full origin-top-right rounded-xl border border-white/5 bg-tertiary-300 p-1 text-sm text-white focus:outline-none z-40">
          {availableLanguages &&
            availableLanguages.map((item, i) => (
              <Menu.Item key={i + "__translation"}>
                <button
                  onClick={() => {
                    window.location.href = `/${item.locale}/${pathname}`;
                  }}
                  className={classNames(
                    "flex items-center w-full gap-3 px-3 py-2 rounded-md",
                    {
                      "bg-white/10": item?.locale === locale,
                    }
                  )}
                >
                  <span className="relative block w-5 h-5 min-w-5 rounded-full overflow-hidden">
                    <img
                      src={item?.img}
                      className="object-cover w-full h-full"
                      alt={item?.name}
                    />
                  </span>
                  <span className="text-xs font-medium capitalize transition-colors duration-300 whitespace-nowrap text-grey-300 group-hover:text-white">
                    {item?.name}
                  </span>
                </button>
              </Menu.Item>
            ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default LanguageSwitcher;

import { navActionTypes, NavState, NavActions } from "@/fe-core/meta/types/nav";

const initialState: NavState = {
  pending: false,
  data: null,
  status: null,
};

const navReducer = (
  state: NavState = initialState,
  action: NavActions
): NavState => {
  switch (action.type) {
    case navActionTypes.NAV_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case navActionTypes.NAV_SUCCESS:
      const { payload } = action;
      return {
        pending: false,
        data: payload,
        status: {
          success: true,
          message: "Successfully retrieved navigation",
        },
      };
    case navActionTypes.NAV_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    case navActionTypes.CLEAR_NAV:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default navReducer;

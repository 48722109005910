import { IPaymentMethodsViewModel } from "@/fe-core/meta/interfaces/paymentMethods";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  paymentMethodsActionTypes,
  GetPaymentMethodsFailureAction,
  GetPaymentMethodsRequestAction,
  GetPaymentMethodsSuccessAction,
} from "@/fe-core/meta/types/paymentMethods";

export const getPaymentMethodsRequest = (): GetPaymentMethodsRequestAction => ({
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_REQUEST,
});

export const getPaymentMethodsSuccess = (
  payload: IPaymentMethodsViewModel
): GetPaymentMethodsSuccessAction => ({
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const getPaymentMethodsFailure = (
  payload: IErrorPayload
): GetPaymentMethodsFailureAction => ({
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_FAILURE,
  payload,
});

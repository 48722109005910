import { call, take, put, all, takeLatest, select } from "redux-saga/effects";
import { eventChannel, buffers, EventChannel } from "redux-saga";
import { Action } from "redux";
import { getWebPubSub } from "@/fe-core/helpers/webPubSub";
import { webPubSubActionTypes } from "@/fe-core/meta/types/webPubSub";
import {
  initializeWebPubSub,
  webPubSubConnectionFailed,
} from "@/fe-core/_redux/actions/webPubSubActions";
import { webPubSubSessionConnectionAttemptsSelector } from "@/fe-core/_redux/selectors/webPubSubSelectors";
import { SOCKET_CONNECTION_MAX_ATTEMPTS } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { NotUndefined, Buffer } from "@redux-saga/types";
import { Socket } from "socket.io-client";
import { ISessionViewModel } from "@/fe-core/meta/interfaces/session";

function startWebPubSub(
  sessionId: string | undefined,
  userId: string | undefined
): EventChannel<NotUndefined> {
  const buffer: Buffer<NotUndefined> = buffers.expanding();
  return eventChannel((emitter) => {
    let socket: Socket | null = null;
    socket = getWebPubSub(userId, sessionId);
    socket.on("HubBet", (data) => {
      return emitter({
        type: webPubSubActionTypes.WEBPUBSUB_HUB_BET,
        payload: data,
      });
    });

    socket.on("connect_error", (error) => {
      console.log(`socket error`, error); // true
    });
    socket.on("connect", () => {
      return emitter({
        type: webPubSubActionTypes.WEBPUBSUB_CONNECTED,
        //payload: session,
      });
    });

    socket.on("disconnect", () => {
      emitter({ type: webPubSubActionTypes.RECONNECT_WEBPUBSUB });

      return true;
    });

    return () => {
      socket?.disconnect?.();
    };
  }, buffer);
}

function* webPubSubConnectedSaga() {
  console.log("connected");
}

function* initializeWebPubSubSaga() {
  const session: ISessionViewModel = yield select(sessionDataSelector);
  const channel: EventChannel<NotUndefined> = yield call(() =>
    startWebPubSub(session?.sessionId, session?.userId)
  );

  while (true) {
    const action: Action = yield take(channel);
    yield put(action);
  }
}

function* reconnectWebPubSubSaga() {
  const sessionConnectionAttempts: number = yield select(
    webPubSubSessionConnectionAttemptsSelector
  );

  if (sessionConnectionAttempts > SOCKET_CONNECTION_MAX_ATTEMPTS) {
    yield put(webPubSubConnectionFailed());
  } else {
    yield put(initializeWebPubSub());
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* socketSaga() {
  yield all([
    takeLatest(
      webPubSubActionTypes.INITIALIZE_WEBPUBSUB,
      initializeWebPubSubSaga
    ),
    takeLatest(
      webPubSubActionTypes.WEBPUBSUB_CONNECTED,
      webPubSubConnectedSaga
    ),
    takeLatest(
      webPubSubActionTypes.RECONNECT_WEBPUBSUB,
      reconnectWebPubSubSaga
    ),
  ]);
}

import { RootActions } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  ValidateActions,
} from "@/fe-core/meta/types/user";
import { ValidateSignupIpState } from "@/fe-core/meta/types/user/register/validate/ip";

const initialState: ValidateSignupIpState = {
  pending: false,
  status: null,
};

const validateSignupIpReducer = (
  state: ValidateSignupIpState = initialState,
  action: ValidateActions | RootActions
): ValidateSignupIpState => {
  switch (action.type) {
    case userActionTypes.VALIDATE_SIGNUP_IP_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.VALIDATE_SIGNUP_IP_SUCCESS:
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
      };
    case userActionTypes.VALIDATE_SIGNUP_IP_FAILURE:
      return {
        ...state,
        pending: false,
        status: {
          success: false,
        },
      };
      case userActionTypes.RESET_SIGNUP_PRE_CHECK:
        return {
          ...initialState,
        };
    default:
      return {
        ...state,
      };
  }
};

export default validateSignupIpReducer;

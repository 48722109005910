export enum pwaActionTypes {
  SHOW_PWA_MODAL = "SHOW_PWA_MODAL",
  HIDE_PWA_MODAL = "HIDE_PWA_MODAL",
}

export type ShowPwaModalAction = {
  type: pwaActionTypes.SHOW_PWA_MODAL;
};

export type HidePwaModalAction = {
  type: pwaActionTypes.HIDE_PWA_MODAL;
};

export type PWAActions = ShowPwaModalAction | HidePwaModalAction;

export type PWAState = {
  showPwaModal: boolean;
};

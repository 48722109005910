import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  GetTCRequirementsRequestAction,
  ResolveTCRestrictionRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  authenticateRequest,
  loginFailure,
  loginSuccess,
  loginSuccessfulWrapper,
} from "@/fe-core/_redux/actions/sessionActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";
import { openLoginMenu } from "@/fe-core/_redux/actions/menusActions";
import {
  getTCRequirementsFailure,
  resolveTCRestrictionSuccess,
  setMarketingRestrictions,
  setTCRestrictions,
} from "@/fe-core/_redux/actions/userActions/restrictionsActions";
import {
  headerKeys,
  MARKETING_CONSENT_NAMES,
  storageKeys,
} from "@/config/general";
import {
  IRestrictionTCResponseModel,
  IRestrictionUserDetailsPayload,
} from "@/fe-core/meta/interfaces/user/restrictions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  IAuthenticateRequestPayload,
  ILoginViewModel,
} from "@/fe-core/meta/interfaces/session";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { restrictionsDetailSelector } from "@/fe-core/_redux/selectors/userSelectors";
import Storage from "@/fe-core/helpers/storage";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";

function* resolveTCRestrictionSaga({
  payload,
}: ResolveTCRestrictionRequestAction) {
  try {
    const restrictionDetailsPayload: IRestrictionUserDetailsPayload =
      yield select(restrictionsDetailSelector);
    const response: IJsonResponse<ILoginViewModel | IErrorPayload> =
      yield fetch("/api/user/consents", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: restrictionDetailsPayload.sessionId,
          [headerKeys.USER_ID]: restrictionDetailsPayload.userId,
        }),
        body: JSON.stringify(payload),
      });

    if (response.status == 200) {
      yield put(resolveTCRestrictionSuccess());
      Storage.set(storageKeys.SESSION_ID, restrictionDetailsPayload.sessionId);

      yield put(
        authenticateRequest(
          //@ts-ignore
          restrictionDetailsPayload as IAuthenticateRequestPayload
        )
      );
      yield put(loginSuccess());
    }
  } catch (error) {
    yield put(clearState());
    yield put(loginFailure(parseFrontendError(error)));
    yield put(openLoginMenu());
  }
}

function* getTCRequirementsSaga({}: GetTCRequirementsRequestAction) {
  try {
    const { sessionId, userId } = yield select(restrictionsDetailSelector);
    const { locale } = yield select(localeSelector);
    const response: IJsonResponse<ILoginViewModel | IErrorPayload> =
      yield fetch(
        `/api/user/${
          process.env.NEXT_PUBLIC_GM_CORE === "GM1"
            ? "consentRequirements"
            : "consents"
        } `,
        <RequestInit>{
          method: methodTypes.GET,
          headers: new Headers({
            [headerKeys.SESSION_ID]: sessionId,
            [headerKeys.USER_ID]: userId,
            [headerKeys.LOCALE]: locale,
          }),
        }
      );

    if (response.status == 200) {
      //@ts-ignore
      const tcResponse = yield response.json();

      const needsTCAccept = tcResponse?.some(
        (item: IRestrictionTCResponseModel) => item.mustAccept || item?.status === 'Expired'
      );

      if (needsTCAccept) {
        yield put(setTCRestrictions());
      }

      const needsMarketingAccept = tcResponse?.some(
        (item: IRestrictionTCResponseModel) => {
          return MARKETING_CONSENT_NAMES.includes(item.tagCode);
        }
      );

      if (needsMarketingAccept) {
        yield put(setMarketingRestrictions());
      }
    }
  } catch (error) {
    yield put(getTCRequirementsFailure(parseFrontendError(error)));
  }
}

export default function* rolesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.RESOLVE_TC_RESTRICTION_REQUEST,
      resolveTCRestrictionSaga
    ),
  ]);
  yield all([
    takeLatest(
      userActionTypes.GET_TC_REQUIREMENTS_REQUEST,
      getTCRequirementsSaga
    ),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getTCRequirementsSaga),
  ]);
}

import {
  applyBonusFailure,
  applyBonusSuccess,
  cancelBonusFailure,
  cancelBonusSuccess,
  clearBonusMesage,
  clearRedeemBonusMessage,
  depositEligibleBonusPlansFailure,
  depositEligibleBonusPlansSuccess,
  getBonusesFailure,
  getBonusesSuccess,
  getEligibleOptInBonusPlansFailure,
  getEligibleOptInBonusPlansSuccess,
  optInBonusFailure,
  optInBonusSuccess,
  redeemBonusFailure,
  redeemBonusSuccess,
} from "@/fe-core/_redux/actions/userActions";
import {
  encryptedJsonData,
  OMEGA_STATUS_TYPES,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import {
  IApplyBonusViewModel,
  IBonus,
  IBonusResponsePayload,
  IBonusResponseViewModal,
  IDepositEligibleBonusPlansResponsePayload,
  IEligibleOptInBonusPlan,
  IEligibleOptInBonusPlansResponsePayload,
  IForfeitBonusViewModel,
  IOptInBonusResponsePayload,
  IRedeemBonusViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  ApplyBonusRequestAction,
  CancelBonusRequestAction,
  DepositEligibleBonusPlansRequestAction,
  GetBonusesRequestAction,
  GetEligibleOptInBonusPlansRequestAction,
  OptInBonusRequestAction,
  RedeemBonusRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  all,
  AllEffect,
  delay,
  fork,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

import { headerKeys } from "@/config/general";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import {
  userBalanceDataSelector,
  userProfileDataSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  clearOptInBonusMessage,
  clearCancelBonusMessage,
} from "../../actions/userActions/bonusActions";
import Storage from "@/fe-core/helpers/storage";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";

function* bonusRequestSaga({}: GetBonusesRequestAction) {
  try {
    const { locale } = yield select(localeSelector);
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<IApplyBonusViewModel | IErrorPayload> =
      yield fetch(`/api/user/bonus/all`, <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    const bonusData: IBonusResponseViewModal | IErrorPayload =
      yield response.json();
    if (response.status == 200) {
      const viewModel = bonusData as IBonusResponseViewModal;

      if (process.env.NEXT_PUBLIC_HAS_AFFILIATE_SYNC === "YES") {
        try {
          Storage.set(
            localStorageKeys.BONUS_DATA,
            encryptedJsonData(viewModel)
          );
        } catch (error) {}
      }

      yield put(getBonusesSuccess(viewModel));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (bonusData as IBonusResponseViewModal)?.status ===
            OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        getBonusesFailure(parseFrontendError(bonusData as IErrorPayload))
      );
    }
  } catch (error) {
    yield put(getBonusesFailure(parseFrontendError(error)));
  }
}

function* eligibleOptInBonusPlansRequestSaga({ }: GetEligibleOptInBonusPlansRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<
      IEligibleOptInBonusPlansResponsePayload | IErrorPayload
    > = yield fetch(`/api/user/bonus/eligible`, <RequestInit>{
      method: methodTypes.GET,
      headers: new Headers({
        [headerKeys.SESSION_ID]: sessionId,
      }),
    });

    const bonusData: IEligibleOptInBonusPlansResponsePayload | IErrorPayload =
      yield response.json();

    if (response.status === 200) {
      const viewModel = bonusData as IEligibleOptInBonusPlansResponsePayload;
      yield put(getEligibleOptInBonusPlansSuccess(viewModel?.optInBonusPlans as IEligibleOptInBonusPlan[]));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (bonusData as IEligibleOptInBonusPlansResponsePayload)?.status ===
          OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        getEligibleOptInBonusPlansFailure(parseFrontendError(bonusData as IErrorPayload))
      );
    }
  } catch (error) {
    yield put(getEligibleOptInBonusPlansFailure(parseFrontendError(error)));
  }
}

function* depositEligibleBonusPlansRequestSaga({
  payload,
}: DepositEligibleBonusPlansRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<
      IDepositEligibleBonusPlansResponsePayload | IErrorPayload
    > = yield fetch(`/api/user/bonus/eligible/deposit`, <RequestInit>{
      method: methodTypes.POST,
      headers: new Headers({
        [headerKeys.SESSION_ID]: sessionId,
      }),
      body: JSON.stringify(payload),
    });

    const bonusData: IDepositEligibleBonusPlansResponsePayload | IErrorPayload =
      yield response.json();

    if (response.status === 200) {
      const viewModel = bonusData as IDepositEligibleBonusPlansResponsePayload;
      yield put(depositEligibleBonusPlansSuccess(viewModel?.bonusPlans));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (bonusData as IDepositEligibleBonusPlansResponsePayload)?.status ===
          OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        depositEligibleBonusPlansFailure(
          parseFrontendError(bonusData as IErrorPayload)
        )
      );
    }
  } catch (error) {
    yield put(depositEligibleBonusPlansFailure(parseFrontendError(error)));
  }
}

function* optInBonusRequestSaga({ payload }: OptInBonusRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { partyId } = yield select(userProfileDataSelector) || {};

    const body = {
      optInId: payload.optInId,
      partyId
    }

    const response: IJsonResponse<IOptInBonusResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/bonus/optIn`, <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(body),
      });

    const responsePayload: IOptInBonusResponsePayload =
      yield response.json();

    if (response.status === 200) {
      yield put(
        optInBonusSuccess({
          ...responsePayload,
          bonusPlanId: payload?.bonusPlanId,
        })
      );
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (responsePayload)?.status ===
          OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        optInBonusFailure({
          ...parseFrontendError(responsePayload),
          bonusPlanId: payload?.bonusPlanId,
        })
      );
    }
    yield fork(clearOptInBonusMessageSaga, 5000);
  } catch (error) {
    yield put(
      optInBonusFailure({
        ...parseFrontendError(error),
        bonusPlanId: payload?.bonusPlanId,
      })
    );
    yield fork(clearOptInBonusMessageSaga, 5000);
  }
}

//
// function* claimableBonusRequestSaga() {
//   try {
//     const socketSession: Session = yield select(socketSessionSelector)
//
//     const emResponse: Result | AutoBahnError = yield doEMCall(
//         socketSession,
//         '/user/bonus#getClaimableBonuses',
//         {}
//     )
//
//     if (emResponse instanceof AutoBahnError) {
//       throw new Error('User is unauthorized')
//     }
//
//     const payload = emResponse?.kwargs || null
//
//     yield put(getClaimableBonusesSuccess(payload))
//   } catch (error) {
//     yield put(getClaimableBonusesFailure(parseFrontendError(error)))
//   }
// }

function* applyBonusRequestSaga({ payload }: ApplyBonusRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { totalCurrency: currency } = yield select(userBalanceDataSelector) ||
      {};
    const response: IJsonResponse<IApplyBonusViewModel | IErrorPayload> =
      yield fetch("/api/user/bonus/claim", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify({ ...payload, currency }),
      });
    const responsePayload: IJsonResponse<IApplyBonusViewModel | IErrorPayload> = yield response.json();
    if (response.status == 200) {
      yield put(applyBonusSuccess());
      // if (payload?.isTournamentTicket === true) {
      //   yield put(
      //     getBonusesRequest({
      //       additionalData: "tournamentTicket",
      //     })
      //   );
      // } else {
      // }
      yield fork(clearBonusMessageSaga, 5000);
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }
      //@ts-ignore
      yield put(applyBonusFailure({ message: responsePayload?.message || "Error applying bonus" }));
      yield fork(clearBonusMessageSaga, 5000);
    }
  } catch (error) {
    yield put(applyBonusFailure(parseFrontendError(error)));
    yield fork(clearBonusMessageSaga, 5000);
  }
}

function* redeemBonusRequestSaga({ payload }: RedeemBonusRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IRedeemBonusViewModel | IErrorPayload> =
      yield fetch("/api/user/bonus/redeem", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload),
      });

    const responsePayload: IRedeemBonusViewModel | IErrorPayload =
      yield response.json();

    if (response.status === 200) {
      const viewModel = responsePayload as IErrorPayload;
      yield put(redeemBonusSuccess(viewModel));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (responsePayload as IRedeemBonusViewModel)?.status ===
          OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        redeemBonusFailure(responsePayload as IErrorPayload)
      );
    }
    yield fork(clearRedeemBonusMessageSaga, 5000);
  } catch (error) {
    yield put(redeemBonusFailure(parseFrontendError(error)));
    yield fork(clearRedeemBonusMessageSaga, 5000);
  }
}

function* cancelBonusRequestSaga({ payload }: CancelBonusRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<IApplyBonusViewModel | IErrorPayload> =
      yield fetch(`/api/user/bonus/cancel`, <
        RequestInit
        >{
          method: methodTypes.POST,
          headers: new Headers({
            [headerKeys.SESSION_ID]: sessionId,
          }),
          body: JSON.stringify(payload),
        });
    const responsePayload: IForfeitBonusViewModel | IErrorPayload =
      yield response.json();

    if (response.status === 200) {
      // const viewModel = responsePayload as IErrorPayload;
      yield put(cancelBonusSuccess());
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (responsePayload as IForfeitBonusViewModel)?.status ===
          OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(
        cancelBonusFailure(responsePayload as IErrorPayload)
      );
    }
    yield fork(clearCancelBonusMessageSaga, 5000);
  } catch (error) {
    yield put(cancelBonusFailure(parseFrontendError(error)));
    yield fork(clearCancelBonusMessageSaga, 5000);
  }
}

function* clearBonusMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearBonusMesage());
}

function* clearRedeemBonusMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearRedeemBonusMessage());
}

function* clearOptInBonusMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearOptInBonusMessage());
}
function* clearCancelBonusMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearCancelBonusMessage());
}

export default function* bonusSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest([userActionTypes.GET_BONUSES_REQUEST, userActionTypes.REDEEM_BONUS_SUCCESS, userActionTypes.CANCEL_BONUS_SUCCESS], bonusRequestSaga),
  ]);
  yield all([
    takeLatest(
      [userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_REQUEST, userActionTypes.UPDATE_TAGS_SUCCESS],
      eligibleOptInBonusPlansRequestSaga
    ),
  ]);
  yield all([
    takeLatest(
      userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_REQUEST,
      depositEligibleBonusPlansRequestSaga
    ),
  ]);
  yield all([
    takeLatest(userActionTypes.OPT_IN_BONUS_SUCCESS, eligibleOptInBonusPlansRequestSaga),
  ]);
  yield all([
    takeLatest(
      userActionTypes.OPT_IN_BONUS_REQUEST,
      optInBonusRequestSaga
    ),
  ]);
  yield all([
    takeLatest(userActionTypes.APPLY_BONUS_REQUEST, applyBonusRequestSaga),
  ]);
  yield all([
    takeLatest(userActionTypes.REDEEM_BONUS_REQUEST, redeemBonusRequestSaga),
  ]);
  yield all([
    takeLatest(userActionTypes.CANCEL_BONUS_REQUEST, cancelBonusRequestSaga),
  ]);
}

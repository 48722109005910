import Link from "next/link";
// import { Link } from "../../navigation";
import { FC } from "react";
import { IViewNavigationResponse } from "@/fe-core/meta/interfaces/navigation";
import useTracking from "../Hooks/useTracking";

const FooterLinks: FC<{
  footerNavigation: IViewNavigationResponse[] | undefined;
}> = async ({ footerNavigation }) => {
  const { navigate } = useTracking();
  return (
    <div className="flex flex-col justify-between gap-8 md:flex-row md:gap-10 ">
      {footerNavigation?.map((item, index) => {
        return (
          <div
            key={`footerNavigation__${index}`}
            className="flex flex-col gap-4 md:gap-8"
          >
            <div className="text-base font-bold text-center md:text-lg md:text-start text-white/60">
              {item?.title}
            </div>
            <ul className="flex flex-wrap justify-center gap-4 md:flex-col md:flex-nowrap md:gap-5 md:justify-start">
              {item?.items.length > 0
                ? item?.items.map((navItem, i) => {
                    return (
                      <li key={`navItem__${i}`}>
                        <Link
                          href={navItem?.path || "casino"}
                          className="text-xs md:text-sm text-tertiary dark:text-tertiary-50/70 dark:hover:text-primary-500   "
                          onClick={() => {
                            navigate(`${navItem?.path || "casino"}`, {
                              component: "FooterLinks",
                              label: navItem?.title,
                              navigateFrom: "Footer Links",
                            });
                          }}
                        >
                          {navItem?.title}
                        </Link>
                      </li>
                    );
                  })
                : null}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default FooterLinks;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  SessionLimitState,
  UserActions,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: SessionLimitState = {
  pending: false,
  status: null,
};

const sessionLimitReducer = (
  state: SessionLimitState = initialState,
  action: UserActions | RootActions
): SessionLimitState => {
  switch (action.type) {
    case userActionTypes.SESSION_LIMIT_REQUEST: {
      return {
        ...state,
        pending: true,
      };
    }
    case userActionTypes.SESSION_LIMIT_SUCCESS: {
      return {
        ...state,
        pending: false,
      };
    }
    case userActionTypes.SESSION_LIMIT_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default sessionLimitReducer;

import { headerKeys } from "@/config/general";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  bankingHistoryFailure,
  bankingHistorySuccess,
} from "@/fe-core/_redux/actions/userActions/cashierActions/bankingHistoryActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IBankHistory } from "@/fe-core/meta/interfaces/user/cashier/bankingHistory";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  BankingHistoryRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

function* bankingHistoryRequestSaga({ payload }: BankingHistoryRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/bankingHistory`,
      <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload),
      }
    );

    const viewModel: IBankHistory[] | IErrorPayload = yield response.json();
    if (response.status == 200) {
      yield put(bankingHistorySuccess(viewModel as IBankHistory[]));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }
      yield put(
        bankingHistoryFailure(parseFrontendError(viewModel as IErrorPayload))
      );
    }
  } catch (error) {
    yield put(bankingHistoryFailure(parseFrontendError(error)));
  }
}

function* bankingHistorySaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.BANKING_HISTORY_REQUEST,
      bankingHistoryRequestSaga
    ),
  ]);
}

export default bankingHistorySaga;

import { IPageData } from "@/fe-core/meta/interfaces/page";
import React, { FC, useState } from "react";
import useTracking from "../Hooks/useTracking";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";

const SEOText: FC<{
  seoContentData: IPageData | null;
}> = ({ seoContentData }) => {
  const [showMore, setShowMore] = useState(false);
  const { click } = useTracking();
  const t = useTranslations(I18N_NAMESPACE.SEO_TEXT);

  if (!seoContentData?.attributes?.content) return null;

  if (seoContentData) {
    return (
      <div className="px-5 py-8   sm:px-0 md:py-10 max-w-none dark:bg-tertiary-300 ">
        <div className="flex flex-col gap-4 md:gap-6 md:container">
          {seoContentData?.attributes?.content && (
            <div
              className={`m-0 overflow-hidden prose-sm max-w-none prose-invert opacity-70 transition-all ${
                showMore ? "max-h-[200vh]" : "max-h-44"
              }`}
              dangerouslySetInnerHTML={{
                __html: seoContentData?.attributes?.content,
              }}
            />
          )}
          {seoContentData?.attributes?.content && (
            <button
              className="text-sm font-bold leading-[14px] text-[#868686] w-max"
              onClick={() => {
                setShowMore(!showMore);
                click(`toggle SEO text`, {
                  buttonLabel: showMore
                    ? `${t("show-less")}`
                    : `${t("show-more")}`,
                  component: "SEOText",
                });
              }}
            >
              {showMore ? `${t("show-less")}` : `${t("show-more")}`}
            </button>
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default SEOText;

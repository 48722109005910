import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  validatePersonalInformationAddressWithNoSpecialChar,
  validatePersonalInformationBirthdate,
  validatePersonalInformationCityWithNoSpecialChar,
  validatePersonalInformationCountry,
  validatePersonalInformationCurrency,
  validatePersonalInformationFirstName,
  validatePersonalInformationLastName,
  validatePersonalInformationPayload,
  validatePersonalInformationProvinceWithNoSpecialChar,
} from "@/fe-core/helpers/general";
import { ISignupPayload } from "@/fe-core/meta/interfaces/user";
import { signupScreens } from "@/fe-core/meta/types/user";
import Select, { StylesConfig } from "react-select";
import {
  checkCredentialRequest,
  clearSignUpError,
  setActiveSignupScreen,
  signupEmailConfirmationClearError,
  signupRequest,
} from "@/fe-core/_redux/actions/userActions";
import {
  checkMobileCredentialPendingSelector,
  checkMobileCredentialStatusSelector,
  consentRequirementsSelector,
  isPendingConsentSelector,
  signupPendingSelector,
  signupStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import { INPUT_STATUSES, storageKeys } from "@/config/general";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../SVGIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTheme } from "next-themes";
import { IConsentItem } from "@/fe-core/meta/interfaces/user/register/consentRequirements";
import { CountriesOption } from "@/fe-core/meta/interfaces/user/register/countries";
import {
  isPendingValidCountriesSelector,
  validCountriesSelector,
} from "@/fe-core/_redux/selectors/userSelectors/registerSelectors/validCountries";
import PhoneInput, {
  getCountries,
  isValidPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import {
  currenciesSelector,
  isPendingCurrenciesSelector,
} from "@/fe-core/_redux/selectors/userSelectors/registerSelectors/currencies";
import classNames from "classnames";
import countryToCurrency from "country-to-currency";
import useTracking from "../Hooks/useTracking";
import Button from "../Button";
import dayjs from "dayjs";
import { validatePersonalInformationPostalCodeWithNoSpecialChar } from "@/fe-core/helpers/general";
import Storage, {CookiesStorage} from "@/fe-core/helpers/storage";
import en from "react-phone-number-input/locale/en.json";
import Image from "next/image";
import debounce from "lodash.debounce";

const PersonalInformationScreen: FC<{
  payload: ISignupPayload;
  setPayload: (
    payload: ISignupPayload | ((prevPayload: ISignupPayload) => ISignupPayload)
  ) => void;
  handleLoginClick: () => void;
}> = ({ payload, setPayload, handleLoginClick }) => {
  const dispatch = useDispatch();
  const { click } = useTracking();
  const country = CookiesStorage.getItem(storageKeys.COUNTRY);
  const signupPending = useSelector(signupPendingSelector);
  const signupStatus = useSelector(signupStatusSelector);
  const validCountries = useSelector(validCountriesSelector);
  const isLoadingValidCountries = useSelector(isPendingValidCountriesSelector);
  const currencies = useSelector(currenciesSelector);
  const isLoadingCurrencies = useSelector(isPendingCurrenciesSelector);
  const isLoadingConsentList = useSelector(isPendingConsentSelector);
  const consents = useSelector(consentRequirementsSelector);
  const checkMobileCredentialPending = useSelector(
    checkMobileCredentialPendingSelector

  );
  const checkMobileCredentialStatus = useSelector(
    checkMobileCredentialStatusSelector
  );

  const { theme } = useTheme();

  const t = useTranslations(I18N_NAMESPACE.REGISTER);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const profileT = useTranslations(I18N_NAMESPACE.PROFILE);
  const bettingHistoryT = useTranslations(I18N_NAMESPACE.BETTING_HISTORY);
  const consentsT = useTranslations(I18N_NAMESPACE.CONSENTS);

  const [firstNameErr, setFirstNameErr] = useState<string | null>("");
  const [lastNameErr, setLastNameErr] = useState<string | null>("");
  const [cityErr, setCityErr] = useState<string | null>("");
  const [postalCodeErr, setPostalCodeErr] = useState<string | null>("");
  const [provinceErr, setProvinceErr] = useState<string | null>("");
  const [countryErr, setCountryErr] = useState<string | null>("");
  const [currencyErr, setCurrencyErr] = useState<string | null>("");
  const [addressErr, setAddressErr] = useState<string | null>("");
  const [consentErr, setConsentErr] = useState<string[] | []>([]);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [mobileNumberErr, setMobileNumberErr] = useState<string | null>("");
  const [mobilePrefixCountry, setMobilePrefixCountry] = useState<string>();
  const [apiMobileNumberErr, setApiMobileNumberErr] = useState<string | null>(
    ""
  );
  const [birthErr, setBirthErr] = useState<string>("");
  const [isValidated, setIsValidated] = useState<boolean>(false);

  const maxDate = dayjs().subtract(18, "years").subtract(1, "day").toDate();

  const requiredConsentList = useMemo(() => {
    return consents?.filter((item) => item.isMandatory);
  }, [consents]);

  const hasEmptyFields = useMemo(
    () =>
      !(
        consentErr.length === 0 &&
        payload.firstName &&
        payload.lastName &&
        payload.city &&
        payload.country &&
        payload.currency &&
        payload.address &&
        payload.birthDate &&
        mobileNumber
      ),
    [
      consentErr,
      payload.firstName,
      payload.lastName,
      payload.city,
      payload.country,
      payload.currency,
      payload.address,
      payload.birthDate,
      mobileNumber,
    ]
  );

  const hasValidationError = useMemo(() => {
    return (
      !!firstNameErr ||
      !!lastNameErr ||
      !!birthErr ||
      !!mobileNumberErr ||
      !!currencyErr ||
      !!addressErr ||
      !!cityErr ||
      consentErr?.length > 0 ||
      !!countryErr ||
      !!apiMobileNumberErr
    );
  }, [
    firstNameErr,
    lastNameErr,
    birthErr,
    mobileNumberErr,
    currencyErr,
    addressErr,
    cityErr,
    consentErr,
    countryErr,
    apiMobileNumberErr,
  ]);

  useEffect(() => {
    if (checkMobileCredentialStatus?.success === false && mobileNumber)
      setApiMobileNumberErr(t("mobile-not-available"));
    else setApiMobileNumberErr("");
  }, [checkMobileCredentialStatus]);

  useEffect(() => {
    if (requiredConsentList && requiredConsentList?.length > 0) {
      validateConsent(payload.userConsents);
    }
  }, [requiredConsentList]);

  const debouncedMobileSearch = useCallback(
    debounce(
      (mobileNumber: string) =>
        dispatch(
          checkCredentialRequest({ field: "mobilePhone", value: mobileNumber })
        ),
      1000
    ),
    []
  );

  const countryOptions: CountriesOption[] = useMemo(() => {
    if (!validCountries || validCountries.length === 0) return [];

    const validCountryCodes = getCountries();
    const filteredCountries: any[] = [];

    validCountries.forEach((code) => {
      /* @ts-ignore */
      if (validCountryCodes.includes(code)) {
        filteredCountries.push({
          label: (
            <div className="flex items-center gap-3">
              <div className="flex-1 w-5 h-5">
                <img
                  className="w-full h-full object-contain object-center"
                  src={`/img/flags/countries-flag/${code?.toLowerCase()}.svg`}
                  alt={code}
                />
              </div>
              <div className="flex items-center justify-between w-full gap-6 hideme">
                {/* @ts-ignore */}
                <span>{`${en[code]}`}</span>
                <span className="text-white text-opacity-50">
                  {/* @ts-ignore */}
                  {getCountryCallingCode(code)}
                </span>
              </div>
            </div>
          ),
          value: code,
        });
      }
    });
    return filteredCountries;
  }, [validCountries]);

  useEffect(() => {
    if (countryOptions && countryOptions.length > 0) {
      if (!mobilePrefixCountry)
        handlePhoneCountryChange(countryOptions?.find(item => item.value === payload.country) as CountriesOption);
      if (!payload.country)
        handleCountryChange({ value: countryOptions?.find(item => item.value === payload.country).value });
    }
  }, [countryOptions]);

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      lastName: event.target.value,
    });
    setLastNameErr("");
  };

  const handleLastNameBlur = () => {
    const lastNameValidationError = validatePersonalInformationLastName(
      payload.lastName,
      t
    );
    setLastNameErr(lastNameValidationError);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayload({
      ...payload,
      firstName: event.target.value,
    });
    setFirstNameErr("");
  };

  const handleFirstNameBlur = () => {
    const firstNameValidationError = validatePersonalInformationFirstName(
      payload.firstName,
      t
    );
    setFirstNameErr(firstNameValidationError);
  };

  const handleBirthDateChange = (value: Date) => {
    setPayload({
      ...payload,
      birthDate: value?.toISOString(),
    });
    setBirthErr("");
  };

  const handleBirthDateBlur = () => {
    const birthDateValidationError = validatePersonalInformationBirthdate(
      payload.birthDate || "",
      profileT
    );
    setBirthErr(birthDateValidationError);
  };

  const handlePhoneCountryChange = (newValue: CountriesOption) => {
    if (!newValue?.value) return;
    // @ts-ignore
    const mobilePrefixMain = getCountryCallingCode(newValue?.value);
    const mobilePrefix = "+" + mobilePrefixMain;
    setPayload((payload) => ({
      ...payload,
      mobile: {
        ...payload.mobile,
        prefix: mobilePrefix,
      },
    }));
    setMobilePrefixCountry(newValue?.value);
    setMobileNumber(mobilePrefix);
    validateMobileNumber(mobilePrefix);
  };

  const validateMobileNumber = (phoneNumber: string) => {
    if (!isValidated) {
      setIsValidated(true);
      return;
    }

    if (!payload.mobile.prefix) {
      setMobileNumberErr(t("phone-prefix-is-required"));
      return t("phone-prefix-is-required");
    }
    let mobileError = phoneNumber
      ? isValidPhoneNumber(phoneNumber)
        ? null
        : profileT("phone-number-is-invalid")
      : profileT("phone-number-is-mandatory");
    setMobileNumberErr(mobileError);

    if (!mobileError) debouncedMobileSearch(phoneNumber);

    return mobileError;
  };

  const handlePhoneChange = (value: string) => {
    setMobileNumber(value);
    validateMobileNumber(value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      city: event.target.value,
    });
    setCityErr("");
  };

  const handleCityBlur = () => {
    const cityValidationError =
      validatePersonalInformationCityWithNoSpecialChar(payload.city, profileT);
    setCityErr(cityValidationError);
  };

  const handlePostalCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayload({
      ...payload,
      postalCode: event.target.value,
    });
    setPostalCodeErr("");
  };

  const handlePostalCodeBlur = () => {
    const postalCodeValidationError =
      validatePersonalInformationPostalCodeWithNoSpecialChar(
        payload.postalCode,
        t
      );
    setPostalCodeErr(postalCodeValidationError);
  };

  const handleProvinceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      province: event.target.value,
    });
    setProvinceErr("");
  };

  const handleProvinceBlur = () => {
    const provinceValidationError =
      validatePersonalInformationProvinceWithNoSpecialChar(payload.province, t);
    setProvinceErr(provinceValidationError);
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      address: event.target.value,
    });
    setAddressErr("");
  };

  const handleAddressBlur = () => {
    const addressValidationError =
      validatePersonalInformationAddressWithNoSpecialChar(payload.address, t);
    setAddressErr(addressValidationError);
  };

  const handleCountryChange = (newValue: any) => {
    const detectedCurrency = (countryToCurrency as any)[
      newValue?.value?.toUpperCase() || ""
    ];

    newValue &&
      setPayload({
        ...payload,
        country: newValue?.value,
        currency: currencies?.includes(detectedCurrency)
          ? detectedCurrency
          : payload.currency,
      });
    setCountryErr("");
    setCurrencyErr("");
  };

  const handleCountryBlur = () => {
    const countryValidationError = validatePersonalInformationCountry(
      payload.country,
      t
    );
    setCountryErr(countryValidationError);
  };

  const handleCurrencyChange = (newValue: any) => {
    newValue &&
      setPayload({
        ...payload,
        currency: newValue?.value,
      });
    setCurrencyErr("");
  };

  const handleCurrencyBlur = () => {
    const currencyValidationError = validatePersonalInformationCurrency(
      payload.currency,
      t
    );
    setCurrencyErr(currencyValidationError);
  };

  const handleBackButtonClick = () => {
    dispatch(clearSignUpError());
    dispatch(signupEmailConfirmationClearError());
    dispatch(setActiveSignupScreen(signupScreens.ACCOUNT_INFORMATION));
  };

  const handleContinueButtonClick = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const validationError = validatePersonalInformationPayload(payload, t);
    if (validationError) return;

    const consentError = validateConsent(payload.userConsents);
    if (consentError.length > 0) {
      setConsentErr(consentError);
      return;
    }

    const mobileErr = validateMobileNumber(mobileNumber);
    if (mobileErr) return;

    const consentedVersions = [];
    const unConsentedVersions = [];
    for (const [key, value] of Object.entries(payload?.userConsents || {})) {
      if (value) consentedVersions.push(key);
      else unConsentedVersions.push(key);
    }

    dispatch(
      signupRequest({
        ...payload,
        mobileNumber,
        referralId: Storage.get(storageKeys.AFFILIATE_MARKER) as string,
        consentedVersions,
        unConsentedVersions,
      })
    );
  };

  const getErrorComponent = () => {
    if (!signupStatus) return null;

    const { success, message } = signupStatus;

    return (
      !success && <div className="text-xs text-center text-red">{message}</div>
    );
  };

  const handleConsentChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string | number
  ) => {
    const newPayload = {
      ...payload,
      userConsents: {
        ...payload.userConsents,
        [key]: event.target.checked,
      },
    };

    setPayload(newPayload);
    validateConsent(newPayload.userConsents);
  };

  const validateConsent = (
    userConsents: { [key: string]: boolean } | any
  ): string[] => {
    const consentList = requiredConsentList as IConsentItem[] | undefined;
    const invalidConsents: string[] = [];
    consentList &&
      consentList.forEach((consent) => {
        const { consentVersionId, isMandatory } = consent;
        if (isMandatory && !(userConsents as any)[consentVersionId]) {
          invalidConsents.push(consentVersionId);
        }
      });
    setConsentErr(invalidConsents);
    return invalidConsents;
  };

  const colorStyles: StylesConfig = {
    container: (baseStyles) => ({
      ...baseStyles,
      ":focus-visible": {
        borderColor: "none",
        boxShadow: "none",
        outline: "none",
      },
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      padding: "10px 16px",
      fontSize: "14px",
      lineHeight: "22px",
      border: "none",
      borderRadius: "8px",
      color: theme == "dark" ? "white" : "#131416",
      backgroundColor: theme == "dark" ? "#191a1d" : "#e9e9e9",
      width: "100%",
      ":focus": {
        borderColor: "#902bf5",
        boxShadow: "none",
      },
      ":hover": {
        border: "none",
      },
      ":focus-visible": {
        boxShadow: "none",
      },
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: theme == "dark" ? "white" : "#131416",
      fontWeight: "700",
      margin: "0",
      ":focus": {
        boxShadow: "none",
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      display: "none",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: theme == "dark" ? "#191a1d" : "#e9e9e9",
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      color: theme == "dark" ? "white" : "#131416",
      backgroundColor: theme == "dark" ? "#191a1d" : "#e9e9e9",
      ":hover": {
        cursor: "pointer",
        backgroundColor: theme == "dark" ? "#cc9dfa" : "#e9e9e9",
      },
    }),
  };

  function getInputStatus(
    inputValue: string,
    validationErr: string | null,
    remoteValidationErr?: { isAvailable: boolean } | null,
    remoteValidationPending?: boolean
  ) {
    if (validationErr) {
      return INPUT_STATUSES.ERROR;
    } else if (
      inputValue &&
      remoteValidationPending !== undefined &&
      !remoteValidationPending
    ) {
      if (!remoteValidationErr?.isAvailable) {
        return INPUT_STATUSES.ERROR;
      }

      return INPUT_STATUSES.VALID;
    } else {
      if (inputValue) {
        return INPUT_STATUSES.VALID;
      }
    }

    return INPUT_STATUSES.NOT_VALIDATED;
  }

  return (
    <div className="flex flex-col gap-6">
      <form onSubmit={handleContinueButtonClick}>
        <div className="flex flex-col items-center flex-1 w-full gap-4">
          <div className="grid grid-cols-2 gap-4 content-start">
            <div className="relative flex flex-col-reverse   w-full text-left max-w-none justify-end">
              {firstNameErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {firstNameErr}
                </div>
              )}
              <div className="relative w-full">
                <input
                  className={`form-input !text-base sm:!text-sm ${getInputStatus(
                    payload.firstName,
                    firstNameErr
                  )}`}
                  onChange={handleFirstNameChange}
                  onBlur={handleFirstNameBlur}
                  type="text"
                  id="input-first-name"
                  value={payload.firstName}
                  placeholder={t("first-name")}
                  disabled={signupPending}
                />
                <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                  {getInputStatus(payload.firstName, firstNameErr) ===
                    "valid" && (
                    <SVGIcon
                      icon="circle-valid"
                      className="w-6 h-5 text-green"
                    />
                  )}
                  {getInputStatus(payload.firstName, firstNameErr) ===
                    "error" && (
                    <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                  )}
                </div>
              </div>
            </div>

            <div className="relative flex flex-col-reverse   w-full text-left max-w-none justify-end">
              {lastNameErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {lastNameErr}
                </div>
              )}
              <div className="relative w-full">
                <input
                  className={`form-input !text-base sm:!text-sm ${getInputStatus(
                    payload.lastName,
                    lastNameErr
                  )}`}
                  onChange={handleLastNameChange}
                  onBlur={handleLastNameBlur}
                  type="text"
                  id="input-last-name"
                  value={payload.lastName}
                  placeholder={t("last-name")}
                  disabled={signupPending}
                />
                <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                  {getInputStatus(payload.lastName, lastNameErr) ===
                    "valid" && (
                    <SVGIcon
                      icon="circle-valid"
                      className="w-6 h-5 text-green"
                    />
                  )}
                  {getInputStatus(payload.lastName, lastNameErr) ===
                    "error" && (
                    <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {birthErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {birthErr}
              </div>
            )}
            <div className="relative w-full birthdate-picker-group !mt-0">
              <DatePicker
                wrapperClassName="block w-full"
                placeholderText={t("date-of-birth")}
                className="w-full block px-4 text-sm font-normal leading-6 md:leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white focus:border-primary-500 focus:shadow-none focus:ring-0 placeholder:text-tertiary placeholder:font-bold"
                onChange={handleBirthDateChange}
                onBlur={handleBirthDateBlur}
                maxDate={maxDate}
                yearDropdownItemNumber={100}
                showYearDropdown
                scrollableYearDropdown
                selected={
                  payload?.birthDate ? new Date(payload.birthDate) : null
                }
                onSelect={handleBirthDateChange}
                disabled={signupPending}
              />
              <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                <SVGIcon
                  className="w-4 h-4 m-auto text-tertiary/50 dark:text-white/50 dark:text-white/50"
                  icon={"calendar"}
                />
                {getInputStatus(payload.birthDate, birthErr) === "valid" && (
                  <SVGIcon icon="circle-valid" className="w-6 h-5 text-green" />
                )}
                {getInputStatus(payload.birthDate, birthErr) === "error" && (
                  <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                )}
              </div>
            </div>
            {/* <label
            htmlFor="input-date-of-birth"
            className="uppercase mt-1.5 text-xs text-tertiary-300"
          >
            {DEFAULT_DATE_FORMAT}
          </label> */}
          </div>

          <div className="relative w-full">
            <div className="relative flex w-full gap-3 flex-nowrap prefix-dropdown">
              <Select
                className="dropdown-select !max-w-[70px] !static registration-phone-input-country"
                classNamePrefix="select"
                menuPlacement="auto"
                minMenuHeight={500}
                menuShouldBlockScroll={true}
                options={countryOptions}
                value={
                  countryOptions &&
                  countryOptions.find(
                    ({ value }) => value === mobilePrefixCountry
                  )
                }
                isDisabled={isLoadingValidCountries || signupPending}
                isSearchable={false}
                placeholder={"+"}
                inputId="input-prefix"
                noOptionsMessage={() => t("no-available-country")}
                onChange={(option) =>
                  handlePhoneCountryChange({
                    value: option?.value,
                  } as CountriesOption)
                }
              />
              <PhoneInput
                className="w-full phone-number-input registration-phone-input"
                placeholder={profileT("enter-phone")}
                value={mobileNumber}
                onCountryChange={(country) => {
                  handlePhoneCountryChange({
                    value: country,
                  } as CountriesOption);
                }}
                addInternationalOption={false}
                onChange={handlePhoneChange}
                disabled={signupPending}
                //@ts-ignore
                countries={isLoadingValidCountries ? [] : validCountries}
              />
              {checkMobileCredentialPending && (
                <Image
                  src="/img/icons/loading-dots.svg"
                  width={40}
                  height={30}
                  alt="spinner"
                  className="absolute top-1 right-3"
                  loading="lazy"
                />
              )}
            </div>
            {(mobileNumberErr || apiMobileNumberErr) && (
              <div className="m-0 mt-0.5 text-xs font-medium text-red/70">
                {mobileNumberErr || apiMobileNumberErr}
              </div>
            )}
          </div>

          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {countryErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {countryErr}
              </div>
            )}
            <div className="relative w-full dropdown-select">
              <Select
                className="w-full "
                classNamePrefix="select"
                options={validCountries?.map((country) => ({
                  value: country,
                  label: en[country],
                }))}
                inputId="input-country"
                isSearchable={false}
                placeholder={
                  isLoadingValidCountries
                    ? generalT("loading")
                    : generalT("country")
                }
                isDisabled={signupPending}
                isLoading={isLoadingValidCountries}
                onChange={handleCountryChange}
                onBlur={handleCountryBlur}
                styles={colorStyles}
                value={validCountries
                  ?.map((country) => ({
                    value: country,
                    label: en[country],
                  }))
                  ?.find((country) => country.value === payload.country)}
              />
            </div>
          </div>

          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {currencyErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {currencyErr}
              </div>
            )}
            <div className="relative w-full dropdown-select">
              <Select
                className="rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:placeholder:text-white/50 focus:shadow-none focus:ring-0 placeholder:font-bold"
                classNamePrefix="select"
                options={currencies?.map((currency) => ({
                  value: currency,
                  label: currency,
                }))}
                inputId="input-currency"
                isSearchable={false}
                placeholder={
                  isLoadingCurrencies
                    ? generalT("loading")
                    : bettingHistoryT("currency")
                }
                onChange={handleCurrencyChange}
                onBlur={handleCurrencyBlur}
                styles={colorStyles}
                isDisabled={signupPending}
                isLoading={isLoadingCurrencies}
                value={currencies
                  ?.map((currency) => ({ value: currency, label: currency }))
                  ?.find((currency) => currency.value === payload.currency)}
              />
            </div>
          </div>

          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {postalCodeErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {postalCodeErr}
              </div>
            )}
            <div className="relative w-full">
              <input
                className={`form-input !text-base sm:!text-sm ${getInputStatus(
                  payload.postalCode,
                  postalCodeErr
                )}`}
                onChange={handlePostalCodeChange}
                onBlur={handlePostalCodeBlur}
                type="text"
                id="input-postal-code"
                value={payload.postalCode}
                placeholder={profileT("postalcode")}
                disabled={signupPending}
              />
              <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                {getInputStatus(payload.postalCode, postalCodeErr) ===
                  "valid" && (
                  <SVGIcon icon="circle-valid" className="w-6 h-5 text-green" />
                )}
                {getInputStatus(payload.postalCode, postalCodeErr) ===
                  "error" && (
                  <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                )}
              </div>
            </div>
          </div>

          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {addressErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {addressErr}
              </div>
            )}
            <div className="relative w-full">
              <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                {getInputStatus(payload.address, addressErr) === "valid" && (
                  <SVGIcon icon="circle-valid" className="w-6 h-5 text-green" />
                )}
                {getInputStatus(payload.address, addressErr) === "error" && (
                  <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                )}
              </div>
              <input
                className={`w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary/50 border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0 ${getInputStatus(
                  payload.address,
                  addressErr
                )}`}
                onChange={handleAddressChange}
                onBlur={handleAddressBlur}
                type="text"
                id="input-address"
                value={payload.address}
                placeholder={t("address")}
                disabled={signupPending}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 content-start">
            <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none justify-end">
              {provinceErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {provinceErr}
                </div>
              )}
              <div className="relative w-full">
                <input
                  className={`form-input !text-base sm:!text-sm ${getInputStatus(
                    payload.province,
                    provinceErr
                  )}`}
                  onChange={handleProvinceChange}
                  onBlur={handleProvinceBlur}
                  type="text"
                  id="input-province"
                  value={payload.province}
                  placeholder={generalT("province")}
                  disabled={signupPending}
                />
                <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                  {getInputStatus(payload.province, provinceErr) ===
                    "valid" && (
                    <SVGIcon
                      icon="circle-valid"
                      className="w-6 h-5 text-green"
                    />
                  )}
                  {getInputStatus(payload.province, provinceErr) ===
                    "error" && (
                    <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                  )}
                </div>
              </div>
            </div>

            <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none justify-end">
              {cityErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {cityErr}
                </div>
              )}
              <div className="relative w-full">
                <input
                  className={`form-input !text-base sm:!text-sm ${getInputStatus(
                    payload.city,
                    cityErr
                  )}`}
                  onChange={handleCityChange}
                  onBlur={handleCityBlur}
                  type="text"
                  id="input-city"
                  value={payload.city}
                  placeholder={generalT("city")}
                  disabled={signupPending}
                />
                <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                  {getInputStatus(payload.city, cityErr) === "valid" && (
                    <SVGIcon
                      icon="circle-valid"
                      className="w-6 h-5 text-green"
                    />
                  )}
                  {getInputStatus(payload.city, cityErr) === "error" && (
                    <SVGIcon icon="circle-error" className="w-6 h-5 text-red" />
                  )}
                </div>
              </div>
            </div>
          </div>

          {consents?.map((item: IConsentItem) => {
            return (
              <div
                className="flex gap-2"
                key={`registrationConsents_${item.consentVersionId}`}
              >
                <input
                  type="checkbox"
                  id={item.consentVersionId}
                  checked={
                    payload.userConsents &&
                    (payload.userConsents as any)[item.consentVersionId]
                  }
                  className={classNames("form-checkbox", {
                    "border-red dark:border-red": consentErr.includes(
                      item.consentVersionId as never
                    ),
                  })}
                  required={item.isMandatory}
                  onChange={(e) =>
                    handleConsentChange(e, item.consentVersionId)
                  }
                  disabled={signupPending}
                />
                <label
                  htmlFor={item.key}
                  className="text-xs font-normal cursor-pointer text-tertiary dark:text-white"
                >
                  {consentsT.rich(`consent-${item?.key}`, {
                    privacyPolicy: (chunks) => (
                      <a
                        href="/support/privacy-policy"
                        target="_blank"
                        className="underline"
                      >
                        {chunks}
                      </a>
                    ),
                    termsAndConditions: (chunks) => (
                      <a
                        href="/support/terms-and-conditions"
                        target="_blank"
                        className="underline"
                      >
                        {chunks}
                      </a>
                    ),
                  })}
                </label>
              </div>
            );
          })}

          {getErrorComponent()}
        </div>

        <div className="">
          <div className="flex flex-col items-center gap-5 mt-5">
            <Button
              className="min-w-[230px] w-full"
              type="primary"
              size="lg"
              buttonType="submit"
              disabled={
                signupPending ||
                isLoadingConsentList ||
                !consents ||
                hasEmptyFields ||
                hasValidationError ||
                checkMobileCredentialPending
              }
              label={t("save-cta-btn")}
              loading={signupPending}
            />
            <button
              className="text-xs font-bold text-pink/50"
              onClick={handleBackButtonClick}
              disabled={signupPending}
            >
              {generalT("goback")}
            </button>
          </div>
          <div className="flex gap-2 mt-5">
            <p className="text-xs font-normal text-tertiary dark:text-white">
              {t("do-already-have-account")}{" "}
              <a
                className=" text-primary-500 font-bold"
                onClick={() => {
                  handleLoginClick();
                  click(t("login"), {
                    text: t("login"),
                    from: "RegisterModal",
                    component: "AccountinformationScreen",
                  });
                }}
              >
                {t("login")}
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonalInformationScreen;

import { BannerActions, bannerActionTypes } from "@/fe-core/meta/types/banner";
import { BannerState } from "@/fe-core/meta/types/banner";

const initialState: BannerState = {
  pending: false,
  banners: null,
};

const bannerReducer = (
  state: BannerState = initialState,
  action: BannerActions
): BannerState => {
  switch (action.type) {
    case bannerActionTypes.GET_BANNERS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case bannerActionTypes.GET_BANNERS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case bannerActionTypes.GET_BANNERS_SUCCESS:
      return {
        ...state,
        pending: false,
        banners: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default bannerReducer;

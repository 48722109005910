import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import {
  NOT_LOGGED_IN_ERROR_STATUS,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import {
  IGetWithdrawMethodsResponse,
  IProcessWithdrawResponsePayload,
} from "@/fe-core/meta/interfaces/user";
import {
  GetWithdrawMethodsRequestAction,
  ProcessWithdrawRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  getBalanceRequest,
  getWithdrawMethodsFailure,
  getWithdrawMethodsSuccess,
  processWithdrawFailure,
  processWithdrawSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { clearState } from "@/redux/actions/rootActions";
import { methodTypes } from "@/fe-core/meta/types/root";

function* getWithdrawMethodsRequestSaga({ }: GetWithdrawMethodsRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/withdraw`,
      <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId
        }),
      }
    );

    const viewModel: IGetWithdrawMethodsResponse = yield response.json();
    if (response.status == 200) {
      yield put(getWithdrawMethodsSuccess(viewModel));
    } else {
      // @ts-ignore
      if (response.status === 401 || (response.status === 400 && viewModel?.status === NOT_LOGGED_IN_ERROR_STATUS)) {
        yield put(clearState());
      }
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getWithdrawMethodsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getWithdrawMethodsFailure(parseFrontendError(error)));
  }
}

function* processWithdrawRequestSaga({ payload }: ProcessWithdrawRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      payload?.paymentMethod === "PRAXIS_ALL"
        ? `/api/user/cashier/prepareCashier`
        : `/api/user/cashier/withdraw/processWithdraw`,
      <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload),
      }
    );

    const { status } = response;
    const responsePayload: IProcessWithdrawResponsePayload | IErrorPayload =
      yield response.json();

    const viewModel = responsePayload as IProcessWithdrawResponsePayload;

    if (status == 200) {
      yield put(processWithdrawSuccess(viewModel));
      yield put(getBalanceRequest());
    } else {
      if (
        status === 401 ||
        (status === 400 && viewModel?.status === NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }

      const errorPayload = responsePayload as IErrorPayload;
      yield put(processWithdrawFailure(errorPayload));
    }
  } catch (error) {
    yield put(processWithdrawFailure(parseFrontendError(error)));
  }
}

function* withdrawSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([takeLatest(userActionTypes.GET_WITHDRAW_METHODS_REQUEST, getWithdrawMethodsRequestSaga),
  takeLatest(
    userActionTypes.PROCESS_WITHDRAW_REQUEST,
    processWithdrawRequestSaga
  ),]);
}

export default withdrawSaga;

import rootReducer from "@/redux/reducers/rootReducer";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { OMEGA_STATUS_TYPES } from "@/fe-core/helpers/general";

export enum rootActionTypes {
  CLEAR_STATE = "CLEAR_STATE",
}

export type ClearStateAction = {
  type: rootActionTypes.CLEAR_STATE;
};

export type RootActions = ClearStateAction;

export type RootState = ReturnType<typeof rootReducer>;

export enum methodTypes {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type StatusState = {
  pending: boolean;
  status: IStatus | null;
};

export type OmegaStatusType = typeof OMEGA_STATUS_TYPES[keyof typeof OMEGA_STATUS_TYPES];
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { gamesExpand } from "@/config/casino";
import { headerKeys } from "@/config/general";
import {
  getBottomPromotionFailure,
  getBottomPromotionSuccess,
  getPromotionGamesFailure,
  getPromotionGamesSuccess,
  getPromotionsFailure,
  getPromotionsSuccess,
} from "@/fe-core/_redux/actions/promotionsActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { buildCasinoQueryString } from "@/fe-core/helpers/casino";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  buildBottomPromotionQueryString,
  buildPromotionsQueryString,
} from "@/fe-core/helpers/promotions";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { ICasinoGamesPayload } from "@/fe-core/meta/interfaces/casino";
import {
  IBottomPromotionsResponsePayload,
  IPromotionsViewModel,
} from "@/fe-core/meta/interfaces/promotions";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";
import {
  GetPromotionGamesRequestAction,
  GetPromotionsRequestAction,
  promotionsActionTypes,
} from "@/fe-core/meta/types/promotions";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { isUserAuthenticatedSelector } from "../selectors/sessionSelectors";

function* getPromotionsSaga({ payload }: GetPromotionsRequestAction) {
  try {
    const { userRoles = [], userBonuses = [] } = payload || {};
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);
    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);
    const isIdentified = localStorage.getItem(localStorageKeys.IDENTIFIED);
    const isAuthenticated: boolean = yield select(isUserAuthenticatedSelector);

    const queryString: string = buildPromotionsQueryString(
      theme,
      userRoles,
      userBonuses,
      btag ? [btag] : [],
      isAuthenticated ? "1" : "0",
      isIdentified ? "1" : "0"
    );

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/promotions${queryString}`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        const viewModel: IPromotionsViewModel = yield response.json();
        yield put(getPromotionsSuccess(viewModel));
        break;
      default:
        throw new Error("Promotions couldn't be fetched");
    }
  } catch (error) {
    yield put(getPromotionsFailure(parseFrontendError(error)));
  }
}

function* getPromotionGamesSaga({ payload }: GetPromotionGamesRequestAction) {
  try {
    const filters = [
      `${
        payload.platform === "PC"
          ? "supportsDesktop=true"
          : "supportsMobile=true"
      }`,
    ];

    if (payload?.gameIds?.length && payload?.gameIds?.length > 0) {
      payload?.gameIds?.forEach((id) => filters.push(`gameId=${id}`));
    }

    const queryString: string = buildCasinoQueryString({
      expand: gamesExpand,
      filters,
    });

    const response: IJsonResponse<ICasinoGamesPayload> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/games${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;

    switch (status) {
      case 200:
        const viewModel: ICasinoGamesPayload = yield response.json();
        yield put(
          getPromotionGamesSuccess({
            games:
              viewModel?.items?.filter((game) =>
                payload?.gameIds?.includes(game?.gameId)
              ) || [],
            promotion: payload?.promotion,
          })
        );
        break;
      default:
        throw new Error("A problem has occurred while retrieving games");
    }
  } catch (error) {
    yield put(getPromotionGamesFailure(payload?.promotion as string));
  }
}

function* getBottomPromotionsSaga({ payload }: GetPromotionsRequestAction) {
  try {
    const { locale } = yield select(localeSelector);
    const isAuthenticated: boolean = yield select(isUserAuthenticatedSelector);
    const isIdentified =
      localStorage.getItem(localStorageKeys.IDENTIFIED) || "0";

    const queryString: string = buildBottomPromotionQueryString(
      isAuthenticated ? "1" : "0",
      isIdentified ? "1" : "0"
    );
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/promotion-bottom${queryString}`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        const viewModel: IBottomPromotionsResponsePayload =
          yield response.json();

        yield put(getBottomPromotionSuccess(viewModel));
        break;
      default:
        throw new Error("Promotions couldn't be fetched");
    }
  } catch (error) {
    yield put(getBottomPromotionFailure(parseFrontendError(error)));
  }
}

function* promotionsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(promotionsActionTypes.GET_PROMOTIONS_REQUEST, getPromotionsSaga),
    takeLatest(
      promotionsActionTypes.GET_PROMOTION_GAMES_REQUEST,
      getPromotionGamesSaga
    ),
    takeLatest(
      promotionsActionTypes.GET_BOTTOM_PROMOTIONS_REQUEST,
      getBottomPromotionsSaga
    ),
  ]);
}
//@todo fetch again on toggle theme
export default promotionsSaga;

import { IErrorPayload } from '../interfaces/root'
import { IPopUpsData, IPopUpsViewModel } from '../interfaces/popUps'

export enum popUpsActionTypes {
  GET_POPUPS_REQUEST = 'GET_POPUPS_REQUEST',
  GET_POPUPS_SUCCESS = 'GET_POPUPS_SUCCESS',
  GET_POPUPS_FAILURE = 'GET_POPUPS_FAILURE',
}

export type GetPopUpsRequestAction = {
  type: popUpsActionTypes.GET_POPUPS_REQUEST
}

export type GetPopUpsSuccessAction = {
  type: popUpsActionTypes.GET_POPUPS_SUCCESS
  payload: IPopUpsViewModel
}

export type GetPopUpsFailureAction = {
  type: popUpsActionTypes.GET_POPUPS_FAILURE
  payload: IErrorPayload
}

export type PopUpsActions =
  | GetPopUpsRequestAction
  | GetPopUpsSuccessAction
  | GetPopUpsFailureAction

export type PopUpsState = {
  pending: boolean
  popUps: IPopUpsData | null
}

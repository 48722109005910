import { call, take, put, all, takeLatest, select } from "redux-saga/effects";
import { eventChannel, buffers, EventChannel } from "redux-saga";
import { Action } from "redux";
import { Result, Session, Error as AutoBahnError } from "autobahn";

import {
  getSocket,
  doEMCall,
  setCid,
  clearCid,
  doEMSubscription,
} from "@/fe-core/helpers/socket";
import Storage from "@/fe-core/helpers/storage";
import {
  socketActionTypes,
  SocketIdentityPayload,
  SocketSessionStatePayload,
} from "@/fe-core/meta/types/socket";
import {
  initializeSocket,
  socketConnectionFailed,
} from "@/fe-core/_redux/actions/socketActions";
import {
  socketSessionSelector,
  socketSessionConnectionAttemptsSelector,
} from "@/fe-core/_redux/selectors/socketSelectors";
import { SOCKET_CONNECTION_MAX_ATTEMPTS, storageKeys } from "@/config/general";
import { authenticateRequest } from "@/fe-core/_redux/actions/sessionActions";
//@ts-ignore
function startSocket(): EventChannel<unknown> {
  const buffer = buffers.expanding();

  return eventChannel((emitter) => {
    const socket = getSocket();

    socket.onopen = (session) => {
      return emitter({
        type: socketActionTypes.SOCKET_CONNECTED,
        payload: session,
      });
    };

    socket.onclose = () => {
      // try to reconnect for SOCKET_CONNECTION_MAX_ATTEMPTS times
      emitter({ type: socketActionTypes.RECONNECT_SOCKET });

      return true;
    };

    if (!socket.isConnected) {
      socket.open();
    }

    // unsubscribe function
    return () => {
      socket.close();
    };
    //@ts-ignore
  }, buffer);
}

function* socketConnectedSaga() {
  const cid = Storage.get(storageKeys.CID) as string;

  if (!cid) {
    const socketSession: Session = yield select(socketSessionSelector);

    const json: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/connection#getClientIdentity",
      {}
    );

    const identityResponse: SocketIdentityPayload = json.kwargs;
    const reqCid = identityResponse.cid;

    yield setCid(reqCid);
    yield put(initializeSocket());
  } else {
    //yield put(authenticateRequest({ sessionId: "", isAutoLogin: true }));
  }
}

function* initializeSocketSaga() {
  //@ts-ignore
  const channel: EventChannel<unknown> = yield call(startSocket);

  while (true) {
    const action: Action = yield take(channel);
    yield put(action);
  }
}

function* reconnectSocketSaga() {
  const sessionConnectionAttempts: number = yield select(
    socketSessionConnectionAttemptsSelector
  );

  yield clearCid();

  if (sessionConnectionAttempts > SOCKET_CONNECTION_MAX_ATTEMPTS) {
    yield put(socketConnectionFailed());
  } else {
    yield put(initializeSocket());
  }
}

function* sessionStateSaga() {
  const socketSession: Session = yield select(socketSessionSelector);
  const buffer = buffers.expanding();
  const channel = eventChannel((emitter) => {
    doEMSubscription(socketSession, "/sessionStateChange", (data) => {
      const socketSessionStatePayload = data as SocketSessionStatePayload;
      const { code } = socketSessionStatePayload;

      // https://everymatrix.atlassian.net/wiki/spaces/WA1/pages/153976891/Session+State+Change
      return emitter({
        type: socketActionTypes.SESSION_STATE_CHANGED,
        payload: code,
      });
    });

    return () => {
      return true;
    };
    //@ts-ignore
  }, buffer);

  while (true) {
    const action: Action = yield take(channel);
    yield put(action);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* socketSaga() {
  yield all([
    takeLatest(socketActionTypes.INITIALIZE_SOCKET, initializeSocketSaga),
    takeLatest(socketActionTypes.SOCKET_CONNECTED, socketConnectedSaga),
    takeLatest(socketActionTypes.RECONNECT_SOCKET, reconnectSocketSaga),
    takeLatest(socketActionTypes.SUBSCRIBE_SESSION_STATE, sessionStateSaga),
  ]);
}

import { ICMSJackpotWinnerViewModel } from '../interfaces/jackpotWinners'
import { IErrorPayload, IStatus } from '../interfaces/root'

export enum jackpotWinnersActionTypes {
  GET_JACKPOT_WINNERS_REQUEST = 'GET_JACKPOT_WINNERS_REQUEST',
  GET_JACKPOT_WINNERS_SUCCESS = 'GET_JACKPOT_WINNERS_SUCCESS',
  GET_JACKPOT_WINNERS_FAILURE = 'GET_JACKPOT_WINNERS_FAILURE',
}

export type GetJackpotWinnersRequestAction = {
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_REQUEST
}

export type GetJackpotWinnersSuccessAction = {
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_SUCCESS
  payload: ICMSJackpotWinnerViewModel[]
}

export type GetJackpotWinnersFailureAction = {
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_FAILURE
  payload: IErrorPayload
}

export type JackpotWinnersActions =
  | GetJackpotWinnersRequestAction
  | GetJackpotWinnersSuccessAction
  | GetJackpotWinnersFailureAction

export type JackpotWinnersState = {
  jackpotWinners: ICMSJackpotWinnerViewModel[]
  pending: boolean
  status: IStatus | null
}

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  ProcessWithdrawActions,
  ProcessWithdrawState,
} from "@/fe-core/meta/types/user/cashier/processWithdraw";

const initialState: ProcessWithdrawState = {
  pending: false,
  paymentMethod: null,
  status: null,
};

const processWithdrawReducer = (
  state: ProcessWithdrawState = initialState,
  action: ProcessWithdrawActions | RootActions
): ProcessWithdrawState => {
  switch (action.type) {
    case userActionTypes.OPEN_PROCESS_WITHDRAW_MODAL:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case userActionTypes.CLOSE_PROCESS_WITHDRAW_MODAL: {
      return {
        ...state,
        paymentMethod: null,
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST: {
      return {
        ...state,
        pending: true,
        status: null,
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
        ...action.payload
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST_FAILURE: {
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message: action.payload.message,
        },
      };
    }
    case userActionTypes.CLEAR_PROCESS_WITHDRAW:
    case rootActionTypes.CLEAR_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default processWithdrawReducer;

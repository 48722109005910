import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { popUpsActionTypes } from "@/fe-core/meta/types/popups";
import { IPopUpsViewModel } from "@/fe-core/meta/interfaces/popUps";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getPopUpsFailure,
  getPopUpsSuccess,
} from "@/fe-core/_redux/actions/popUpsActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildPopUpsQueryString } from "@/fe-core/helpers/popUps";
import { methodTypes } from "@/fe-core/meta/types/root";
// import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getPopUpsSaga() {
  try {
    // const { theme, locale } = yield select(localeSelector);
    const queryString: string = buildPopUpsQueryString([], []);

    const response: IJsonResponse<IPopUpsViewModel[]> = yield fetch(
      `/api/popups${queryString}`,
      {
        method: methodTypes.GET,
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPopUpsViewModel = yield response.json();
        yield put(getPopUpsSuccess(viewModel));
        break;
      default:
        throw new Error("PopUp couldn't be fetched");
    }
  } catch (error) {
    yield put(getPopUpsFailure(parseFrontendError(error)));
  }
}

function* popUpsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([takeLatest(popUpsActionTypes.GET_POPUPS_REQUEST, getPopUpsSaga)]);
}

export default popUpsSaga;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  UserActions,
  PendingWithdrawalsState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: PendingWithdrawalsState = {
  pending: false,
  data: null,
  status: null,
  cancelWithdrawalPending: false,
  cancelWithdrawalData: null,
  cancelWithdrawalStatus: null,
};

const pendingWithdrawalsReducer = (
  state: PendingWithdrawalsState = initialState,
  action: UserActions | RootActions
): PendingWithdrawalsState => {
  switch (action.type) {
    case userActionTypes.PENDING_WITHDRAWALS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.PENDING_WITHDRAWALS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.PENDING_WITHDRAWALS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    case userActionTypes.CANCEL_PENDING_WITHDRAWAL_REQUEST:
      return {
        ...state,
        cancelWithdrawalPending: true,
        cancelWithdrawalStatus: null,
      };
    case userActionTypes.CANCEL_PENDING_WITHDRAWAL_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        cancelWithdrawalPending: false,
        cancelWithdrawalData: payload,
        cancelWithdrawalStatus: {
          success: true,
        },
      };
    }
    case userActionTypes.CANCEL_PENDING_WITHDRAWAL_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        cancelWithdrawalPending: false,
        cancelWithdrawalStatus: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CLEAR_PENDING_WITHDRAWALS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default pendingWithdrawalsReducer;

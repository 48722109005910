import {
  PopUpsActions,
  PopUpsState,
  popUpsActionTypes,
} from "@/fe-core/meta/types/popups";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: PopUpsState = {
  pending: false,
  popUps: null,
};

const popUpsReducer = (
  state: PopUpsState = initialState,
  action: PopUpsActions | RootActions
): PopUpsState => {
  switch (action.type) {
    case popUpsActionTypes.GET_POPUPS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case popUpsActionTypes.GET_POPUPS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case popUpsActionTypes.GET_POPUPS_SUCCESS:
      return {
        ...state,
        pending: false,
        popUps: action.payload.data,
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default popUpsReducer;

import { FC, useEffect, useMemo, useState } from "react";
import { setLoginMenuScreen } from "../../../fe-core-omega/_redux/actions/menusActions";
import { loginMenuScreens } from "../../../fe-core-omega/meta/types/menus";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../../SVGIcon";
import Button from "@/components/Button";
import useTracking from "@/components/Hooks/useTracking";
import {
  validateAccountInformationConfirmPassword,
  validateAccountInformationPassword,
} from "@/fe-core/helpers/general";
import {
  userPasswordRuleSelector,
  userResetPasswordPendingSelector,
  userResetPasswordSendEmailStatusSelector,
  userResetPasswordStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import { IPasswordPolicyViewModel } from "@/fe-core/meta/interfaces/user";
import { INPUT_STATUSES } from "@/config/general";
import {
  resetPasswordRequest,
  resetPasswordSendEmailRequest,
} from "@/fe-core/_redux/actions/userActions";
import ResendTimer from "./ResendTimer";
import { toast } from "react-toastify";
interface Props {
  email: string;
}

const PasswordConfirmationScreen: FC<Props> = ({ email }) => {
  const { click } = useTracking();
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const forgotPasswordT = useTranslations(I18N_NAMESPACE.FORGOT_PASSWORD);
  const resetPasswordT = useTranslations(I18N_NAMESPACE.RESET_PASSWORD);

  const [otp, setOtp] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [otpErr, setOtpErr] = useState<string | null>("");
  const [passwordErr, setPasswordErr] = useState<string | null>("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<string | null>(
    ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { regularExpression }: IPasswordPolicyViewModel = useSelector(
    userPasswordRuleSelector
  );
  const resetPasswordStatus = useSelector(userResetPasswordStatusSelector);
  const resetPasswordPending = useSelector(userResetPasswordPendingSelector);
  const resendEmailStatus = useSelector(
    userResetPasswordSendEmailStatusSelector
  );

  const hasEmptyFields = useMemo(
    () => !(otp && confirmPassword && confirmPassword),
    [otp, confirmPassword, confirmPassword]
  );

  const hasValidationError = useMemo(() => {
    return !!otpErr || !!passwordErr || !!confirmPasswordErr;
  }, [otpErr, passwordErr, confirmPasswordErr]);

  useEffect(() => {
    if (
      resetPasswordStatus &&
      !resetPasswordPending &&
      resetPasswordStatus.success
    )
      toast.success(resetPasswordStatus.message);
  }, [resetPasswordStatus, resetPasswordPending]);

  useEffect(() => {
    if (resendEmailStatus && resendEmailStatus.success) {
      toast.success(generalT("otp-sent"));
    }
  }, [resendEmailStatus]);

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
    setOtpErr(event.target.value?.length !== 4 ? registerT("invalid-otp") : "");
  };

  const handleVerifyClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordValidationError = handlePasswordBlur();
    const confirmPasswordValidationError = handleConfirmPasswordBlur();
    if (passwordValidationError || confirmPasswordValidationError || otpErr)
      return;

    dispatch(
      resetPasswordRequest({
        resetPasswordKey: otp,
        newPassword: password,
        email,
      })
    );
    click(`reset password`, {
      buttonLabel: forgotPasswordT("reset-password"),
      component: "PasswordConfirmationScreen",
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value.split(" ").join(""));
    setPasswordErr("");
  };

  const handlePasswordBlur = () => {
    const passwordValidationError = validateAccountInformationPassword(
      password,
      regularExpression,
      registerT
    );
    setPasswordErr(passwordValidationError);
    return passwordValidationError;
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value.split(" ").join(""));
    setConfirmPasswordErr("");
  };

  const handleConfirmPasswordBlur = () => {
    const confirmPasswordValidationError =
      validateAccountInformationConfirmPassword(
        password,
        confirmPassword,
        regularExpression,
        registerT
      );
    setConfirmPasswordErr(confirmPasswordValidationError);
    return confirmPasswordValidationError;
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onResend = () => {
    dispatch(resetPasswordSendEmailRequest({ email }));
  };

  const getErrorComponent = () => {
    if (!resetPasswordStatus) return null;

    const { success, message } = resetPasswordStatus;
    return (
      !success && (
        <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500 text-center">
          {message || resetPasswordT("issue-with-password-reset")}
        </div>
      )
    );
  };

  const verifyPassword = () => {
    const letterRegex = /[a-zA-Z]/;
    const numberRegex = /\d/;

    const hasLetter = letterRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasLength = password.length > 7;

    return (
      <>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasLength ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasLength
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {registerT("password-validation-min-8-char")}
        </div>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasLetter ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasLetter
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {registerT("password-validation-min-1-letter")}
        </div>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasNumber ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasNumber
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {registerT("password-validation-min-1-number")}
        </div>
      </>
    );
  };

  function getInputStatus(inputValue: string, validationErr: string | null) {
    if (validationErr) {
      return INPUT_STATUSES.ERROR;
    } else {
      if (inputValue) {
        return INPUT_STATUSES.VALID;
      }
    }
    return INPUT_STATUSES.NOT_VALIDATED;
  }

  return (
    <div className="relative flex flex-col items-center gap-5 p-0 pt-16 text-center bg-white sm:pt-8 dark:bg-tertiary-600">
      <h5 className="text-base font-bold dark:text-white">
        {t("check-your-email")}
      </h5>

      <SVGIcon
        icon="emailSent"
        className="block w-20 h-20 text-primary-600 dark:text-primary-200"
      />
      <p className="text-sm md:max-w-[80%] dark:text-white">
        {t("sent-password-mail")}
      </p>

      <form className="space-y-4" onSubmit={handleVerifyClick}>
        <div className="relative w-full ">
          <input
            className={`w-full px-4 text-2xl font-bold leading-5.5 py-2.5 border rounded text-tertiary/50 border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0 tracking-widest text-center`}
            onChange={handleOtpChange}
            type="number"
            id="input-otp"
            value={otp}
            placeholder={"_ _ _ _"}
            disabled={resetPasswordPending}
          />
          {otpErr && (
            <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
              {otpErr}
            </div>
          )}
        </div>

        <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
          <div className="flex items-center gap-1 lg:gap-2 mt-1 text-[11px] lg:text-xs flex-wrap">
            {verifyPassword()}
          </div>
          <div className="relative w-full">
            <input
              className={`form-input ${getInputStatus(password, passwordErr)}`}
              required
              onChange={handlePasswordChange}
              onBlur={handlePasswordBlur}
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={password}
              autoComplete="new-password"
              placeholder={registerT("password")}
              disabled={resetPasswordPending}
            />
            <button
              type="button"
              className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-1 w-10 h-10"
              onClick={toggleShowPassword}
            >
              <SVGIcon
                className="w-4 h-4 m-auto text-tertiary/50 dark:text-white/50"
                icon={!showPassword ? "eye" : "blind"}
              />
            </button>
          </div>
        </div>
        <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
          {confirmPasswordErr && (
            <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
              {confirmPasswordErr}
            </div>
          )}
          <div className="relative w-full">
            <input
              className={`form-input ${getInputStatus(
                confirmPassword,
                confirmPasswordErr
              )}`}
              required
              onChange={handleConfirmPasswordChange}
              onBlur={handleConfirmPasswordBlur}
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              placeholder={registerT("confirm-password")}
              disabled={resetPasswordPending}
            />
            <button
              type="button"
              className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-1 w-10 h-10"
              onClick={toggleShowConfirmPassword}
            >
              <SVGIcon
                className="w-4 h-4 m-auto text-tertiary/50 dark:text-white/50"
                icon={!showConfirmPassword ? "eye" : "blind"}
              />
            </button>
          </div>
        </div>

        {getErrorComponent()}

        <Button
          buttonType="submit"
          label={forgotPasswordT("reset-password")}
          trackParams={{
            component: "PasswordConfirmationScreen",
          }}
          disabled={
            hasEmptyFields || hasValidationError || resetPasswordPending
          }
          loading={resetPasswordPending}
        />

        <ResendTimer onResend={onResend} />

        {/* <Button
          type="primary"
          label={t("back-to-login")}
          onClick={() => dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN))}
          trackParams={{
            component: "PasswordConfirmationScreen",
          }}
        /> */}
      </form>
    </div>
  );
};

export default PasswordConfirmationScreen;

import { headerKeys } from "@/config/general";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import {
  NOT_LOGGED_IN_ERROR_STATUS,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { ICashbackBalanceViewModel } from "@/fe-core/meta/interfaces/user";
import { LevelupDetailsResponse } from "@/fe-core/meta/interfaces/user/levelupDetails";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  getLevelupDetailsFailure,
  getLevelupDetailsSuccess,
} from "../../actions/userActions/levelupDetailsActions";

function* getLevelupDetails() {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<ICashbackBalanceViewModel | IErrorPayload> =
      yield fetch("/api/user/levelupDetails", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
      });

    const viewModel: LevelupDetailsResponse = yield response.json();
    if (response.status == 200) {
      yield put(getLevelupDetailsSuccess(viewModel.data));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          viewModel.status === NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getLevelupDetailsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getLevelupDetailsFailure(parseFrontendError(error)));
  }
}

export default function* levelupDetailsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(sessionActionTypes.AUTHENTICATE_SUCCESS, getLevelupDetails),
    takeEvery(userActionTypes.GET_LEVEL_UP_DETAILS_REQUEST, getLevelupDetails),
  ]);
}

import { IErrorPayload } from '../interfaces/root'
import { IMaintenanceBarData, IMaintenanceBarViewModel } from '../interfaces/maintenanceBar'

export enum maintenanceBarActionTypes {
  GET_MAINTENANCE_BAR_REQUEST = 'GET_MAINTENANCE_BAR_REQUEST',
  GET_MAINTENANCE_BAR_SUCCESS = 'GET_MAINTENANCE_BAR_SUCCESS',
  GET_MAINTENANCE_BAR_FAILURE = 'GET_MAINTENANCE_BAR_FAILURE',
}

export type GetMaintenanceBarRequestAction = {
  type: maintenanceBarActionTypes.GET_MAINTENANCE_BAR_REQUEST
}

export type GetMaintenanceBarSuccessAction = {
  type: maintenanceBarActionTypes.GET_MAINTENANCE_BAR_SUCCESS
  payload: IMaintenanceBarViewModel
}

export type GetMaintenanceBarFailureAction = {
  type: maintenanceBarActionTypes.GET_MAINTENANCE_BAR_FAILURE
  payload: IErrorPayload
}

export type MaintenanceBarActions =
  | GetMaintenanceBarRequestAction
  | GetMaintenanceBarSuccessAction
  | GetMaintenanceBarFailureAction

export type MaintenanceBarState = {
  pending: boolean
  maintenance: IMaintenanceBarData | null
}

import { IPromotionBannersRequestViewModel, IPromotionBannersResponseViewModel } from "../../meta/interfaces/promotionBanners";
import { IErrorPayload } from "../../meta/interfaces/root";
import {
  promotionBannersActionTypes,
  GetPromotionBannersFailureAction,
  GetPromotionBannersRequestAction,
  GetPromotionBannersSuccessAction,
} from "../../meta/types/promotionBanners";

export const getPromotionBannersRequest = (
  payload?: IPromotionBannersRequestViewModel
): GetPromotionBannersRequestAction => ({
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_REQUEST,
  payload
});

export const getPromotionBannersSuccess = (
  payload: IPromotionBannersResponseViewModel
): GetPromotionBannersSuccessAction => ({
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_SUCCESS,
  payload,
});

export const getPromotionBannersFailure = (
  payload: IErrorPayload
): GetPromotionBannersFailureAction => ({
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_FAILURE,
  payload,
});

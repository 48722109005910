import { ICasinoLiveRTPViewModel } from "@/fe-core/meta/interfaces/casinoLiveRTP";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  casinoLiveRTPActionTypes,
  GetCasinoLiveRTPHourlyRequestAction,
  GetCasinoLiveRTPHourlyFailureAction,
  GetCasinoLiveRTPHourlySuccessAction,
  GetCasinoLiveRTPDailyRequestAction,
  GetCasinoLiveRTPDailyFailureAction,
  GetCasinoLiveRTPDailySuccessAction,
} from "@/fe-core/meta/types/casinoLiveRTP";

export const getCasinoLiveRTPHourlyRequest =
  (): GetCasinoLiveRTPHourlyRequestAction => ({
    type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_REQUEST,
  });

export const getCasinoLiveRTPHourlySuccess = (
  payload: ICasinoLiveRTPViewModel
): GetCasinoLiveRTPHourlySuccessAction => ({
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_SUCCESS,
  payload,
});

export const getCasinoLiveRTPHourlyFailure = (
  payload: IErrorPayload
): GetCasinoLiveRTPHourlyFailureAction => ({
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_FAILURE,
  payload,
});

export const getCasinoLiveRTPDailyRequest =
  (): GetCasinoLiveRTPDailyRequestAction => ({
    type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_REQUEST,
  });

export const getCasinoLiveRTPDailySuccess = (
  payload: ICasinoLiveRTPViewModel
): GetCasinoLiveRTPDailySuccessAction => ({
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_SUCCESS,
  payload,
});

export const getCasinoLiveRTPDailyFailure = (
  payload: IErrorPayload
): GetCasinoLiveRTPDailyFailureAction => ({
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_FAILURE,
  payload,
});

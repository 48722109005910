import { FIELDS_CONFIG, PLACEHOLDER_ADDRESS, PLACEHOLDER_BIRTH, PLACEHOLDER_CITY, PLACEHOLDER_FIRST_NAME, PLACEHOLDER_LAST_NAME, PLACEHOLDER_MOBILE_PHONE, PLACEHOLDER_MOBILE_PREFIX, PLACEHOLDER_ALIAS } from "@/config/profile";
import { IProfileUpdatePayload, IProfileViewModel } from "../meta/interfaces/user";

export const isUserHasPlaceHolderValues = (payload: IProfileViewModel) => {
    if (FIELDS_CONFIG?.alias?.profileUpdate && payload.alias === PLACEHOLDER_ALIAS) {
        return true;
    }else if (FIELDS_CONFIG.firstName.profileUpdate && payload.firstName === PLACEHOLDER_FIRST_NAME) {
        return true;
    } else if (FIELDS_CONFIG.lastName.profileUpdate && payload.lastName === PLACEHOLDER_LAST_NAME) {
        return true;
    } else if (FIELDS_CONFIG.address.profileUpdate && payload.address === PLACEHOLDER_ADDRESS) {
        return true;
    } else if (FIELDS_CONFIG.city.profileUpdate && payload.city === PLACEHOLDER_CITY) {
        return true;
        // By checking the placeholder value it can create a situation where a valid player is being kept asked for details. So disable.
        // } else if (FIELDS_CONFIG.birthDate.profileUpdate && payload.birthDate === PLACEHOLDER_BIRTH) {
        //     console.log("isUserHasPlaceHolderValues","5")
        //     return true;
    } else if (FIELDS_CONFIG.mobilePhone.profileUpdate && payload.mobilePhone === PLACEHOLDER_MOBILE_PHONE && payload.mobilePhonePrefix === PLACEHOLDER_MOBILE_PREFIX) {
        return true;
    } else {
        return false
    }
}

export const hasBlankFields = (
    payload: IProfileUpdatePayload
): boolean => {
    for (const fieldName in FIELDS_CONFIG) {
        const fieldConfig = FIELDS_CONFIG[fieldName];
        if (fieldConfig.profileUpdate && !payload[fieldName]) {
            console.log('fieldName hasBlankFields', fieldConfig.profileUpdate, fieldName);
            return true;
        }
    }
    return false;
};
//08/08/1999 to  1999-08-08T00:00:00
export const convertDateFormat = (dateString: string) => {
    try {
        if (dateString.includes("/")) {
            const [month, day, year] = dateString.split('/');
            const dateObject = `${year}-${month}-${day}T00:00:00`;
            const formattedDate = dateObject.toString().replace('Z', '');
            return formattedDate;
        } else {
            return dateString
        }
    } catch (error) {
        console.log('error--error', error);
        return dateString;
    }
}

export const convertToRegistrationBirthdata = (dateString: string): {
    day?: number;
    month?: number;
    year?: number;
} => {
    try {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const dateObject = {
            day: day,
            month: month,
            year: year
        };
        return dateObject;
    } catch (error) {
        console.log('error--error--convertToRegistrationBirthdata', error);
        return {
            day: 1,
            month: 1,
            year: 1999
        };
    }
}
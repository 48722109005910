import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { ICeSessionViewModel } from "@/fe-core/meta/interfaces/ceSession";
import {
  getCeSessionFailure,
  getCeSessionSuccess,
} from "@/fe-core/_redux/actions/ceSessionActions";
import { ceSessionActionTypes } from "@/fe-core/meta/types/ceSession";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";

function* getCeSessionSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `${process.env.NEXT_PUBLIC_GAMELAUNCH_URL}/Loader/StartInfo/${process.env.NEXT_PUBLIC_REALM_ID}/sports-betting?_sid=${sessionId}`,
      <RequestInit>{
        method: methodTypes.GET,
        credentials: "include",
      }
    );

    if (response.status == 200) {
      const ceSessionId: ICeSessionViewModel = yield response.json();

      yield put(getCeSessionSuccess(ceSessionId));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getCeSessionFailure(errorPayload));
    }
  } catch (error) {
    yield put(getCeSessionFailure(parseFrontendError(error)));
  }
}

function* ceSessionSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(ceSessionActionTypes.GET_CE_SESSION_REQUEST, getCeSessionSaga),
  ]);
}

export default ceSessionSaga;

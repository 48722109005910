import { ICMSLatestBetViewModel } from "../interfaces/latestBets";
import { IErrorPayload, IStatus } from "../interfaces/root";
import { webPubSubActionTypes } from "@/fe-core/meta/types/webPubSub";

export enum latestBetsActionTypes {
  GET_LATEST_BETS_REQUEST = "GET_LATEST_BETS_REQUEST",
  GET_LATEST_BETS_SUCCESS = "GET_LATEST_BETS_SUCCESS",
  GET_LATEST_BETS_FAILURE = "GET_LATEST_BETS_FAILURE",
}

export type GetLatestBetsRequestAction = {
  type: latestBetsActionTypes.GET_LATEST_BETS_REQUEST;
};

export type GetLatestBetsSuccessAction = {
  type: latestBetsActionTypes.GET_LATEST_BETS_SUCCESS;
  payload: ICMSLatestBetViewModel[];
};

export type GetLatestBetsFailureAction = {
  type: latestBetsActionTypes.GET_LATEST_BETS_FAILURE;
  payload: IErrorPayload;
};

export type PushLatestBetAction = {
  type: webPubSubActionTypes.WEBPUBSUB_HUB_BET;
  payload: ICMSLatestBetViewModel;
};

export type LatestBetsActions =
  | GetLatestBetsRequestAction
  | GetLatestBetsSuccessAction
  | GetLatestBetsFailureAction
  | PushLatestBetAction;

export type LatestBetsState = {
  latestBets: ICMSLatestBetViewModel[];
  pending: boolean;
  status: IStatus | null;
};

import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  CurrenciesActions,
  CurrenciesState,
} from "@/fe-core/meta/types/user/register/currencies";

const initialState: CurrenciesState = {
  detectedCurrency: null,
  pending: false,
  currencies: null,
};

const currenciesReducer = (
  state: CurrenciesState = initialState,
  action: CurrenciesActions
): CurrenciesState => {
  switch (action.type) {
    case userActionTypes.GET_CURRENCIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case userActionTypes.GET_CURRENCIES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case userActionTypes.SET_DETECTED_CURRENCY:
      return {
        ...state,
        detectedCurrency: action.payload.currency,
      };
    case userActionTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        pending: false,
        currencies: action.payload.currencies,
      };
    default:
      return {
        ...state,
      };
  }
};

export default currenciesReducer;

"use client";
import React, { useRef, useState } from "react";

import MenuGroup from "../Menu/MenuGroup";
import MenuGroupItem from "../Menu/MenuGroupItem";

import { useDispatch, useSelector } from "react-redux";
import { openLoginMenu } from "../../fe-core-omega/_redux/actions/menusActions";
import {
  isUserAuthenticatedSelector,
  sessionPendingSelector,
} from "../../fe-core-omega/_redux/selectors/sessionSelectors";
import { useTranslations } from "next-intl";
import { isUserNeedKYCDocumentsSelector } from "../../fe-core-omega/_redux/selectors/userSelectors/documentsSelectors";
import Loader from "../Loader";
import { useScreen } from "../../context/ScreenContext";
import { setShowSignup } from "../../fe-core-omega/_redux/actions/userActions";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../SVGIcon";
import { Link, usePathname } from "../../navigation";
import LocaleCurrencyTabsModal from "../Layout/LocaleCurrencyTabsModal";
import { toggleChatbox } from "../../fe-core-omega/_redux/actions/chatboxActions";
import { smarticoUnreadInboxSelector } from "../../fe-core-omega/_redux/selectors/smartico";
import {
  activeLocaleCurrenyTabSelector,
  isOpenLocaleCurrenyModalSelector,
} from "../../fe-core-omega/_redux/selectors/localeSelectors";
import {
  setActiveLocaleCurrenyTab,
  toggleLocaleCurrencyModal,
} from "../../fe-core-omega/_redux/actions/localeActions";
import { logoutRequest } from "../../fe-core-omega/_redux/actions/sessionActions";
import { userBonusesActiveSelector } from "../../fe-core-omega/_redux/selectors/userSelectors/bonusSelectors";
import { Transition } from "@headlessui/react";
import useTracking from "../Hooks/useTracking";
import DotNotification from "../DotNotification";
import Skeleton from "react-loading-skeleton";
import { isMobile } from "react-device-detect";
import { userProfileDataSelector } from "@/fe-core/_redux/selectors/userSelectors";
import { kycStatuses } from "@/fe-core/meta/interfaces/user";

const HeaderComponents: ({}: {}) => JSX.Element | null = ({}) => {
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.GENERAL);
  const profileT = useTranslations(I18N_NAMESPACE.PROFILE);
  const pathname = usePathname() ?? "";
  const { isDesktop } = useScreen();
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  const isUserNeedKYCDocuments = useSelector(isUserNeedKYCDocumentsSelector);
  const sessionPending = useSelector(sessionPendingSelector);
  const localeCurrencySelectorIsOpen = useSelector(
    isOpenLocaleCurrenyModalSelector
  );
  const activeLocaleCurrencyTab = useSelector(activeLocaleCurrenyTabSelector);
  const menuContainer = useRef<HTMLDivElement>(null);
  const unreadInbox = useSelector(smarticoUnreadInboxSelector);
  const bonuses = useSelector(userBonusesActiveSelector);

  const { kycStatus } = useSelector(userProfileDataSelector) || {};
  const isKycPassed = kycStatus === kycStatuses.PASS;

  const handleLoginButtonClick = () => {
    dispatch(openLoginMenu());
  };
  const { navigate, click } = useTracking();

  const handleSignupButtonClick = () => {
    dispatch(setShowSignup());
  };

  const [showMenu, setShowMenu] = useState(false);

  if (sessionPending) {
    return (
      <div className="relative w-40 h-10">
        <Skeleton
          count={1}
          containerClassName="h-full w-full !flex overflow-hidden  absolute inset-0 flex gap-2 w-full  "
          className="w-full rounded"
        />
      </div>
    );
  }

  const toggleChatboxMenu = () => {
    dispatch(toggleChatbox());
  };

  const setLocaleCurrencySelectorIsOpen = (isOpen: boolean) => {
    dispatch(toggleLocaleCurrencyModal({ isOpen }));
  };
  const openLocaleCurrencyModal = (_tabIndex: number) => {
    setLocaleCurrencySelectorIsOpen(true);
    dispatch(setActiveLocaleCurrenyTab({ tabIndex: _tabIndex }));
  };

  const bonusesCount = () => {
    let ret = <></>;
    if (bonuses) {
      const length = bonuses.filter(
        (bonus) => bonus.status === "active"
      ).length;
      if (length) {
        ret = <span>{length}</span>;
      }
    }

    return ret;
  };

  const handleLogoutClick = () => {
    dispatch(logoutRequest());
  };
  return (
    <>
      {!isUserAuthenticated && (
        <>
          <button
            className={`btn  btn--secondary btn--secondary--outline  ${
              isMobile ? "btn--sm" : ""
            } `}
            onClick={() => {
              click("login", {
                buttonLabel: t("login"),
                component: "HeaderComponents",
              });
              handleLoginButtonClick();
            }}
          >
            {t("login")}
          </button>
          <button
            className={`btn btn--secondary   ${isMobile ? "btn--sm" : ""}   `}
            onClick={() => {
              handleSignupButtonClick();
              click("register", {
                buttonLabel: t("register"),
                component: "HeaderComponents",
              });
            }}
          >
            <span>{t("register")}</span>
          </button>
        </>
      )}

      {isUserAuthenticated && (
        <>
          <div
            className="relative   flex justify-center items-center"
            ref={menuContainer}
          >
            <div>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setShowMenu(!showMenu);
                  // navigate("profile", {
                  //   component: "HeaderComponents",
                  // });
                }}
                aria-label="Navigate to profile"
                href={"/profile"}
                // onMouseEnter={(e) => isDesktop && setShowMenu(true)}
                className="relativew-8 h-8  flex justify-center items-center  "
              >
                <SVGIcon
                  icon="user"
                  className="w-6 h-6 text-tertiary/70 dark:text-tertiary"
                />
              </Link>
            </div>

            <Transition
              show={showMenu}
              className="flex flex-col text-left transition-all !p-1 bg-white rounded dark:bg-tertiary-700 text-tertiary dark:text-white md:max-h-none max-h-[80vh] overflow-auto absolute w-52 top-10 md:-ml-24 right-0 space-y-1 z-[2]"
              enter="transition duration-100 ease-out"
              enterFrom="transform translate-y-2 opacity-0"
              enterTo="transform -translate-y-0 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform -translate-y-0 opacity-100"
              leaveTo="transform translate-y-2 opacity-0"
              onClick={() => {
                setShowMenu(false);
                click("Show Profile Menu", {
                  component: "HeaderComponents",
                });
              }}
            >
              <MenuGroup className="gap-1">
                {/* <MenuGroupItem
                    onClick={() => {
                      setShowMenu(false);
                      dispatch(clearDeposit());
                      navigate("/profile/transactions/depositmoney", {
                        component: "HeaderComponents",
                        label: profileT("deposit"),
                        navigateFrom: "Profile Menu",
                      });
                    }}
                    href="/profile/transactions/depositmoney"
                    active={pathname === "/profile/transactions/depositmoney"}
                  >
                    <SVGIcon icon="deposit" className="w-5 h-5 min-w-5 text-primary" />
                    <div>{profileT("deposit")}</div>
                  </MenuGroupItem>
                  <MenuGroupItem
                    onClick={() => {
                      setShowMenu(false);
                      dispatch(clearWithdraw());
                      navigate("/profile/transactions/withdrawmoney", {
                        component: "HeaderComponents",
                        label: profileT("withdrawal"),
                        navigateFrom: "Profile Menu",
                      });
                    }}
                    href="/profile/transactions/withdrawmoney"
                    active={pathname === "/profile/transactions/withdrawmoney"}
                  >
                    <SVGIcon icon="withdraw" className="w-5 h-5 min-w-5 text-primary" />
                    <div>{profileT("withdrawal")}</div>
                  </MenuGroupItem> */}
                <MenuGroupItem
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/profile", {
                      component: "HeaderComponents",
                      label: profileT("my-profile"),
                      navigateFrom: "Profile Menu",
                    });
                  }}
                  href="/profile"
                  active={pathname === "/profile"}
                >
                  <SVGIcon
                    icon="user"
                    className="w-5 h-5 min-w-5 text-primary"
                  />
                  <div className="profile-item">
                    <div>{profileT("my-profile")}</div>
                  </div>
                </MenuGroupItem>
                {process.env.NEXT_PUBLIC_HAS_DOCUMENT_VERIFICATION ===
                  "YES" && (
                  <MenuGroupItem
                    onClick={() => {
                      setShowMenu(false);
                      navigate("/profile/documents", {
                        component: "HeaderComponents",
                        label: profileT("my-documents"),
                        navigateFrom: "Profile Menu",
                      });
                    }}
                    href="/profile/documents"
                    active={pathname === "/profile/documents"}
                  >
                    <SVGIcon
                      icon="kyc"
                      className="w-5 h-5 min-w-5 text-primary"
                    />
                    <div className="relative flex items-center gap-2">
                      <div>{profileT("my-documents")}</div>
                      {/* {isUserNeedKYCDocuments && (
                        <DotNotification className="!top-1" />
                      )} */}
                      {isKycPassed ? (
                        <SVGIcon
                          icon="verified"
                          className="text-green-500 w-4 h-4"
                        />
                      ) : (
                        <DotNotification className="!top-1" />
                      )}
                    </div>
                  </MenuGroupItem>
                )}
                {/* <MenuGroupItem
          href="/profile/transactions/depositmoney"
          active={pathname === "/profile/transactions/depositmoney"}
        >
          <SVGIcon icon="wallet" className="w-5 h-5 min-w-5 text-primary" />
          <div>{t("wallet")}</div>
        </MenuGroupItem> */}
                <MenuGroupItem
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/profile/bonuses", {
                      component: "HeaderComponents",
                      label: profileT("my-bonuses"),
                      navigateFrom: "Profile Menu",
                    });
                  }}
                  href="/profile/bonuses"
                  active={
                    pathname === "/profile/bonuses" ||
                    pathname === "/profile/bonuses/active"
                  }
                >
                  <SVGIcon
                    icon="reward"
                    className="w-5 h-5 min-w-5 text-primary"
                  />
                  <div className="relative">
                    <div>{profileT("my-bonuses")}</div>

                    {bonusesCount()}
                  </div>
                </MenuGroupItem>
                {process.env.NEXT_PUBLIC_HAS_SPORTS === "YES" && (
                  <MenuGroupItem
                    onClick={() => {
                      setShowMenu(false);
                      navigate("/profile/mybets", {
                        component: "HeaderComponents",
                        label: profileT("betting-history"),
                        navigateFrom: "Profile Menu",
                      });
                    }}
                    href="/profile/mybets"
                    active={pathname === "/profile/mybets"}
                  >
                    <SVGIcon
                      icon="history"
                      className="w-5 h-5 min-w-5 text-primary"
                    />
                    <div>{profileT("betting-history")}</div>
                  </MenuGroupItem>
                )}
                <MenuGroupItem
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/profile/history", {
                      component: "HeaderComponents",
                      label: profileT("history"),
                      navigateFrom: "Profile Menu",
                    });
                  }}
                  href="/profile/history"
                  active={pathname === "/profile/history"}
                >
                  <SVGIcon
                    icon="history"
                    className="w-5 h-5 min-w-5 text-primary"
                  />
                  <div>{profileT("history")}</div>
                </MenuGroupItem>
                {/*<MenuGroupItem*/}
                {/*  href="/profile/responsiblegambling"*/}
                {/*  active={pathname === "/profile/responsiblegambling"}*/}
                {/*>*/}
                {/*  <SVGIcon icon="reward" className="w-5 h-5 min-w-5 text-primary" />*/}
                {/*  <div>{t("responsible-gaming")}</div>*/}
                {/*</MenuGroupItem>*/}
                {/* <MenuGroupItem
                    onClick={() => {
                      window._smartico.dp("dp:gf");
                      navigate("Open Mission Modal", {
                        component: "HeaderComponents",
                        label: profileT("missions"),
                        navigateFrom: "Profile Menu",
                      });
                    }}
                  >
                    <SVGIcon icon="trophy" className="w-5 h-5 min-w-5 text-primary" />
                    <div>{profileT("missions")}</div>
                  </MenuGroupItem> */}
                <MenuGroupItem
                  onClick={() => {
                    setShowMenu(false);
                    navigate("/profile/global-settings", {
                      component: "HeaderComponents",
                      label: profileT("global-settings"),
                      navigateFrom: "Profile Menu",
                    });
                  }}
                  href="/profile/global-settings"
                  active={pathname === "/profile/global-settings"}
                >
                  <SVGIcon
                    icon="settings"
                    className="w-5 h-5 min-w-5 text-primary"
                  />
                  <div>{profileT("global-settings")}</div>
                </MenuGroupItem>
                {/* <div className="bg-tertiary-100 h-[1px] my-1 dark:bg-tertiary-400" />
                <MenuGroupItem
                  onClick={() => {
                    setShowMenu(false);
                    handleLogoutClick();
                    click("Log out", {
                      component: "HeaderComponents",
                      label: profileT("logout"),
                      navigateFrom: "Profile Menu",
                    });
                  }}
                  className=""
                >
                  <SVGIcon
                    icon="logout"
                    className="w-5 h-5 min-w-5 text-primary"
                  />
                  <div>{profileT("logout")}</div>
                </MenuGroupItem> */}
              </MenuGroup>
            </Transition>
            <Transition
              show={showMenu}
              className="fixed inset-0 z-[0]  "
              onClick={() => {
                setShowMenu(false);
              }}
            ></Transition>
          </div>
        </>
      )}
      {/* to be removed false condition when we add multi language */}
      {false && (!isUserAuthenticated || isDesktop) && (
        <button
          className="btn btn--white md:btn--lg md:btn--square md:!block"
          onClick={() => {
            openLocaleCurrencyModal(0);
            click("Open Language Modal", {
              component: "HeaderComponents",
              navigateFrom: "Profile Menu",
            });
          }}
        >
          <SVGIcon
            icon="globe"
            className="w-5 h-5 min-w-5 text-primary text-tertiary/70 dark:text-white/70"
          />
        </button>
      )}
      {/*<div className="w-[1px] bg-tertiary-100 h-full" />*/}
      {/*<button*/}
      {/*  className="btn btn--white btn--lg !px-3 !font-normal !gap-0.5"*/}
      {/*  onClick={() => openLocaleCurrencyModal(1)}*/}
      {/*>*/}
      {/*  <SVGIcon icon="dollar-outline" className="w-5 h-5 min-w-5 text-primary" />*/}
      {/*  USD*/}
      {/*</button>*/}

      {/* <LocaleCurrencyTabsModal
        defaultTab={activeLocaleCurrencyTab}
        isOpen={localeCurrencySelectorIsOpen}
        setIsOpen={setLocaleCurrencySelectorIsOpen}
      /> */}
    </>
  );
};

export default HeaderComponents;

import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBalanceWarning } from "../../../fe-core-omega/_redux/actions/userActions";
import { balanceWarningSelector } from "../../../fe-core-omega/_redux/selectors/userSelectors/balanceWarningSelectors";
import Modal from "../../Modal";
import "react-toastify/dist/ReactToastify.css";
import { userBalanceDataSelector } from "../../../fe-core-omega/_redux/selectors/userSelectors";
import { CookiesStorage } from "../../../fe-core-omega/helpers/storage";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { useFormatter, useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import useTracking from "@/components/Hooks/useTracking";

const BalanceWarningModal: FC = () => {
  const { navigate, click, track } = useTracking();
  const dispatch = useDispatch();
  const router = useRouter();
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const balanceWarning = useSelector(balanceWarningSelector);
  const { showBalanceWarning } = balanceWarning;
  const format = useFormatter();
  const { total, totalCurrency } =
    useSelector(userBalanceDataSelector) || {};

  const isCookieSet = CookiesStorage.getItem("balanceWarning");

  const setBalanceWarningCookie = (days: number) => {
    CookiesStorage.setItem("balanceWarning", "true", { expires: days });
  };
  useEffect(() => {
    if (
      !isCookieSet &&
      showBalanceWarning &&
      total !== undefined &&
      total < 5
    ) {
      track(`Open Balance Warning Modal`, {
        component: "BalanceWarningModal",
      });
    }
  }, [isCookieSet, showBalanceWarning, total]);

  const cancelBalanceWarning = () => {
    dispatch(setBalanceWarning({ showBalanceWarning: false }));
    setBalanceWarningCookie(1);
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "BalanceWarning",
    //     userId: userId,
    //     action: "close",
    //   },
    // });
  };

  const handleBalanceWarning = () => {
    dispatch(setBalanceWarning({ showBalanceWarning: false }));
    setBalanceWarningCookie(1);
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "BalanceWarning",
    //     userId: userId,
    //     action: "deposit",
    //   },
    // });

    router.push("/profile/transactions/depositmoney");
    click(generalT("deposit"), {
      buttonLabel: generalT("deposit"),
      component: "BalanceWarningModal",
    });
  };

  const handleGoToPromotions = () => {
    dispatch(setBalanceWarning({ showBalanceWarning: false }));
    setBalanceWarningCookie(1);
    navigate(`/promotions`, {
      component: "BalanceWarningModal",
      label: t("all-promotions-here"),
    });
  };

  const handleDelayWarning = () => {
    dispatch(setBalanceWarning({ showBalanceWarning: false }));
    setBalanceWarningCookie(7);
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "BalanceWarning",
    //     userId: userId,
    //     action: "postpone",
    //   },
    // });
    click(t("do-not-display"), {
      buttonLabel: t("do-not-display"),
      component: "BalanceWarningModal",
    });
  };

  return (
    <Modal
      className="!p-0 balance-notification-modal max-w-[calc(100dvw-32px)] sm:max-w-md"
      childClassName="!h-auto"
      onClose={cancelBalanceWarning}
      isOpen={
        !isCookieSet && showBalanceWarning && total !== undefined && total < 5
      }
    >
      <div className="p-8 pt-16 text-center sm:pt-8">
        <h5 className="mb-4 text-base font-bold uppercase">
          {generalT("information")}
        </h5>
        {/*<SVGIcon*/}
        {/*  icon="circle-error"*/}
        {/*  className="block w-20 h-20 mx-auto my-6 text-yellow-500"*/}
        {/*/>*/}
        <p className="mb-2 text-base ">
          {t("your-account-balance")}{" "}
          <strong>
            {format.number(total ?? 0, {
              style: "currency",
              currency: totalCurrency,
            })}
          </strong>
          .
        </p>
        <p className="mb-5 text-base ">{t("deposit-and-enjoy")}</p>

        <div className="flex flex-col items-center justify-center gap-5 text-sm ">
          <Link
            href="/promotions"
            className="underline text-pink"
            onClick={handleGoToPromotions}
            target="_blank"
            rel="noreferrer"
          >
            {t("all-promotions-here")}
          </Link>

          <button
            className="btn btn--primary btn--lg"
            onClick={handleBalanceWarning}
          >
            {generalT("deposit")}
          </button>

          <a
            className="block text-xs cursor-pointer text-tertiary/50 dark:text-white/50"
            onClick={handleDelayWarning}
          >
            {t("do-not-display")}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default BalanceWarningModal;

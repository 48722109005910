import { useState, useEffect } from "react";

function checkForIOS() {
  if (navigator.standalone) {
    return false;
  }
  const today = new Date();
  const installPrompt = localStorage.getItem("installPrompt");
  const lastPrompt = new Date(installPrompt);
  let Difference_In_Time = lastPrompt.getTime() - today.getTime();
  let days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
  const prompt = (!installPrompt || days > 30) && isIOS && isSafari;
  //const prompt = isIOS && isSafari;
  if (prompt && "localStorage" in window) {
    localStorage.setItem("installPrompt", new Date().toISOString());
  }

  return { isIOS, isSafari, prompt };
}

export default function useIsIOS() {
  const [isIOS, setIsIOS] = useState({});

  useEffect(() => {
    setIsIOS(checkForIOS());
    return () => console.log("CLEANUP INSTALL PROMPT", isIOS);
  }, []);

  return isIOS;
}

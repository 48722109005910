import { IDeadSimpleChatViewModel } from "@/fe-core/meta/interfaces/deadSimpleChat";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetDscAccessTokenFailureAction,
  GetDscAccessTokenRequestAction,
  GetDscAccessTokenSuccessAction,
  deadSimpleChatActionTypes,
} from "@/fe-core/meta/types/deadSimpleChat";

export const getDscAccessTokenRequest = (): GetDscAccessTokenRequestAction => ({
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_REQUEST,
});

export const getDscAccessTokenSuccess = (
  payload: IDeadSimpleChatViewModel
): GetDscAccessTokenSuccessAction => ({
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_SUCCESS,
  payload,
});

export const getDscAccessTokenFailure = (
  payload: IErrorPayload
): GetDscAccessTokenFailureAction => ({
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_FAILURE,
  payload,
});

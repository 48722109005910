import { storageKeys } from "@/config/general";
import { signupScreens } from "../meta/types/user";
import Storage, { CookiesStorage } from "./storage";
import { localStorageKeys } from "../meta/types/localStorage";

export const stepNumber = (activeScreen: string) => {
  let activeStep;
  switch (activeScreen) {
    case signupScreens.ACCOUNT_INFORMATION:
    case signupScreens.SMS_CONFIRMATION:
    default:
      activeStep = 1;
      break;
    case signupScreens.PERSONAL_INFORMATION:
      activeStep = 2;
      break;
    case signupScreens.EMAIL_CONFIRMATION:
      activeStep = 3;
      break;
  }

  return activeStep;
};

export const clearStorage = () => {
  try {
    CookiesStorage.removeItem(storageKeys.SESSION_ID);
    Storage.remove(storageKeys.SESSION_ID);
    Storage.remove(localStorageKeys.USER_DATA);
    Storage.remove(localStorageKeys.PROFILE_DATA);
    Storage.remove(localStorageKeys.IS_LOGGED_IN);
    if (storageKeys.LAST_DEPOSIT_AMOUNT)
      CookiesStorage.removeItem(storageKeys.LAST_DEPOSIT_AMOUNT);
  } catch (error) {}
};

import { IErrorPayload } from "../../interfaces/root";
import { ISmarticoPublicPropsModel } from "../../interfaces/smartico/publicProps";

export enum smarticoPublicPropsActionTypes {
    GET_SMARTICO_PUBLIC_PROPS_REQUEST = "GET_SMARTICO_PUBLIC_PROPS_REQUEST",
    GET_SMARTICO_PUBLIC_PROPS_SUCCESS = "GET_SMARTICO_PUBLIC_PROPS_SUCCESS",
    GET_SMARTICO_PUBLIC_PROPS_FAILURE = "GET_SMARTICO_PUBLIC_PROPS_FAILURE",
}

export type GetSmarticoPublicPropsRequestAction = {
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_REQUEST;
};

export type GetSmarticoPublicPropsSuccessAction = {
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_SUCCESS;
    payload: ISmarticoPublicPropsModel;
};

export type GetSmarticoPublicPropsFailureAction = {
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_FAILURE;
    payload: IErrorPayload;
};

export type SmarticoPublicPropsActions =
    | GetSmarticoPublicPropsRequestAction
    | GetSmarticoPublicPropsSuccessAction
    | GetSmarticoPublicPropsFailureAction;

export type SmarticoPublicPropsState = {
    pending: boolean;
    ach_gamification_in_control_group: boolean | null;
    ach_level_current: string | null;
    ach_level_current_id: number | null;
    ach_points_balance: number | null;
    ach_points_board_period_type_1: number | null;
    ach_points_board_period_type_2: number | null;
    ach_points_ever: number | null;
    avatar_id: string | null;
    core_inbox_unread_count: number | null;
    core_is_test_account: boolean | null;
    core_public_tags: string[] | null;
    core_registration_date: number | null;
    core_user_language: string | null;
    public_username: string | null;
    user_country: string | null;
    user_last_session_push_state: string | null;
} & ISmarticoPublicPropsModel;

import { IErrorPayload, IStatus } from '../interfaces/root'
import { IPopularCompetitionViewModel } from '../interfaces/popularCompetitions'

export enum popularCompetitionsActionTypes {
  POPULAR_COMPETITIONS_REQUEST = 'POPULAR_COMPETITIONS_REQUEST',
  POPULAR_COMPETITIONS_SUCCESS = 'POPULAR_COMPETITIONS_SUCCESS',
  POPULAR_COMPETITIONS_FAILURE = 'POPULAR_COMPETITIONS_FAILURE',
}

export type PopularCompetitionsRequestAction = {
  type: popularCompetitionsActionTypes.POPULAR_COMPETITIONS_REQUEST
}

export type PopularCompetitionsSuccessAction = {
  type: popularCompetitionsActionTypes.POPULAR_COMPETITIONS_SUCCESS
  payload: IPopularCompetitionViewModel[]
}

export type PopularCompetitionsFailureAction = {
  type: popularCompetitionsActionTypes.POPULAR_COMPETITIONS_FAILURE
  payload: IErrorPayload
}

export type PopularCompetitionsActions =
  | PopularCompetitionsRequestAction
  | PopularCompetitionsSuccessAction
  | PopularCompetitionsFailureAction

export type PopularCompetitionsState = {
  popularCompetitions: IPopularCompetitionViewModel[]
  pending: boolean
  status: IStatus | null
}

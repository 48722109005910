import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  ValidateActions,
  ValidateMobileState,
} from "@/fe-core/meta/types/user";

const initialState: ValidateMobileState = {
  pending: false,
  data: null,
  status: null,
};

const validateMobileReducer = (
  state: ValidateMobileState = initialState,
  action: ValidateActions | RootActions
): ValidateMobileState => {
  switch (action.type) {
    case userActionTypes.VALIDATE_MOBILE_REQUEST:
      return {
        ...state,
        pending: true,
        data: null,
        status: null,
      };
    case userActionTypes.VALIDATE_MOBILE_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
          message: "Mobile successfuly validated",
        },
      };
    case userActionTypes.VALIDATE_MOBILE_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default validateMobileReducer;

import { combineReducers } from "redux";

import menusReducer from "./menusReducer";
import navReducer from "./navReducer";
import userReducer from "./userReducer";
import casinoReducer from "./casinoReducer";
import sessionReducer from "./sessionReducer";
import casinoGroups from "./casinoGroupsReducer";
import socketReducer from "./socketReducer";
import bannerReducer from "./bannerReducer";
import liveCasinoGroupsReducer from "./liveCasinoGroupsReducer";
import casinoJackpotReducer from "./casinoJackpotReducer";
import popularCompetitionsReducer from "./popularCompetitionsReducer";
import sportsReducer from "./sportsReducer";
import activateEmailReducer from "./activateEmailReducer";
import routingReducer from "./routingReducer";
import recentWinnersReducer from "./recentWinnersReducer";
import latestBetsReducer from "./latestBetsReducer";
import promotionsReducer from "./promotionsReducer";
import casinoGamesHighlightedReducer from "./casinoGamesHighlightedReducer";
import adventCalendarReducer from "./adventCalendarReducer";
import maintenanceBarReducer from "./maintenanceBarReducer";
import pageReducer from "./pageReducer";
import casinoLiveRTPReducer from "./casinoLiveRTPReducer";
import casinoGamePlayModalReducer from "./casinoGamePlayModal";
import gameOfTheWeekReducer from "./gameOfTheWeekReducer";
import casinoJackpotGroupsReducer from "./casinoJackpotGroupsReducer";
import tournamentsReducer from "./tournamentsReducer";
import topWinnersReducer from "./topWinnersReducer";
import drawerReducer from "./drawerReducer";
import chatboxReducer from "./chatboxReducer";
import deadSimpleChatReducer from "./deadSimpleChatReducer";
import smarticoReducer from "./smarticoReducer";
import jackpotWinnersReducer from "./jackpotWinnersReducer";
import popUpsReducer from "./popUpsReducer";
import registrationContentReducer from "./registrationContentReducer";
import footerReducer from "./footerReducer";
import paymentMethodsReducer from "./paymentMethodsReducer";
import themeReducer from "./themeReducer";
import localeReducer from "./localeReducer";
import supportReducer from "./supportReducer";
import promotionContentReducer from "./promotionContentReducer";
import promotionBannersReducer from "./promotionBannersReducer";
import casinoVendorsReducer from "./casinoVendorsReducer";
import pwaReducer from "./pwaReducer";
import loyaltyLevelsReducer from "./loyaltyLevelsReducer";
import promotionDrawerReducer from "./promotionDrawerReducer";
import rewardsDrawerReducer from "./rewardsDrawerReducer";

const reducers = {
  menus: menusReducer,
  drawer: drawerReducer,
  chatbox: chatboxReducer,
  deadSimpleChat: deadSimpleChatReducer,
  smartico: smarticoReducer,
  nav: navReducer,
  user: userReducer,
  casino: casinoReducer,
  casinoGroups: casinoGroups,
  liveCasinoGroups: liveCasinoGroupsReducer,
  session: sessionReducer,
  socket: socketReducer,
  banners: bannerReducer,
  casinoJackpot: casinoJackpotReducer,
  casinoJackpotGroups: casinoJackpotGroupsReducer,
  popularCompetitions: popularCompetitionsReducer,
  sports: sportsReducer,
  activateEmail: activateEmailReducer,
  routing: routingReducer,
  recentWinners: recentWinnersReducer,
  latestBets: latestBetsReducer,
  topWinners: topWinnersReducer,
  jackpotWinners: jackpotWinnersReducer,
  promotions: promotionsReducer,
  casinoGamesHighlighted: casinoGamesHighlightedReducer,
  // adventCalendar: adventCalendarReducer,
  maintenanceBar: maintenanceBarReducer,
  page: pageReducer,
  casinoLiveRTP: casinoLiveRTPReducer,
  casinoGamePlayModal: casinoGamePlayModalReducer,
  // gameOfTheWeek: gameOfTheWeekReducer,
  tournament: tournamentsReducer,
  popUps: popUpsReducer,
  registrationContent: registrationContentReducer,
  footer: footerReducer,
  paymentMethods: paymentMethodsReducer,
  theme: themeReducer,
  locale: localeReducer,
  support: supportReducer,
  promotionContent: promotionContentReducer,
  promotionBanners: promotionBannersReducer,
  casinoVendors: casinoVendorsReducer,
  pwa: pwaReducer,
  loyaltyLevels: loyaltyLevelsReducer,
  promotionDrawer: promotionDrawerReducer,
  rewardsDrawer: rewardsDrawerReducer,
};

export default reducers;

"use client";

import { FC, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../../fe-core-omega/_redux/actions/sessionActions";
import {
  closeLoginMenu,
  setLoginMenuScreen,
} from "../../../fe-core-omega/_redux/actions/menusActions";
import {
  isUserAuthenticatedSelector,
  sessionLoginPendingSelector,
  sessionLoginStatusSelector,
} from "../../../fe-core-omega/_redux/selectors/sessionSelectors";
import { loginMenuScreens } from "../../../fe-core-omega/meta/types/menus";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../../SVGIcon";
import Image from "next/image";
import Button from "../../Button";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "../../../fe-core-omega/_redux/selectors/registrationContentSelectors";
import { useScreen } from "../../../context/ScreenContext";
import { IImageAttributesViewModel } from "../../../fe-core-omega/meta/interfaces/root";
import { setShowSignup } from "../../../fe-core-omega/_redux/actions/userActions";
import useTracking from "@/components/Hooks/useTracking";

interface Props {
  username: string;
  password: string;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
}

const LoginScreen: FC<Props> = ({
  username,
  password,
  setUsername,
  setPassword,
}) => {
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.LOGIN);
  const loginPending = useSelector(sessionLoginPendingSelector);
  const loginStatus = useSelector(sessionLoginStatusSelector);
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  const [showPassword, setShowPassword] = useState(false);
  const { isMobile } = useScreen();
  const { track, click } = useTracking();
  const hasEmptyFields = !(username && password);

  useEffect(() => {
    if (isAuthenticated) {
      setUsername("");
      setPassword("");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loginStatus?.success) {
      dispatch(closeLoginMenu());
      track(`Login Success`, {
        userName: username,
        succMsg: loginStatus?.success,
        component: "LoginScreen",
      });
    }

    if (loginStatus?.success === false) {
      track(`Login Failed`, {
        errMsg: loginStatus.message,
        userName: username,
        component: "LoginScreen",
      });
    }
  }, [loginStatus, dispatch, username]);

  const handleLoginButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    dispatch(loginRequest({ username, password }));
    e.preventDefault();
  };

  const handleRegisterClick = () => {
    dispatch(closeLoginMenu());
    dispatch(setShowSignup());
    click(t("registration"), {
      text: t("registration"),
      from: "Login Modal",
      component: "LoginScreen",
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getStatusMessage = () => {
    if (!loginStatus || loginStatus.success) {
      return null;
    }
    return (
      <div className="text-xs text-center text-red">{loginStatus.message}</div>
    );
  };

  const leftBannerImg = useMemo(() => {
    const attributes =
      registrationContent && registrationContent[0]?.attributes;
    const leftBannerImg = isMobile
      ? attributes
        ? (attributes?.mobileImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined
      : attributes
      ? (attributes?.desktopImage?.data
          ?.attributes as IImageAttributesViewModel)
      : undefined;
    return leftBannerImg;
  }, [isMobile, registrationContent]);
  return (
    <div className="grid grid-cols-1 h-screen md:grid-cols-2 sm:h-auto sm:min-h-[640px]">
      <div className="relative hidden md:block">
        {isPendingRegistrationContent ? (
          <Image
            src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
            objectFit="cover"
            fill
            alt="reg-banner"
            className="w-full h-full"
            loading="lazy"
          />
        ) : (
          <Image
            src={
              leftBannerImg
                ? leftBannerImg.url
                : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
            }
            objectFit="cover"
            fill
            alt={leftBannerImg ? leftBannerImg?.name : " registration banner"}
            className="w-full h-full"
            loading="lazy"
          />
        )}
      </div>
      <div className="relative flex flex-col justify-between gap-10 p-8 pt-16 sm:pt-8 dark:bg-tertiary-600">
        <div className="flex flex-col gap-6">
          <h5 className="text-base font-bold dark:text-white">{t("header")}</h5>
          <form
            className="flex flex-col gap-6"
            onSubmit={handleLoginButtonClick}
          >
            <div className="flex flex-col gap-4">
              <div className="">
                <input
                  className="form-input !text-base sm:!text-sm"
                  type="text"
                  id="login-email"
                  value={username}
                  onChange={(event) =>
                    setUsername(event.target.value.replace(/\s+/g, ""))
                  }
                  placeholder={t("username")}
                />
              </div>
              <div className="relative">
                <input
                  className="form-input !text-base sm:!text-sm"
                  type={showPassword ? "text" : "password"}
                  id="login-password"
                  value={password}
                  onChange={(event) =>
                    setPassword(event.target.value.split(" ").join(""))
                  }
                  placeholder={t("password")}
                />
                <button
                  type="button"
                  className="absolute top-0 bottom-0 m-auto cursor-pointer right-1 w-10 h-10"
                  onClick={toggleShowPassword}
                >
                  <SVGIcon
                    className="w-4 h-4 m-auto opacity-50 text-tertiary dark:text-white"
                    icon={!showPassword ? "eye" : "blind"}
                  />
                </button>
              </div>
              <div
                className="text-right cursor-pointer"
                onClick={() => {
                  dispatch(
                    setLoginMenuScreen(loginMenuScreens.FORGOT_PASSWORD)
                  );
                  click(t("forgot-the-password"), {
                    text: t("forgot-the-password"),
                    from: "Login Modal",
                    component: "LoginScreen",
                  });
                }}
              >
                <a className="text-xs font-normal text-tertiary dark:text-white/70 hover:opacity-100 opacity-70">
                  {t("forgot-the-password")}
                </a>
              </div>
              {getStatusMessage()}
            </div>
            <div className="flex flex-col gap-4">
              {/* <button
                type="submit"
                className="btn btn--lg btn--primary min-w-[230px] w-full"
              >
                Sign In
              </button> */}
              <Button
                label={t("login-button")}
                disabled={hasEmptyFields || loginPending}
                loading={loginPending}
                buttonType="submit"
                className="btn--lg min-w-[230px] w-full"
                trackParams={{
                  component: "LoginScreen",
                }}
              />
              <div className="flex self-stretch gap-3">
                <p className="text-xs font-normal text-tertiary dark:text-white">
                  {t("registration-header")}{" "}
                  <button
                    className=" text-primary-500 font-bold"
                    onClick={handleRegisterClick}
                    type="button"
                  >
                    {t("registration")}
                  </button>{" "}
                </p>
              </div>
            </div>
          </form>
        </div>
        {/*<SocialMediaLogin />*/}
      </div>
    </div>
  );
};
export default LoginScreen;

import { IErrorPayload } from '../interfaces/root'
import { IRegistrationContentResponseViewModel, IRegistrationcontentViewModel } from '../interfaces/registrationContent'

export enum registrationContentActionTypes {
  GET_REGISTRATION_CONTENT_REQUEST = 'GET_REGISTRATION_CONTENT_REQUEST',
  GET_REGISTRATION_CONTENT_SUCCESS = 'GET_REGISTRATION_CONTENT_SUCCESS',
  GET_REGISTRATION_CONTENT_FAILURE = 'GET_REGISTRATION_CONTENT_FAILURE',
}

export type GetRegistrationContentRequestAction = {
  type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_REQUEST
}

export type GetRegistrationContentSuccessAction = {
  type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_SUCCESS
  payload: IRegistrationContentResponseViewModel
}

export type GetRegistrationContentFailureAction = {
  type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_FAILURE
  payload: IErrorPayload
}

export type RegistrationContentActions =
  | GetRegistrationContentRequestAction
  | GetRegistrationContentSuccessAction
  | GetRegistrationContentFailureAction

export type RegistrationContentState = {
  pending: boolean
  registrationContent: IRegistrationcontentViewModel[] | null
}

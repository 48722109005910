import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { LevelupDetailsItem } from "@/fe-core/meta/interfaces/user/levelupDetails";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  GetLevelupDetailsFailureAction,
  GetLevelupDetailsRequestAction,
  GetLevelupDetailsSuccessAction,
} from "@/fe-core/meta/types/user/levelupDetails";

export const getLevelupDetailsRequest = (): GetLevelupDetailsRequestAction => ({
  type: userActionTypes.GET_LEVEL_UP_DETAILS_REQUEST,
});

export const getLevelupDetailsSuccess = (
  payload: LevelupDetailsItem[]
): GetLevelupDetailsSuccessAction => ({
  type: userActionTypes.GET_LEVEL_UP_DETAILS_SUCCESS,
  payload,
});

export const getLevelupDetailsFailure = (
  payload: IErrorPayload
): GetLevelupDetailsFailureAction => ({
  type: userActionTypes.GET_LEVEL_UP_DETAILS_FAILURE,
  payload,
});

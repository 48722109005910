import {
  IProcessDepositRequestPayload,
  IProcessDepositResponsePayload,
} from "@/fe-core/meta/interfaces/user/cashier/processDeposit";
import { userActionTypes } from "..";
import { IErrorPayload, IStatus } from "../../../interfaces/root";

export type ResetProcessDepositStatusAction = {
  type: userActionTypes.RESET_PROCESS_DEPOSIT_STATUS;
};

export type OpenProcessDepositModalAction = {
  type: userActionTypes.OPEN_PROCESS_DEPOSIT_MODAL;
  payload: depositPaymentMethodTypes;
};

export type CloseProcessDepositModalAction = {
  type: userActionTypes.CLOSE_PROCESS_DEPOSIT_MODAL;
};

export type ProcessDepositRequestAction = {
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST;
  payload: IProcessDepositRequestPayload;
};

export type ProcessDepositSuccessAction = {
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST_SUCCESS;
  payload: IProcessDepositResponsePayload;
};

export type ProcessDepositFailureAction = {
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST_FAILURE;
  payload: IErrorPayload;
};
export type ClearProcessDepositAction = {
  type: userActionTypes.CLEAR_PROCESS_DEPOSIT;
};

export type ClearProcessDepositMessageAction = {
  type: userActionTypes.CLEAR_PROCESS_DEPOSIT_MESSAGE;
};

export type ProcessDepositActions =
  | OpenProcessDepositModalAction
  | CloseProcessDepositModalAction
  | ProcessDepositRequestAction
  | ProcessDepositSuccessAction
  | ProcessDepositFailureAction
  | ClearProcessDepositAction
  | ClearProcessDepositMessageAction
  | ResetProcessDepositStatusAction;

export enum depositPaymentMethodTypes {
  OMEGA_BANK = "OMEGA_BANK",
  PRAXIS_ALL = "PRAXIS_ALL",
}

export type ProcessDepositState = {
  pending: boolean;
  paymentMethod: depositPaymentMethodTypes | null;
  status: IStatus | null;
  continueUrl?: string | null;
  token?: string | null;
  accessToken?: string | null;
  authToken?: string | null;
};

import { ISetActiveLocaleCurrenyTabPayloadModel, ISetLocalePayloadModel, IToggleLocaleCurrenyModalPayloadModel } from "@/fe-core/meta/interfaces/locale";
import {
  GetCurrentLocaleAction,
  SetActiveLocaleCurrenyTabAction,
  SetCurrentLocaleAction,
  ToggleLocaleCurrenyModalAction,
  localeActionTypes,
} from "@/fe-core/meta/types/locale";

export const getCurrentLocaleRequest = (): GetCurrentLocaleAction => ({
  type: localeActionTypes.GET_CURRENT_LOCALE,
});

export const setCurrentLocaleRequest = (
  payload: ISetLocalePayloadModel
): SetCurrentLocaleAction => ({
  type: localeActionTypes.SET_CURRENT_LOCALE,
  payload,
});


export const toggleLocaleCurrencyModal = (
  payload: IToggleLocaleCurrenyModalPayloadModel
): ToggleLocaleCurrenyModalAction => ({
  type: localeActionTypes.TOGGLE_LOCALE_CURRENCY_MODAL,
  payload: payload.isOpen,
});

export const setActiveLocaleCurrenyTab = (
  payload: ISetActiveLocaleCurrenyTabPayloadModel
): SetActiveLocaleCurrenyTabAction => ({
  type: localeActionTypes.SET_ACTIVE_LOCALE_CURRENCY_TAB,
  payload: payload.tabIndex,
});


import {
  navActionTypes,
  NavRequestAction,
  NavSuccessAction,
  NavFailureAction,
  ClearNavAction,
} from "@/fe-core/meta/types/nav";
import { INavViewModel } from "@/fe-core/meta/interfaces/nav";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";

export const navRequest = (payload: string): NavRequestAction => ({
  type: navActionTypes.NAV_REQUEST,
  payload,
});

export const navSuccess = (payload: INavViewModel): NavSuccessAction => ({
  type: navActionTypes.NAV_SUCCESS,
  payload,
});

export const navFailure = (payload: IErrorPayload): NavFailureAction => ({
  type: navActionTypes.NAV_FAILURE,
  payload,
});

export const clearNav = (): ClearNavAction => ({
  type: navActionTypes.CLEAR_NAV,
});

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  BankingHistoryActions,
  BankingHistoryState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: BankingHistoryState = {
  pending: false,
  data: null,
  status: null,
};

const bankingHistoryReducer = (
  state: BankingHistoryState = initialState,
  action: BankingHistoryActions | RootActions
): BankingHistoryState => {
  switch (action.type) {
    case userActionTypes.BANKING_HISTORY_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.BANKING_HISTORY_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.BANKING_HISTORY_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default bankingHistoryReducer;

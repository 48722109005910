import {
  casinoJackpotActionTypes,
  CasinoJackpotState,
  CasinoJackpotActions,
} from "@/fe-core/meta/types/casinoJackpot";

const initialState: CasinoJackpotState = {
  egtJackpot: { pending: false, jackpots: null, status: null },
  eqJackpot: { pending: false, jackpots: null, status: null },
};

const casinoJackpotReducer = (
  state: CasinoJackpotState = initialState,
  action: CasinoJackpotActions
): CasinoJackpotState => {
  switch (action.type) {
    case casinoJackpotActionTypes.CASINO_JACKPOT_REQUEST:
      return {
        ...state,
        egtJackpot: {
          ...state.egtJackpot,
          pending: true,
          status: null,
        },
      };
    case casinoJackpotActionTypes.CASINO_JACKPOT_SUCCESS:
      const { payload: egtPayload } = action;
      return {
        ...state,
        egtJackpot: {
          ...state.egtJackpot,
          pending: false,
          jackpots: egtPayload,
          status: {
            success: true,
            message: "Successfully retrieved navigation",
          },
        },
      };
    case casinoJackpotActionTypes.CASINO_JACKPOT_FAILURE:
      const {
        payload: { message: egtMessage },
      } = action;
      return {
        ...state,
        egtJackpot: {
          pending: false,
          jackpots: null,
          status: {
            success: false,
            message: egtMessage,
          },
        },
      };
    case casinoJackpotActionTypes.CASINO_EQ_JACKPOT_REQUEST:
      return {
        ...state,
        eqJackpot: {
          ...state.eqJackpot,
          pending: true,
          status: null,
        },
      };
    case casinoJackpotActionTypes.CASINO_EQ_JACKPOT_SUCCESS:
      const { payload: eqPayload } = action;
      return {
        ...state,
        eqJackpot: {
          ...state.eqJackpot,
          pending: false,
          jackpots: eqPayload,
          status: {
            success: true,
            message: "Successfully retrieved navigation",
          },
        },
      };
    case casinoJackpotActionTypes.CASINO_EQ_JACKPOT_FAILURE:
      const {
        payload: { message: eqMessage },
      } = action;
      return {
        ...state,
        eqJackpot: {
          pending: false,
          jackpots: null,
          status: {
            success: false,
            message: eqMessage,
          },
        },
      };

    default:
      return {
        ...state,
      };
  }
};

export default casinoJackpotReducer;

import { externalCashierSelector } from "..";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { IPaymentIqCancelTransactionViewModel, IPaymentIqTransactionHistoryViewModel, IPaymentIqTransactionTimeIntervalViewModel, IPaymentIqTransactionDataModel } from "@/fe-core/meta/interfaces/user";
import { RootState } from "@/fe-core/meta/types/root";
import { PaymentIqTransactionHistoryState } from "@/fe-core/meta/types/user";

export const paymentIqTransactionHistorySelector = (
  state: RootState
): PaymentIqTransactionHistoryState => {
  const { paymentIqTransactionHistory } = externalCashierSelector(state);

  return paymentIqTransactionHistory;
};

export const paymentIqTransactionHistoryPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = paymentIqTransactionHistorySelector(state);

  return pending;
};

export const paymentIqCancelTransactionPendingSelector = (
  state: RootState
): boolean => {
  const { cancelTransactionPending } = paymentIqTransactionHistorySelector(state);

  return cancelTransactionPending;
};
export const paymentIqCancelTransactionDataSelector = (
  state: RootState
): IPaymentIqCancelTransactionViewModel | null => {
  const { cancelTransactionData } = paymentIqTransactionHistorySelector(state);

  return cancelTransactionData;
};
export const paymentIqCancelTransactionStatusSelector = (
  state: RootState
): IStatus | null => {
  const { cancelTransactionStatus } = paymentIqTransactionHistorySelector(state);

  return cancelTransactionStatus;
};


export const paymentIqTransactionHistoryDataSelector = (
  state: RootState
): IPaymentIqTransactionHistoryViewModel | null => {
  const { data } = paymentIqTransactionHistorySelector(state);

  return data;
};
export const paymentIqDepositHistoryDataSelector = (
  state: RootState
): IPaymentIqTransactionDataModel[] | null => {
  const { deposit } = paymentIqTransactionHistorySelector(state);

  return deposit;
};
export const paymentIqWithdrawalHistoryDataSelector = (
  state: RootState
): IPaymentIqTransactionDataModel[] | null => {
  const { withdrawal } = paymentIqTransactionHistorySelector(state);

  return withdrawal;
};
export const paymentIqPendingWithdrawalHistoryDataSelector = (
  state: RootState
): IPaymentIqTransactionDataModel[] | null => {
  const { pendingWithdrawal } = paymentIqTransactionHistorySelector(state);

  return pendingWithdrawal;
};
export const paymentIqPendingWithdrawalCountSelector = (
  state: RootState
): number => {
  const { pendingWithdrawalCount } = paymentIqTransactionHistorySelector(state);

  return pendingWithdrawalCount;
};

export const paymentIqTransactionTimeIntervalSelector = (
  state: RootState
): IPaymentIqTransactionTimeIntervalViewModel => {
  const { timeInterval } = paymentIqTransactionHistorySelector(state);

  return timeInterval;
};

export const paymentIqTransactionHistoryStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = paymentIqTransactionHistorySelector(state);

  return status;
};

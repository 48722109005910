import { validateSelector } from ".";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidateSignupIpState } from "@/fe-core/meta/types/user";

export const validateSignupIpSelector = (state: RootState): ValidateSignupIpState => {
  const { signupIP } = validateSelector(state);

  return signupIP;
};

export const validateSignupIpPendingSelector = (state: RootState): boolean => {
  const { pending } = validateSignupIpSelector(state);

  return pending;
};

export const validateSignupIpStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = validateSignupIpSelector(state);

  return status;
};

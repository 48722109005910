import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IActivateEmailPayload,
  IActivateEmailViewModel,
} from "@/fe-core/meta/interfaces/activateEmail";
import {
  ActivateEmailFailureAction,
  ActivateEmailRequestAction,
  ActivateEmailSuccessAction,
  ClearActiveEmailDataAction,
  SetHideActivateEmailModalAction,
  SetShowActivateEmailModalAction,
  activateEmailActionTypes,
} from "@/fe-core/meta/types/activateEmail";

export const setShowActivateEmailModal = (): SetShowActivateEmailModalAction => ({
  type: activateEmailActionTypes.SET_SHOW_ACTIVE_EMAIL_MODAL,
});

export const setHideActivateEmailModal = (): SetHideActivateEmailModalAction => ({
  type: activateEmailActionTypes.SET_HIDE_ACTIVE_EMAIL_MODAL,
});

export const activateEmailRequest = (
  payload: IActivateEmailPayload
): ActivateEmailRequestAction => ({
  type: activateEmailActionTypes.ACTIVATE_EMAIL_REQUEST,
  payload,
});

export const activateEmailSuccess = (
  payload: IActivateEmailViewModel
): ActivateEmailSuccessAction => ({
  type: activateEmailActionTypes.ACTIVATE_EMAIL_SUCCESS,
  payload,
});

export const activateEmailFailure = (
  payload: IErrorPayload
): ActivateEmailFailureAction => ({
  type: activateEmailActionTypes.ACTIVATE_EMAIL_FAILURE,
  payload,
});

export const clearActivateEmailData = (): ClearActiveEmailDataAction => ({
  type: activateEmailActionTypes.CLEAR_ACTIVE_EMAIL_DATA,
});
import {
  EnableCoolOffFailureAction,
  EnableCoolOffRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IEnableCoolOffPayload } from "@/fe-core/meta/interfaces/user";

export const enableCoolOffRequest = (
  payload: IEnableCoolOffPayload
): EnableCoolOffRequestAction => ({
  type: userActionTypes.ENABLE_COOL_OFF_REQUEST,
  payload,
});

export const enableCoolOffFailure = (
  payload: IErrorPayload
): EnableCoolOffFailureAction => ({
  type: userActionTypes.ENABLE_COOL_OFF_FAILURE,
  payload,
});

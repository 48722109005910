import {
  ActivateEmailActions,
  activateEmailActionTypes,
  ActivateEmailState,
} from "@/fe-core/meta/types/activateEmail";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: ActivateEmailState = {
  data: null,
  pending: false,
  status: null,
  showActivateEmailModal: false,
};

const activateEmailReducer = (
  state: ActivateEmailState = initialState,
  action: ActivateEmailActions | RootActions
): ActivateEmailState => {
  switch (action.type) {
    case activateEmailActionTypes.SET_SHOW_ACTIVE_EMAIL_MODAL:
      return {
        ...state,
        showActivateEmailModal: true,
      };
    case activateEmailActionTypes.SET_HIDE_ACTIVE_EMAIL_MODAL:
      return {
        ...state,
        showActivateEmailModal: false,
      };
    case activateEmailActionTypes.ACTIVATE_EMAIL_REQUEST:
      return {
        ...state,
        pending: true,
        data: null,
        status: null,
      };
    case activateEmailActionTypes.ACTIVATE_EMAIL_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case activateEmailActionTypes.ACTIVATE_EMAIL_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    }
    case activateEmailActionTypes.CLEAR_ACTIVE_EMAIL_DATA:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default activateEmailReducer;

import { IRegistrationContentResponseViewModel } from "@/fe-core/meta/interfaces/registrationContent";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  registrationContentActionTypes,
  GetRegistrationContentFailureAction,
  GetRegistrationContentRequestAction,
  GetRegistrationContentSuccessAction,
} from "@/fe-core/meta/types/registrationContent";

export const getRegistrationContentRequest =
  (): GetRegistrationContentRequestAction => ({
    type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_REQUEST,
  });

export const getRegistrationContentSuccess = (
  payload: IRegistrationContentResponseViewModel
): GetRegistrationContentSuccessAction => ({
  type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_SUCCESS,
  payload,
});

export const getRegistrationContentFailure = (
  payload: IErrorPayload
): GetRegistrationContentFailureAction => ({
  type: registrationContentActionTypes.GET_REGISTRATION_CONTENT_FAILURE,
  payload,
});

import { userActionTypes, UserActions } from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { LimitsActions, LimitsState } from "@/fe-core/meta/types/user/limits";

const initialState: LimitsState = {
  pending: false,
  limitsPending: {
    depositPending: false,
    wageringPending: false,
    lossPending: false,
  },
  data: {
    depositLimits: [],
    wageringLimits: [],
    lossLimits: [],
    sessionLimits: [],
  },
  status: null,
};

const limitsReducer = (
  state: LimitsState = initialState,
  action: RootActions | LimitsActions
): LimitsState => {
  switch (action.type) {
    case userActionTypes.GET_LIMITS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_LIMITS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_LIMITS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.SET_DEPOSIT_LIMITS_REQUEST:
    case userActionTypes.DELETE_DEPOSIT_LIMITS_REQUEST:
      const { type, period } = action.payload;
      return {
        ...state,
        limitsPending: { ...state.limitsPending, [`${type}Pending`]: true, [`${period}${type}Pending`]: true },
      };
    case userActionTypes.SET_DEPOSIT_LIMITS_SUCCESS:
    case userActionTypes.SET_DEPOSIT_LIMITS_FAILURE:
    case userActionTypes.DELETE_DEPOSIT_LIMITS_SUCCESS:
    case userActionTypes.DELETE_DEPOSIT_LIMITS_FAILURE:
      return {
        ...state,
        limitsPending: {},
      };
    case userActionTypes.CLEAR_LIMITS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default limitsReducer;

import {
    SmarticoPublicPropsActions,
    smarticoPublicPropsActionTypes,
} from "@/fe-core/meta/types/smartico/publicProps";
import { SmarticoPublicPropsState } from "@/fe-core/meta/types/smartico/publicProps";

const initialState: SmarticoPublicPropsState = {
    pending: false,
    ach_gamification_in_control_group: null,
    ach_level_current: null,
    ach_level_current_id: null,
    ach_points_balance: null,
    ach_points_board_period_type_1: null,
    ach_points_board_period_type_2: null,
    ach_points_ever: null,
    avatar_id: null,
    core_inbox_unread_count: null,
    core_is_test_account: null,
    core_public_tags: [],
    core_registration_date: null,
    core_user_language: null,
    public_username: null,
    user_country: null,
    user_last_session_push_state: null,
};

const smarticoPublicPropsReducer = (
    state: SmarticoPublicPropsState = initialState,
    action: SmarticoPublicPropsActions
): SmarticoPublicPropsState => {
    switch (action.type) {
        case smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_REQUEST:
            return {
                ...state,
                pending: true,
            };
        case smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_FAILURE:
            return {
                ...state,
                pending: false,
            };
        case smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_SUCCESS:
            return {
                ...state,
                pending: false,
                ...action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default smarticoPublicPropsReducer;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  UserActions,
  userActionTypes,
  PasswordState,
} from "@/fe-core/meta/types/user";

const initialState: PasswordState = {
  changePassword: {
    pending: false,
    status: null,
  },
  resetPasswordSendEmail: {
    pending: false,
    status: null,
  },
  resetPasswordIsKeyAvailable: {
    pending: false,
    status: null,
    isAvailable: false,
  },
  resetPassword: {
    pending: false,
    status: null,
  },
  rule: {
    regularExpression: new RegExp(""),
  },
  showChangePassword: false,
};

const passwordReducer = (
  state: PasswordState = initialState,
  action: UserActions | RootActions
): PasswordState => {
  switch (action.type) {
    case userActionTypes.SET_SHOW_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        showChangePassword: true,
      };

    case userActionTypes.SET_HIDE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        showChangePassword: false,
      };

    case userActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePassword: {
          pending: true,
          status: null,
        },
      };

    case userActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          pending: false,
          status: {
            success: true,
            message: "Password was changed succesfully",
          },
        },
      };

    case userActionTypes.CHANGE_PASSWORD_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        changePassword: {
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };

    case userActionTypes.RESET_PASSWORD_SEND_EMAIL_REQUEST:
      return {
        ...state,
        resetPasswordSendEmail: {
          pending: true,
          status: null,
        },
      };

    case userActionTypes.RESET_PASSWORD_SEND_EMAIL_SUCCESS:
      return {
        ...state,
        resetPasswordSendEmail: {
          pending: false,
          status: {
            success: true,
          },
        },
      };

    case userActionTypes.RESET_PASSWORD_SEND_EMAIL_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        resetPasswordSendEmail: {
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_REQUEST:
      return {
        ...state,
        resetPasswordIsKeyAvailable: {
          pending: true,
          status: null,
          isAvailable: false,
        },
      };

    case userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_SUCCESS:
      const { payload } = action;

      return {
        ...state,
        resetPasswordIsKeyAvailable: {
          pending: false,
          status: {
            success: true,
          },
          isAvailable: payload.isAvailable,
        },
      };

    case userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        resetPasswordIsKeyAvailable: {
          pending: false,
          status: {
            success: false,
            message,
          },
          isAvailable: false,
        },
      };
    }

    case userActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {
          pending: true,
          status: null,
        },
      };

    case userActionTypes.RESET_PASSWORD_SUCCESS:
      const {
        //@ts-ignore
        payload: { message: successMessage },
      } = action;
      return {
        ...state,
        resetPassword: {
          pending: false,
          status: {
            success: true,
            message: successMessage
          },
        },
      };

    case userActionTypes.RESET_PASSWORD_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        resetPassword: {
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.PASSWORD_POLICY_SET: {
      const { payload } = action;
      return {
        ...state,
        rule: payload,
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default passwordReducer;

import { PromotionBannersActions, PromotionBannersState, promotionBannersActionTypes } from "@/fe-core/meta/types/promotionBanners"

const initialState: PromotionBannersState = {
  pending: false,
  promotionBanners: null,
}

const promotionBannersReducer = (
  state: PromotionBannersState = initialState,
  action: PromotionBannersActions
): PromotionBannersState => {
  switch (action.type) {
    case promotionBannersActionTypes.GET_PROMOTION_BANNERS_REQUEST:
      return {
        ...state,
        pending: true
      }
    case promotionBannersActionTypes.GET_PROMOTION_BANNERS_FAILURE:
      return {
        ...state,
        pending: false
      }
    case promotionBannersActionTypes.GET_PROMOTION_BANNERS_SUCCESS:
      return {
        ...state,
        pending: false,
        promotionBanners: action.payload.data
      }
    default:
      return {
        ...state,
      }
  }
}

export default promotionBannersReducer

import {
  all,
  AllEffect,
  call,
  cancel,
  delay,
  fork,
  ForkEffect,
  put,
  race,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import {
  GetTournamentLeaderBoardIntervalAction,
  TournamentRequestAction,
  tournamentsActionTypes,
} from "@/fe-core/meta/types/tournaments";
import { methodTypes } from "@/fe-core/meta/types/root";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  LeaderboardRequestPayload,
  LeaderboardResponse,
  selectedFilterPayload,
  TournamentModel,
  TournamentsListModel,
  TournamentRequestPayload,
} from "@/fe-core/meta/interfaces/tournaments";
import {
  leaderBoardFailure,
  leaderBoardSuccess,
  tournamentFailure,
  tournamentsListFailure,
  tournamentsListSuccess,
  tournamentSuccess,
  setSelectedFilterForTournament,
  getTournament,
  getTournamentMoreGamesFailure,
  getTournamentMoreGamesSuccess,
  setTournamentPending,
  setLeaderboardPending,
  getEligibleTournamentByGameSlugSuccess,
  setEligibleTournamentByGameSlugPending,
  setTournamentListPending,
} from "@/fe-core/_redux/actions/tournamentsActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  getTournamentURLParams,
  LEADERBOARD_ITEM_PER_PAGE,
  TOURNAMENTS_PER_PAGE,
  DEFAULT_OFFSET,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GAMES_ITEMS_PER_PAGE,
  DEFAULT_OFFSET_TOURNAMENTS_GAMES,
  DEFAULT_EXHIBITION_END_TIME,
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_TYPE,
  STATE_RUNNING,
} from "@/config/tournaments";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { ISessionViewModel } from "@/fe-core/meta/interfaces/session";
import { getFilterStateSelector } from "@/fe-core/_redux/selectors/tournamentsSelectors";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { casinoActionTypes, GetSingleGameRequestAction } from "@/fe-core/meta/types/casino";

// function* updateLeaderboardData() {
//   console.log('updateLeaderboardData--updateLeaderboardData');
//   const leaderBoardData: LeaderboardDataModel = yield select(getLeaderBoardSelector);
//   console.log('updateLeaderboardData--updateLeaderboardData',leaderBoardData);
//   yield all(
//     Object.keys(leaderBoardData).map((tournamentId) => {
//       const defaultQueryParams: LeaderboardRequestPayload = {
//         tournamentId,
//         filter: `IncludeUnqualifiedPlayer=true`,
//         pagination: `limit=${LEADERBOARD_ITEM_PER_PAGE},offset=0`,
//         language: "en",
//       };
//       return call(getLeaderboardRequest, defaultQueryParams);
//     })
//   );
// }

function* getEligibleTournamentByGameSlug({ payload }: GetSingleGameRequestAction) {
  try {
    yield put(setEligibleTournamentByGameSlugPending({ isEligibleTournamentByGameSlugPending: true }));
    const { locale } = yield select(localeSelector);
    const { slug } = payload;
    const tournamentPayload = {
      filter: `State=${STATE_RUNNING}`,
      expand: `games`,
      fields: `id,games(id,slug)`,
      pagination: `games(limit=1000000,offset=0)`
    };
    const queryParams = getTournamentURLParams(tournamentPayload);
    const response: IJsonResponse<TournamentsListModel | IErrorPayload> =
      yield fetch(`/api/tournaments${queryParams}`, {
        method: methodTypes.GET,
      });

    if (response.status == 200) {
      const viewModel: TournamentsListModel = yield response.json();
      if (viewModel?.count > 0) {
        const eligibleTournamentByGameSlug = viewModel?.items?.find(x => x?.games?.items?.find(x => x.slug === slug));
        if (eligibleTournamentByGameSlug?.id) {
          yield put(getEligibleTournamentByGameSlugSuccess({ eligibleTournamentByGameSlug: eligibleTournamentByGameSlug, isEligibleTournament: true }));
          const defaultQueryParams: LeaderboardRequestPayload = {
            tournamentId: eligibleTournamentByGameSlug.id,
            filter: `IncludeUnqualifiedPlayer=true`,
            pagination: `limit=${LEADERBOARD_ITEM_PER_PAGE},offset=0`,
            language: locale,
          };
          yield fork(getLeaderboardRequest, defaultQueryParams);
        } else {
          yield put(getEligibleTournamentByGameSlugSuccess({ isEligibleTournament: false }));
        }
      } else {
        yield put(getEligibleTournamentByGameSlugSuccess({ isEligibleTournament: false }));
      }
    } else {
      yield put(getEligibleTournamentByGameSlugSuccess({ isEligibleTournament: false }));
    }
  } catch (error) {
    yield put(getEligibleTournamentByGameSlugSuccess({ isEligibleTournament: false }));
  } finally {
    yield put(setEligibleTournamentByGameSlugPending({ isEligibleTournamentByGameSlugPending: false }));
  }
}

function* getLeaderboardRequest(payload: LeaderboardRequestPayload) {
  try {
    yield put(setLeaderboardPending({ isPending: true, tournamentId: payload.tournamentId }));
    const sessionData: ISessionViewModel = yield select(sessionDataSelector);
    const headers = new Headers();
    if (sessionData && sessionData.sessionId) {
      headers.append(headerKeys.SESSION_ID, sessionData.sessionId);
    }

    const queryParams = getTournamentURLParams(payload);
    const response: IJsonResponse<LeaderboardResponse | IErrorPayload> =
      yield fetch(
        `/api/tournaments/leaderboard/${payload.tournamentId}${queryParams}`,
        {
          method: methodTypes.GET,
          headers,
        }
      );
    if (response.status === 200) {
      const leaderboardData: LeaderboardResponse = yield response.json();
      yield put(
        leaderBoardSuccess({
          data: leaderboardData,
          tournamentId: payload.tournamentId,
        })
      );
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(
        leaderBoardFailure({
          data: errorPayload,
          tournamentId: payload.tournamentId,
        })
      );
    }
  } catch (error) {
    console.error("Error fetching leaderboard:", error);
    yield put(
      leaderBoardFailure({
        data: parseFrontendError(error),
        tournamentId: payload.tournamentId,
      })
    );
  } finally {
    yield put(setLeaderboardPending({ isPending: false, tournamentId: payload.tournamentId }));
  }
}

function* getTournamentsListRequestSaga() {
  try {
    yield put(setTournamentListPending(true));
    const sessionData: ISessionViewModel = yield select(sessionDataSelector);
    const headers = new Headers();
    if (sessionData && sessionData.sessionId) {
      headers.append(headerKeys.SESSION_ID, sessionData.sessionId);
    }

    const selectedFilterData: selectedFilterPayload = yield select(
      getFilterStateSelector
    );
    const { locale } = yield select(localeSelector);
    const payload = {
      filter: `State=${selectedFilterData.status},ExhibitionEndTime=${DEFAULT_EXHIBITION_END_TIME}`,
      sortOrder: DEFAULT_SORT_ORDER,
      sortType: DEFAULT_SORT_TYPE,
      pagination: `limit=${TOURNAMENTS_PER_PAGE},offset=${selectedFilterData.offset}`,
      language: locale,
    };

    const queryParams = getTournamentURLParams(payload);
    const response: IJsonResponse<TournamentsListModel | IErrorPayload> =
      yield fetch(`/api/tournaments${queryParams}`, {
        method: methodTypes.GET,
        headers,
      });

    if (response.status == 200) {
      const viewModel: TournamentsListModel = yield response.json();
      if (viewModel.total > 0 && viewModel.count === 0) {
        //if user is on last currentPage
        yield put(
          setSelectedFilterForTournament({
            ...selectedFilterData,
            offset: DEFAULT_OFFSET,
            currentPage: DEFAULT_CURRENT_PAGE,
          })
        );
      }
      yield put(tournamentsListSuccess(viewModel));
      // yield all(
      //   viewModel.items.map((tournament) => {
      //     const defaultQueryParams: LeaderboardRequestPayload = {
      //       tournamentId: tournament.id,
      //       filter: `IncludeUnqualifiedPlayer=true`,
      //       pagination: `limit=${LEADERBOARD_ITEM_PER_PAGE},offset=0`,
      //       language: locale,
      //     };
      //     return call(getLeaderboardRequest, defaultQueryParams);
      //   })
      // );
      yield all(
        viewModel.items.map((tournament) => {
          const tournamentPayload: TournamentRequestPayload = {
            id: tournament.id,
            language: locale,
            pagination: {
              offset: DEFAULT_OFFSET_TOURNAMENTS_GAMES,
              limit: DEFAULT_GAMES_ITEMS_PER_PAGE,
            },
          };
          //  return put(getTournament(tournamentPayload));
          return call(getSingleTournament, tournamentPayload);
        })
      );
    } else {
      console.log("error--error--error");
      const errorPayload: IErrorPayload = yield response.json();
      yield put(tournamentsListFailure(errorPayload));
    }
  } catch (error) {
    console.log("error--error--error", error);
    yield put(tournamentsListFailure(parseFrontendError(error)));
  } finally {
    yield put(setTournamentListPending(false));
  }
}

function* getSingleTournament(payload: TournamentRequestPayload) {
  try {
    yield put(setTournamentPending({ isPending: true, tournamentId: payload.id }));
    const { id, language, pagination } = payload;
    const { locale } = yield select(localeSelector);
    const sessionData: ISessionViewModel = yield select(sessionDataSelector);
    const headers = new Headers();
    if (sessionData && sessionData.sessionId) {
      headers.append(headerKeys.SESSION_ID, sessionData.sessionId);
    }

    const queryPayload = {
      id,
      pagination: `games(limit=${pagination?.limit},offset=${pagination?.offset})`,
      language,
    };

    const queryParams = getTournamentURLParams(queryPayload);
    const response: IJsonResponse<TournamentModel | IErrorPayload> =
      yield fetch(`/api/tournaments/${payload.id}${queryParams}&expand=games`, {
        method: methodTypes.GET,
        headers,
      });

    if (response.status == 200) {
      const viewModel: TournamentModel = yield response.json();
      yield put(
        tournamentSuccess({ data: viewModel, tournamentId: payload.id })
      );
      const defaultQueryParams: LeaderboardRequestPayload = {
        tournamentId: payload.id,
        filter: `IncludeUnqualifiedPlayer=true`,
        pagination: `limit=${LEADERBOARD_ITEM_PER_PAGE},offset=0`,
        language: locale,
      };
      yield fork(getLeaderboardRequest, defaultQueryParams);
      // const authSuccessAction: SessionState | undefined = yield select(
      //   (state: RootState) => !!state?.session?.data?.sessionId
      // );
      // if (authSuccessAction) {
      //   console.log('authSuccessAction--for auth already got', authSuccessAction);
      //   yield dispatchBonusesActions();
      // } else {
      //   const action: AuthenticateSuccessAction = yield take(sessionActionTypes.AUTHENTICATE_SUCCESS);
      //   console.log('action--for auth wait and then', action);
      //   yield dispatchBonusesActions();
      // }
    } else {
      console.log("error--error--error");
      const errorPayload: IErrorPayload = yield response.json();
      yield put(tournamentFailure(errorPayload));
      yield put(setTournamentPending({ isPending: false, tournamentId: payload.id }));
    }
  } catch (error) {
    console.log("error--error--error", error);
    yield put(tournamentFailure(parseFrontendError(error)));
  } finally {
    yield put(setTournamentPending({ isPending: false, tournamentId: payload.id }));
  }
}
function* getSingleTournamentRequestSaga({ payload }: TournamentRequestAction) {
  yield call(getSingleTournament, payload);
}

function* getTournamentMoreGames(payload: TournamentRequestPayload) {
  try {
    const { id, language, pagination } = payload;
    const sessionData: ISessionViewModel = yield select(sessionDataSelector);
    const headers = new Headers();
    if (sessionData && sessionData.sessionId) {
      headers.append(headerKeys.SESSION_ID, sessionData.sessionId);
    }
    const queryPayload = {
      id,
      pagination: `games(limit=${pagination?.limit},offset=${pagination?.offset})`,
      language,
    };

    const queryParams = getTournamentURLParams(queryPayload);

    const response: IJsonResponse<TournamentModel | IErrorPayload> =
      yield fetch(`/api/tournaments/${payload.id}${queryParams}&expand=games`, {
        method: methodTypes.GET,
        headers,
      });
    if (response.status == 200) {
      const viewModel: TournamentModel = yield response.json();
      yield put(
        getTournamentMoreGamesSuccess({
          games: viewModel.item.games,
          tournamentId: payload.id,
        })
      );
    } else {
      console.log("error--error--error");
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getTournamentMoreGamesFailure(errorPayload));
    }
  } catch (error) {
    console.log("error--error--error", error);
    yield put(getTournamentMoreGamesFailure(parseFrontendError(error)));
  }
}

function* getTournamentMoreGamesRequestSaga({
  payload,
}: TournamentRequestAction) {
  yield call(getTournamentMoreGames, payload);
}

function* handleTournamentsActions() {
  yield delay(500);
  yield race({
    request: call(getTournamentsListRequestSaga),
  });
}

function* leaderboardInterval(payload: LeaderboardRequestPayload) {
  while (true) {
    const { locale } = yield select(localeSelector);
    const defaultQueryParams: LeaderboardRequestPayload = {
      tournamentId: payload.tournamentId,
      filter: `IncludeUnqualifiedPlayer=true`,
      pagination: `limit=${LEADERBOARD_ITEM_PER_PAGE},offset=0`,
      language: locale,
    };
    yield fork(getLeaderboardRequest, defaultQueryParams);
    yield delay(5000);
  }
}
let intervalTask: any;
function* startLeaderboard({ payload }: GetTournamentLeaderBoardIntervalAction) {
  // @ts-ignore
  intervalTask = yield fork(leaderboardInterval, payload);
}
function* stopLeaderboard() {
  if (intervalTask) {
    yield cancel(intervalTask);
  }
}

export default function* tournamentsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      [
        tournamentsActionTypes.TOURNAMENTS_LIST_REQUEST,
        sessionActionTypes.AUTHENTICATE_SUCCESS,
        tournamentsActionTypes.SET_SELECTED_FILTER_STATE,
        userActionTypes.APPLY_BONUS_SUCCESS,
        localeActionTypes.SET_CURRENT_LOCALE,
      ],
      handleTournamentsActions
    ),
    takeLatest(
      tournamentsActionTypes.TOURNAMENT_REQUEST,
      getSingleTournamentRequestSaga
    ),
    takeLatest(
      tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_REQUEST,
      getTournamentMoreGamesRequestSaga
    ),
    takeLatest(tournamentsActionTypes.STOP_LEADERBOARD_INTERVAL, stopLeaderboard),
    takeLatest(tournamentsActionTypes.START_LEADERBOARD_INTERVAL, startLeaderboard),
    takeLatest(casinoActionTypes.GET_SINGLE_GAME_REQUEST, getEligibleTournamentByGameSlug),
  ]);
}

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  UserConsentsActions,
  UserConsentsState,
} from "@/fe-core/meta/types/user/register/userConsents";

const initialState: UserConsentsState = {
  pending: false,
  userConsents: null,
};

const userConsentsReducer = (
  state: UserConsentsState = initialState,
  action: UserConsentsActions | RootActions
): UserConsentsState => {
  switch (action.type) {
    case userActionTypes.GET_USER_CONSENTS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case userActionTypes.GET_USER_CONSENTS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case userActionTypes.GET_USER_CONSENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        userConsents: action.payload,
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userConsentsReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  takeLatest,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { casinoGamesHighlightedActionTypes } from "@/fe-core/meta/types/casinoGamesHighlighted";
import { ICasinoGamesHighlightedViewModel } from "@/fe-core/meta/interfaces/casinoGamesHighlighted";
import {
  getCasinoGamesHighlightedFailure,
  getCasinoGamesHighlightedSuccess,
} from "@/fe-core/_redux/actions/casinoGamesHighlightedActions";

function* getCasinoGamesHighlightedRequestSaga() {
  try {
    const response: IJsonResponse<
      ICasinoGamesHighlightedViewModel | IErrorPayload
    > = yield fetch("/api/casinoGamesHighlighted", {
      method: methodTypes.GET,
    });

    const { status } = response;

    switch (status) {
      case 200:
        const casinoGamesHighlighted: ICasinoGamesHighlightedViewModel =
          yield response.json();
        yield put(getCasinoGamesHighlightedSuccess(casinoGamesHighlighted));
        break;
      default:
        throw new Error("Casino Highlighted games couldn't be fetched");
    }
  } catch (error) {
    yield put(getCasinoGamesHighlightedFailure(parseFrontendError(error)));
  }
}

export default function* casinoGamesHighlightedSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_REQUEST,
      getCasinoGamesHighlightedRequestSaga
    ),
  ]);
}

import { IErrorPayload } from '@/fe-core/meta/interfaces/root';
import { IPromotionBannersViewModel, IPromotionBannersResponseViewModel, IPromotionBannersRequestViewModel } from '@/fe-core/meta/interfaces/promotionBanners';

export enum promotionBannersActionTypes {
  GET_PROMOTION_BANNERS_REQUEST = "GET_PROMOTION_BANNERS_REQUEST",
  GET_PROMOTION_BANNERS_SUCCESS = "GET_PROMOTION_BANNERS_SUCCESS",
  GET_PROMOTION_BANNERS_FAILURE = "GET_PROMOTION_BANNERS_FAILURE",
}

export type GetPromotionBannersRequestAction = {
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_REQUEST;
  payload?: IPromotionBannersRequestViewModel
};

export type GetPromotionBannersSuccessAction = {
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_SUCCESS;
  payload: IPromotionBannersResponseViewModel;
};

export type GetPromotionBannersFailureAction = {
  type: promotionBannersActionTypes.GET_PROMOTION_BANNERS_FAILURE;
  payload: IErrorPayload;
};

export type PromotionBannersActions =
  | GetPromotionBannersRequestAction
  | GetPromotionBannersSuccessAction
  | GetPromotionBannersFailureAction;

export type PromotionBannersState = {
  pending: boolean;
  promotionBanners: IPromotionBannersViewModel[] | null;
};

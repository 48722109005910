/* eslint-disable @next/next/no-async-client-component */
"use client";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "../../navigation";
import { useTranslations, useFormatter } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import Balance from "@/components/Balance";
import { useSelector } from "react-redux";
import {
  isUserAuthenticatedSelector,
  requireEmailActivation,
  sessionPendingSelector,
} from "../../fe-core-omega/_redux/selectors/sessionSelectors";
import {
  userBalanceDataSelector,
  userIsBalanceLoadedSelector,
  userTotalBalanceSelector,
} from "../../fe-core-omega/_redux/selectors/userSelectors";
import SVGIcon from "../SVGIcon";
import { Transition } from "@headlessui/react";
import useTracking from "../Hooks/useTracking";
import { useScreen } from "../../context/ScreenContext";

const HeaderCTA: FC = ({}) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  const isBalanceLoaded = useSelector(userIsBalanceLoadedSelector);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const format = useFormatter();
  const { totalCurrency, total } = useSelector(userBalanceDataSelector) || {};
  const totalBalance = useSelector(userTotalBalanceSelector);
  const requireEmailValidation = useSelector(requireEmailActivation);
  const sessionPending = useSelector(sessionPendingSelector);
  const [showBalanceDetails, setShowBalanceDetails] = useState(false);
  const { screenSizeLevel, isMobile, isTablet } = useScreen();
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const { click, navigate } = useTracking();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target)
      ) {
        setShowBalanceDetails(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const getBalance = () => {
    let balanceString = generalT("loading");

    if (requireEmailValidation) {
      return format.number(total || 0, {
        style: "currency",
        currency: totalCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    if (isBalanceLoaded && totalCurrency) {
      balanceString = format.number(totalBalance, {
        style: "currency",
        currency: totalCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return balanceString;
  };

  const handleButtonClick = (event: any) => {
    // Prevent the click event from propagating to document and closing the dropdown immediately
    event.stopPropagation();
    setShowBalanceDetails(!showBalanceDetails);
  };

  if (isUserAuthenticated) {
    return (
      <>
        <button
          ref={dropdownButtonRef}
          className=" btn btn--secondary "
          onClick={(event) => {
            handleButtonClick(event);
            click(`Balance Detail`, {
              component: "HeaderCTA",
              isMobileOrTablet: isMobile || isTablet ? "Yes" : "No",
              toggleDetail: showBalanceDetails
                ? "close balance detail"
                : "open balance detail",
            });
          }}
        >
          {/* <SVGIcon icon={"currency"} className="w-4 h-4" /> */}
          {getBalance()}
          <SVGIcon
            icon="angleArrow"
            className={`w-3 h-3 ${showBalanceDetails ? "rotate-90" : ""}`}
          />
        </button>
        <Transition
          show={showBalanceDetails}
          enter="transition duration-100 ease-out"
          enterFrom="transform translate-y-2 opacity-0"
          enterTo="transform -translate-y-0 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform -translate-y-0 opacity-100"
          leaveTo="transform translate-y-2 opacity-0"
          className={
            "absolute md:w-auto w-full sm:left-auto left-0 top-14 md:top-16 bg-tertiary-50 dark:bg-tertiary-700 rounded shadow p-4 transform transition-all duration-100"
          }
          ref={dropdownRef}
          onClick={() => {
            setShowBalanceDetails(!showBalanceDetails);
            click("Balance Detail", {
              component: "HeaderCTA",
              isMobileOrTablet: isMobile || isTablet ? "Yes" : "No",
              toggleDetail: showBalanceDetails
                ? "close balance detail"
                : "open balance detail",
            });
          }}
        >
          <div>
            <Balance />
          </div>
        </Transition>

        <Link
          href={"/profile/transactions/depositmoney"}
          passHref
          className="btn btn--secondary "
          onClick={() => {
            navigate("depositmoney", {
              component: "HeaderCTA",
              label: generalT("deposit"),
              navigateFrom: "HeaderCTA",
            });
          }}
        >
          <SVGIcon icon={"deposit"} className="w-4 h-4 text-primary-500" />
        </Link>
      </>
    );
  }
  return <></>;
};

export default HeaderCTA;

import {
  MaintenanceBarActions,
  maintenanceBarActionTypes,
} from "@/fe-core/meta/types/maintenanceBar";
import { MaintenanceBarState } from "@/fe-core/meta/types/maintenanceBar";

const initialState: MaintenanceBarState = {
  pending: false,
  maintenance: null,
};

const maintenanceReducer = (
  state: MaintenanceBarState = initialState,
  action: MaintenanceBarActions
): MaintenanceBarState => {
  switch (action.type) {
    case maintenanceBarActionTypes.GET_MAINTENANCE_BAR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case maintenanceBarActionTypes.GET_MAINTENANCE_BAR_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case maintenanceBarActionTypes.GET_MAINTENANCE_BAR_SUCCESS:
      return {
        ...state,
        pending: false,
        maintenance: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default maintenanceReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IGetTagsResponsePayload } from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import { UpdateTagsRequestAction, userActionTypes } from "@/fe-core/meta/types/user";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { updateTagsFailure, updateTagsSuccess } from "../../actions/userActions/tagsActions";

function* updateTagsRequestSaga({ payload }: UpdateTagsRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<IGetTagsResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/tag/update-tag`, <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload)
      });

    if (response.status == 200) {
      yield put(updateTagsSuccess());
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(updateTagsFailure(errorPayload));
    }
  } catch (error) {
    yield put(updateTagsFailure(parseFrontendError(error)));
  }
}

export default function* tagsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.UPDATE_TAGS_REQUEST, updateTagsRequestSaga)
  ]);
}
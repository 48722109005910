import {
  CasinoJackpotActions,
  CasinoJackpotGroupsState,
  casinoJackpotActionTypes,
} from "@/fe-core/meta/types/casinoJackpot";

const initialState: CasinoJackpotGroupsState = {
  pending: false,
  err: "",
  groups: null,
};

const casinoJackpotGroupsReducer = (
  state: CasinoJackpotGroupsState = initialState,
  action: CasinoJackpotActions
): CasinoJackpotGroupsState => {
  switch (action.type) {
    case casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_SUCCESS:
      return {
        ...state,
        pending: false,
        groups: action.payload,
      };
    case casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_FAILURE:
      return {
        ...state,
        pending: false,
        err: "Custom Err.",
      };
    case casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_SUCCESS: {
      const {
        id,
        name,
        games: { count, items },
      } = action.payload;

      if (state.groups) {
        const idx = state.groups.findIndex((group) => group.id === id);

        if (idx < 0) {
          return {
            ...state,
          };
        }

        return {
          ...state,
          groups: [
            ...state.groups.slice(0, idx),
            {
              id,
              name,
              games: {
                total: state.groups[idx].games.total,
                items: state.groups[idx].games.items.concat(items),
                count: state.groups[idx].games.count + count,
              },
            },
            ...state.groups.slice(idx + 1),
          ],
        };
      }
    }
    default:
      return {
        ...state,
      };
  }
};

export default casinoJackpotGroupsReducer;

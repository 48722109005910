import { combineReducers } from 'redux'
import validateUsernameReducer from './usernameReducer'
import validateEmailReducer from './emailReducer'
import validateMobileReducer from './mobileReducer'
import validateCnpReducer from './cnpReducer'
import validateSignupIpReducer from './ipReducer'

const validateReducer = combineReducers({
  cnp: validateCnpReducer,
  username: validateUsernameReducer,
  email: validateEmailReducer,
  mobile: validateMobileReducer,
  signupIP: validateSignupIpReducer,
})

export default validateReducer

//@ts-nocheck
import { FC, useEffect, useRef } from "react";
import Modal from "../../Modal";
import Currency from "./Currency";
import Language from "./Language";
import TabbedView from "@/components/Tabs";
import Tab from "@/components/Tabs/Tab";
import { useLocale, useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import { SUPPORTVIDEOBUTTONS, SUPPORTCHATBUTTONS } from "@/config/liveagent";
import Script from "next/script";
import SVGIcon from "@/components/SVGIcon";
import { headers } from "next/headers";
import useTracking from "@/components/Hooks/useTracking";
import {useSelector} from "react-redux";
import {sessionDataSelector} from "@/fe-core/_redux/selectors/sessionSelectors";
import {headerKeys} from "@/config/general";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  defaultTab: number;
};
const SupportModal: FC<Props> = ({ isOpen, setIsOpen, defaultTab }) => {
  const { click } = useTracking();
  const t = useTranslations(I18N_NAMESPACE.GENERAL);
  const { sessionId = "" } = useSelector(sessionDataSelector) || {};
  const liveChatRef = useRef(null);
  const locale = useLocale();


  useEffect(() => {
    console.log(liveChatRef, "liveChatRef");
    if (liveChatRef.current !== null) {
      console.log(
        liveChatRef.current.className,
        "liveChatRef.current.className"
      );
    }
  }, []);

  //const nonce = headers().get("x-nonce") as string;


  const authenticate = async (w) => {

    if (!!sessionId) {
      const response = await fetch('/api/live-chat/authenticate', {
        method: 'GET',
        headers: {
          [headerKeys.SESSION_ID]: sessionId,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const responsePayload = await response.json();
        w.location = `${process.env.NEXT_PUBLIC_AVAYA_URL}/en/ftv?s=${responsePayload?.s}`;
        w.focus();
        return;
      }
    }

    w.location = `${process.env.NEXT_PUBLIC_AVAYA_URL}/en/ftv`;
    w.focus();
  }
  return (
    <Modal
      className="!p-0 !max-w-md"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        click(`Close ContactUs Modal`, {
          component: "ContactUsModal - SupportModal",
        });
      }}
      closeButtonClassName="!top-6 !right-6"
    >
      <div className="flex px-6 py-6 border-b border-tertiary-100 dark:border-tertiary-400">
        <h5 className="text-base font-bold  ">{t("contact-us-header")}</h5>
      </div>
      <div className="relative p-6">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex  group flex-col items-center justify-center h-[140px] p-8 px-3 rounded dark:hover:bg-tertiary-900 live-chat-button hover:bg-[#F5F5F5] cursor-pointer relative">
            <div className="flex-1 w-full h-10  ">
              <a onClick={(e) => {
                e.preventDefault();
                const w = window.open('', "FashionTV chat support", "right=0,top=0,width=360,height=760");
                authenticate(w);
                setIsOpen(false);
              }}>
                <SVGIcon
                icon={"chat"}
                className="w-10 h-10 text-primary-500 dark:text-primary  mx-auto block "
              />
                </a>
            </div>
            <p className="te xt-lg font-bold text-center text-tertiary dark:text-white dark:text-opacity-70 group-hoverdark:text-opacity-100 text-opacity-70 group-hover:text-opacity-100  mt-1.5">
              {t("live-chat")}
            </p>
          </div>{" "}
          <a
            className="flex  group flex-col items-center justify-center h-[140px] p-8 px-3 rounded dark:hover:bg-tertiary-900 live-chat-button hover:bg-[#F5F5F5] cursor-pointer relative"
            href="mailto:ensupport@ftvclub.com" onClick={()=> setIsOpen(false)}
          >
            <div>
              <SVGIcon
                icon="email"
                className="w-10 h-10 text-primary-500 dark:text-primary   "
              />
            </div>
            <p className="text-lg font-bold text-center text-tertiary dark:text-opacity-70 group-hoverdark:text-opacity-100 dark:text-white text-opacity-70 group-hover:text-opacity-100 mt-1.5">
              {t("email")}
              <span className="block text-sm font-normal mt-0">
                <a
                  href="mailto:ensupport@ftvclub.com"
                  onClick={() => {
                    click(t("email"), {
                      component: "ContactUsModal - SupportModal",
                      text: "ensupport@ftvclub.com",
                    });
                  }}
                >
                  ensupport@ftvclub.com
                </a>
              </span>
            </p>
            {/* <Script>{`LiveAgent.createButton('${LIVE_CALL_BUTTON_ID}', document.getElementById('${LIVE_CALL_BUTTON_ID}'));`}</Script> */}
          </a>
        </div>

        {/*<div>*/}
        {/*  <Script>{`(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.defer=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,*/}
        {/*    'https://pazuru.ladesk.com/scripts/track.js',*/}
        {/*    function(e){ LiveAgent.createKbSearchWidget('7gostq0j', e); });`}</Script>*/}
        {/*</div>*/}
        {/*<Tab className="p-6" name={"Currency"}>*/}
        {/*  <Currency />*/}
        {/*</Tab>*/}
      </div>
    </Modal>
  );
};

export default SupportModal;

import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { DEFAULT_SELF_EXCLUSION_REASON, headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  ICfgViewModel,
  IGetLimitsResponsePayload,
  IPeriod,
  ISelfExclusionApiRequestPayload,
  ISelfExclusionResponsePayload,
  ISelfExclusionViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  SelfExclusionRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  selfExclusionConfigFailure,
  selfExclusionConfigSuccess,
  selfExclusionFailure,
  selfExclusionSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { clearStorage } from "@/fe-core/helpers/register";

function* selfExclusionConfigRequestSaga() {
  try {
    const response: IJsonResponse<ICfgViewModel | IErrorPayload> = yield fetch(
      "/api/user/limits/selfExclusionSettings",
      { method: methodTypes.GET }
    );

    if (response.status == 200) {
      //@ts-ignore
      const payloadResponse = yield response.json();
      const SelfExclusionConfigViewModel: ISelfExclusionViewModel = (
        payloadResponse as ISelfExclusionResponsePayload
      ).periods;

      yield put(selfExclusionConfigSuccess(SelfExclusionConfigViewModel));
    }
  } catch (error) {
    const { message } = error as Error;

    yield put(selfExclusionConfigFailure(parseFrontendError(message)));
  }
}

function* selfExclusionRequestSaga({ payload }: SelfExclusionRequestAction) {
  try {
    const { period, selfExclusionDate, lockType } = payload;

    const body = {
      period, expiryDate: selfExclusionDate, lockType
    }

    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);
    const response: IJsonResponse<IGetLimitsResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/limits/selfExclusionSettings`, <RequestInit>{
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(body),
      });

    if (response.status == 200) {
      yield put(selfExclusionSuccess());
      clearStorage()
      window.location.href = "/"
    }
  } catch (error) {
    const { message } = error as Error;

    yield put(selfExclusionFailure(parseFrontendError(message)));
  }
}

function* selfExclusionSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.SELF_EXCLUSION_CONFIG_REQUEST,
      selfExclusionConfigRequestSaga
    ),
    takeLatest(
      userActionTypes.SELF_EXCLUSION_REQUEST,
      selfExclusionRequestSaga
    ),
  ]);
}

export default selfExclusionSaga;

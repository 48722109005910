export function buildPromotionQueryString(
  theme: string,
  btagCodes: string[],
  authenticated: boolean
): string {
  const queryParams = new URLSearchParams();
  queryParams.append("theme", theme);
  queryParams.append("btagCodes", btagCodes?.join(","));
  queryParams.append("authenticated", authenticated.toString());
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

import { TournamentsState } from "@/fe-core/meta/types/tournaments";
import { RootState } from "@/fe-core/meta/types/root";
import {
  LeaderboardDataModel,
  LeaderboardPendingModel,
  LeaderboardResponse,
  TournamentItem,
  TournamentsListModel,
  selectedFilterPayload,
} from "@/fe-core/meta/interfaces/tournaments";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import { STATE_RUNNING } from "@/config/tournaments";

export const tournamentsSelector = ({
  tournament,
}: RootState): TournamentsState => tournament;

export const getTournamentListStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = tournamentsSelector(state);
  return status;
};

export const tournamentsListSelector = (
  state: RootState
): TournamentsListModel | null => {
  const { tournamentsList } = tournamentsSelector(state);

  return tournamentsList;
};
export const tournamentsListPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = tournamentsSelector(state);
  return pending;
};

export const singleActiveTournamentSelector = (
  state: RootState
): TournamentItem | null => {
  const { tournamentsList, tournament } = tournamentsSelector(state);
  const activeTournamentId = tournamentsList?.items?.find(x => x.state === STATE_RUNNING)?.id;
  const activeTournamentDetails = tournament && activeTournamentId && tournament[activeTournamentId] && tournament[activeTournamentId]?.item || null;
  return activeTournamentDetails;
};
export const singleActiveTournamentPendingSelector =
  (state: RootState): boolean => {
    const { tournamentPending, pending, tournamentsList } = tournamentsSelector(state);
    const activeTournamentId = tournamentsList?.items?.find(x => x.state === STATE_RUNNING)?.id;
    const isLoading = !!(pending || activeTournamentId && tournamentPending[activeTournamentId]);
    return isLoading;
  };
export const singleActiveTournamentLeaderBoardSelector = (
  state: RootState
): LeaderboardResponse | null => {
  const { tournamentsList } = tournamentsSelector(state);
  const leaderboardData: LeaderboardDataModel | undefined = tournamentsSelector(state).leaderboardData;
  const activeTournamentId = tournamentsList?.items?.find(x => x.state === STATE_RUNNING)?.id;
  const activeLeaderboardDetails = leaderboardData && activeTournamentId && leaderboardData[activeTournamentId] || null;
  return activeLeaderboardDetails;
};
export const singleActiveTournamentLeaderBoardPendingSelector =
  (state: RootState): boolean => {
    const { tournamentPending, pending, tournamentsList, leaderboardPending } = tournamentsSelector(state);
    const activeTournamentId = tournamentsList?.items?.find(x => x.state === STATE_RUNNING)?.id;
    const isLoading = !!(pending || activeTournamentId && (tournamentPending[activeTournamentId] || leaderboardPending[activeTournamentId]));
    return isLoading;
  };

export const tournamentSelector =
  (id: string) =>
    (state: RootState): TournamentItem => {
      const { tournament } = tournamentsSelector(state);
      return tournament && tournament[id] && tournament[id]?.item;
    };

export const tournamentPendingSelector =
  (id: string) =>
    (state: RootState): boolean => {
      const { tournamentPending } = tournamentsSelector(state);
      return tournamentPending[id];
    };

export const getLeaderBoardSelector = (
  state: RootState
): LeaderboardDataModel => {
  const { leaderboardData } = tournamentsSelector(state);

  return leaderboardData;
};

export const getLeaderBoardByIdSelector =
  (id: string) =>
    (state: RootState): LeaderboardResponse | undefined => {
      const leaderboardData: LeaderboardDataModel | undefined =
        tournamentsSelector(state).leaderboardData;
      return leaderboardData ? leaderboardData[id] : undefined;
    };

export const getLeaderBoardPendingSelector =
  (id: string) =>
    (state: RootState): boolean => {
      const leaderboardPending: LeaderboardPendingModel =
        tournamentsSelector(state).leaderboardPending;
      return leaderboardPending[id];
    };

export const getFilterStateSelector = (
  state: RootState
): selectedFilterPayload => {
  const { selectedFilterState } = tournamentsSelector(state);

  return selectedFilterState;
};

export const getEligibleTournamentByGameSlugSelector = (
  state: RootState
): TournamentItem | null | undefined => {
  const { eligibleTournamentByGameSlug } = tournamentsSelector(state);
  return eligibleTournamentByGameSlug;
};
export const getEligibleTournamentLeaderBoardByGameSlugSelector = (
  state: RootState
): LeaderboardResponse | undefined | null => {
  const { eligibleTournamentByGameSlug } = tournamentsSelector(state);
  const leaderboardData: LeaderboardDataModel | undefined = tournamentsSelector(state).leaderboardData;
  const data = eligibleTournamentByGameSlug && leaderboardData && leaderboardData[eligibleTournamentByGameSlug?.id];
  return data;
};

export const isEligibleTournamentByGameSlugSelector = (
  state: RootState
): boolean | null => {
  const { isEligibleTournament } = tournamentsSelector(state);
  return isEligibleTournament;
};
export const isEligibleTournamentByGameSlugPendingSelector = (
  state: RootState
): boolean => {
  const { isEligibleTournamentByGameSlugPending } = tournamentsSelector(state);
  return isEligibleTournamentByGameSlugPending;
};
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  FavoritesState,
  RecommendedState,
  RecommendedActions,
} from "@/fe-core/meta/types/user";

const initialState: RecommendedState = {
  pending: false,
  data: null,
  status: null,
};

const recommendedReducer = (
  state: RecommendedState = initialState,
  action: RecommendedActions | RootActions
): FavoritesState => {
  switch (action.type) {
    case userActionTypes.GET_RECOMMENDED_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_RECOMMENDED_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: {
          ...payload,
          games: {
            ...payload.games,
            count: payload.games.items.length,
            total: payload.games.items.length,
          },
        },
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_RECOMMENDED_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default recommendedReducer;

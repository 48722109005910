import { Result, Session, Error as AutoBahnError } from "autobahn";

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys, PASSWORD_TEST_REGEX } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  IPasswordPolicyResponsePayload,
  IPasswordPolicyViewModel,
  IResetPasswordResetViewModel,
} from "@/fe-core/meta/interfaces/user";
import { loginMenuScreens } from "@/fe-core/meta/types/menus";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  ChangePasswordRequestAction,
  ResetPasswordRequestAction,
  ResetPasswordSendEmailRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  openLoginMenu,
  setLoginMenuScreen,
} from "@/fe-core/_redux/actions/menusActions";
import {
  changePasswordFailure,
  changePasswordSuccess,
  resetPasswordFailure,
  resetPasswordSendEmailFailure,
  resetPasswordSendEmailSuccess,
  resetPasswordSuccess,
  setPasswordPolicy,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* changePasswordRequestSaga({ payload }: ChangePasswordRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IErrorPayload> = yield fetch(
      "/api/user/password/changeWithPassword",
      <ResponseInit>{
        method: methodTypes.POST,
        "Content-Type": "application/json",
        Accept: "application/json",
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(payload),
      }
    );

    if (response.status == 200) {
      yield put(changePasswordSuccess());
    } else {
      const responsePayload: IErrorPayload = yield response.json()
      yield put(changePasswordFailure(responsePayload));
    }
  } catch (error) {
    yield put(changePasswordFailure(parseFrontendError(error)));
  }
}

function* resetPasswordSendEmailRequestSaga({
  payload,
}: ResetPasswordSendEmailRequestAction) {
  try {
    const response: IJsonResponse<IErrorPayload> = yield fetch(
      "/api/user/password/resetRequestWithEmail",
      <ResponseInit>{
        method: methodTypes.POST,
        body: JSON.stringify(payload),
      }
    );

    if (response.status == 200) {
      yield put(resetPasswordSendEmailSuccess());
      yield put(setLoginMenuScreen(loginMenuScreens.PASSWORD_CONFIRMATION));
      yield put(openLoginMenu());
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(
        resetPasswordSendEmailFailure(parseFrontendError(errorPayload))
      );
    }
  } catch (error) {
    yield put(resetPasswordSendEmailFailure(parseFrontendError(error)));
  }
}

function* resetPasswordRequestSaga({ payload }: ResetPasswordRequestAction) {
  try {
    try {
      const { locale } = yield select(localeSelector);
      const response: IJsonResponse<
        IResetPasswordResetViewModel | IErrorPayload
      > = yield fetch("/api/user/password/resetByHashKey", <RequestInit>{
        method: methodTypes.POST,
        body: JSON.stringify({
          email: payload.email,
          newPassword: payload.newPassword,
          resetPasswordKey: payload.resetPasswordKey,
          locale
        }),
      });

      const responsePayload: IErrorPayload = yield response.json();
      if (response.status == 200) {
        yield put(resetPasswordSuccess(responsePayload));
        yield put(setLoginMenuScreen(loginMenuScreens.LOGIN));
      } else {
        yield put(resetPasswordFailure(responsePayload));
      }
    } catch (error) {
      const { message } = error as Error;
      yield put(resetPasswordFailure(parseFrontendError(message)));
    }
  } catch (error) {
    yield put(resetPasswordFailure(parseFrontendError(error)));
  }
}

const passwordPolicyResponsePayloadToViewModel = ({
  regularExpression,
}: IPasswordPolicyResponsePayload): IPasswordPolicyViewModel => ({
  regularExpression: RegExp(regularExpression),
});

const defaultPasswordRuleToViewModel = (
  PASSWORD_TEST_REGEX: RegExp
): IPasswordPolicyViewModel => ({
  regularExpression: PASSWORD_TEST_REGEX,
});

function* passwordPolicyRequestSaga() {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/pwd#getPolicy",
      {}
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const passwordPolicy: IPasswordPolicyResponsePayload = emResponse?.kwargs;
    const passwordPolicyViewModel: IPasswordPolicyViewModel =
      passwordPolicyResponsePayloadToViewModel(passwordPolicy);

    yield put(setPasswordPolicy(passwordPolicyViewModel));
  } catch (error) {
    yield put(
      setPasswordPolicy(defaultPasswordRuleToViewModel(PASSWORD_TEST_REGEX))
    );
  }
}

export default function* passwordSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.CHANGE_PASSWORD_REQUEST,
      changePasswordRequestSaga
    ),
    takeLatest(
      userActionTypes.RESET_PASSWORD_SEND_EMAIL_REQUEST,
      resetPasswordSendEmailRequestSaga
    ),
    // takeLatest(
    //   userActionTypes.RESET_PASSWORD_IS_KEY_AVAILABLE_REQUEST,
    //   resetPasswordIsKeyAvailableRequestSaga
    // ),
    takeLatest(
      userActionTypes.RESET_PASSWORD_REQUEST,
      resetPasswordRequestSaga
    ),
    takeLatest(
      userActionTypes.PASSWORD_POLICY_REQUEST,
      passwordPolicyRequestSaga
    ),
  ]);
}

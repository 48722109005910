import { IErrorPayload } from '../interfaces/root'
import { IPaymentMethodViewModel, IPaymentMethodsViewModel } from '../interfaces/paymentMethods'

export enum paymentMethodsActionTypes {
  GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST',
  GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS',
  GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE',
}

export type GetPaymentMethodsRequestAction = {
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_REQUEST
}

export type GetPaymentMethodsSuccessAction = {
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_SUCCESS
  payload: IPaymentMethodsViewModel
}

export type GetPaymentMethodsFailureAction = {
  type: paymentMethodsActionTypes.GET_PAYMENT_METHODS_FAILURE
  payload: IErrorPayload
}

export type PaymentMethodsActions =
  | GetPaymentMethodsRequestAction
  | GetPaymentMethodsSuccessAction
  | GetPaymentMethodsFailureAction

export type PaymentMethodsState = {
  pending: boolean
  paymentMethods: IPaymentMethodViewModel | null
}

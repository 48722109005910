import { IPageData, IShopItem } from "@/fe-core/meta/interfaces/page";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  pageActionTypes,
  GetPageFailureAction,
  GetPageRequestAction,
  GetPageSuccessAction,
  GetShopsPageFailureAction,
  GetShopsPageRequestAction,
  GetShopsPageSuccessAction,
} from "@/fe-core/meta/types/page";

export const getPageRequest = (payload: {
  slug: string;
}): GetPageRequestAction => ({
  type: pageActionTypes.GET_PAGE_REQUEST,
  payload,
});

export const getPageSuccess = (
  slug: string,
  data: IPageData
): GetPageSuccessAction => ({
  type: pageActionTypes.GET_PAGE_SUCCESS,
  payload: { slug, data },
});

export const getPageFailure = (
  payload: IErrorPayload
): GetPageFailureAction => ({
  type: pageActionTypes.GET_PAGE_FAILURE,
  payload,
});

export const getShopsPageSuccess = (
  data: IShopItem[]
): GetShopsPageSuccessAction => ({
  type: pageActionTypes.GET_SHOPS_PAGE_SUCCESS,
  payload: data,
});

export const getShopsPageFailure = (
  payload: IErrorPayload
): GetShopsPageFailureAction => ({
  type: pageActionTypes.GET_SHOPS_PAGE_FAILURE,
  payload,
});

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  ValidateActions,
  ValidateEmailState,
} from "@/fe-core/meta/types/user";

const initialState: ValidateEmailState = {
  pending: false,
  data: null,
  status: null,
};

const validateEmailReducer = (
  state: ValidateEmailState = initialState,
  action: ValidateActions | RootActions
): ValidateEmailState => {
  switch (action.type) {
    case userActionTypes.VALIDATE_EMAIL_REQUEST:
      return {
        ...state,
        pending: true,
        data: null,
        status: null,
      };
    case userActionTypes.VALIDATE_EMAIL_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
          message: "Email successfuly validated",
        },
      };
    case userActionTypes.VALIDATE_EMAIL_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
      case rootActionTypes.CLEAR_STATE:
        return {
          ...initialState,
        };
    default:
      return {
        ...state,
      };
  }
};

export default validateEmailReducer;

import { ISetThemePayloadModel } from '../interfaces/theme'

export enum themeActionTypes {
  SET_CURRENT_THEME = "SET_CURRENT_THEME",
  GET_CURRENT_THEME = "GET_CURRENT_THEME",
}

export type GetCurrentThemeAction = {
  type: themeActionTypes.GET_CURRENT_THEME
}

export type SetCurrentThemeAction = {
  type: themeActionTypes.SET_CURRENT_THEME,
  payload: ISetThemePayloadModel
}

export type themeActions =
  | GetCurrentThemeAction
  | SetCurrentThemeAction

export type themeState = {
  theme: string
}

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IRecentWinnerViewModel } from "@/fe-core/meta/interfaces/recentWinners";
import {
  recentWinnersActionTypes,
  GetRecentWinnersRequestAction,
} from "@/fe-core/meta/types/recentWinners";
import {
  getRecentWinnersFailure,
  getRecentWinnersSuccess,
} from "@/fe-core/_redux/actions/recentWinnersActions";

import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";

import qs from "qs"

function* getRecentWinnersSaga({ payload }: GetRecentWinnersRequestAction) {
  try {
    const query = qs.stringify(payload)
    const response: IJsonResponse<IRecentWinnerViewModel[] | IErrorPayload> =
      yield fetch(`/api/recentWinners?${query}`, {
        method: methodTypes.GET
      });

    if (response.status == 200) {
      const winners: IRecentWinnerViewModel[] = yield response.json();
      yield put(getRecentWinnersSuccess(winners));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getRecentWinnersFailure(errorPayload));
    }
  } catch (error) {
    yield put(getRecentWinnersFailure(parseFrontendError(error)));
  }
}

function* casinoSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(
      recentWinnersActionTypes.GET_RECENT_WINNERS_REQUEST,
      getRecentWinnersSaga
    ),
  ]);
}

export default casinoSaga;

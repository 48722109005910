import { userSelector } from "..";
import { RootState } from "@/fe-core/meta/types/root";
import { ExternalCashierState } from "@/fe-core/meta/types/user";

export const externalCashierSelector = (state: RootState): ExternalCashierState => {
  const { externalCashier } = userSelector(state);

  return externalCashier;
};

export * from "./paymentIq/transactionHistorySelectors";

// import { VENDORS_ICONS } from "@/config/icons";
import {
  GameTooltip,
  ICasinoAllGamesState,
  ICasinoCategoryViewModel,
  ICasinoGamesPayload,
  ICasinoGameViewModel,
  ICasinoGroupViewModel,
  IMobileGamePlayModalDetailsModel,
} from "@/fe-core/meta/interfaces/casino";
import {
  INavCasinoViewModel,
  INavItemViewModel,
} from "@/fe-core/meta/interfaces/nav";
import { IStatus } from "@/fe-core/meta/interfaces/root";
import {
  CasinoState,
  LiveCasinoGroupsState,
} from "@/fe-core/meta/types/casino";
import { RootState } from "@/fe-core/meta/types/root";

export const casinoSelector = ({ casino }: RootState): CasinoState => casino;

export const gameAlterModalSelector = (state: RootState): boolean => {
  const { isShowGameAlterModal } = casinoSelector(state);

  return isShowGameAlterModal;
};

export const isGameAlterHasFunModeSelector = (state: RootState): boolean => {
  const { alterGameHasFunMode } = casinoSelector(state);

  return alterGameHasFunMode;
};

export const categoriesSelector = (
  state: RootState
): ICasinoCategoryViewModel[] | null => {
  const { categories } = casinoSelector(state);

  return categories;
};

export const gamesSelector = (state: RootState): ICasinoGameViewModel[] => {
  const { games } = casinoSelector(state);

  return games;
};

export const singleGameSelector = (
  state: RootState
): ICasinoGameViewModel | null => {
  const { singleGame } = casinoSelector(state);

  return singleGame;
};

export const casinoAllGamesSelector = (
  state: RootState
): ICasinoAllGamesState => {
  const { casinoAllGames } = casinoSelector(state);
  return casinoAllGames;
};

export const mobileGamePlayModalDetailsSelector = (
  state: RootState
): IMobileGamePlayModalDetailsModel => {
  const { mobileGamePlayModalDetails } = casinoSelector(state);
  return mobileGamePlayModalDetails;
};

export const gameCardTooltipGameSelector = (
  state: RootState
): GameTooltip | null => {
  const { gameCardTooltip } = casinoSelector(state);

  return gameCardTooltip || null;
};

export const singleGamePendingSelector = (state: RootState): boolean => {
  const { singleGamePending } = casinoSelector(state);

  return singleGamePending;
};

export const countSelector = (state: RootState): number => {
  const { totalCount } = casinoSelector(state);

  return totalCount;
};

export const filtersSelector = (state: RootState): string[] => {
  const { filters } = casinoSelector(state);

  return filters;
};

export const casinoGroupsSelector = ({
  casinoGroups,
}: RootState): {
  pending: boolean;
  err: string;
  groups: ICasinoGroupViewModel[] | null;
} => casinoGroups.casinoGroups;

export const casinoGroupsMoreGamesPendingSelector = ({
  casinoGroups,
}: RootState): boolean => casinoGroups.casinoGroups.moreGamesLoading;

export const casinoHomeGroupsSelectorMain = ({
  casinoGroups,
}: RootState): {
  pending: boolean;
  err: string;
  groups: ICasinoGroupViewModel[] | null;
} => casinoGroups.casinoHomeGroups;

export const casinoHomeGroupsPendingSelector = (state: RootState): boolean => {
  const { pending } = casinoHomeGroupsSelectorMain(state);
  return pending;
};
export const casinoHomeGroupsSelector = (
  state: RootState
): ICasinoGroupViewModel[] | null => {
  const { groups } = casinoHomeGroupsSelectorMain(state);

  if (groups) {
    return groups;
  }

  return null;
};
export const getCasinoHomeGroupByIdSelector =
  (groupId: string) =>
    (state: RootState): ICasinoGroupViewModel | undefined => {
      const groups = casinoHomeGroupsSelector(state);
      if (!groups) {
        return undefined;
      }

      return groups.find((group) => {
        return group?.slug === decodeURIComponent(groupId)?.split("$")?.pop();
      });
    };

export const casinoGroupsPendingSelector = (state: RootState): boolean => {
  const { pending } = casinoGroupsSelector(state);
  return pending;
};

export const casinoPopularGamesGroupSelector = ({
  casinoGroups,
}: RootState): {
  pending: boolean;
  err: string;
  popularGamesGroup: ICasinoGroupViewModel[] | null;
} => casinoGroups.popularGroups;

export const casinoPopularGamesGroupByIdSelector =
  (groupId: string) =>
    (state: RootState): ICasinoGroupViewModel | undefined => {
      const groups = casinoPopularGamesGroupSelector(state).popularGamesGroup;

      if (!groups) {
        return undefined;
      }

      return groups.find((group) => {
        return group.id === groupId;
      });
    };

export const liveCasinoPopularGamesGroupSelector = ({
  casinoGroups,
}: RootState): {
  pending: boolean;
  err: string;
  popularLiveGamesGroup: ICasinoGroupViewModel[] | null;
} => casinoGroups.popularLiveGroups;

export const liveCasinoPopularGamesGroupByIdSelector =
  (groupId: string) =>
    (state: RootState): ICasinoGroupViewModel | undefined => {
      const groups =
        liveCasinoPopularGamesGroupSelector(state).popularLiveGamesGroup;

      console.log("groups", groups);
      if (!groups) {
        return undefined;
      }

      return groups.find((group) => {
        return group.id === groupId;
      });
    };

export const casinoAuditGroupSelector = ({
  casinoGroups,
}: RootState): {
  pending: boolean;
  err: string;
  auditGamesGroup: ICasinoGroupViewModel[] | null;
} => casinoGroups.auditGroups;

export const groupsSelector = (
  state: RootState
): ICasinoGroupViewModel[] | null => {
  const { groups } = casinoGroupsSelector(state);

  if (groups) {
    return groups;
  }

  return null;
};

export const getGroupByIdSelector =
  (groupId: string) =>
    (state: RootState): ICasinoGroupViewModel | undefined => {
      const groups = groupsSelector(state);

      if (!groups) {
        return undefined;
      }

    return groups.find((group) => {
      return group?.slug === decodeURIComponent(groupId)?.split("$")?.pop();
    });
  };

export const liveCasinoGroupsSelector = ({
  liveCasinoGroups,
}: RootState): LiveCasinoGroupsState => liveCasinoGroups;

export const liveGroupsSelector = (
  state: RootState
): ICasinoGroupViewModel[] | null => {
  const { groups } = liveCasinoGroupsSelector(state);

  return groups;
};

export const liveGroupsPendingSelector = (state: RootState): boolean => {
  const { pending } = liveCasinoGroupsSelector(state);

  return pending;
};

export const casinoAllGamesPendingSelector = (state: RootState): boolean => {
  const { pending } = casinoAllGamesSelector(state);

  return pending;
};

export const casinoAllMoreGamesPendingSelector = (state: RootState): boolean => {
  const { moreGamesPending } = casinoAllGamesSelector(state);

  return moreGamesPending;
};

export const casinoAllGamesDataSelector = (state: RootState): ICasinoGamesPayload | null => {
  const { data } = casinoAllGamesSelector(state);

  return data;
};

export const casinoAllGamesStatusSelector = (state: RootState): IStatus | null=> {
  const { status } = casinoAllGamesSelector(state);

  return status;
};

export const liveGroupsMoreGamesPendingSelector = (state: RootState): boolean => {
  const { moreGamesLoading } = liveCasinoGroupsSelector(state);

  return moreGamesLoading;
};

export const getLiveGroupByIdSelector =
  (groupId: string) =>
    (state: RootState): ICasinoGroupViewModel | undefined => {
      const groups = liveGroupsSelector(state);

      if (!groups) {
        return undefined;
      }

    return groups.find((group) => {
      return group?.slug === decodeURIComponent(groupId)?.split("$")?.pop();
    });
  };

export const casinoNavSelector = (
  state: RootState
): INavCasinoViewModel | null => {
  const { groups } = casinoGroupsSelector(state);

  const categories = ["Promotii Casino", "Cazino Live"];
  const items: { [key: string]: INavItemViewModel[] } = {
    "Promotii Cazino": [
      {
        name: "Promoții Cazino",
        slug: "/promotions/cazinou",
        icon: "promo",
        color: "primary",
      },
    ],
    "Cazinou Live": [
      {
        name: "Cazino Live",
        slug: "/casino/live",
        icon: "live-casino",
        color: "primary",
      },
    ],
  };

  if (groups?.length) {
    const popularGroups: INavItemViewModel[] = groups.map((group) => {
      const categorySlug = group.id
        .substring(group.id.indexOf("$") + 1)
        .replace("&", "");

      return {
        name: group.name,
        slug: `/casino/${categorySlug}`,
        icon: categorySlug,
        color: "primary",
      };
    });

    popularGroups.splice(1, 0, {
      name: `RTP Live`,
      slug: `/casino/rtp-live`,
      icon: `hot`,
      color: "danger",
      isNew: true,
    });

    items["Categorii Populare"] = popularGroups;
    categories.push("Categorii Populare");
  }

  // if (vendors?.length) {
  //   const vends = vendors.map((vendor) => {
  //     const iconName = VENDORS_ICONS[vendor.displayName]
  //       ? VENDORS_ICONS[vendor.displayName]
  //       : "sports";
  //     return {
  //       name: vendor.displayName,
  //       slug: `/casino/search?vendor=${vendor.displayName}`,
  //       icon: iconName,
  //     };
  //   });

  //   items["Furnizori"] = vends;
  //   categories.push("Furnizori");
  // }

  const casinoNav: INavCasinoViewModel = {
    title: "Casino",
    items,
    categories,
  };

  return casinoNav;
};

export const gameFullScreenButtonSelector = (state: RootState): boolean => {
  const { isShowGameFullScreenButton } = casinoSelector(state);

  return isShowGameFullScreenButton;
};

export const showGameInFullScreenSelector = (state: RootState): boolean => {
  const { isShowGameInFullScreen } = casinoSelector(state);

  return isShowGameInFullScreen;
};
import { ISmarticoPublicPropsModel } from "@/fe-core/meta/interfaces/smartico/publicProps";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
    GetSmarticoPublicPropsFailureAction,
    GetSmarticoPublicPropsRequestAction,
    GetSmarticoPublicPropsSuccessAction,
    smarticoPublicPropsActionTypes,
} from "@/fe-core/meta/types/smartico/publicProps";

export const getSmarticoPublicPropsRequest = (): GetSmarticoPublicPropsRequestAction => ({
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_REQUEST,
});

export const getSmarticoPublicPropsSuccess = (
    payload: ISmarticoPublicPropsModel
): GetSmarticoPublicPropsSuccessAction => ({
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_SUCCESS,
    payload,
});

export const getSmarticoPublicPropsFailure = (
    payload: IErrorPayload
): GetSmarticoPublicPropsFailureAction => ({
    type: smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_FAILURE,
    payload,
});

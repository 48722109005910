import {
  EnableCoolOffRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { enableCoolOffFailure } from "@/fe-core/_redux/actions/userActions/coolOff";
import { IGetLimitsResponsePayload, } from "@/fe-core/meta/interfaces/user";
import { logoutRequest } from "@/fe-core/_redux/actions/sessionActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { buildCoolOffPayload } from "@/fe-core/helpers/coolOff";

function* enableCoolOffRequestSaga({ payload }: EnableCoolOffRequestAction) {
  try {
    const coolOffPayload = buildCoolOffPayload(payload);

    // //@todo check for date OR period
    // let limit = {
    //   coolOff: coolOffPayload,
    // };

    //enableCoolOffPayloadToRequestPayload(payload);
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IGetLimitsResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/limits`, <RequestInit>{
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),

        //@todo, write map function
        body: JSON.stringify(coolOffPayload),
      });

    if (response.status == 200) {
      yield put(logoutRequest());
    }
  } catch (error) {
    yield put(enableCoolOffFailure(parseFrontendError(error)));
  }
}

export default function* coolOffSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.ENABLE_COOL_OFF_REQUEST,
      enableCoolOffRequestSaga
    ),
  ]);
}

import {
  TopWinnersActions,
  topWinnersActionTypes,
  TopWinnersState,
} from "@/fe-core/meta/types/topWinners";

const initialState: TopWinnersState = {
  topWinners: [],
  pending: false,
  status: null,
};

const topWinnersReducer = (
  state: TopWinnersState = initialState,
  action: TopWinnersActions
): TopWinnersState => {
  switch (action.type) {
    case topWinnersActionTypes.GET_TOP_WINNERS_REQUEST:
      return {
        ...state,
        pending: true,
        topWinners: [],
        status: null,
      };
    case topWinnersActionTypes.GET_TOP_WINNERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        topWinners: payload,
        status: {
          success: true,
        },
      };
    }
    case topWinnersActionTypes.GET_TOP_WINNERS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        topWinners: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default topWinnersReducer;

import {
  casinoGamesHighlightedActions,
  casinoGamesHighlightedActionTypes,
  CasinoGamesHighlightedState,
} from "@/fe-core/meta/types/casinoGamesHighlighted";

const initialState: CasinoGamesHighlightedState = {
  pending: false,
  gamesHighlighted: null,
  status: null,
};

const casinoGamesHighlightedReducer = (
  state: CasinoGamesHighlightedState = initialState,
  action: casinoGamesHighlightedActions
): CasinoGamesHighlightedState => {
  switch (action.type) {
    case casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        gamesHighlighted: payload,
        status: {
          success: true,
        },
      };
    }
    case casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default casinoGamesHighlightedReducer;

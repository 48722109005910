import { RootState } from "@/fe-core/meta/types/root";
import { RoutingState } from "@/fe-core/meta/types/routing";

export const routingSelector = ({ routing }: RootState): RoutingState =>
  routing;

export const routingBackRouteSelector = (state: RootState): string => {
  const { backRoute } = routingSelector(state);

  return backRoute;
};

export const loginNextRouteSelector = (state: RootState): string => {
  const { loginNextRoute } = routingSelector(state);

  return loginNextRoute;
};

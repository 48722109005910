import { combineReducers } from "redux";
import balanceReducer from "./balanceReducer";
// import bettingHistoryReducer from "./bettingHistoryReducer";
import bonusReducer from "./bonusReducer";
import cashierReducer from "./cashierReducer";
import casinoHistoryReducer from "./casinoHistoryReducer";
import consentsReducer from "./consentsReducer";
import documentsReducer from "./documentsReducer";
import favoritesReducer from "./favoritesReducer";
import limitsReducer from "./limitsReducer";
import passwordReducer from "./passwordReducer";
import profileReducer from "./profileReducer";
import realityCheckReducer from "./realityCheckReducer";
import registerReducer from "./registerReducer";
import rolesReducer from "./rolesReducer";
import selfExclusionReducer from "./selfExclusionReducer";
import regionsReducer from "./regionsReducer";
import coolOffReducer from "./coolOffReducer";
import restrictionsReducer from "./restrictionsReducer";
import recentPlayedReducer from "./recentPlayedReducer";
import recommendedReducer from "./recommendedReducer";
import balanceWarningReducer from "./balanceWarningReducer";
import loginsReducer from "./loginsReducer";
import ExternalCashierReducer from "./externalCashierReducer";
import affiliateReducer from "./affiliateReducer/affiliateReducer";
import campaignsReducer from "./affiliateReducer/campaignsReducer";
import claimReducer from "./affiliateReducer/claimReducer";
import gameSessionsReducer from "./gameSessionsReducer";
import sessionLimitReducer from "./sessionLimitReducer";
import cashbackReducer from "./cashbackReducer";
import tagsReducer from "./tagsReducer";
import levelupDetailsReducer from "./levelupReducer";

const userReducer = combineReducers({
  balance: balanceReducer,
  cashback: cashbackReducer,
  levelupDetails: levelupDetailsReducer,
  profile: profileReducer,
  password: passwordReducer,
  consents: consentsReducer,
  limits: limitsReducer,
  register: registerReducer,
  roles: rolesReducer,
  tags: tagsReducer,
  // bettingHistory: bettingHistoryReducer,
  casinoHistory: casinoHistoryReducer,
  bonus: bonusReducer,
  favorites: favoritesReducer,
  realityCheck: realityCheckReducer,
  cashier: cashierReducer,
  externalCashier: ExternalCashierReducer,
  selfExclusion: selfExclusionReducer,
  documents: documentsReducer,
  regions: regionsReducer,
  coolOff: coolOffReducer,
  restrictions: restrictionsReducer,
  recentPlayed: recentPlayedReducer,
  recommended: recommendedReducer,
  balanceWarning: balanceWarningReducer,
  logins: loginsReducer,
  affiliate: affiliateReducer,
  campaigns: campaignsReducer,
  claim: claimReducer,
  gameSessions: gameSessionsReducer,
  sessionLimit: sessionLimitReducer,
});

export default userReducer;

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IRestrictionTCRequestPayload,
  IRestrictionUserDetailsPayload,
} from "@/fe-core/meta/interfaces/user/restrictions";
import {
  GetTCRequirementsFailureAction,
  GetTCRequirementsRequestAction,
  GetTCRequirementsSuccessAction,
  ResolveTCRestrictionFailureAction,
  ResolveTCRestrictionRequestAction,
  ResolveTCRestrictionSuccessAction,
  SetMarketingRestrictionsAction,
  SetTCRestrictionsAction,
  SetRestrictionsUserDetailsAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const setTCRestrictions = (): SetTCRestrictionsAction => ({
  type: userActionTypes.SET_TC_RESTRICTIONS,
});

export const resolveTCRestrictionRequest = (
  payload: IRestrictionTCRequestPayload
): ResolveTCRestrictionRequestAction => ({
  type: userActionTypes.RESOLVE_TC_RESTRICTION_REQUEST,
  payload,
});

export const resolveTCRestrictionSuccess =
  (): ResolveTCRestrictionSuccessAction => ({
    type: userActionTypes.RESOLVE_TC_RESTRICTION_SUCCESS,
  });

export const resolveTCRestrictionFailure = (
  payload: IErrorPayload
): ResolveTCRestrictionFailureAction => ({
  type: userActionTypes.RESOLVE_TC_RESTRICTION_FAILURE,
  payload,
});

export const setMarketingRestrictions = (): SetMarketingRestrictionsAction => ({
  type: userActionTypes.SET_MARKETING_RESTRICTIONS,
});

export const setRestrictionsUserDetails = (
  //@ts-ignore
  payload
): SetRestrictionsUserDetailsAction => ({
  type: userActionTypes.SET_RESTRICTION_USER_DETAILS,
  //@ts-ignore
  payload,
});

export const getTCRequirementsRequest = (): GetTCRequirementsRequestAction => ({
  type: userActionTypes.GET_TC_REQUIREMENTS_REQUEST,
});

export const getTCRequirementsSuccess = (): GetTCRequirementsSuccessAction => ({
  type: userActionTypes.GET_TC_REQUIREMENTS_SUCCESS,
});

export const getTCRequirementsFailure = (
  payload: IErrorPayload
): GetTCRequirementsFailureAction => ({
  type: userActionTypes.GET_TC_REQUIREMENTS_FAILURE,
  payload,
});

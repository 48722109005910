import { all, AllEffect, ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import {
  userActionTypes,
  SignupEmailRequestAction,
  SignupEmailResendRequestAction,
} from "@/fe-core/meta/types/user";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { signupEmailResendFailure, signupEmailResendSuccess } from "@/fe-core/_redux/actions/userActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { setLoginMenuScreen } from "@/fe-core/_redux/actions/menusActions";
import { loginMenuScreens, menuStatus } from "@/fe-core/meta/types/menus";
import { loginMenuStatusSelector } from "@/fe-core/_redux/selectors/menusSelectors";

function* signupEmailRequestSaga({ payload }: SignupEmailRequestAction) {
  try {
    yield fetch(`/api/user/signup/email`, {
      method: "POST",
      body: JSON.stringify(payload),
    });
  } catch {}
}

function* signupEmailResendRequestSaga({
  payload,
}: SignupEmailResendRequestAction) {
  try {
    const { locale } = yield select(localeSelector)
    const loginMenuStatus: menuStatus = yield select(loginMenuStatusSelector);
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/email/resend`,
      {
        method: "POST",
        body: JSON.stringify({ ...payload, locale }),
      }
    );

    const { status } = response;
    
    switch (status) {
      case 200:
        yield put(signupEmailResendSuccess());
        if(loginMenuStatus === "open") yield put(setLoginMenuScreen(loginMenuScreens.VERIFY_EMAIL));
        break;
      default:
        const errModel: { message: string } = yield response.json();
        throw new Error(errModel.message);
    }
  } catch (error) {
    yield put(signupEmailResendFailure(parseFrontendError(error)));
  }
}

function* signupEmailSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.SIGNUP_EMAIL_REQUEST, signupEmailRequestSaga),
  ]);
  yield all([
    takeLatest(
      userActionTypes.SIGNUP_RESEND_EMAIL_REQUEST,
      signupEmailResendRequestSaga
    ),
  ]);
}

export default signupEmailSaga;

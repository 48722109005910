import {
  ChatboxActions,
  ChatboxState,
  chatboxActionTypes,
} from "@/fe-core/meta/types/chatbox";

const initialState: ChatboxState = {
  isChatboxOpen: false,
};

const chatboxReducer = (
  state: ChatboxState = initialState,
  action: ChatboxActions
): ChatboxState => {
  switch (action.type) {
    case chatboxActionTypes.TOGGLE_CHATBOX:
      return {
        ...state,
        isChatboxOpen: !state.isChatboxOpen,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chatboxReducer;

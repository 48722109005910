import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  UserActions,
  DepositState,
  userActionTypes,
  DepositActions,
} from "@/fe-core/meta/types/user";

const initialState: DepositState = {
  pending: false,
  data: null,
  status: null,
  showDepositModal: false,
  depositTempData: null,
  depositMethods: null
};

const depositReducer = (
  state: DepositState = initialState,
  action: RootActions | DepositActions
): DepositState => {
  switch (action.type) {
    case userActionTypes.SET_OPEN_DEPOSIT_MODAL:
      return {
        ...state,
        showDepositModal: true,
      };

    case userActionTypes.SET_CLOSE_DEPOSIT_MODAL:
      return {
        ...state,
        showDepositModal: false,
      };

    case userActionTypes.GET_DEPOSIT_METHODS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_DEPOSIT_METHODS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        depositMethods: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_DEPOSIT_METHODS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    // case userActionTypes.DEPOSIT_REQUEST:
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     data: payload,
    //     depositTempData: payload,
    //     pending: true,
    //     status: null,
    //   };
    // case userActionTypes.DEPOSIT_SUCCESS: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     pending: false,
    //     data: payload,
    //     status: {
    //       success: true,
    //     },
    //   };
    // }
    // case userActionTypes.DEPOSIT_FAILURE: {
    //   const {
    //     payload: { message },
    //   } = action;
    //   return {
    //     ...state,
    //     pending: false,
    //     status: {
    //       success: false,
    //       message,
    //     },
    //   };
    // }
    case userActionTypes.CLEAR_DEPOSIT:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...state.depositTempData,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default depositReducer;

import { ICeSessionViewModel } from "@/fe-core/meta/interfaces/ceSession";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetCeSessionRequestAction,
  GetCeSessionSuccessAction,
  GetCeSessionFailureAction,
  ceSessionActionTypes,
} from "@/fe-core/meta/types/ceSession";

export const getCeSessionRequest = (): GetCeSessionRequestAction => ({
  type: ceSessionActionTypes.GET_CE_SESSION_REQUEST,
});

export const getCeSessionSuccess = (
  payload: ICeSessionViewModel
): GetCeSessionSuccessAction => ({
  type: ceSessionActionTypes.GET_CE_SESSION_SUCCESS,
  payload,
});

export const getCeSessionFailure = (
  payload: IErrorPayload
): GetCeSessionFailureAction => ({
  type: ceSessionActionTypes.GET_CE_SESSION_FAILURE,
  payload,
});

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  IGameSessionsPayload,
  IGameSessionsViewModel,
} from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  GameSessionsRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  gameSessionsFailure,
  gameSessionsSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

const getURLParams = (payload: IGameSessionsPayload) => {
  const qs = Object.keys(payload)
    .map((key) => `${key}=${payload[key as keyof IGameSessionsPayload]}`)
    .join("&");

  return qs ? `?${qs}` : "";
};

function* getGameSessionsRequestSaga({ payload }: GameSessionsRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const queryParams = getURLParams(payload);

    const response: IJsonResponse<IGameSessionsViewModel | IErrorPayload> =
      yield fetch(`/api/user/gameSessions${queryParams}`, <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: IGameSessionsViewModel = yield response.json();

      yield put(gameSessionsSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(gameSessionsFailure(errorPayload));
    }
  } catch (error) {
    yield put(gameSessionsFailure(parseFrontendError(error)));
  }
}

export default function* gameSessionsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.GAME_SESSIONS_REQUEST,
      getGameSessionsRequestSaga
    ),
  ]);
}

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getUserConsentsFailure,
  getUserConsentsSuccess,
} from "@/fe-core/_redux/actions/userActions/registerActions/userConsentsActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user/index";
import {
  IConsentsRequirementResponsePayload,
  IConsentItem,
} from "@/fe-core/meta/interfaces/user/register/consentRequirements";

function* getUserConsentsSaga() {
  try {
    const response: IJsonResponse<IConsentsRequirementResponsePayload> =
      yield fetch(`/api/user/consentRequirements`, {
        method: methodTypes.GET,
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IConsentItem[] = yield response.json();
        yield put(getUserConsentsSuccess(viewModel));
        break;
      default:
        throw new Error("User Consents couldn't be fetched");
    }
  } catch (error) {
    yield put(getUserConsentsFailure(parseFrontendError(error)));
  }
}

function* userConsentsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_USER_CONSENTS_REQUEST, getUserConsentsSaga),
  ]);
}

export default userConsentsSaga;

"use client";

import { useSearchParams, usePathname } from "next/navigation";
import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_COUNTRY_CODE,
  storageKeys,
  VERSION_COOKIE,
} from "@/config/general";
import Storage, { CookiesStorage } from "../fe-core-omega/helpers/storage";
import { closeNavMenu } from "../fe-core-omega/_redux/actions/menusActions";
import {
  authenticateRequest,
  anonymousSessionRequest,
} from "../fe-core-omega/_redux/actions/sessionActions";

import {
  isUserAuthenticatedSelector,
  sessionAuthenticatePendingSelector,
} from "../fe-core-omega/_redux/selectors/sessionSelectors";
import { getMessageFallback, onError } from "../fe-core-omega/helpers/i18n";
import { AbstractIntlMessages, NextIntlClientProvider } from "next-intl";
import { useTheme } from "next-themes";
import { getRegistrationContentRequest } from "../fe-core-omega/_redux/actions/registrationContentActions";
import {
  AFFILIATE_MARKER_QUERY_KEYS,
  DEFAULT_AFFILIATE_MARKER_COOKIES_EXPIRES_DAYS,
} from "@/config/affiliate";
import { initDrawer } from "../fe-core-omega/_redux/actions/drawerActions";
import { useScreen } from "../context/ScreenContext";
import { getBonusesRequest, validateSignupIPRequest } from "@/fe-core/_redux/actions/userActions";
import { clearStorage } from "@/fe-core/helpers/register";

type Props = {
  children: ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
};

const InitWrapper: FC<Props> = ({ children, locale, messages }: Props) => {
  const searchParams = useSearchParams() as URLSearchParams;
  const pathname = usePathname();
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const { isMobile, isTablet } = useScreen();
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const sessionAuthenticatedPending = useSelector(
    sessionAuthenticatePendingSelector
  );

  useEffect(() => {
    const getCountry = async () => {
      const response = await fetch(
        `https://www.ftvclub.com/cdn-cgi/trace`
      );
      const data = await response.text();
      const arr = data.split("\n");
      const country =
        arr.find((v) => v.includes("loc="))?.replace("loc=", "") ??
        DEFAULT_COUNTRY_CODE;
      CookiesStorage.setItem(storageKeys.COUNTRY, country as string);
    };
    getCountry();
  }, []);

  useEffect(() => {
    dispatch(
      initDrawer({ isMobile: isMobile || false, isTablet: isTablet || false })
    );
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (!sessionAuthenticatedPending && !isAuthenticated) {
      dispatch(getRegistrationContentRequest());
      dispatch(validateSignupIPRequest());
    }
  }, [sessionAuthenticatedPending, isAuthenticated]);

  useEffect(() => {
    const sessionId = Storage.get(storageKeys.SESSION_ID) as string;
    if (sessionId) {
      dispatch(
        authenticateRequest({
          sessionId,
          locale,
          theme,
          isAutoLogin: true,
        })
      );
    } else {
      clearStorage();
      // If we don't have an ID, we need to get defaults based on locales
      //@todo, move theme to separate reducer
      dispatch(
        anonymousSessionRequest({
          locale,
          theme,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(closeNavMenu());
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!sessionAuthenticatedPending && isAuthenticated) {
      dispatch(getBonusesRequest({}));
    }
  }, [dispatch, isAuthenticated, sessionAuthenticatedPending]);

  useEffect(() => {
    const getCookieOptions = () => {
      const cookieOptions: { expires: number; domain?: string } = {
        expires: DEFAULT_AFFILIATE_MARKER_COOKIES_EXPIRES_DAYS,
      };
      if (process.env.NODE_ENV !== "development") {
        const url =
          process.env.NEXT_PUBLIC_BASE_URL &&
          new URL(process.env.NEXT_PUBLIC_BASE_URL);
        cookieOptions.domain = url && url.hostname;
      }
      return cookieOptions;
    };

    const affiliateMarkerKey = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) => !!searchParams?.get(affiliateMarkerQueryKey)
    );

    if (affiliateMarkerKey) {
      let affiliateMarker = searchParams?.get(affiliateMarkerKey);

      if (Array.isArray(affiliateMarker)) {
        affiliateMarker = affiliateMarker[0];
      }

      if (affiliateMarker) {
        const options = getCookieOptions();
        CookiesStorage.setItem(
          affiliateMarkerKey,
          affiliateMarker as string,
          options
        );

        Storage.set(storageKeys.AFFILIATE_MARKER, affiliateMarker as string);
      }
    }

    const versionCookie = searchParams?.get(VERSION_COOKIE);
    const options = getCookieOptions();

    if (versionCookie) {
      CookiesStorage.setItem("aw-version", versionCookie as string, options);
    }
  }, [dispatch, searchParams]);

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      timeZone={process.env.NEXT_PUBLIC_DEFAULT_TIMEZONE}
      onError={onError}
      getMessageFallback={getMessageFallback}
    >
      {children}
    </NextIntlClientProvider>
  );
};

export default InitWrapper;

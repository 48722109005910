import { combineReducers } from "redux";
import depositBonusesReducer from "./depositBonusesReducer";
import depositReducer from "./depositReducer";
import pendingWithdrawalsReducer from "./pendingWithdrawalsReducer";
import processDepositReducer from "./processDepositReducer";
import processWithdrawReducer from "./processWithdrawReducer";
import transactionHistoryReducer from "./transactionHistoryReducer";
import transactionSummaryReducer from "./transactionSummaryReducer";
import withdrawReducer from "./withdrawReducer";
import bankingHistoryReducer from "./bankingHistoryReducer";
import prepareWithdrawReducer from "./prepareWithdrawReducer";

const cashierReducer = combineReducers({
  deposit: depositReducer,
  depositBonuses: depositBonusesReducer,
  withdraw: withdrawReducer,
  pendingWithdrawals: pendingWithdrawalsReducer,
  transactionHistory: transactionHistoryReducer,
  bankingHistory: bankingHistoryReducer,
  transactionSummary: transactionSummaryReducer,
  processDeposit: processDepositReducer,
  processWithdraw: processWithdrawReducer,
  prepareWithdraw: prepareWithdrawReducer,
});

export default cashierReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { loyaltyLevelsActionTypes } from "@/fe-core/meta/types/loyaltyLevels";
import { ILoyaltyLevelsResponseModel } from "@/fe-core/meta/interfaces/loyaltyLevels";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getLoyaltyLevelsFailure,
  getLoyaltyLevelsSuccess,
} from "@/fe-core/_redux/actions/loyaltyLevelsActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildLoyaltyLevelsQueryString } from "@/fe-core/helpers/loyaltyLevels";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getLoyaltyLevelsSaga() {
  try {
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);
    const queryString: string = buildLoyaltyLevelsQueryString(theme);

    const response: IJsonResponse<ILoyaltyLevelsResponseModel[]> = yield fetch(
      `/api/loyalty-levels-content${queryString}`,
      {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: ILoyaltyLevelsResponseModel = yield response.json();
        yield put(getLoyaltyLevelsSuccess(viewModel));
        break;
      default:
        throw new Error("LoyaltyLevels couldn't be fetched");
    }
  } catch (error) {
    yield put(getLoyaltyLevelsFailure(parseFrontendError(error)));
  }
}

function* loyaltyLevelsSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_REQUEST, getLoyaltyLevelsSaga),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getLoyaltyLevelsSaga),
  ]);
}

export default loyaltyLevelsSaga;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  userActionTypes,
  SignupRequestAction,
  signupScreens,
} from "@/fe-core/meta/types/user";
import {
  checkCredentialEmailFailure,
  checkCredentialEmailSuccess,
  checkCredentialMobileFailure,
  checkCredentialMobileSuccess,
  quickSignupFailure,
  quickSignupSuccess,
  setActiveSignupScreen,
  signupFailure,
  signupSuccess,
  usernameAvailabilityCheckFailure,
  usernameAvailabilityCheckSuccess,
} from "@/fe-core/_redux/actions/userActions";
import TagManager from "react-gtm-module";
import { encodeToB64 } from "@/fe-core/helpers/general";
import { CheckCredentialRequestAction, QuickSignupRequestAction, SignupUsernameCheckAction } from "@/fe-core/meta/types/user/register/signup";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { headerKeys } from "@/config/general";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { methodTypes } from "@/fe-core/meta/types/root";

function* quickSignupRequestSaga({ payload }: QuickSignupRequestAction) {
  try {
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/quickRegister`,
      {
        method: "POST",
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(payload),
      }
    );
    const { status } = response;
    switch (status) {
      case 204:
        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: "registrationCompleted",
        //     username: encodeToB64(payload.username),
        //     email: payload.email,
        //   },
        // });
        yield put(quickSignupSuccess());
        yield put(setActiveSignupScreen(signupScreens.EMAIL_CONFIRMATION));
        break;
      default:
        const errorPayload: IErrorPayload = yield response.json();
        yield put(quickSignupFailure(errorPayload));
      // throw new Error("Could not sign up user");
    }
  } catch (error) {
    const { message } = error as Error;
    yield put(quickSignupFailure({ message }));
  }
}

function* signupRequestSaga({ payload }: SignupRequestAction) {
  try {
    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);

    const requestPayload =
      process.env.NEXT_PUBLIC_HAS_AFFILIATE_SYNC === "YES"
        ? {
            ...payload,
            userTrackingCodes: btag ? JSON.stringify({ btag }) : "",
          }
        : payload;

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup`,
      {
        method: "POST",
        body: JSON.stringify(requestPayload),
      }
    );
    const { status } = response;
   
    switch (status) {
      case 204:
        TagManager.dataLayer({
          dataLayer: {
            event: "registrationCompleted",
            username: encodeToB64(payload.username),
            email: payload.email,
          },
        });
        yield put(signupSuccess());
        yield put(setActiveSignupScreen(signupScreens.EMAIL_CONFIRMATION));
        break;
      default:
        const errorPayload: IErrorPayload = yield response.json()
        throw new Error(errorPayload.message || "Could not sign up user");
    }
  } catch (error) {
    const { message } = error as Error;
    yield put(signupFailure({ message }));
  }
}

function* signupCheckUsernameRequestSaga({ payload }: SignupUsernameCheckAction) {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/usernameCheck`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    const { status } = response;
   
    switch (status) {
      case 200:
        yield put(usernameAvailabilityCheckSuccess());
        break;
      default:
        const errorPayload: IErrorPayload = yield response.json()
        yield put(usernameAvailabilityCheckFailure(errorPayload));
    }
  } catch (error) {
    const { message } = error as Error;
    yield put(usernameAvailabilityCheckFailure({ message }));
  }
}

function* checkCredentialRequestSaga({
  payload,
}: CheckCredentialRequestAction) {
  const { field } = payload || {};
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/checkCredential`,
      {
        method: methodTypes.POST,
        body: JSON.stringify(payload),
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        if (field === "email") yield put(checkCredentialEmailSuccess());
        else if (field === "mobilePhone") yield put(checkCredentialMobileSuccess());
         
        break;
      default:
        const errorPayload: IErrorPayload = yield response.json();
        if (field === "email") yield put(checkCredentialEmailFailure(errorPayload));
        else if (field === "mobilePhone")
          yield put(checkCredentialMobileFailure(errorPayload));
    }
  } catch (error) {
    const { message } = error as Error;
    if (field === "email") yield put(checkCredentialEmailFailure({ message }));
    else if (field === "mobilePhone")
      yield put(checkCredentialMobileFailure({ message }));
  }
}

function* signupSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(userActionTypes.SIGNUP_REQUEST, signupRequestSaga),
    takeLatest(
      userActionTypes.SIGNUP_USERNAME_CHECK_REQUEST,
      signupCheckUsernameRequestSaga
    ),
    takeLatest(
      userActionTypes.CHECK_CREDENTIAL_REQUEST,
      checkCredentialRequestSaga
    ),
    takeLatest(userActionTypes.QUICK_SIGNUP_REQUEST, quickSignupRequestSaga),
  ]);
}

export default signupSaga;

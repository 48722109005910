import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  UserActions,
  SelfExclusionState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: SelfExclusionState = {
  pending: false,
  data: null,
  status: null,
};

const selfExclusionReducer = (
  state: SelfExclusionState = initialState,
  action: UserActions | RootActions
): SelfExclusionState => {
  switch (action.type) {
    case userActionTypes.SELF_EXCLUSION_CONFIG_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.SELF_EXCLUSION_CONFIG_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.SELF_EXCLUSION_CONFIG_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.SELF_EXCLUSION_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.SELF_EXCLUSION_SUCCESS: {
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.SELF_EXCLUSION_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default selfExclusionReducer;

import { ICMSJackpotWinnerViewModel } from "@/fe-core/meta/interfaces/jackpotWinners";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetJackpotWinnersFailureAction,
  GetJackpotWinnersRequestAction,
  GetJackpotWinnersSuccessAction,
  jackpotWinnersActionTypes,
} from "@/fe-core/meta/types/jackpotWinners";

export const getJackpotWinnersRequest = (): GetJackpotWinnersRequestAction => ({
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_REQUEST,
});

export const getJackpotWinnersSuccess = (
  payload: ICMSJackpotWinnerViewModel[]
): GetJackpotWinnersSuccessAction => ({
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_SUCCESS,
  payload,
});

export const getJackpotWinnersFailure = (
  payload: IErrorPayload
): GetJackpotWinnersFailureAction => ({
  type: jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_FAILURE,
  payload,
});

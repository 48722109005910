import { storageKeys } from "@/config/general";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import {
  localeActions,
  localeState,
  localeActionTypes,
} from "@/fe-core/meta/types/locale";

const initialState: localeState = {
  locale: (CookiesStorage.getItem(storageKeys.LOCALE) as string) || "en",
  isOpenLocaleCurrenyModal: false,
  activeLocaleCurrenyTabIndex: 0
};

const localeReducer = (
  state: localeState = initialState,
  action: localeActions
): localeState => {
  switch (action.type) {
    case localeActionTypes.SET_CURRENT_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case localeActionTypes.TOGGLE_LOCALE_CURRENCY_MODAL:
      return {
        ...state,
        isOpenLocaleCurrenyModal: action.payload,
      };
    case localeActionTypes.SET_ACTIVE_LOCALE_CURRENCY_TAB:
      return {
        ...state,
        activeLocaleCurrenyTabIndex: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default localeReducer;

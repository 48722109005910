import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  SignupSmsState,
  SignupSmsActions,
} from "@/fe-core/meta/types/user";

const initialState: SignupSmsState = {
  data: null,
  getSms: {
    pending: false,
    status: null,
  },
  resend: {
    pending: false,
    status: null,
  },
};

const signupSmsReducer = (
  state: SignupSmsState = initialState,
  action: SignupSmsActions | RootActions
): SignupSmsState => {
  switch (action.type) {
    case userActionTypes.SIGNUP_SMS_REQUEST:
      return {
        ...state,
        getSms: { pending: true, status: null },
      };
    case userActionTypes.SIGNUP_SMS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        data: payload,
        getSms: {
          pending: false,
          status: {
            success: true,
            message: "SMS confirmed successfully",
          },
        },
      };
    case userActionTypes.SIGNUP_SMS_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        getSms: {
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    case userActionTypes.SIGNUP_SMS_RESEND_REQUEST:
      return {
        ...state,
        resend: { pending: true, status: null },
      };
    case userActionTypes.SIGNUP_SMS_RESEND_SUCCESS:
      return {
        ...state,
        resend: {
          pending: false,
          status: {
            success: true,
            message: "SMS resent successfully",
          },
        },
      };
    case userActionTypes.SIGNUP_SMS_RESEND_FAILURE:
      const {
        payload: { message: resendMessage },
      } = action;
      return {
        ...state,
        resend: {
          pending: false,
          status: {
            success: false,
            message: resendMessage,
          },
        },
      };
    case userActionTypes.CLEAR_RESEND:
      return {
        ...state,
        resend: {
          pending: false,
          status: null,
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default signupSmsReducer;

import autobahn, { Session } from "autobahn";
import { storageKeys, timeOuts } from "@/config/general";

import Storage, { CookiesStorage } from "./storage";
import { SubscriptionTypes } from "@/fe-core/meta/types/socket";

let URL = process.env.NEXT_PUBLIC_WEBAPI_URL || "";
const REALM = process.env.NEXT_PUBLIC_WEBAPI_REALM || "";

let socket: autobahn.Connection | null = null;

export function setCid(cid: string): void {
  Storage.set(storageKeys.CID, cid);
  // removed when retriving cid for both login and logout
  //socket?.close()

  socket = null;
}

export function clearCid(): void {
  Storage.remove(storageKeys.CID);
  socket = null;
}

export function getSocket(): autobahn.Connection {
  const cid = Storage.get(storageKeys.CID) as string;

  if (cid) {
    URL += `?cid=${encodeURIComponent(cid)}`;
  }

  if (!socket) {
    socket = new autobahn.Connection({
      url: URL,
      realm: REALM,
    });
  }

  return socket;
}

export function doEMCall(
  session: Session,
  url: string,
  params: object | null
): When.Promise<unknown> {
  const culture = CookiesStorage.getItem(storageKeys.LOCALE) as string;
  return session.call(
    url,
    [],
    { ...params, culture },
    {
      timeout: timeOuts.WEBAPI_CALL_TIMEOUT,
    }
  );
}

export function doEMSubscription(
  session: Session,
  topic: string,
  handler: (args: SubscriptionTypes) => void
): When.Promise<unknown> {
  return session.subscribe(
    topic,
    //@ts-ignore
    (_args: unknown[], kwargs: SubscriptionTypes) => {
      handler(kwargs);
    }
  );
}

"use client";
import React, { FC, Suspense, useEffect } from "react";
import Image from "next/image";
import FooterLinks from "./FooterLinks";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import {
  bottomLogoSelector,
  licenseSelector,
  paymentsSelector,
  providersSelector,
  sealsSelector,
  socialSelector,
} from "@/fe-core/_redux/selectors/footerSelectors";
import { useDispatch, useSelector } from "react-redux";
import { IViewNavigationResponse } from "@/fe-core/meta/interfaces/navigation";
import { Logo } from "../Logo";
import Script from "next/script";
import SEOText from "./SEOText";
import { IPageData } from "@/fe-core/meta/interfaces/page";
import useTracking from "../Hooks/useTracking";
import { Link, usePathname } from "../../navigation";
import { getFooterRequest } from "@/fe-core/_redux/actions/footerActions";
import LanguageSwitcher from "../Layout/LanguageSwitcher";

const LayoutFooter: FC<{
  seoContentData: IPageData | null;
  footerNavigation: IViewNavigationResponse[] | undefined;
}> = ({ seoContentData, footerNavigation }) => {
  const { navigate } = useTracking();
  const t = useTranslations(I18N_NAMESPACE.GENERAL);
  const footerT = useTranslations(I18N_NAMESPACE.FOOTER);
  const seals = useSelector(sealsSelector);
  const licenseText = useSelector(licenseSelector);
  const socialData = useSelector(socialSelector);
  const providersData = useSelector(providersSelector);
  const paymentsData = useSelector(paymentsSelector);
  const bottomLogo = useSelector(bottomLogoSelector);

  const pathname = usePathname();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFooterRequest());
  }, []);

  const FooterSocial = () => (
    <div className="flex flex-col gap-0 lg:max-w-max">
      <h5 className="mb-6 text-base font-bold text-center md:text-lg md:mb-8 md:text-start text-white/60">
        {footerT("join-our-community-header")}
      </h5>
      <div className="flex justify-center grid-cols-3 gap-4 md:justify-start md:gap-5 lg:grid">
        {socialData?.map((item, index) => {
          return (
            <Link
              href={item?.link ?? "#"}
              target={item?.target}
              key={`socialData__${index}`}
              onClick={() => {
                navigate(`${item?.link ?? "#"}`, {
                  component: "FooterSocial",
                  label:
                    item?.name ||
                    item.icons?.data?.attributes?.alternativeText ||
                    item?.link,
                  navigateFrom: "Footer Links",
                });
              }}
              className="relative w-10 h-10"
            >
              {item?.icons?.data?.attributes?.url && (
                <Image
                  src={`${process.env.NEXT_PUBLIC_CMS_URL}${item?.icons?.data?.attributes?.url}`}
                  fill
                  className="w-full h-full"
                  alt={
                    item.icons?.data?.attributes?.alternativeText || item?.name
                  }
                  loading="lazy"
                />
              )}
            </Link>
          );
        })}
      </div>
      {/* <Link href="https://www.instagram.com/" passHref target="_blank">
        <Icon name="instagram" color="bg-white" size="medium" />
      </Link>
      <Link href="https://twitter.com/" passHref target="_blank">
        <Icon name="twitter" color="bg-white" size="medium" />
      </Link> */}
    </div>
  );
  const FooterInfo = async () => {
    return (
      licenseText && (
        <div
          className="text-xs text-center font-bold text-white/50"
          dangerouslySetInnerHTML={{ __html: licenseText || "" }}
        />
      )
    );
  };
  const FooterPayments = () =>
    paymentsData && paymentsData?.length > 0 ? (
      <div className="py-8">
        <h5 className="mb-2 text-lg font-bold text-center md:text-start text-white/60">
          {footerT("payments-header")}
        </h5>
        <section className="grid lg:grid-cols-7 md:grid-cls-4 grid-cols-2 gap-3.5 lg:!gap-4 mt-5 gap-y-2">
          {paymentsData?.map((item, index) => {
            return (
              <Link
                href={item?.link ?? "#"}
                target={item?.target}
                key={`seals_${index}`}
                onClick={() => {
                  navigate(`${item?.link ?? "#"}`, {
                    component: "FooterPayments",
                    label:
                      item?.name ||
                      item.icons?.data?.attributes?.alternativeText ||
                      item?.link,
                    navigateFrom: "Footer Links",
                  });
                }}
                className={` md:w-auto p-3 border border-white/20 rounded ${!item?.link ? "pointer-events-none" : ""
                  }`}
              >
                {item?.icons?.data?.attributes?.url && (
                  <img
                    src={`${process.env.NEXT_PUBLIC_CMS_URL}${item?.icons?.data?.attributes?.url}`}
                    className="!relative mx-auto max-h-10"
                    alt={
                      item.icons?.data?.attributes?.alternativeText ||
                      item?.name
                    }
                  />
                )}
              </Link>
            );
          })}
        </section>
      </div>
    ) : (
      <></>
    );

  const FooterProviders = () =>
    providersData && providersData?.length > 0 ? (
      <div className="py-8">
        <h5 className="mb-2 text-lg font-bold text-center md:text-start text-white/60">
          {footerT("providers-header")}
        </h5>
        <section className="grid lg:grid-cols-6 md:grid-cls-4 grid-cols-2  gap-5 mt-5 gap-y-8">
          {providersData?.map((item, index) => {
            return (
              <Link
                href={item?.link ?? "#"}
                target={item?.target}
                key={`seals_${index}`}
                onClick={() => {
                  navigate(`${item?.link ?? "#"}`, {
                    component: "FooterProviders",
                    label:
                      item?.name ||
                      item.icons?.data?.attributes?.alternativeText ||
                      item?.link,
                    navigateFrom: "Footer Links",
                  });
                }}
                className={` md:w-auto p-3 border border-white/20 rounded ${!item?.link ? "pointer-events-none" : ""
                  }`}
              >
                {item?.icons?.data?.attributes?.url && (
                  <img
                    src={`${process.env.NEXT_PUBLIC_CMS_URL}${item?.icons?.data?.attributes?.url}`}
                    className="!relative !mx-auto block max-h-[20px]"
                    alt={
                      item.icons?.data?.attributes?.alternativeText ||
                      item?.name
                    }
                  />
                )}
              </Link>
            );
          })}
        </section>
      </div>
    ) : (
      <></>
    );

  const FooterRegulators = () => (
    <div className="py-8">
      <h5 className="mb-2 text-lg font-bold text-center md:text-start text-white/60">
        {footerT("sponsorship-gaming-responsibilities-header")}
      </h5>
      <section className="flex flex-wrap justify-center md:justify-start md:gap-8 mt-7 gap-y-8">
        {/* <div
          id="anj-92a0921b-2365-4be2-a384-d9ba41079c6d"
          data-anj-seal-id="92a0921b-2365-4be2-a384-d9ba41079c6d"
          data-anj-image-size="40"
          data-anj-image-type="basic-small"
          data-anj-margin="auto"
          className="w-1/3 md:w-auto"
        ></div> */}

        {/* <Script
          id="license-seal-init"
          dangerouslySetInnerHTML={{
            __html: `window?.anj_92a0921b_2365_4be2_a384_d9ba41079c6d?.init();`,
          }}
        /> */}
        {seals?.map((item, index) => {
          return (
            <Link
              href={item?.link ?? "#"}
              target={item?.target}
              key={`seals_${index}`}
              onClick={() => {
                navigate(`${item?.link ?? "#"}`, {
                  component: "FooterRegulators",
                  label:
                    item?.name ||
                    item.icons?.data?.attributes?.alternativeText ||
                    item?.link,
                  navigateFrom: "Footer Links",
                });
              }}
              className={`w-1/3 md:w-auto ${!item?.link ? "pointer-events-none" : ""
                }`}
            >
              {item?.icons?.data?.attributes?.url && (
                <img
                  src={`${process.env.NEXT_PUBLIC_CMS_URL}${item?.icons?.data?.attributes?.url}`}
                  className="!relative mx-auto md:m-0 max-h-[30px]"
                  alt={
                    item.icons?.data?.attributes?.alternativeText || item?.name
                  }
                />
              )}
            </Link>
          );
        })}
      </section>
    </div>
  );

  const FooterBottom = () => (
    <div className="pt-11 lg:pt-13">
      {bottomLogo?.data?.attributes?.url && (
        <Link href="/" passHref className="block w-max mx-auto mb-3.5">
          <Image
            src={`${process.env.NEXT_PUBLIC_CMS_URL}${bottomLogo?.data?.attributes?.url}`}
            fill
            alt="fashiontv-games"
            className="!w-auto !h-14 sm:!h-16 !relative"
            loading="eager"
          />
        </Link>
      )}

      <div className="flex items-center justify-center mb-5">
        <p className="text-xs font-bold text-white/50">
          &copy; {new Date().getFullYear()}{" "}
          {t("all-rights-reserved")}
        </p>
      </div>

      {FooterInfo()}
    </div>
  );

  const FooterDesktopLayout = () => (
    <Suspense fallback={<div>{t("loading")}</div>}>
      <div className="flex flex-col justify-between gap-9 flex-wrap lg:flex-row lg:gap-28">
        {/* {FooterLogo()} */}

        <div className="flex-1 divide-y">
          <FooterLinks footerNavigation={footerNavigation} />
        </div>
        <div className="space-y-[26px] lg:space-y-11 pt-7.5 lg:pt-0 pb-[38px] lg:pb-0 border-t border-tertiary-300 lg:border-t-0">
          {FooterSocial()}
          <LanguageSwitcher />
        </div>
      </div>
      <div className="">
        <div className="lg:pt-4.5 lg:pb-6 divide-y divide-tertiary-300 border-y border-tertiary-300 lg:divide-none lg:border-t-0">
          {FooterProviders()}
          {FooterPayments()}
          {FooterRegulators()}
        </div>
        {/* {FooterConsumerLinks()} */}
        {FooterBottom()}
      </div>
    </Suspense>
  );

  return (
    <>
      <div className="mt-10" id="footer">
        <SEOText seoContentData={seoContentData} />

        <footer
          className={`w-full py-20 bg-white dark:bg-tertiary-600 dark:text-tertiary-50 pb-28 text-tertiary md:pb-10`}
        >
          <div className="container">
            <div className="">{FooterDesktopLayout()}</div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LayoutFooter;
//@todo, translation static text

import { IBannersRequestViewModel, IBannersViewModel } from "@/fe-core/meta/interfaces/banner";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  bannerActionTypes,
  GetBannersFailureAction,
  GetBannersRequestAction,
  GetBannersSuccessAction,
} from "@/fe-core/meta/types/banner";

export const getBannersRequest = (
  payload?: IBannersRequestViewModel
): GetBannersRequestAction => ({
  type: bannerActionTypes.GET_BANNERS_REQUEST,
  payload
});

export const getBannersSuccess = (
  payload: IBannersViewModel
): GetBannersSuccessAction => ({
  type: bannerActionTypes.GET_BANNERS_SUCCESS,
  payload,
});

export const getBannersFailure = (
  payload: IErrorPayload
): GetBannersFailureAction => ({
  type: bannerActionTypes.GET_BANNERS_FAILURE,
  payload,
});

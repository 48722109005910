import { ActivateEmailState } from "@/fe-core/meta/types/activateEmail";
import { RootState } from "@/fe-core/meta/types/root";

export const activateEmailSelector = ({
  activateEmail,
}: RootState): ActivateEmailState => activateEmail;

export const activateEmailModalSelector = (state: RootState): boolean => {
  const { showActivateEmailModal } = activateEmailSelector(state);

  return showActivateEmailModal;
};
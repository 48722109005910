import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  ValidateActions,
  ValidateUsernameState,
} from "@/fe-core/meta/types/user";

const initialState: ValidateUsernameState = {
  pending: false,
  data: null,
  status: null,
};

const validateUsernameReducer = (
  state: ValidateUsernameState = initialState,
  action: ValidateActions | RootActions
): ValidateUsernameState => {
  switch (action.type) {
    case userActionTypes.VALIDATE_USERNAME_REQUEST:
      return {
        ...state,
        pending: true,
        data: null,
        status: null,
      };
    case userActionTypes.VALIDATE_USERNAME_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
          message: "Username successfuly validated",
        },
      };
    case userActionTypes.VALIDATE_USERNAME_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default validateUsernameReducer;

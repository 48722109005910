import {
  CasinoGamePlayModalActions,
  CasinoGamePlayModalState,
  casinoGamePlayModalTypes,
} from "@/fe-core/meta/types/casinoGamePlayModal";

const initialState: CasinoGamePlayModalState = {
  game: null,
};

const casinoGamePlayModalReducer = (
  state: CasinoGamePlayModalState = initialState,
  action: CasinoGamePlayModalActions
): CasinoGamePlayModalState => {
  switch (action.type) {
    case casinoGamePlayModalTypes.SET_CASINO_GAME:
      return {
        game: action.payload,
      };
    case casinoGamePlayModalTypes.REMOVE_CASINO_GAME:
      return {
        game: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default casinoGamePlayModalReducer;

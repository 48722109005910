import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import limitsSaga from "./_limitsSaga_nwa";
import balanceSaga from "./_balanceSaga_nwa";
import _consentsSaga_nwa from "./_consentsSaga_nwa";
import passwordSaga from "./_passwordSaga_nwa";
import _profileSaga_nwa from "./_profileSaga_nwa";
import registerSaga from "./registerSaga";
import _rolesSaga_nwa from "./_rolesSaga_nwa";
import bettingHistorySaga from "./_bettingHistorySaga_nwa";
import casinoHistorySaga from "./_casinoHistorySaga_nwa";
import bonusSaga from "./_bonusSaga_nwa";
import _favoritesSaga_nwa from "./_favoritesSaga_nwa";
import cashierSaga from "./cashierSaga";
import selfExclusionSaga from "./_selfExclusionSaga_nwa";
import documentsSaga from "./documentsSaga";
import regionsSaga from "./regionsSaga";
import coolOffSaga from "./_coolOffSaga_nwa";
import restrictionsSaga from "./_restrictionsSaga_nwa";
import _recentPlayedSaga_nwa from "./_recentPlayedSaga_nwa";
import externalCashierSaga from "./externalCashierSaga";
import loginsSaga_nwa from "./_loginsSaga_nwa";
import gameSessionsSage from "./_gameSessionsSaga_nwa";
import _campaignsSaga_nwa from "./affiliateSaga/_campaignsSaga_nwa";
import _affiliateSaga_nwa from "./affiliateSaga/_affiliateSaga_nwa";
import _claimSaga_nwa from "./affiliateSaga/_claimSaga_nwa";
import sessionLimitSaga from "./_sessionLimitSaga_nwa";
import bankingHistorySaga from "./cashierSaga/bankingHistorySaga";
import cashbackSaga from "./_cashbackSaga_nwa";
import tagsSaga from "./tagsSaga";
import levelupDetailsSaga from "./levelupDetailsSaga";

function* userSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    fork(_profileSaga_nwa),
    fork(balanceSaga),
    fork(passwordSaga),
    fork(_consentsSaga_nwa),
    fork(limitsSaga),
    fork(registerSaga),
    fork(_rolesSaga_nwa),
    fork(tagsSaga),
    fork(bettingHistorySaga),
    fork(casinoHistorySaga),
    fork(bonusSaga),
    fork(_favoritesSaga_nwa),
    fork(cashierSaga),
    fork(externalCashierSaga),
    fork(selfExclusionSaga),
    fork(documentsSaga),
    fork(regionsSaga),
    fork(coolOffSaga),
    fork(restrictionsSaga),
    fork(_recentPlayedSaga_nwa),
    fork(loginsSaga_nwa),
    fork(_campaignsSaga_nwa),
    fork(_affiliateSaga_nwa),
    fork(_claimSaga_nwa),
    fork(gameSessionsSage),
    fork(sessionLimitSaga),
    fork(bankingHistorySaga),
    process.env.NEXT_PUBLIC_HAS_CASHBACK === "YES" ? fork(cashbackSaga) : null,
    process.env.NEXT_PUBLIC_HAS_LEVELUP === "YES"
      ? fork(levelupDetailsSaga)
      : null,
  ]);
}

export default userSaga;

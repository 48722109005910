import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { GetEligibleTournamentByGameSlugSuccessAction, SetEligibleTournamentByGameSlugPendingAction, SetTournamentListPendingAction } from '../../meta/types/tournaments';
import { SetEligibleTournamentByGameSlugPendingPayload } from '../../meta/interfaces/tournaments';
import {
  TournamentsRequestPayload as TournamentsRequestPayload,
  TournamentsListModel,
  TournamentRequestPayload,
  TournamentModel,
  LeaderboardRequestPayload,
  LeaderboardSuccessPayload,
  LeaderboardFailurePayload,
  TournamentStatus,
  selectedFilterPayload,
  TournamentSuccessPayload,
  TournamentMoreGamesSuccessPayload,
  ISetTournamentPendingViewModel,
  GetEligibleTournamentByGameSlugSuccessPayload,
} from "@/fe-core/meta/interfaces/tournaments";
import {
  GetTournamentLeaderBoardIntervalAction,
  GetTournamentMoreGamesFailureAction,
  GetTournamentMoreGamesRequestAction,
  GetTournamentMoreGamesSuccessAction,
  LeaderboardFailureAction,
  LeaderboardRequestAction,
  LeaderboardSuccessAction,
  SetLeaderboardPendingAction,
  SetTournamentPendingAction,
  TournamentFailureAction,
  TournamentRequestAction,
  TournamentSuccessAction,
  TournamentsFailureAction,
  TournamentsRequestAction,
  TournamentsSuccessAction,
  setSelectedFilterStateAction,
  tournamentsActionTypes,
} from "@/fe-core/meta/types/tournaments";

export const getTournamentsList = (
  payload: Partial<TournamentsRequestPayload> = {}
): TournamentsRequestAction => ({
  type: tournamentsActionTypes.TOURNAMENTS_LIST_REQUEST,
  payload,
});

export const tournamentsListSuccess = (
  payload: TournamentsListModel
): TournamentsSuccessAction => ({
  type: tournamentsActionTypes.TOURNAMENTS_LIST_SUCCESS,
  payload,
});

export const tournamentsListFailure = (
  payload: IErrorPayload
): TournamentsFailureAction => ({
  type: tournamentsActionTypes.TOURNAMENTS_LIST_FAILURE,
  payload,
});

export const getTournament = (
  payload: TournamentRequestPayload
): TournamentRequestAction => ({
  type: tournamentsActionTypes.TOURNAMENT_REQUEST,
  payload,
});

export const setTournamentPending = (
  payload: ISetTournamentPendingViewModel
): SetTournamentPendingAction => ({
  type: tournamentsActionTypes.SET_TOURNAMENT_PENDING,
  payload,
});

export const tournamentSuccess = (
  payload: TournamentSuccessPayload
): TournamentSuccessAction => ({
  type: tournamentsActionTypes.TOURNAMENT_SUCCESS,
  payload,
});

export const tournamentFailure = (
  payload: IErrorPayload
): TournamentFailureAction => ({
  type: tournamentsActionTypes.TOURNAMENT_FAILURE,
  payload,
});

export const getLeaderBoard = (
  payload: LeaderboardRequestPayload
): LeaderboardRequestAction => ({
  type: tournamentsActionTypes.LEADERBOARD_REQUEST,
  payload,
});

export const setLeaderboardPending = (
  payload: ISetTournamentPendingViewModel
): SetLeaderboardPendingAction => ({
  type: tournamentsActionTypes.SET_LEADERBOARD_PENDING,
  payload,
});

export const setTournamentListPending = (
  payload: boolean
): SetTournamentListPendingAction => ({
  type: tournamentsActionTypes.SET_TOURNAMENTS_LIST_PENDING,
  payload,
});

export const leaderBoardSuccess = (
  payload: LeaderboardSuccessPayload
): LeaderboardSuccessAction => ({
  type: tournamentsActionTypes.LEADERBOARD_SUCCESS,
  payload,
});

export const leaderBoardFailure = (
  payload: LeaderboardFailurePayload
): LeaderboardFailureAction => ({
  type: tournamentsActionTypes.LEADERBOARD_FAILURE,
  payload,
});

export const setSelectedFilterForTournament = (
  payload: selectedFilterPayload
): setSelectedFilterStateAction => ({
  type: tournamentsActionTypes.SET_SELECTED_FILTER_STATE,
  payload,
});

export const getTournamentMoreGamesRequest = (
  payload: TournamentRequestPayload
): GetTournamentMoreGamesRequestAction => ({
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_REQUEST,
  payload,
});

export const getTournamentMoreGamesSuccess = (
  payload: TournamentMoreGamesSuccessPayload
): GetTournamentMoreGamesSuccessAction => ({
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_SUCCESS,
  payload,
});

export const getTournamentMoreGamesFailure = (
  payload: IErrorPayload
): GetTournamentMoreGamesFailureAction => ({
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_FAILURE,
  payload,
});
export const startLeaderboardInterval = (
  payload: LeaderboardRequestPayload
): GetTournamentLeaderBoardIntervalAction => ({
  type: tournamentsActionTypes.START_LEADERBOARD_INTERVAL,
  payload,
});

export const stopLeaderboardInterval = () => ({
  type: tournamentsActionTypes.STOP_LEADERBOARD_INTERVAL,
});

export const getEligibleTournamentByGameSlugSuccess = (
  payload: GetEligibleTournamentByGameSlugSuccessPayload
): GetEligibleTournamentByGameSlugSuccessAction => ({
  type: tournamentsActionTypes.SINGLE_TOURNAMENT_BY_GAME_SLUG_SUCCESS,
  payload,
});

export const setEligibleTournamentByGameSlugPending = (
  payload: SetEligibleTournamentByGameSlugPendingPayload
): SetEligibleTournamentByGameSlugPendingAction => ({
  type: tournamentsActionTypes.SET_SINGLE_TOURNAMENT_BY_GAME_SLUG_PENDING,
  payload,
});
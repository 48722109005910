import {
  ALL_GAMES_SLUG,
  CASINO_DATA_SOURCE,
  MAX_GAME_COUNT_ON_GAME_SWIPER,
} from "@/config/casino";
import { filterGroups } from "@/fe-core/helpers/casino";
import {
  CasinoActions,
  casinoActionTypes,
  CasinoGroupsState,
} from "@/fe-core/meta/types/casino";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: CasinoGroupsState = {
  casinoGroups: {
    pending: false,
    err: "",
    groups: null,
    moreGamesLoading: false,
  },
  casinoHomeGroups: {
    pending: false,
    err: "",
    groups: null,
  },
  popularGroups: {
    pending: false,
    err: "",
    popularGamesGroup: null,
  },
  popularLiveGroups: {
    pending: false,
    err: "",
    popularLiveGamesGroup: null,
  },
  auditGroups: {
    pending: false,
    err: "",
    auditGamesGroup: null,
  },
};

const casinoGroupsReducer = (
  state: CasinoGroupsState = initialState,
  action: CasinoActions | RootActions
): CasinoGroupsState => {
  switch (action.type) {
    case casinoActionTypes.GET_CASINO_GROUPS_REQUEST:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          pending: true,
        },
      };
    case casinoActionTypes.SET_ALL_GAMES_GROUP:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          pending: false,
          groups: filterGroups(
            state?.casinoGroups?.groups?.map((group) =>
              group?.slug === ALL_GAMES_SLUG
                ? {
                    ...group,
                    games: action.payload,
                    featuredGames: action.payload?.items,
                  }
                : group
            ) || null,
            MAX_GAME_COUNT_ON_GAME_SWIPER
          ),
        },
        casinoHomeGroups: {
          ...state.casinoHomeGroups,
          pending: false,
          groups: filterGroups(
            state?.casinoHomeGroups?.groups?.map((group) =>
              group?.slug === ALL_GAMES_SLUG
                ? {
                    ...group,
                    games: action.payload,
                    featuredGames: action.payload?.items,
                  }
                : group
            ) || null,
            MAX_GAME_COUNT_ON_GAME_SWIPER
          ),
        },
      };
    case casinoActionTypes.GET_CASINO_GROUPS_SUCCESS:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          pending: false,
          groups: action.payload,
        },
      };
    case casinoActionTypes.GET_CASINO_GROUPS_FAILURE:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          pending: false,
          err: "Custom Err.",
        },
      };
    case casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          moreGamesLoading: true,
        },
      };
    case casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST:
      return {
        ...state,
        casinoGroups: {
          ...state.casinoGroups,
          moreGamesLoading: false,
        },
      };
    case casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST:
      return {
        ...state,
        popularLiveGroups: {
          ...state.popularLiveGroups,
          pending: true,
          err: "",
        },
      };

    case casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_SUCCESS:
      return {
        ...state,
        popularLiveGroups: {
          ...state.popularLiveGroups,
          pending: false,
          popularLiveGamesGroup: action.payload,
        },
      };
    case casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_FAILURE:
      return {
        ...state,
        popularLiveGroups: {
          ...state.popularLiveGroups,
          pending: false,
          err: "Custom Err.",
        },
      };
    case casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_REQUEST:
      return {
        ...state,
        popularGroups: {
          ...state.popularGroups,
          pending: true,
          err: "",
        },
      };

    case casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_SUCCESS:
      return {
        ...state,
        popularGroups: {
          ...state.popularGroups,
          pending: false,
          popularGamesGroup: action.payload,
        },
      };
    case casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_FAILURE:
      return {
        ...state,
        popularGroups: {
          ...state.popularGroups,
          pending: false,
          err: "Custom Err.",
        },
      };
    case casinoActionTypes.GET_CASINO_AUDIT_GROUP_REQUEST:
      return {
        ...state,
        auditGroups: {
          ...state.auditGroups,
          pending: true,
          err: "",
        },
      };
    case casinoActionTypes.GET_CASINO_AUDIT_GROUP_SUCCESS:
      return {
        ...state,
        auditGroups: {
          ...state.auditGroups,
          pending: false,
          auditGamesGroup: action.payload,
        },
      };
    case casinoActionTypes.GET_CASINO_AUDIT_GROUP_FAILURE:
      return {
        ...state,
        auditGroups: {
          ...state.auditGroups,
          pending: false,
          err: "Custom Err.",
        },
      };
    case casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_SUCCESS: {
      const {
        slug,
        dataSource,
        games: { count, items },
      } = action.payload;

      if (dataSource === CASINO_DATA_SOURCE && state?.casinoGroups?.groups) {
        const groupToUpdate = state?.casinoGroups?.groups?.find(
          (group) => group?.slug === slug
        );

        if (!groupToUpdate) {
          return { ...state };
        }

        return {
          ...state,
          casinoGroups: {
            ...state.casinoGroups,
            groups: state.casinoGroups.groups.map((group) =>
              group.slug === slug
                ? {
                    ...group,
                    games: {
                      total: group.games.total,
                      items: group.games.items.concat(items),
                      count: group.games.count + count,
                    },
                  }
                : group
            ),
            moreGamesLoading: false,
          },
        };
      }
      return state;
    }

    //home datasource
    case casinoActionTypes.GET_CASINO_HOME_GROUPS_REQUEST:
      return {
        ...state,
        casinoHomeGroups: {
          ...state.casinoHomeGroups,
          pending: true,
        },
      };
    case casinoActionTypes.GET_CASINO_HOME_GROUPS_SUCCESS:
      return {
        ...state,
        casinoHomeGroups: {
          ...state.casinoHomeGroups,
          pending: false,
          groups: action.payload,
        },
      };
    case casinoActionTypes.GET_CASINO_HOME_GROUPS_FAILURE:
      return {
        ...state,
        casinoHomeGroups: {
          ...state.casinoHomeGroups,
          pending: false,
          err: "Custom Err.",
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...state,
        auditGroups: {
          err: "",
          pending: false,
          auditGamesGroup: null,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default casinoGroupsReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  IPaymentIqCancelTransactionViewModel,
  IPaymentIqTransactionHistoryViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  CancelPaymentIqTransactionAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  cancelECTrasactionFailure,
  cancelECTrasactionSuccess,
  ecTransactionHistoryFailure,
  ecTransactionHistoryRequest,
  ecTransactionHistorySuccess,
  setECTransactionHistoryPending,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import {
  BuildCancelECTransactionQueryString,
  BuildECTransactionQueryString,
} from "@/fe-core/helpers/externalCashier";
import dayjs from "dayjs";
import { paymentIqTransactionTimeIntervalSelector } from "@/fe-core/_redux/selectors/userSelectors/externalCashierSelectors";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* paymentIqTransactionHistoryRequestSaga() {
  try {
    yield put(setECTransactionHistoryPending({ isPending: true }));
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);
    const { startDate, endDate } = yield select(
      paymentIqTransactionTimeIntervalSelector
    );

    const startTime = `${dayjs(startDate).format("YYYY-MM-DD")} 00:00:00`;
    const endTime = dayjs(endDate).format("YYYY-MM-DD HH:mm:ss");

    const queryString: string = BuildECTransactionQueryString(
      startTime,
      endTime
    );
    const headers = new Headers({
      [headerKeys.SESSION_ID]: sessionId,
      [headerKeys.LOCALE]: locale,
    });
    const response: IJsonResponse<
      IPaymentIqTransactionHistoryViewModel | IErrorPayload
    > = yield fetch(`/api/paymentiq/transaction-history${queryString}`, <
      RequestInit
      >{
        method: methodTypes.GET,
        headers,
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPaymentIqTransactionHistoryViewModel =
          yield response.json();
        yield put(ecTransactionHistorySuccess(viewModel));
        break;
      case 500:
        const errorPayload: IErrorPayload = yield response.json();
        yield put(ecTransactionHistoryFailure(errorPayload));
        break;
      default:
        throw new Error("EC Transaction History couldn't be fetched");
    }
  } catch (error) {
    yield put(ecTransactionHistoryFailure(parseFrontendError(error)));
  } finally {
    yield put(setECTransactionHistoryPending({ isPending: false }));
  }
}
function* cancelPaymentIqTransactionRequestSaga({
  payload,
}: CancelPaymentIqTransactionAction) {
  try {
    // yield put(setECTransactionHistoryPending({ isPending: true }));
    const { sessionId } = yield select(sessionDataSelector);

    const queryString: string = BuildCancelECTransactionQueryString(
      payload.transactionId
    );
    const headers = new Headers({});
    if (sessionId) {
      headers.set(headerKeys.SESSION_ID, sessionId);
    }
    const response: IJsonResponse<
      IPaymentIqCancelTransactionViewModel | IErrorPayload
    > = yield fetch(`/api/paymentiq/cancel-transaction${queryString}`, <
      RequestInit
      >{
        method: methodTypes.DELETE,
        headers,
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPaymentIqCancelTransactionViewModel =
          yield response.json();
        yield put(cancelECTrasactionSuccess(viewModel));
        yield put(ecTransactionHistoryRequest());
        break;
      case 500:
        const errorPayload: IErrorPayload = yield response.json();
        yield put(cancelECTrasactionFailure(errorPayload));
        break;
      default:
        throw new Error("Cancel Transaction couldn't be done");
    }
  } catch (error) {
    yield put(cancelECTrasactionFailure(parseFrontendError(error)));
  } finally {
    // yield put(setECTransactionHistoryPending({ isPending: false }));
  }
}

function* paymentIqTransactionHistorySaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_REQUEST,
      paymentIqTransactionHistoryRequestSaga
    ),
    takeLatest(
      userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_REQUEST,
      cancelPaymentIqTransactionRequestSaga
    ),
    takeLatest(
      userActionTypes.SET_PAYMENT_IQ_TRANSACTION_TIME_INTERVAL,
      paymentIqTransactionHistoryRequestSaga
    ),
    takeLatest(
      sessionActionTypes.AUTHENTICATE_SUCCESS,
      paymentIqTransactionHistoryRequestSaga
    ),
  ]);
}

export default paymentIqTransactionHistorySaga;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  ICMSTopWinnerViewModel,
  ITopWinnerViewModel,
} from "@/fe-core/meta/interfaces/topWinners";
import {
  topWinnersActionTypes,
  GetTopWinnersRequestAction,
} from "@/fe-core/meta/types/topWinners";
import {
  getTopWinnersFailure,
  getTopWinnersSuccess,
} from "@/fe-core/_redux/actions/topWinnersActions";

import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";

function* getTopWinnersSaga({}: GetTopWinnersRequestAction) {
  try {
    const response: IJsonResponse<ITopWinnerViewModel[] | IErrorPayload> =
      yield fetch(`/api/topWinners`, {
        method: methodTypes.GET,
      });

    if (response.status == 200) {
      const winners: ITopWinnerViewModel[] = yield response.json();

      const cmsWinners: ICMSTopWinnerViewModel[] = winners.map((item) => {
        const n = item.gameModel.launchUrl.lastIndexOf("/");
        const slug = item.gameModel.launchUrl.substring(n + 1);

        return {
          username: item.username,
          amount: item.amount,
          gameId: item.gameId,
          gameCode: item.gameId,
          gameName: item.gameName,
          gameSlug: slug,
          gameDesktopThumbnail: item.gameModel.icons["114"],
          gameMobileThumbnail: item.gameModel.icons["114"],
          gameHasFunMode: item.gameModel.hasFunMode,
          timestamp: new Date().toISOString(),
        };
      });

      yield put(getTopWinnersSuccess(cmsWinners));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getTopWinnersFailure(errorPayload));
    }
  } catch (error) {
    yield put(getTopWinnersFailure(parseFrontendError(error)));
  }
}

// function* getGBTopWinnersSaga({}: GetTopWinnersRequestAction) {
//   try {
//     const response: IJsonResponse<IGBTopWinnerViewModel[] | IErrorPayload> =
//       yield fetch(`/api/gbTopWinners`, {
//         method: methodTypes.GET,
//       });

//     if (response.status == 200) {
//       const winners: IGBTopWinnerViewModel[] = yield response.json();

//       const cmsWinners: ICMSTopWinnerViewModel[] = winners.map((item) => {
//         return {
//           username: item.username,
//           amount: item.amount,
//           gameId: item.gameCode,
//           gameCode: item.gameCode,
//           gameName: item.gameName,
//           gameSlug: item.slug,
//           gameDesktopThumbnail: item.desktopThumbnail,
//           gameMobileThumbnail: item.mobileThumbnail,
//           gameHasFunMode: false,
//           timestamp: item.timestamp,
//         };
//       });

//       yield put(getTopWinnersSuccess(cmsWinners));
//     } else {
//       const errorPayload: IErrorPayload = yield response.json();
//       yield put(getTopWinnersFailure(errorPayload));
//     }
//   } catch (error) {
//     yield put(getTopWinnersFailure(parseFrontendError(error)));
//   }
// }

// function* getCMSTopWinnersSaga({}: GetTopWinnersRequestAction) {
//   try {
//     const response: IJsonResponse<ICMSTopWinnerViewModel[] | IErrorPayload> =
//       yield fetch(`/api/cmsTopWinners`, {
//         method: methodTypes.GET,
//       });

//     if (response.status == 200) {
//       const winners: ICMSTopWinnerViewModel[] = yield response.json();

//       yield put(getTopWinnersSuccess(winners));
//     } else {
//       const errorPayload: IErrorPayload = yield response.json();
//       yield put(getTopWinnersFailure(errorPayload));
//     }
//   } catch (error) {
//     yield put(getTopWinnersFailure(parseFrontendError(error)));
//   }
// }

function* topWinnersSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      topWinnersActionTypes.GET_TOP_WINNERS_REQUEST,
      getTopWinnersSaga
    ),
  ]);
}

export default topWinnersSaga;

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  ICasinoHistoryPayload,
  ICasinoHistoryViewModel,
} from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  CasinoHistoryRequestAction,
  GetCasinoHistoryMoreRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  casinoHistoryFailure,
  casinoHistorySuccess,
  getCasinoHistoryMoreFailure,
  getCasinoHistoryMoreSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

const getURLParams = (payload: ICasinoHistoryPayload) => {
  const qs = Object.keys(payload)
    .map((key) => `${key}=${payload[key as keyof ICasinoHistoryPayload]}`)
    .join("&");

  return qs ? `?${qs}` : "";
};

function* getCasinoHistoryMoreRequestSaga({ payload }: GetCasinoHistoryMoreRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const queryParams = getURLParams(payload);

    const response: IJsonResponse<ICasinoHistoryViewModel | IErrorPayload> =
      yield fetch(`/api/user/casinoHistory${queryParams}`, <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: ICasinoHistoryViewModel = yield response.json();

      yield put(getCasinoHistoryMoreSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getCasinoHistoryMoreFailure(errorPayload));
    }
  } catch (error) {
    yield put(getCasinoHistoryMoreFailure(parseFrontendError(error)));
  }
}
function* getCasinoHistoryRequestSaga({ payload }: CasinoHistoryRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const queryParams = getURLParams(payload);

    const response: IJsonResponse<ICasinoHistoryViewModel | IErrorPayload> =
      yield fetch(`/api/user/casinoHistory${queryParams}`, <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: ICasinoHistoryViewModel = yield response.json();

      yield put(casinoHistorySuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(casinoHistoryFailure(errorPayload));
    }
  } catch (error) {
    yield put(casinoHistoryFailure(parseFrontendError(error)));
  }
}

export default function* casinoHistorySaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.CASINO_HISTORY_REQUEST,
      getCasinoHistoryRequestSaga
    ),
    takeLatest(
      userActionTypes.GET_CASINO_HISTORY_MORE_REQUEST,
      getCasinoHistoryMoreRequestSaga
    ),
  ]);
}

import { ClaimActions, ClaimState } from "@/fe-core/meta/interfaces/user/affiliate/claim";
import { RootActions } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";


const initialState: ClaimState = {
  pending: false,
  data: null,
  status: null,
};

const claimReducer = (
  state: ClaimState = initialState,
  action: ClaimActions | RootActions
): ClaimState => {
  switch (action.type) {
    case userActionTypes.GET_CLAIM_REQUEST:
    case userActionTypes.POST_CLAIM_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_CLAIM_SUCCESS:
    case userActionTypes.POST_CLAIM_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_CLAIM_FAILURE:
    case userActionTypes.POST_CLAIM_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    default:
      return state;
  }
};

export default claimReducer;

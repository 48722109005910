import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  FavoritesState,
  UserActions,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: FavoritesState = {
  pending: false,
  data: null,
  status: null,
};

const favoritesReducer = (
  state: FavoritesState = initialState,
  action: UserActions | RootActions
): FavoritesState => {
  switch (action.type) {
    case userActionTypes.GET_FAVORITES_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_FAVORITES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_FAVORITES_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.ADD_FAVORITE_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.ADD_FAVORITE_SUCCESS: {
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.ADD_FAVORITE_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.DELETE_FAVORITE_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.DELETE_FAVORITE_SUCCESS: {
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.DELETE_FAVORITE_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CLEAR_FAVORITES:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default favoritesReducer;

import {
  JackpotWinnersActions,
  jackpotWinnersActionTypes,
  JackpotWinnersState,
} from "@/fe-core/meta/types/jackpotWinners";

const initialState: JackpotWinnersState = {
  jackpotWinners: [],
  pending: false,
  status: null,
};

const jackpotWinnersReducer = (
  state: JackpotWinnersState = initialState,
  action: JackpotWinnersActions
): JackpotWinnersState => {
  switch (action.type) {
    case jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_REQUEST:
      return {
        ...state,
        pending: true,
        jackpotWinners: [],
        status: null,
      };
    case jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        jackpotWinners: payload,
        status: {
          success: true,
        },
      };
    }
    case jackpotWinnersActionTypes.GET_JACKPOT_WINNERS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        jackpotWinners: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default jackpotWinnersReducer;

"use client";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  restrictionsSelector,
  uploadDocumentShowModalSelector,
} from "../../fe-core-omega/_redux/selectors/userSelectors";
import Restrictions from "../Restrictions";
import "react-toastify/dist/ReactToastify.css";
import LoginMenu from "./LoginMenu";
import { ToastContainer } from "react-toastify";
import RealityCheckModal from "./RealityCheckModal";
import BalanceWarningModal from "./BalanceWarningModal";
import SignupModal from "./SignupModal";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import { InstallPWA } from "../InstallPWA";
import useIsIOS from "../Hooks/useIsIOS";
import LiveAgent from "../LiveAgent";
import Smartico from "../Smartico";
import ProfileUpdateModal from "./ProfileUpdateModal";
import SupportModal from "./SupportModal";

import { toggleSupportModal } from "../../fe-core-omega/_redux/actions/supportActions";
import { useFormatter } from "next-intl";
import {
  activeSupportTabSelector,
  isOpenSupportModalSelector,
} from "../../fe-core-omega/_redux/selectors/supportSelectors";
import ScrollToTop from "@/components/ScrollToTop";
import { isUserAuthenticatedSelector } from "../../fe-core-omega/_redux/selectors/sessionSelectors";
import ActivateEmailModal from "./ActivationEmailModal";
import GameCardTooltip from "@/components/CasinoPage/GameCardTooltip";
import { gameCardTooltipGameSelector } from "../../fe-core-omega/_redux/selectors/casinoSelectors";
import { createPortal } from "react-dom";
import SessionExpiredModal from "./SessionExpiredModal";
import UploadDocumentModal from "../KycDocuments/UploadDocumentModal";

const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {
  // const router = useRouter();
  const { prompt } = useIsIOS();
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const format = useFormatter();
  const { needsTCAccept, needsMarketingAccept } =
    useSelector(restrictionsSelector);

  const hasToAcceptConsents = needsTCAccept || needsMarketingAccept;

  const supportSelectorIsOpen = useSelector(isOpenSupportModalSelector);
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  const activeSupportTab = useSelector(activeSupportTabSelector);
  const activeTooltipGame = useSelector(gameCardTooltipGameSelector);
  const selectedDocument = useSelector(uploadDocumentShowModalSelector);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const forwardAndRefresh = async () => {
  //     if (logoutStatus?.success) {
  //       window.location.href = "/";
  //     }
  //   };
  //   forwardAndRefresh();
  // }, [logoutStatus]);
  // new chat
  // useEffect(() => {
  //   if (router.isReady) {
  //     if (router.asPath.includes('/wiki/contact')) {
  //       if (
  //         typeof window !== 'undefined' &&
  //         window?.quriobot?.inited &&
  //         !window?.quriobot?.isOpen
  //       ) {
  //         window.quriobot.show()
  //       }
  //     } else {
  //       if (typeof window !== 'undefined' && window?.quriobot?.inited) {
  //         window.quriobot.hide()
  //       }
  //     }
  //   }
  // }, [router])

  // // old chat
  // useEffect(() => {
  //   const result: HTMLDivElement | null =
  //     document.querySelector('.circleChatButtonWrap') ||
  //     document.querySelector('.circleContactButtonWrap')

  //   if (result !== null) {
  //     if (router.asPath.includes('/contact')) {
  //       result.style.display = 'block'
  //     } else {
  //       result.style.display = 'none'
  //     }
  //   }
  // })

  const setSupportSelectorIsOpen = (isOpen: boolean) => {
    dispatch(toggleSupportModal({ isOpen }));
  };
  return (
    <>
      {children}
      <LoginMenu />
      <SessionExpiredModal />
      <ActivateEmailModal />
      {!isUserAuthenticated && <SignupModal />}
      <UploadDocumentModal selectedDocument={selectedDocument} />
      {/* <NavMenu /> */}
      {/*<ChatIconScript />*/}
      {/* <OldChatIconScript /> */}
      {/* {hasToAcceptConsents && <Restrictions />} */}
      <RealityCheckModal />
      <BalanceWarningModal />
      {/* <ProfileUpdateModal /> */}
      <SupportModal
        defaultTab={activeSupportTab}
        isOpen={supportSelectorIsOpen}
        setIsOpen={setSupportSelectorIsOpen}
      />
      <ToastContainer />
      {/* <LiveAgent /> */}
      {/* <Smartico /> */}
      {/*//@ts-ignore*/}
      {/* {prompt && <InstallPWA />}  */}
    </>
  );
};

export default Layout;

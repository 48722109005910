import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  takeLatest,
} from "redux-saga/effects";

import Storage from "../../fe-core-omega/helpers/storage";
import { storageKeys } from "@/config/general";
import { rootActionTypes } from "../../fe-core-omega/meta/types/root";
import sagas from "../../fe-core-omega/_redux/sagas";
function* clearStateSaga() {
  yield Storage.remove(storageKeys.SESSION_ID);
}

export function* rootSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    takeLatest(rootActionTypes.CLEAR_STATE, clearStateSaga),
    ...sagas,
  ]);
}

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
    GetClaimFailureAction,
    GetClaimRequestAction,
    GetClaimSuccessAction,
    IClaimViewModel,
    ISetClaimRequestPayload,
    PostClaimFailureAction,
    PostClaimRequestAction,
    PostClaimSuccessAction
} from "@/fe-core/meta/interfaces/user/affiliate/claim";
import { userActionTypes } from "@/fe-core/meta/types/user";

export const getClaimRequest = (): GetClaimRequestAction => ({
    type: userActionTypes.GET_CLAIM_REQUEST,
});

export const getClaimSuccess = (
    payload: IClaimViewModel
): GetClaimSuccessAction => ({
    type: userActionTypes.GET_CLAIM_SUCCESS,
    payload,
});

export const getClaimFailure = (
    payload: IErrorPayload
): GetClaimFailureAction => ({
    type: userActionTypes.GET_CLAIM_FAILURE,
    payload,
});

export const postClaimRequest = (payload: ISetClaimRequestPayload): PostClaimRequestAction => ({
    type: userActionTypes.POST_CLAIM_REQUEST,
    payload,
});

export const postClaimSuccess = (
    payload: IClaimViewModel
): PostClaimSuccessAction => ({
    type: userActionTypes.POST_CLAIM_SUCCESS,
    payload,
});

export const postClaimFailure = (
    payload: IErrorPayload
): PostClaimFailureAction => ({
    type: userActionTypes.POST_CLAIM_FAILURE,
    payload,
});

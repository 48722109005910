import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IDeadSimpleChatViewModel } from "@/fe-core/meta/interfaces/deadSimpleChat";
import {
  getDscAccessTokenFailure,
  getDscAccessTokenSuccess,
} from "@/fe-core/_redux/actions/deadSimpleChatActions";
import { deadSimpleChatActionTypes } from "@/fe-core/meta/types/deadSimpleChat";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { localeSelector } from "../selectors/localeSelectors";

function* getDscAccessTokenSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);
    const response: IJsonResponse<{ accessToken: string }> = yield fetch(
      "/api/create-user",
      <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      }
    );

    console.log("DEADSIMPLECHAT", response);
    if (response.status == 200) {
      const accessToken: IDeadSimpleChatViewModel = yield response.json();

      yield put(getDscAccessTokenSuccess(accessToken));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getDscAccessTokenFailure(errorPayload));
    }
  } catch (error) {
    yield put(getDscAccessTokenFailure(parseFrontendError(error)));
  }
}

function* deadSimpleChatSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_REQUEST,
      getDscAccessTokenSaga
    ),
    takeLatest(sessionActionTypes.AUTHENTICATE_SUCCESS, getDscAccessTokenSaga),
  ]);
}

export default deadSimpleChatSaga;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  GameSessionsActions,
  GameSessionsState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: GameSessionsState = {
  pending: false,
  data: null,
  status: null,
};

const gameSessionsReducer = (
  state: GameSessionsState = initialState,
  action: GameSessionsActions | RootActions
): GameSessionsState => {
  switch (action.type) {
    case userActionTypes.GAME_SESSIONS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GAME_SESSIONS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GAME_SESSIONS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default gameSessionsReducer;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  WithdrawActions,
  WithdrawState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: WithdrawState = {
  pending: false,
  data: null,
  status: null,
  withdrawMethods: null,
  selectedPaymentMethod: null,
  processWithdrawPending: false,
  processWithdrawStatus: null
};

const withdrawReducer = (
  state: WithdrawState = initialState,
  action: WithdrawActions | RootActions
): WithdrawState => {
  switch (action.type) {
    case userActionTypes.RESET_PROCESS_WITHDRAW_STATUS:
      return {
        ...state,
        processWithdrawStatus: null,
      };
    case userActionTypes.GET_WITHDRAW_METHODS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_WITHDRAW_METHODS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        withdrawMethods: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_WITHDRAW_METHODS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.OPEN_PROCESS_WITHDRAW_MODAL:
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      };
    case userActionTypes.CLOSE_PROCESS_WITHDRAW_MODAL: {
      return {
        ...state,
        processWithdrawPending: false,
        processWithdrawStatus: null,
        selectedPaymentMethod: null,
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST: {
      return {
        ...state,
        processWithdrawPending: true,
        processWithdrawStatus: null,
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST_SUCCESS: {
      return {
        ...state,
        processWithdrawPending: false,
        processWithdrawStatus: {
          success: true,
          message: "",
          ...action.payload,
        },
      };
    }
    case userActionTypes.PROCESS_WITHDRAW_REQUEST_FAILURE: {
      return {
        ...state,
        processWithdrawPending: false,
        processWithdrawStatus: {
          success: false,
          message: action.payload.message,
        },
      };
    }
    // case userActionTypes.WITHDRAW_REQUEST:
    //   return {
    //     ...state,
    //     pending: true,
    //     status: null,
    //   };
    // case userActionTypes.WITHDRAW_SUCCESS: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     pending: false,
    //     data: payload,
    //     status: {
    //       success: true,
    //     },
    //   };
    // }
    // case userActionTypes.WITHDRAW_FAILURE: {
    //   const {
    //     payload: { message },
    //   } = action;
    //   return {
    //     ...state,
    //     pending: false,
    //     status: {
    //       success: false,
    //       message,
    //     },
    //   };
    // }
    case userActionTypes.CLEAR_WITHDRAW:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default withdrawReducer;

import {
  userActionTypes,
  RestrictionsState,
  UserActions,
  RestrictionsDetailState,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: RestrictionsState = {
  pending: false,
  status: null,
  needsTCAccept: false,
  needsMarketingAccept: false,
  detail: {
    userId: "",
    sessionId: "",
    phone: null,
    phonePrefix: null,
  },
};

const restrictionsReducer = (
  state: RestrictionsState = initialState,
  action: UserActions | RootActions
): RestrictionsState => {
  switch (action.type) {
    case userActionTypes.SET_TC_RESTRICTIONS:
      return {
        ...state,
        needsTCAccept: true,
      };
    case userActionTypes.SET_MARKETING_RESTRICTIONS:
      return {
        ...state,
        needsMarketingAccept: true,
      };
    case userActionTypes.SET_RESTRICTION_USER_DETAILS:
      const {
        //@ts-ignore
        payload: { userId, sessionId, phone, phonePrefix },
      } = action;
      return {
        ...state,
        detail: {
          userId,
          sessionId,
          phone,
          phonePrefix,
        },
      };
    case userActionTypes.RESOLVE_TC_RESTRICTION_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.RESOLVE_TC_RESTRICTION_SUCCESS: {
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
        needsTCAccept: false,
        needsMarketingAccept: false,
      };
    }
    case userActionTypes.RESOLVE_TC_RESTRICTION_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default restrictionsReducer;

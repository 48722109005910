import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IProcessDepositRequestPayload,
  IProcessDepositResponsePayload,
} from "@/fe-core/meta/interfaces/user/cashier/processDeposit";
import {ClearDepositAction, userActionTypes} from "@/fe-core/meta/types/user";
import {
  ClearProcessDepositAction,
  ClearProcessDepositMessageAction,
  CloseProcessDepositModalAction,
  depositPaymentMethodTypes,
  OpenProcessDepositModalAction,
  ProcessDepositFailureAction,
  ProcessDepositRequestAction,
  ProcessDepositSuccessAction,
  ResetProcessDepositStatusAction,
} from "@/fe-core/meta/types/user/cashier/processDeposit";

export const resetProcessDepositStatus = (): ResetProcessDepositStatusAction => ({
  type: userActionTypes.RESET_PROCESS_DEPOSIT_STATUS,
});

export const openProcessDepositModal = (
  payload: depositPaymentMethodTypes
): OpenProcessDepositModalAction => ({
  type: userActionTypes.OPEN_PROCESS_DEPOSIT_MODAL,
  payload,
});

export const closeProcessDepositModal = (): CloseProcessDepositModalAction => ({
  type: userActionTypes.CLOSE_PROCESS_DEPOSIT_MODAL,
});

export const processDepositRequest = (
  payload: IProcessDepositRequestPayload
): ProcessDepositRequestAction => ({
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST,
  payload,
});

export const processDepositSuccess = (
  payload: IProcessDepositResponsePayload
): ProcessDepositSuccessAction => ({
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST_SUCCESS,
  payload,
});

export const processDepositFailure = (
  payload: IErrorPayload
): ProcessDepositFailureAction => ({
  type: userActionTypes.PROCESS_DEPOSIT_REQUEST_FAILURE,
  payload,
});

export const clearProcessDeposit = (): ClearProcessDepositAction => ({
  type: userActionTypes.CLEAR_PROCESS_DEPOSIT,
});

export const clearProcessDepositMessage = (): ClearProcessDepositMessageAction => ({
  type: userActionTypes.CLEAR_PROCESS_DEPOSIT_MESSAGE,
});
import {
  FooterActions,
  FooterState,
  footerActionTypes,
} from "@/fe-core/meta/types/footer";

const initialState: FooterState = {
  pending: false,
  footer: null,
};

const footerReducer = (
  state: FooterState = initialState,
  action: FooterActions
): FooterState => {
  switch (action.type) {
    case footerActionTypes.GET_FOOTER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case footerActionTypes.GET_FOOTER_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case footerActionTypes.GET_FOOTER_SUCCESS:
      return {
        ...state,
        pending: false,
        footer: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default footerReducer;

import { ICMSLatestBetViewModel } from "@/fe-core/meta/interfaces/latestBets";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetLatestBetsFailureAction,
  GetLatestBetsRequestAction,
  GetLatestBetsSuccessAction,
  latestBetsActionTypes,
  PushLatestBetAction,
} from "@/fe-core/meta/types/latestBets";
import { webPubSubActionTypes } from "@/fe-core/meta/types/webPubSub";

export const getLatestBetsRequest = (): GetLatestBetsRequestAction => ({
  type: latestBetsActionTypes.GET_LATEST_BETS_REQUEST,
});

export const getLatestBetsSuccess = (
  payload: ICMSLatestBetViewModel[]
): GetLatestBetsSuccessAction => ({
  type: latestBetsActionTypes.GET_LATEST_BETS_SUCCESS,
  payload,
});

export const getLatestBetsFailure = (
  payload: IErrorPayload
): GetLatestBetsFailureAction => ({
  type: latestBetsActionTypes.GET_LATEST_BETS_FAILURE,
  payload,
});

export const pushFeFunctionBet = (
  payload: ICMSLatestBetViewModel
): PushLatestBetAction => ({
  type: webPubSubActionTypes.WEBPUBSUB_HUB_BET,
  payload,
});

import { Session } from "autobahn";
// import { BalanceChangedPayload } from './balance'

export enum socketActionTypes {
  INITIALIZE_SOCKET = "INITIALIZE_SOCKET",
  SOCKET_CONNECTED = "SOCKET_CONNECTED",
  SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED",
  RECONNECT_SOCKET = "RECONNECT_SOCKET",
  SOCKET_CONNECTION_FAILED = "socket/SOCKET_CONNECTION_FAILED",
  SUBSCRIBE_SESSION_STATE = "socket/SUBSCRIBE_SESSION_STATE",
  SESSION_STATE_CHANGED = "socket/SESSION_STATE_CHANGED",
  SUBSCRIBE_WATCH_BALANCE = "socket/SUBSCRIBE_WATCH_BALANCE",
  SESSION_BALANCE_CHANGED = "socket/SESSION_BALANCE_CHANGED",
  SESSION_BALANCE_UPDATE = "socket/SESSION_BALANCE_UPDATE",
}

export type InitializeSocketAction = {
  type: socketActionTypes.INITIALIZE_SOCKET;
};

export type SocketConnectedAction = {
  type: socketActionTypes.SOCKET_CONNECTED;
  payload: Session;
};

export type SocketDisconnectedAction = {
  type: socketActionTypes.SOCKET_DISCONNECTED;
};

export type ReconnectSocketAction = {
  type: socketActionTypes.RECONNECT_SOCKET;
};

export type SocketIdentityPayload = {
  cid: string;
  cidEx: string;
};

export type SocketConnectionFailedAction = {
  type: socketActionTypes.SOCKET_CONNECTION_FAILED;
};

export type SocketSubscribeSessionStateAction = {
  type: socketActionTypes.SUBSCRIBE_SESSION_STATE;
};

export type SocketSessionStateChangedAction = {
  type: socketActionTypes.SESSION_STATE_CHANGED;
  payload: number;
};

export type SocketSubscribeWatchBalanceAction = {
  type: socketActionTypes.SUBSCRIBE_WATCH_BALANCE;
};

export type SocketSessionBalanceChangedAction = {
  type: socketActionTypes.SESSION_BALANCE_CHANGED;
  payload: SocketBalanceChangedPayload;
};

export type SocketSessionBalanceUpdateAction = {
  type: socketActionTypes.SESSION_BALANCE_UPDATE;
  payload: SocketBalanceChangedPayload;
};

export type SocketSessionStatePayload = {
  code: number;
  description: string;
};

export type SocketBalanceChangedPayload = {
  id: number;
  vendor: string;
  type: string;
  amount: number;
  bonusAmount: number;
  isFirstDeposit: boolean;
};

export type SubscriptionTypes =
  | SocketSessionStatePayload
  | SocketBalanceChangedPayload;
// | BalanceChangedPayload

export type SocketActions =
  | SocketConnectedAction
  | SocketDisconnectedAction
  | ReconnectSocketAction
  | InitializeSocketAction
  | SocketConnectionFailedAction
  | SocketSubscribeSessionStateAction
  | SocketSessionStateChangedAction
  | SocketSubscribeWatchBalanceAction
  | SocketSessionBalanceChangedAction
  | SocketSessionBalanceUpdateAction;

export type SocketState = {
  sessionStatus: number;
  session: Session | null;
  sessionConnectionAttempts: number;
  socketConnectionFailed: boolean;
};

import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { DEFAULT_GAMING_ACCOUNT_ID, headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  DepositRequestBonusesViewModel,
  IApplyBonusViewModel,
  IBonusResponsePayload,
  IDepositBonusesRequestPayload,
  IDepositBonusesResponsePayload,
  IDepositBonusesViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  DepositBonusesRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  depositBonusesFailure,
  depositBonusesSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

const depositBonusesResponsePayloadToViewModel = ({
  bonuses,
}: IDepositBonusesResponsePayload): IDepositBonusesViewModel =>
  bonuses
    .filter((bonus) => !bonus.code.startsWith("TICKET_"))
    .map(({ code, name }) => ({
      code,
      name,
    }));

const getURLParams = (payload: DepositRequestBonusesViewModel) => {
  const qs = Object.keys(payload)
    .map(
      (key) => `${key}=${payload[key as keyof DepositRequestBonusesViewModel]}`
    )
    .join("&");

  return qs ? `?${qs}` : "";
};

function* depositBonusesRequestSaga({ payload }: DepositBonusesRequestAction) {
  try {
    const queryParams = getURLParams(payload);
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);
    const requestPayload: IDepositBonusesRequestPayload = {
      gamingAccountID: parseInt(DEFAULT_GAMING_ACCOUNT_ID, 10),
      type: "deposit",
    };

    const response: IJsonResponse<IApplyBonusViewModel | IErrorPayload> =
      yield fetch(`/api/user/bonus/eligible${queryParams}`, <RequestInit>{
        //${queryString}
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });
    if (response.status == 200) {
      //@ts-ignore
      const payload = yield response.json();
      const depositBonusesViewModel: IDepositBonusesViewModel =
        depositBonusesResponsePayloadToViewModel(payload);

      yield put(depositBonusesSuccess(depositBonusesViewModel));
    } else {
      yield put(depositBonusesFailure(parseFrontendError(payload)));
    }
  } catch (error) {
    yield put(depositBonusesFailure(parseFrontendError(error)));
  }
}

function* depositBonusesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.DEPOSIT_BONUSES_REQUEST,
      depositBonusesRequestSaga
    ),
  ]);
}

export default depositBonusesSaga;

export enum supportActionTypes {
  TOGGLE_SUPPORT_MODAL = "TOGGLE_SUPPORT_MODAL",
  SET_SUPPORT_CURRENCY_TAB = "SET_SUPPORT_CURRENCY_TAB",
}

export type ToggleSupportModalAction = {
  type: supportActionTypes.TOGGLE_SUPPORT_MODAL,
  payload: boolean
}

export type SetActiveSupportTabAction = {
  type: supportActionTypes.SET_SUPPORT_CURRENCY_TAB,
  payload: number
}

export type supportActions =
  | SetActiveSupportTabAction
  | ToggleSupportModalAction

export type supportState = {
  isOpenSupportModal: boolean
  activeSupportTabIndex: number
}

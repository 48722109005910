import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { footerActionTypes } from "@/fe-core/meta/types/footer";
import { IFootersResponseModel } from "@/fe-core/meta/interfaces/footer";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getFooterFailure,
  getFooterSuccess,
} from "@/fe-core/_redux/actions/footerActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildFooterQueryString } from "@/fe-core/helpers/footer";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { themeActionTypes } from "@/fe-core/meta/types/theme";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getFooterSaga() {
  try {
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);
    const queryString: string = buildFooterQueryString(theme);

    const response: IJsonResponse<IFootersResponseModel[]> = yield fetch(
      `/api/footercontent${queryString}`,
      {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IFootersResponseModel = yield response.json();
        yield put(getFooterSuccess(viewModel));
        break;
      default:
        throw new Error("Footer couldn't be fetched");
    }
  } catch (error) {
    yield put(getFooterFailure(parseFrontendError(error)));
  }
}

function* footerSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(footerActionTypes.GET_FOOTER_REQUEST, getFooterSaga),
    takeLatest(themeActionTypes.SET_CURRENT_THEME, getFooterSaga),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getFooterSaga),
  ]);
}

export default footerSaga;

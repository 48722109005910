import { RootState } from "@/fe-core/meta/types/root";
import { localeState } from "@/fe-core/meta/types/locale";

export const localeSelector = ({ locale }: RootState): localeState => locale;

export const isOpenLocaleCurrenyModalSelector = (
    state: RootState
): boolean => {
    const { isOpenLocaleCurrenyModal } = localeSelector(state);
    return isOpenLocaleCurrenyModal;
};

export const activeLocaleCurrenyTabSelector = (
    state: RootState
): number => {
    const { activeLocaleCurrenyTabIndex } = localeSelector(state);

    return activeLocaleCurrenyTabIndex;
};

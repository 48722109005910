export function buildPopUpsQueryString(
  userRoles: string[],
  bonusCodes: string[],
): string {
  const queryParams = new URLSearchParams();

  for (const role of userRoles) {
    queryParams.append("roles", role);
  }

  for (const code of bonusCodes) {
    queryParams.append("codes", code);
  }
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

import {
  DeadSimpleChatActionsActions,
  deadSimpleChatActionTypes,
} from "@/fe-core/meta/types/deadSimpleChat";
import { DeadSimpleChatActionsState } from "@/fe-core/meta/types/deadSimpleChat";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: DeadSimpleChatActionsState = {
  pending: false,
  accessToken: null,
};

const deadSimpleChatReducer = (
  state: DeadSimpleChatActionsState = initialState,
  action: DeadSimpleChatActionsActions | RootActions
): DeadSimpleChatActionsState => {
  switch (action.type) {
    case deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        accessToken: action.payload.accessToken,
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default deadSimpleChatReducer;

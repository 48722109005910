import { IDeadSimpleChatViewModel } from "@/fe-core/meta/interfaces/deadSimpleChat";
import { RootState } from "@/fe-core/meta/types/root";
import {mainDrawerSelector} from "@/fe-core/_redux/selectors/drawerSelectors";
import {mainPromotionBannersSelector} from "@/fe-core/_redux/selectors/promotionBannersSelectors";

export const deadSimpleChatSelector = ({
  deadSimpleChat,
}: RootState): IDeadSimpleChatViewModel | null => deadSimpleChat;

export const isPendingDeadSimpleChatSelector = (state: RootState): boolean => {
  const { pending } = deadSimpleChatSelector(state);
  return pending;
};

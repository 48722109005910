import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetCurrenciesFailureAction,
  GetCurrenciesRequestAction,
  GetCurrenciesSuccessAction,
  SetDetectedCurrencySuccessAction,
} from "@/fe-core/meta/types/user/register/currencies";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  IGetCurrenciesResponsePayload,
  IGetDetectedCurrencyViewModel,
} from "@/fe-core/meta/interfaces/user/register/currencies";

export const getCurrenciesRequest = (): GetCurrenciesRequestAction => ({
  type: userActionTypes.GET_CURRENCIES_REQUEST,
});

export const getCurrenciesSuccess = (
  payload: IGetCurrenciesResponsePayload
): GetCurrenciesSuccessAction => ({
  type: userActionTypes.GET_CURRENCIES_SUCCESS,
  payload,
});

export const setDetectedCurrency = (
  payload: IGetDetectedCurrencyViewModel
): SetDetectedCurrencySuccessAction => ({
  type: userActionTypes.SET_DETECTED_CURRENCY,
  payload,
});

export const getCurrenciesFailure = (
  payload: IErrorPayload
): GetCurrenciesFailureAction => ({
  type: userActionTypes.GET_CURRENCIES_FAILURE,
  payload,
});

import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { IPromotionsResponsePayload, IPromotionsViewModel } from "../meta/interfaces/promotions";
import { localStorageKeys } from "../meta/types/localStorage";
import Storage, { CookiesStorage } from "./storage";
import { allowAddEntry } from "./utils";

export function buildPromotionsQueryString(
  theme: string,
  userRoles: string[],
  bonusCodes: string[],
  btagCodes: string[],
  isAuthenticated: string = "0",
  isIdentified: string = "0"
): string {
  const queryParams = new URLSearchParams();
  queryParams.append("theme", theme);
  queryParams.append("userRoles", userRoles?.join(","));
  queryParams.append("bonusCodes", bonusCodes?.join(","));
  queryParams.append("btagCodes", btagCodes?.join(","));
  queryParams.append("isAuthenticated", isAuthenticated);
  queryParams.append("isIdentified", isIdentified);
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export function buildBottomPromotionQueryString(
  isAuthenticated: string,
  isIdentified: string = "0"
): string {
  const queryParams = new URLSearchParams();
  queryParams.append("isAuthenticated", isAuthenticated);
  queryParams.append("isIdentified", isIdentified);
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export function buildPromotionQueryString(
  slug: string,
  categorySlug: string
): string {
  const queryParams = new URLSearchParams();

  if (slug) {
    queryParams.append("slug", slug);
  }

  if (categorySlug) {
    queryParams.append("categorySlug", categorySlug);
  }

  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export function buildPromotionBannersQueryString(
  theme: string,
  userRoles: string[],
  bonusCodes: string[],
  btagCodes: string[],
  isAuthenticated: string = "0",
  isIdentified: string = "0"
): string {
  const queryParams = new URLSearchParams();
  queryParams.append("theme", theme);
  queryParams.append("userRoles", userRoles?.join(","));
  queryParams.append("bonusCodes", bonusCodes?.join(","));
  queryParams.append("btagCodes", btagCodes?.join(","));
  queryParams.append("isAuthenticated", isAuthenticated);
  queryParams.append("isIdentified", isIdentified);
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export const mapPromotionsToTheme = (promotions: IPromotionsViewModel, theme: string) => {
  const data = promotions?.map(
    ({
      id,
      //@ts-ignore
      attributes: {
        title,
        slug,
        snippet,
        category,
        [`desktopImage${theme}`]: desktopImage,
        [`mobileImage${theme}`]: mobileImage,
        [`desktopThumbnail${theme}`]: desktopThumbnail,
        [`mobileThumbnail${theme}`]: mobileThumbnail,
        rank,
        promotionDuration,
        desktopImageDark,
        mobileImageDark,
        ...rest
      },
    }) => ({
      id,
      title,
      slug,
      snippet,
      category,
      desktopImage,
      mobileImage,
      desktopThumbnail,
      mobileThumbnail,
      rank: rank,
      promotionDuration,
      desktopImageDark,
      mobileImageDark,
      attributes: {
        title,
        slug,
        snippet,
        category,
        desktopImage,
        mobileImage,
        desktopThumbnail,
        mobileThumbnail,
        rank,
        promotionDuration,
        desktopImageDark,
        mobileImageDark,
        ...rest,
      },
      ...rest,
    })
  );
  //@ts-ignore
  return data;
}
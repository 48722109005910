import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IBankHistory,
  IBankingHistoryRequest,
} from "@/fe-core/meta/interfaces/user/cashier/bankingHistory";
import {
  BankingHistoryFailureAction,
  BankingHistoryRequestAction,
  BankingHistorySuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const bankingHistoryRequest = (
  payload: IBankingHistoryRequest
): BankingHistoryRequestAction => ({
  type: userActionTypes.BANKING_HISTORY_REQUEST,
  payload,
});

export const bankingHistorySuccess = (
  payload: IBankHistory[]
): BankingHistorySuccessAction => ({
  type: userActionTypes.BANKING_HISTORY_SUCCESS,
  payload,
});

export const bankingHistoryFailure = (
  payload: IErrorPayload
): BankingHistoryFailureAction => ({
  type: userActionTypes.BANKING_HISTORY_FAILURE,
  payload,
});

import { ICMSTopWinnerViewModel } from "@/fe-core/meta/interfaces/topWinners";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetTopWinnersFailureAction,
  GetTopWinnersRequestAction,
  GetTopWinnersSuccessAction,
  topWinnersActionTypes,
} from "@/fe-core/meta/types/topWinners";

export const getTopWinnersRequest = (): GetTopWinnersRequestAction => ({
  type: topWinnersActionTypes.GET_TOP_WINNERS_REQUEST,
});

export const getTopWinnersSuccess = (
  payload: ICMSTopWinnerViewModel[]
): GetTopWinnersSuccessAction => ({
  type: topWinnersActionTypes.GET_TOP_WINNERS_SUCCESS,
  payload,
});

export const getTopWinnersFailure = (
  payload: IErrorPayload
): GetTopWinnersFailureAction => ({
  type: topWinnersActionTypes.GET_TOP_WINNERS_FAILURE,
  payload,
});

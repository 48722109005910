import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";1
import {
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  validateSignupIpFailure,
  validateSignupIpSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IValidateSignupIpViewModel } from "@/fe-core/meta/interfaces/user/register/validate/ip";
import { methodTypes } from "@/fe-core/meta/types/root";

function* validateSignupIpRequestSaga() {
  try {
    const response: IJsonResponse<
      IValidateSignupIpViewModel | IErrorPayload
    > = yield fetch(`/api/user/signup/ip`, <
      RequestInit
    >{
      method: methodTypes.POST,
    });
    const { status } = response;
    switch (status) {
      case 204:
      case 200:
        yield put(validateSignupIpSuccess());
        break;
      default:
        yield put(validateSignupIpFailure());
    }
  } catch (error) {
    yield put(validateSignupIpFailure());
  } 
}

function* validateSignupIpSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.VALIDATE_SIGNUP_IP_REQUEST,
      validateSignupIpRequestSaga
    ),
  ]);
}

export default validateSignupIpSaga;

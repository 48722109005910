import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { INavViewModel } from "@/fe-core/meta/interfaces/nav";
import { navActionTypes, NavRequestAction } from "@/fe-core/meta/types/nav";
import { navFailure, navSuccess } from "@/fe-core/_redux/actions/navActions";
import { parseFrontendError } from "@/fe-core/helpers/general";

function* navRequestSaga({ payload }: NavRequestAction) {
  try {
    const response: IJsonResponse<INavViewModel> = yield fetch(
      `/api/nav/${payload}`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: INavViewModel = yield response.json();
        yield put(navSuccess(payload));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(navFailure(parseFrontendError(error)));
  }
}

function* navSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([takeLatest(navActionTypes.NAV_REQUEST, navRequestSaga)]);
}

export default navSaga;

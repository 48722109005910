import {
  ALL_GAMES_SLUG,
  CASINO_DATA_SOURCE,
  CASINO_URL,
  casinoGroupExpand,
  casinoGroupFields,
  categoryFields,
  gamesExpand,
  groupExpand,
  groupFields,
  groupGameFields,
  LIVE_CASINO_DATA_SOURCE,
  MAX_GAME_COUNT_ON_GAME_SWIPER,
  MAX_GAMES_PER_PAGE,
  platform,
  singleGamesExpand,
} from "@/config/casino";
import { GAME_ID_SEPARATOR, storageKeys } from "@/config/general";
import {
  getCasinoAllGamesFailure,
  getCasinoAllGamesRequest,
  getCasinoAllGamesSuccess,
  getCasinoAuditGroupSuccess,
  getCasinoGroupsFailure,
  getCasinoGroupsSuccess,
  getCasinoHomeGroupsFailure,
  getCasinoHomeGroupsSuccess,
  getCasinoMoreGamesGroupsFailure,
  getCasinoMoreGamesGroupsSuccess,
  getCasinoPopularGamesGroupSuccess,
  getCategoriesFailure,
  getCategoriesSuccess,
  getGamesFailure,
  getGamesSuccess,
  getLiveCasinoGroupsFailure,
  getLiveCasinoGroupsSuccess,
  getLiveCasinoMoreGamesGroupsFailure,
  getLiveCasinoMoreGamesGroupsSuccess,
  getLiveCasinoPopularGamesGroupSuccess,
  getSingleGameSuccess,
  setCasinoAllGamesGroup,
} from "@/fe-core/_redux/actions/casinoActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import {
  buildCasinoGroupsQueryString,
  buildCasinoQueryString,
  filterGroups,
  getGameDesktopSupportFilterString,
  getGameMobileSupportFilterString,
} from "@/fe-core/helpers/casino";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import {
  ICasinoCategoriesPayload,
  ICasinoGamesPayload,
  ICasinoGameViewModel,
  ICasinoGroupViewModel,
} from "@/fe-core/meta/interfaces/casino";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  casinoActionTypes,
  GetCasinoAllGamesRequestAction,
  GetCasinoAuditGroupRequestAction,
  GetCasinoGroupsRequestAction,
  GetCasinoMoreGamesGroupsRequestAction,
  GetCasinoPopularGamesGroupRequestAction,
  GetGamesRequestAction,
  GetLiveCasinoGroupsRequestAction,
  GetLiveCasinoMoreGamesGroupsRequestAction,
  GetLiveCasinoPopularGamesGroupRequestAction,
  GetMoreGamesRequestAction,
  GetSingleGameRequestAction,
} from "@/fe-core/meta/types/casino";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
// import { useTranslations } from "next-intl";
function* getCategoriesSaga() {
  try {
    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoQueryString({
      fields: categoryFields,
      language: locale,
    });
    const response: IJsonResponse<ICasinoCategoriesPayload> = yield fetch(
      `${CASINO_URL}/categories${queryString}`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoCategoriesPayload = yield response.json();
        yield put(getCategoriesSuccess(payload));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCategoriesFailure(parseFrontendError(error)));
  }
}

function* getGamesSaga({
  payload,
}: GetGamesRequestAction | GetMoreGamesRequestAction) {
  try {
     const queryString: string = buildCasinoQueryString({
       expand: gamesExpand,
       ...payload
     });

     const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
       `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/games${queryString}`,
       { method: methodTypes.GET }
     );
     const { status } = response;
     switch (status) {
       case 200:
         const viewModel: ICasinoGamesPayload = yield response.json();
         yield put(getGamesSuccess(viewModel));
         break;
       default:
         throw new Error("A problem has occurred while retrieving navigation");
     }
  } catch (error) {
    yield put(getGamesFailure(parseFrontendError(error)));
  }
}

function* getSingleGameSaga({ payload }: GetSingleGameRequestAction) {
  try {
    const { slug } = payload;
    const gameId = decodeURIComponent(slug as string)?.split(
      GAME_ID_SEPARATOR
    )?.[0];
    const productCode = decodeURIComponent(slug as string)?.split(
      GAME_ID_SEPARATOR
    )?.pop();

    const filters = [`gameId=${gameId},productCode=${productCode}`];
    const queryString: string = buildCasinoQueryString({
      filters,
      expand: singleGamesExpand,
    });

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/games${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoGamesPayload = yield response.json();
        const game: ICasinoGameViewModel = payload?.items?.find(
          (game) => game?.gameId === gameId
        ) as ICasinoGameViewModel;

        yield put(getSingleGameSuccess(game));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getGamesFailure(parseFrontendError(error)));
  }
}

function* getCasinoGroupsSaga({ payload }: GetCasinoGroupsRequestAction) {
  try {
    // @ts-ignore
    const { dataSource, filters, platform, pagination } = payload;
    const { locale } = yield select(localeSelector);
    // const t = useTranslations("general");
    //const { locale } = yield select(sessionDataSelector);
    // console.log("FAVORITE SAGE", t("favorite"));

    const newFilters = [ ...(filters || []) ];

    newFilters.push(
      `games(${
        platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true)
      })`
    );

    const country = CookiesStorage.getItem(storageKeys.COUNTRY);
    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      platform ? platform : undefined,
      groupGameFields,
      locale,
      newFilters,
      country,
      pagination ? pagination : undefined,
      undefined,
      "order",
      "acs"
    );
    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/groups/${dataSource}/${queryString}`,
      { method: methodTypes.GET }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games?.count > 0 || group?.slug === ALL_GAMES_SLUG;
        })?.map(group => {
          return {
            ...group,
            featuredGames:
              group?.featuredGames?.length && group?.featuredGames?.length > 0
                ? group?.featuredGames
                : group?.games?.items,
          };
        });

        yield put(
          getCasinoGroupsSuccess(
            filterGroups(groups.items, MAX_GAME_COUNT_ON_GAME_SWIPER)
          )
        );
        yield put(
          getCasinoAllGamesRequest({
            platform,
            pagination: {
              offset: 0,
              limit: MAX_GAMES_PER_PAGE,
            },
          })
        );

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoGroupsSaga_BU({ payload }: GetCasinoGroupsRequestAction) {
  try {
    // @ts-ignore
    const { dataSource, filters, platform, pagination } = payload;
    const { locale } = yield select(localeSelector);
    // const t = useTranslations("general");
    //const { locale } = yield select(sessionDataSelector);
    // console.log("FAVORITE SAGE", t("favorite"));
    const country = CookiesStorage.getItem(storageKeys.COUNTRY);
    const queryString: string = buildCasinoGroupsQueryString(
        casinoGroupExpand,
        casinoGroupFields,
        platform ? platform : undefined,
        groupGameFields,
        locale,
        filters,
        country,
        pagination ? pagination : undefined
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
        `${CASINO_URL}/groups/${dataSource}${queryString}`,
        {
          method: "GET",
        }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
            yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0;
        });

        yield put(getCasinoGroupsSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoPopularGamesGroupSaga({
  payload,
}: GetCasinoPopularGamesGroupRequestAction) {
  try {
    const { dataSource, platform } = payload;
    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      platform ? platform : undefined,
      groupGameFields,
      locale,
      [],
      null,
      // @ts-ignore
      { limit: "2000", offset: "0" }
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${CASINO_URL}/groups/${dataSource}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0;
        });

        yield put(getCasinoPopularGamesGroupSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getLiveCasinoPopularGamesGroupSaga({
  payload,
}: GetLiveCasinoPopularGamesGroupRequestAction) {
  try {
    const { dataSource, platform } = payload;
    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoGroupsQueryString(
      groupExpand,
      groupFields,
      platform ? platform : undefined,
      groupGameFields,
      locale
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${CASINO_URL}/groups/${dataSource}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0;
        });

        yield put(getLiveCasinoPopularGamesGroupSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getLiveCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoAuditGroupSaga({
  payload,
}: GetCasinoAuditGroupRequestAction) {
  try {
    const { dataSource } = payload;
    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoGroupsQueryString(
      groupExpand,
      groupFields,
      platform,
      groupGameFields,
      locale
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${CASINO_URL}/groups/${dataSource}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0;
        });

        yield put(getCasinoAuditGroupSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoMoreGamesGroupsSaga({
  payload,
}: GetCasinoMoreGamesGroupsRequestAction) {
  try {
     const { slug, platform, pagination } = payload;
    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      null,
      null,
      null,
      [
        `games(${
          platform === "PC"
            ? getGameDesktopSupportFilterString(true)
            : getGameMobileSupportFilterString(true)
        })`,
      ],
      null,
      pagination
    );

     const response: IJsonResponse<ICasinoGroupViewModel> = yield fetch(
       `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/groups/${CASINO_DATA_SOURCE}/${slug}${queryString}`,
       { method: methodTypes.GET }
     );
     const { status } = response;
     switch (status) {
       case 200:
          const group: ICasinoGroupViewModel = yield response.json();
          yield put(getCasinoMoreGamesGroupsSuccess({
            slug,
            games: group?.games,
            dataSource: payload?.dataSource
          }));
         break;
       default:
         throw new Error("A problem has occurred while retrieving navigation");
     }
  } catch (error) {
    yield put(getCasinoMoreGamesGroupsFailure(parseFrontendError(error)));
  }
}

function* getLiveCasinoMoreGamesGroupsSaga({
  payload,
}: GetLiveCasinoMoreGamesGroupsRequestAction) {
  try {
    const { slug, platform, pagination } = payload;

    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      null,
      null,
      null,
      [
        `games(${
          platform === "PC"
            ? getGameDesktopSupportFilterString(true)
            : getGameMobileSupportFilterString(true)
        })`,
      ],
      null,
      pagination
    );

    const response: IJsonResponse<ICasinoGroupViewModel> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/groups/${LIVE_CASINO_DATA_SOURCE}/${slug}${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const group: ICasinoGroupViewModel = yield response.json();
        yield put(
          getLiveCasinoMoreGamesGroupsSuccess({
            slug,
            games: group?.games,
            dataSource: payload?.dataSource,
          })
        );
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getLiveCasinoMoreGamesGroupsFailure(parseFrontendError(error)));
  }
}

function* getLiveCasinoGroupsSaga({
  payload,
}: GetLiveCasinoGroupsRequestAction) {
  try {
    const { dataSource, platform } = payload;

    const filters = [
      `games(${
        platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true)
      })`,
    ];

    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      platform ? platform : undefined,
      groupGameFields,
      locale,
      filters,
      undefined,
      undefined,
      LIVE_CASINO_DATA_SOURCE,
      "order",
      "acs"
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/groups/${dataSource}/${queryString}`,
      { method: methodTypes.GET }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0 || group?.slug === ALL_GAMES_SLUG;
        })?.map(group => {
          return {
            ...group,
            featuredGames:
              group?.featuredGames?.length && group?.featuredGames?.length > 0
                ? group?.featuredGames
                : group?.games?.items,
          };
        });

        const isAllGamesGroup = groups?.items?.find(
          ({ slug }) => slug === ALL_GAMES_SLUG
        );
        if (isAllGamesGroup)
          yield put(
            getCasinoAllGamesRequest({
              platform,
              pagination: {
                offset: 0,
                limit: MAX_GAMES_PER_PAGE,
              },
            })
          );

        yield put(getLiveCasinoGroupsSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getLiveCasinoGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoHomeGroupsSaga({ payload }: GetCasinoGroupsRequestAction) {
  try {
    const { dataSource, platform, pagination } = payload;

    const filters = [
      `games(${
        platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true)
      })`,
    ];

    const { locale } = yield select(localeSelector);
    const queryString: string = buildCasinoGroupsQueryString(
      casinoGroupExpand,
      casinoGroupFields,
      platform ? platform : undefined,
      groupGameFields,
      locale,
      filters,
      "",
      pagination ? pagination : undefined
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/groups/${dataSource}${queryString}`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();
        groups.items = groups.items
          .filter((group) => {
            return group.games.count > 0 || group?.slug === ALL_GAMES_SLUG;
          })
          ?.map((group) => {
            return {
              ...group,
              featuredGames:
                group?.featuredGames?.length && group?.featuredGames?.length > 0
                  ? group?.featuredGames
                  : group?.games?.items,
            };
          });

        yield put(
          getCasinoHomeGroupsSuccess(
            filterGroups(groups.items, MAX_GAME_COUNT_ON_GAME_SWIPER)
          )
        );
        break;

      default:
        throw new Error(
          "A problem has occurred while retrieving home casino games"
        );
    }
  } catch (error) {
    yield put(getCasinoHomeGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoAllGamesSaga({
  payload,
}: GetCasinoAllGamesRequestAction) {
  try {
    const { platform, ...restPayload } = payload || {};
    const queryString: string = buildCasinoQueryString({
      expand: gamesExpand,
      ...restPayload,
      filters: [
        platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true),
      ],
    });

    const response: IJsonResponse<ICasinoGamesPayload> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/games${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: ICasinoGamesPayload = yield response.json();
        yield put(getCasinoAllGamesSuccess(viewModel));

        if (payload.pagination?.offset === 0) {
          yield put(setCasinoAllGamesGroup(viewModel));
        }

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoAllGamesFailure(parseFrontendError(error)));
  }
}

function* _casinoSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  // yield all([
  //   takeLatest(casinoActionTypes.GET_VENDORS_REQUEST, getVendorsSaga),
  // ]);
  // yield all([
  //   takeLatest(
  //     casinoActionTypes.GET_CASINO_VENDOR_MORE_GAMES_REQUEST,
  //     getCasinoVendorMoreGamesSaga
  //   ),
  // ]);
  yield all([
    takeLatest(casinoActionTypes.GET_CATEGORIES_REQUEST, getCategoriesSaga),
  ]);
  yield all([takeLatest(casinoActionTypes.GET_GAMES_REQUEST, getGamesSaga)]);
  yield all([
    takeLatest(casinoActionTypes.GET_MORE_GAMES_REQUEST, getGamesSaga),
  ]);
  yield all([
    takeLatest(casinoActionTypes.GET_CASINO_ALL_GAMES_REQUEST, getCasinoAllGamesSaga),
  ]);
  yield all([
    takeLatest(casinoActionTypes.GET_SINGLE_GAME_REQUEST, getSingleGameSaga),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_CASINO_GROUPS_REQUEST,
      getCasinoGroupsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_CASINO_POPULAR_GAMES_GROUP_REQUEST,
      getCasinoPopularGamesGroupSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_LIVE_CASINO_POPULAR_GAMES_GROUP_REQUEST,
      getLiveCasinoPopularGamesGroupSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_CASINO_AUDIT_GROUP_REQUEST,
      getCasinoAuditGroupSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_LIVE_CASINO_GROUPS_REQUEST,
      getLiveCasinoGroupsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST,
      getCasinoMoreGamesGroupsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST,
      getLiveCasinoMoreGamesGroupsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoActionTypes.GET_CASINO_HOME_GROUPS_REQUEST,
      getCasinoHomeGroupsSaga
    ),
  ]);
}

export default _casinoSaga_nwa;

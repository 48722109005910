import {
  SportsActions,
  sportsActionTypes,
  SportsState,
} from "@/fe-core/meta/types/sports";

const initialState: SportsState = {
  sports: [],
  pending: false,
  status: null,
};

const sportsReducer = (
  state: SportsState = initialState,
  action: SportsActions
): SportsState => {
  switch (action.type) {
    case sportsActionTypes.SPORTS_REQUEST:
      return {
        ...state,
        pending: true,
        sports: [],
        status: null,
      };
    case sportsActionTypes.SPORTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        sports: payload,
        status: {
          success: true,
        },
      };
    }
    case sportsActionTypes.SPORTS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        sports: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default sportsReducer;

import {
  userActionTypes,
  ProfileState,
  UserActions,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: ProfileState = {
  pending: false,
  data: null,
  status: null,

  isProfileUpdatepending: false,
  profileUpdateStatus: null,

  showProfileUpdateModal: false,
  isUserProfileNeedsToUpdate: null,
  profileUpdateModalProps: {}
};

const profileReducer = (
  state: ProfileState = initialState,
  action: UserActions | RootActions
): ProfileState => {
  switch (action.type) {
    case userActionTypes.GET_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_PROFILE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        profileUpdateStatus: null,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_PROFILE_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isProfileUpdatepending: true,
        profileUpdateStatus: null,
      };
    case userActionTypes.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isProfileUpdatepending: false,
        profileUpdateStatus: {
          success: true,
          message: "Profile Updated Successfully",
        },
      };
    case userActionTypes.PROFILE_UPDATE_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        isProfileUpdatepending: false,
        profileUpdateStatus: {
          success: false,
          message,
        },
      };
    case userActionTypes.SET_IS_USER_PROFILE_NEED_TO_UPDATED:
      return {
        ...state,
        showProfileUpdateModal: !!(action.payload === true && !!(process.env.NEXT_PUBLIC_IS_PROFILE_UPDATE_NEEDS_ON_LOGIN_ACTION === "YES")),
        isUserProfileNeedsToUpdate: action.payload,
      };
    case userActionTypes.SET_SHOW_PROFILE_UPDATE_MODAL:
      return {
        ...state,
        showProfileUpdateModal: true,
        profileUpdateModalProps: action.payload
      };
    case userActionTypes.SET_HIDE_PROFILE_UPDATE_MODAL:
      return {
        ...state,
        showProfileUpdateModal: false,
        profileUpdateModalProps: {}
      };
    case userActionTypes.CLEAR_PROFILE:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default profileReducer;

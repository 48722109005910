import {
  casinoGamesHighlightedActionTypes,
  getCasinoGamesHighlightedFailureAction,
  getCasinoGamesHighlightedRequestAction,
  getCasinoGamesHighlightedSuccessAction,
} from "@/fe-core/meta/types/casinoGamesHighlighted";
import { ICasinoGamesHighlightedViewModel } from "@/fe-core/meta/interfaces/casinoGamesHighlighted";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";

export const getCasinoGamesHighlightedRequest =
  (): getCasinoGamesHighlightedRequestAction => ({
    type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_REQUEST,
  });

export const getCasinoGamesHighlightedSuccess = (
  payload: ICasinoGamesHighlightedViewModel
): getCasinoGamesHighlightedSuccessAction => ({
  type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_SUCCESS,
  payload,
});

export const getCasinoGamesHighlightedFailure = (
  payload: IErrorPayload
): getCasinoGamesHighlightedFailureAction => ({
  type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_FAILURE,
  payload,
});

import { IErrorPayload, IStatus } from '../interfaces/root'
import { ICasinoGamesHighlightedViewModel } from '../interfaces/casinoGamesHighlighted'

export enum casinoGamesHighlightedActionTypes {
  GET_CASINO_GAMES_HIGHLIGHTED_REQUEST = 'GET_CASINO_GAMES_HIGHLIGHTED_REQUEST',
  GET_CASINO_GAMES_HIGHLIGHTED_SUCCESS = 'GET_CASINO_GAMES_HIGHLIGHTED_SUCCESS',
  GET_CASINO_GAMES_HIGHLIGHTED_FAILURE = 'GET_CASINO_GAMES_HIGHLIGHTED_FAILURE',
}

export type getCasinoGamesHighlightedRequestAction = {
  type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_REQUEST
}

export type getCasinoGamesHighlightedSuccessAction = {
  type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_SUCCESS
  payload: ICasinoGamesHighlightedViewModel
}

export type getCasinoGamesHighlightedFailureAction = {
  type: casinoGamesHighlightedActionTypes.GET_CASINO_GAMES_HIGHLIGHTED_FAILURE
  payload: IErrorPayload
}

export type casinoGamesHighlightedActions =
  | getCasinoGamesHighlightedRequestAction
  | getCasinoGamesHighlightedSuccessAction
  | getCasinoGamesHighlightedFailureAction


export type CasinoGamesHighlightedState = {
  pending: boolean
  gamesHighlighted: ICasinoGamesHighlightedViewModel | null
  status: IStatus | null
}

import {
  IActivateEmailPayload,
  IActivateEmailViewModel,
} from '../interfaces/activateEmail'
import { IErrorPayload, IStatus } from '../interfaces/root'

export enum activateEmailActionTypes {
  ACTIVATE_EMAIL_REQUEST = "ACTIVATE_EMAIL_REQUEST",
  ACTIVATE_EMAIL_SUCCESS = "ACTIVATE_EMAIL_SUCCESS",
  ACTIVATE_EMAIL_FAILURE = "ACTIVATE_EMAIL_FAILURE",
  SET_SHOW_ACTIVE_EMAIL_MODAL = "SET_SHOW_ACTIVE_EMAIL_MODAL",
  SET_HIDE_ACTIVE_EMAIL_MODAL = "SET_HIDE_ACTIVE_EMAIL_MODAL",
  CLEAR_ACTIVE_EMAIL_DATA = "CLEAR_ACTIVE_EMAIL_DATA",
}

export type SetShowActivateEmailModalAction = {
  type: activateEmailActionTypes.SET_SHOW_ACTIVE_EMAIL_MODAL;
};

export type SetHideActivateEmailModalAction = {
  type: activateEmailActionTypes.SET_HIDE_ACTIVE_EMAIL_MODAL;
};

export type ActivateEmailRequestAction = {
  type: activateEmailActionTypes.ACTIVATE_EMAIL_REQUEST;
  payload: IActivateEmailPayload;
};

export type ActivateEmailSuccessAction = {
  type: activateEmailActionTypes.ACTIVATE_EMAIL_SUCCESS;
  payload: IActivateEmailViewModel;
};

export type ActivateEmailFailureAction = {
  type: activateEmailActionTypes.ACTIVATE_EMAIL_FAILURE;
  payload: IErrorPayload;
};

export type ClearActiveEmailDataAction = {
  type: activateEmailActionTypes.CLEAR_ACTIVE_EMAIL_DATA
}

export type ActivateEmailActions =
  | SetShowActivateEmailModalAction
  | SetHideActivateEmailModalAction
  | ActivateEmailRequestAction
  | ActivateEmailSuccessAction
  | ActivateEmailFailureAction
  | ClearActiveEmailDataAction;

export type ActivateEmailState = {
  data: IActivateEmailViewModel | null;
  pending: boolean;
  status: IStatus | null;
  showActivateEmailModal: boolean;
};

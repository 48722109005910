import {
  IPromotionsCategoryViewModel,
  IPromotionsViewModel,
} from "@/fe-core/meta/interfaces/promotions";
import {
  PromotionsActions,
  promotionsActionTypes,
} from "@/fe-core/meta/types/promotions";
import { PromotionsState } from "@/fe-core/meta/types/promotions";

const initialState: PromotionsState = {
  pending: false,
  data: null,
  categories: null,
  status: null,

  bottomPromotionData: null,
  bottomPromotionStatus: null,
  pendingBottomPromotion: false,
  promotionGames: {
    pending: {},
    games : {}
  }
};

const getPromotionsCategories = (promotions: IPromotionsViewModel) => {
  let categories: IPromotionsCategoryViewModel[] = [];

  promotions.forEach((promotion) => {
    // if (!promotion.isHidden) {
    promotion.promotionCategories.forEach((category) => {
      const alreadyAdded = categories.some(({ id }) => id === category.id);

      if (!alreadyAdded) {
        categories.push(category);
      }
    });
    // }
  });

  categories = categories.sort((a, b) => {
    if (!a.rank) {
      return 1;
    }

    if (!b.rank) {
      return -1;
    }

    return a.rank - b.rank;
  });
  return categories;
};

const promotionsReducer = (
  state: PromotionsState = initialState,
  action: PromotionsActions
): PromotionsState => {
  switch (action.type) {
    case promotionsActionTypes.GET_PROMOTIONS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case promotionsActionTypes.GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload,
        categories: getPromotionsCategories(action.payload),
        status: {
          success: true,
        },
      };
    case promotionsActionTypes.GET_PROMOTIONS_FAILURE:
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message: action.payload.message,
        },
      };

    case promotionsActionTypes.GET_BOTTOM_PROMOTIONS_REQUEST:
      return {
        ...state,
        pendingBottomPromotion: true,
        bottomPromotionStatus: null,
      };
    case promotionsActionTypes.GET_BOTTOM_PROMOTIONS_SUCCESS:
      return {
        ...state,
        pendingBottomPromotion: false,
        bottomPromotionData: action.payload,
        bottomPromotionStatus: {
          success: true,
        },
      };
    case promotionsActionTypes.GET_BOTTOM_PROMOTIONS_FAILURE:
      return {
        ...state,
        pendingBottomPromotion: false,
        bottomPromotionStatus: {
          success: false,
          message: action.payload.message,
        },
      };

    case promotionsActionTypes.GET_PROMOTION_GAMES_REQUEST:
      return {
        ...state,
        promotionGames: {
          ...state.promotionGames,
          pending: { ...state.promotionGames.pending, [action.payload.promotion]: true },
        },
      };
    case promotionsActionTypes.GET_PROMOTION_GAMES_SUCCESS:
      return {
        ...state,
        promotionGames: {
          ...state.promotionGames,
          pending: {
            ...state.promotionGames.pending,
            [action.payload?.promotion]: false,
          },
          games: {
            ...state.promotionGames.games,
            [action.payload?.promotion]: action.payload?.games || [],
          },
        },
      };
    case promotionsActionTypes.GET_PROMOTION_GAMES_FAILURE:
      return {
        ...state,
        promotionGames: {
          ...state.promotionGames,
          pending: {
            ...state.promotionGames.pending,
            [action.payload]: false,
          },
          games: {
            ...state.promotionGames.games,
            [action.payload]: [],
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default promotionsReducer;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { UserActions, userActionTypes } from "@/fe-core/meta/types/user";
import { RegionsState } from "@/fe-core/meta/types/user/regions";

const initialState: RegionsState = {
  pending: false,
  data: null,
  status: null,
};

const regionsReducer = (
  state: RegionsState = initialState,
  action: UserActions | RootActions
): RegionsState => {
  switch (action.type) {
    case userActionTypes.GET_REGIONS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_REGIONS_SUCCESS: {
      const {
        payload: { countries },
      } = action;
      const { regions } = countries[0];
      return {
        ...state,
        pending: false,
        data: { regions },
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_REGIONS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default regionsReducer;

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetValidCountriesFailureAction,
  GetValidCountriesRequestAction,
  GetValidCountriesSuccessAction,
} from "@/fe-core/meta/types/user/register/validCountries";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { IGetValidCountriesResponseViewModel } from "@/fe-core/meta/interfaces/user/register/validCountries";

export const getValidCountriesRequest = (): GetValidCountriesRequestAction => ({
  type: userActionTypes.GET_VALID_COUNTRIES_REQUEST,
});

export const getValidCountriesSuccess = (
  payload: IGetValidCountriesResponseViewModel
): GetValidCountriesSuccessAction => ({
  type: userActionTypes.GET_VALID_COUNTRIES_SUCCESS,
  payload,
});

export const getValidCountriesFailure = (
  payload: IErrorPayload
): GetValidCountriesFailureAction => ({
  type: userActionTypes.GET_VALID_COUNTRIES_FAILURE,
  payload,
});

import { headerKeys } from "@/config/general";
import { getClaimFailure, getClaimSuccess } from "@/fe-core/_redux/actions/userActions/affiliateActions/claimActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IClaimViewModel } from "@/fe-core/meta/interfaces/user/affiliate/claim";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { clearState } from "@/redux/actions/rootActions";
import { AllEffect, ForkEffect, all, put, select, takeLatest } from "redux-saga/effects";



const ENDPOINT = "/api/user/affiliate/claim";

function* postClaimRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IClaimViewModel | IErrorPayload> = yield fetch(ENDPOINT, {
      method: methodTypes.POST,
      headers: new Headers({
        [headerKeys.SESSION_ID]: sessionId,
        [headerKeys.LOCALE]: locale,
      }),
    });
    if (response.status === 200) {
      const viewModel: IClaimViewModel = yield response.json();
      yield put(getClaimSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getClaimFailure(errorPayload));
    }
  } catch (error) {
    yield put(getClaimFailure(parseFrontendError(error)));
  }
}

function* getClaimRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IClaimViewModel | IErrorPayload> = yield fetch(ENDPOINT, {
      method: methodTypes.GET,
      headers: new Headers({
        [headerKeys.SESSION_ID]: sessionId,
        [headerKeys.LOCALE]: locale,
      }),
    });
    if (response.status === 200) {
      const viewModel: IClaimViewModel = yield response.json();
      yield put(getClaimSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getClaimFailure(errorPayload));
    }
  } catch (error) {
    yield put(getClaimFailure(parseFrontendError(error)));
  }
}

export default function* claimSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.POST_CLAIM_REQUEST, postClaimRequestSaga),
    takeLatest(userActionTypes.GET_CLAIM_REQUEST, getClaimRequestSaga),
  ]);
}

import { userActionTypes } from "@/fe-core/meta/types/user";
import { Error as AutoBahnError, Result, Session } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";
import { doEMCall } from "@/fe-core/helpers/socket";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  getRegionsFailure,
  getRegionsSuccess,
} from "@/fe-core/_redux/actions/userActions/regionsActions";
import { GetRegionsRequestAction } from "@/fe-core/meta/types/user/regions";
import { IRegionsViewModel } from "@/fe-core/meta/interfaces/user/regions";

function* regionsRequestSaga({ payload }: GetRegionsRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/account#getCountries",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("Regions are not available");
    }

    const regions: IRegionsViewModel = emResponse?.kwargs;

    yield put(getRegionsSuccess(regions));
  } catch (error) {
    yield put(getRegionsFailure(parseFrontendError(error)));
  }
}

export default function* regionsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_REGIONS_REQUEST, regionsRequestSaga),
  ]);
}

/* eslint-disable @next/next/no-async-client-component */
"use client";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SVGIcon from "../SVGIcon";
import { isChatboxOpenSelector } from "../../fe-core-omega/_redux/selectors/chatboxSelectors";
import DeadSimpleChat from "@/components/DeadSimpleChat";
import { toggleChatbox } from "../../fe-core-omega/_redux/actions/chatboxActions";
import { DESKTOPBUTTONS } from "@/config/liveagent";
import { useLocale } from "next-intl";
import useTracking from "../Hooks/useTracking";

const LayoutChatbox: FC<{}> = () => {
  const dispatch = useDispatch();
  const { click } = useTracking();
  const isChatboxOpen = useSelector(isChatboxOpenSelector);
  const locale = useLocale();
  // @ts-ignore
  const LIVE_AGENT_BUTTON_ID = DESKTOPBUTTONS[locale];
  const toggleChatboxMenu = () => {
    dispatch(toggleChatbox());
    click("Close Live Chat", {
      icon: "close",
      component: "LayoutChatbox",
    });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isChatboxOpen) {
        document.querySelector("#app")?.classList.add("sm:pr-[280px]");
        document
          .querySelector(`div[id*="b_${LIVE_AGENT_BUTTON_ID}"] img`)
          ?.classList.add("hidden");
      } else {
        document.querySelector("#app")?.classList.remove("sm:pr-[280px]");
        document
          .querySelector(`div[id*="b_${LIVE_AGENT_BUTTON_ID}"] img`)
          ?.classList.remove("hidden");
      }
    }

    return () => {};
  }, [isChatboxOpen]);

  return (
    <div
      className={`fixed top-0 right-0 z-50 flex-col h-full overflow-auto min-h-[100svh] text-center duration-150 lg:shadow-sidebar px-2.5 sm:px-2.5 py-5 pt-0 transition-all bg-white dark:bg-tertiary-600 flex ${
        isChatboxOpen
          ? "sm:w-[280px] w-full px-4 right-0"
          : "sm:w-16 sm:p-0 px-4 w-full sm:!right-0 !-left-full"
      } `}
    >
      <div className="flex h-full flex-col items-end w-full">
        <button
          className="text-center transition-all w-11 min-w-[44px] h-11   md:flex justify-center items-center  "
          onClick={toggleChatboxMenu}
        >
          <SVGIcon
            icon="close"
            className="w-5 h-5 text-tertiary dark:text-white/70"
          />
        </button>
        {isChatboxOpen && <DeadSimpleChat />}
      </div>
    </div>
  );
};

export default LayoutChatbox;

//@ts-nocheck
import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  IApplyBonusViewModel,
  IBonusResponsePayload,
  IGetLimitsResponsePayload,
  ILimitsViewModel,
  IRoleViewModel,
} from "@/fe-core/meta/interfaces/user";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  DeleteDepositLimitRequestAction,
  SetDepositLimitRequestAction,
} from "@/fe-core/meta/types/user/limits";
import {
  deleteDepositLimitFailure,
  deleteDepositLimitSuccess,
  deleteLossLimitFailure,
  getLimitsFailure,
  getLimitsRequest,
  getLimitsSuccess,
  setDepositLimitFailure,
  setDepositLimitSuccess,
  setLossLimitFailure,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";

function* getLimitsRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IGetLimitsResponsePayload | IErrorPayload> =
      yield fetch("/api/user/limits", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const limits: ILimitsViewModel = yield response.json();
      yield put(getLimitsSuccess(limits));
    }
  } catch (error) {
    yield put(getLimitsFailure(parseFrontendError(error)));
  }
}

function* setDepositLimitRequestSaga({
  payload,
}: SetDepositLimitRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const limit = {
      periodType: payload.period,
      limit: Number(payload.amount),
      limitType: payload.type
    };

    const response: IJsonResponse<IGetLimitsResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/limits`, <RequestInit>{
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(limit),
      });

    if (response.status == 200) {
      yield put(setDepositLimitSuccess());
      yield put(getLimitsRequest());
    }
  } catch (error) {
    yield put(setDepositLimitFailure(parseFrontendError(error)));
  }
}

function* deleteDepositLimitRequestSaga({
  payload,
}: DeleteDepositLimitRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const limit = {
      periodType: payload.period,
      limit: "null",
      limitType: payload.type
    };

    const response: IJsonResponse<IGetLimitsResponsePayload | IErrorPayload> =
      yield fetch(`/api/user/limits`, <RequestInit>{
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(limit),
      }
      );

    if (response.status == 200) {
      yield put(deleteDepositLimitSuccess());
      yield put(getLimitsRequest());
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(deleteDepositLimitFailure(errorPayload));
    }
  } catch (error) {
    yield put(deleteDepositLimitFailure(parseFrontendError(error)));
  }
}

function* setLossLimitRequestSaga({ payload }: SetDepositLimitRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/limit#setLossLimit",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    yield put(getLimitsRequest());
  } catch (error) {
    yield put(setLossLimitFailure(parseFrontendError(error)));
  }
}

function* deleteLossLimitRequestSaga({
  payload,
}: DeleteDepositLimitRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/limit#removeLossLimit",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    yield put(getLimitsRequest());
  } catch (error) {
    yield put(deleteLossLimitFailure(parseFrontendError(error)));
  }
}

export default function* limitsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_LIMITS_REQUEST, getLimitsRequestSaga),
    takeLatest(localeActionTypes.GET_CURRENT_LOCALE, getLimitsRequestSaga),

    takeLatest(
      userActionTypes.SET_DEPOSIT_LIMITS_REQUEST,
      setDepositLimitRequestSaga
    ),
    takeLatest(
      userActionTypes.DELETE_DEPOSIT_LIMITS_REQUEST,
      deleteDepositLimitRequestSaga
    ),
    takeLatest(
      userActionTypes.SET_LOSS_LIMITS_REQUEST,
      setLossLimitRequestSaga
    ),
    takeLatest(
      userActionTypes.DELETE_LOSS_LIMITS_REQUEST,
      deleteLossLimitRequestSaga
    ),
  ]);
}

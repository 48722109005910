import {
  CasinoVendorsActions,
  casinoVendorsActionTypes,
  CasinoVendorsState,
} from "@/fe-core/meta/types/casinoVendors";

const initialState: CasinoVendorsState = {
  pending: false,
  singleVendorPending: true,
  vendorDetailsMoreGamesPending: false,
  vendors: null,
  vendor: null,
  subVendors: null,
  subVendor: null,
  totalCount: 0,
  filters: [],
  vendorDetails: null,
  vendorDetailsPending: false,
  vendorDetailsStatus: null,
};

const casinoVendorsReducer = (
  state: CasinoVendorsState = initialState,
  action: CasinoVendorsActions
): CasinoVendorsState => {
  switch (action.type) {
    case casinoVendorsActionTypes.GET_VENDORS_REQUEST:
    case casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_REQUEST:
    case casinoVendorsActionTypes.GET_SUB_VENDORS_REQUEST:
    case casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_REQUEST:
    case casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_REQUEST:
      return { ...state, vendorDetailsMoreGamesPending: true };
    case casinoVendorsActionTypes.GET_VENDORS_FAILURE:
    case casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_FAILURE:
    case casinoVendorsActionTypes.GET_SUB_VENDORS_FAILURE:
    case casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_FAILURE:
    case casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_FAILURE:
      return {
        ...state,
        vendorDetailsMoreGamesPending: false,
      };
    case casinoVendorsActionTypes.GET_VENDORS_SUCCESS:
      return {
        ...state,
        pending: false,
        vendors: action.payload.items,
      };
    case casinoVendorsActionTypes.GET_SUB_VENDORS_SUCCESS:
      return {
        ...state,
        pending: false,
        subVendors: action.payload.items,
      };
    case casinoVendorsActionTypes.GET_VENDOR_DETAILS_REQUEST:
      return {
        ...state,
        singleVendorPending: true,
        vendorDetails: null,
      };
    case casinoVendorsActionTypes.GET_VENDOR_DETAILS_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        singleVendorPending: false,
        vendorDetails: null,
        vendorDetailsStatus: {
          success: false,
          message,
        },
      };
    case casinoVendorsActionTypes.GET_VENDOR_DETAILS_SUCCESS:
      return {
        ...state,
        singleVendorPending: false,
        vendorDetails: action.payload,
        vendorDetailsStatus: {
          success: true,
        },
      };
    case casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_SUCCESS:
      return {
        ...state,
        pending: false,
        vendor: action.payload,
      };
    case casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_SUCCESS:
      return {
        ...state,
        pending: false,
        subVendor: action.payload,
      };
    case casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_SUCCESS:
      const {
        games: { count, items },
      } = action.payload;

      if (state.vendorDetails) {
        return {
          ...state,
          vendorDetailsMoreGamesPending: false,
          vendorDetails: {
            ...state.vendorDetails,
            games: {
              total: state.vendorDetails.games.total,
              items: state.vendorDetails.games.items.concat(items),
              count: state.vendorDetails.games.count + count,
            },
          },
        };
      }
    case casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_SUCCESS: {
      const {
        id: subId,
        displayName: subDisplayName,
        image: subImage,
        logo: subLogo,
        games: { count: subCount, items: subItems },
      } = action.payload;

      if (state.subVendor) {
        return {
          ...state,
          pending: false,
          subVendor: {
            ...state.subVendor,
            id: subId,
            displayName: subDisplayName,
            image: subImage,
            logo: subLogo,
            games: {
              total: state.subVendor.games.total,
              items: state.subVendor.games.items.concat(subItems),
              count: state.subVendor.games.count + subCount,
            },
          },
        };
      }
      return state;
    }
    default:
      return {
        ...state,
      };
  }
};

export default casinoVendorsReducer;

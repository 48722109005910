import { IErrorPayload } from '../interfaces/root'
import { ILoyaltyLevelsModel, ILoyaltyLevelsResponseModel } from '../interfaces/loyaltyLevels'
import { IStatus } from '@/fe-core/meta/interfaces/root';

export enum loyaltyLevelsActionTypes {
  GET_LOYALTY_LEVELS_REQUEST = 'GET_LOYALTY_LEVELS_REQUEST',
  GET_LOYALTY_LEVELS_SUCCESS = 'GET_LOYALTY_LEVELS_SUCCESS',
  GET_LOYALTY_LEVELS_FAILURE = 'GET_LOYALTY_LEVELS_FAILURE',
}

export type GetLoyaltyLevelsRequestAction = {
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_REQUEST
}

export type GetLoyaltyLevelsSuccessAction = {
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_SUCCESS
  payload: ILoyaltyLevelsResponseModel
}

export type GetLoyaltyLevelsFailureAction = {
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_FAILURE
  payload: IErrorPayload
}

export type LoyaltyLevelsActions =
  | GetLoyaltyLevelsRequestAction
  | GetLoyaltyLevelsSuccessAction
  | GetLoyaltyLevelsFailureAction

export type LoyaltyLevelsState = {
  pending: boolean
  loyaltyLevels: ILoyaltyLevelsModel[] | null
  status: IStatus | null
}

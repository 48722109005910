import { IStatus, IErrorPayload } from '../interfaces/root'
import { ICasinoJackpotViewModel } from '../interfaces/casinoJackpot'
import { ICasinoGroupViewModel } from '../interfaces/casino'
import { CasinoPagination } from './casino'

export enum casinoJackpotActionTypes {
  CASINO_JACKPOT_REQUEST = 'CASINO_JACKPOT_REQUEST',
  CASINO_JACKPOT_SUCCESS = 'CASINO_JACKPOT_SUCCESS',
  CASINO_JACKPOT_FAILURE = 'CASINO_JACKPOT_FAILURE',
  GET_CASINO_JACKPOT_GROUPS_REQUEST = 'GET_CASINO_JACKPOT_GROUPS_REQUEST',
  GET_CASINO_JACKPOT_GROUPS_SUCCESS = 'GET_CASINO_JACKPOT_GROUPS_SUCCESS',
  GET_CASINO_JACKPOT_GROUPS_FAILURE = 'GET_CASINO_JACKPOT_GROUPS_FAILURE',
  GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_REQUEST = 'GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_REQUEST',
  GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_SUCCESS = 'GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_SUCCESS',
  GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_FAILURE = 'GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_FAILURE',
  CASINO_EQ_JACKPOT_REQUEST = 'CASINO_EQ_JACKPOT_REQUEST',
  CASINO_EQ_JACKPOT_SUCCESS = 'CASINO_EQ_JACKPOT_SUCCESS',
  CASINO_EQ_JACKPOT_FAILURE = 'CASINO_EQ_JACKPOT_FAILURE',
}

export type CasinoJackpotRequestAction = {
  type: casinoJackpotActionTypes.CASINO_JACKPOT_REQUEST
}

export type CasinoJackpotSuccessAction = {
  type: casinoJackpotActionTypes.CASINO_JACKPOT_SUCCESS
  payload: ICasinoJackpotViewModel
}

export type CasinoJackpotFailureAction = {
  type: casinoJackpotActionTypes.CASINO_JACKPOT_FAILURE
  payload: IErrorPayload
}

export type GetCasinoJackpotGroupsRequestAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_REQUEST
}

export type GetCasinoJackpotGroupsSuccessAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_SUCCESS
  payload: ICasinoGroupViewModel[]
}

export type GetCasinoJackpotGroupsFailureAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_FAILURE
  payload: IErrorPayload
}

export type GetCasinoJackpotMoreGamesGroupsRequestAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_REQUEST
  payload: CasinoJackpotMoreGamesGroupsPayload
}

export type GetCasinoJackpotMoreGamesGroupsSuccessAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_SUCCESS
  payload: ICasinoGroupViewModel
}

export type GetCasinoJackpotMoreGamesGroupsFailureAction = {
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_FAILURE
  payload: IErrorPayload
}

export type CasinoEQJackpotRequestAction = {
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_REQUEST
}

export type CasinoEQJackpotSuccessAction = {
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_SUCCESS
  payload: ICasinoJackpotViewModel
}

export type CasinoEQJackpotFailureAction = {
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_FAILURE
  payload: IErrorPayload
}

export type CasinoJackpotActions =
  | CasinoJackpotRequestAction
  | CasinoJackpotSuccessAction
  | CasinoJackpotFailureAction
  | GetCasinoJackpotGroupsRequestAction
  | GetCasinoJackpotGroupsSuccessAction
  | GetCasinoJackpotGroupsFailureAction
  | GetCasinoJackpotMoreGamesGroupsRequestAction
  | GetCasinoJackpotMoreGamesGroupsSuccessAction
  | GetCasinoJackpotMoreGamesGroupsFailureAction
  | CasinoEQJackpotRequestAction
  | CasinoEQJackpotSuccessAction
  | CasinoEQJackpotFailureAction

export type CasinoJackpotState = {
  egtJackpot: {
    pending: boolean
    jackpots: ICasinoJackpotViewModel | null
    status: IStatus | null
  }
  eqJackpot: {
    pending: boolean
    jackpots: ICasinoJackpotViewModel | null
    status: IStatus | null
  }
}

export type CasinoJackpotGroupsState = {
  pending: boolean
  err: string
  groups: ICasinoGroupViewModel[] | null
}

export type CasinoJackpotMoreGamesGroupsPayload = {
  id: string
  pagination: CasinoPagination
}

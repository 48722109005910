import { DEFAULT_DATE_FORMAT, headerKeys } from "@/config/general";
import {
  clearUploadDocumentStatus,
  getDocumentsFailure,
  getDocumentsSuccess,
  getUserDocumentsFailure,
  getUserDocumentsSuccess,
  uploadDocumentFailure,
  uploadDocumentSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  IDocumentsViewModel,
  IUploadDocumentRequest,
  IUploadDocumentResponse,
  IUserDocumentViewModel,
} from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  UploadDocumentRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import dayjs from "dayjs";
import {
  all,
  AllEffect,
  delay,
  fork,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { clearState } from "../../actions/rootActions";
import { sessionDataSelector } from "../../selectors/sessionSelectors";

function* clearUploadDocumentStatusSaga(interval: number) {
  yield delay(interval);
  yield put(clearUploadDocumentStatus());
}

function* documentsRequestSaga() {
  try {
    const response: IJsonResponse<IDocumentsViewModel | IErrorPayload> =
      yield fetch("/api/user/documents", <RequestInit>{});

    if (response.status == 200) {
      const viewModel: IDocumentsViewModel = yield response.json();
      yield put(getDocumentsSuccess(viewModel));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getDocumentsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getDocumentsFailure(parseFrontendError(error)));
  }
}

function* userDocumentsRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IUserDocumentViewModel[] | IErrorPayload> =
      yield fetch("/api/user/uploadedDocuments", <RequestInit>{
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        method: methodTypes.GET,
      });

    if (response.status === 200) {
      const documents: IUserDocumentViewModel[] = yield response.json();
      yield put(getUserDocumentsSuccess(documents));
    } else {
      if (response?.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getUserDocumentsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getUserDocumentsFailure(parseFrontendError(error)));
  }
}

function* uploadDocumentRequestSaga({ payload }: UploadDocumentRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const formData = new FormData();

    if (payload.file) {
      if (
        (payload.file instanceof FileList || Array.isArray(payload.file)) &&
        payload.multipleFileRequired
      ) {
        Array.from(payload.file).forEach((file: File, index) => {
          formData.append(`_file_${index}`, file);
        });
      } else {
        formData.append("file", payload.file?.[0]);
      }
    }

    (Object.keys(payload) as Array<keyof IUploadDocumentRequest>).forEach(
      (key) => {
        if (key !== "file") {
          const value =
            key?.includes("Date") && payload[key]
              ? dayjs(payload[key] as string).format(DEFAULT_DATE_FORMAT)
              : payload[key];
          if (value !== undefined && value !== null) {
            formData.append(key as string, value as string | Blob);
          }
        }
      }
    );

    const response: IJsonResponse<IDocumentsViewModel | IErrorPayload> =
      yield fetch("/api/user/documents", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: formData,
      });

    const { status } = response;
    const responsePayload: IUploadDocumentResponse | IErrorPayload =
      yield response.json();
    const viewModel = responsePayload as IUploadDocumentResponse;
    if (status == 200) {
      yield put(uploadDocumentSuccess(viewModel.userDocument));
      yield fork(clearUploadDocumentStatusSaga, 5000);
    } else {
      if (status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = responsePayload as IErrorPayload;
      yield put(uploadDocumentFailure(errorPayload));
    }
  } catch (error) {
    yield put(uploadDocumentFailure(parseFrontendError(error)));
  }
}

export default function* documentsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest([userActionTypes.GET_DOCUMENTS_REQUEST], documentsRequestSaga),
    takeLatest(
      [
        userActionTypes.GET_USER_DOCUMENTS_REQUEST,
        userActionTypes.UPLOAD_DOCUMENT_SUCCESS,
      ],
      userDocumentsRequestSaga
    ),
    takeLatest(
      userActionTypes.UPLOAD_DOCUMENT_REQUEST,
      uploadDocumentRequestSaga
    ),
  ]);
}

import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import publicPropsSaga from "./publicPropsSaga";

function* smarticoSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    fork(publicPropsSaga),
  ]);
}

export default smarticoSaga;

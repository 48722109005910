import { IFootersResponseModel } from "@/fe-core/meta/interfaces/footer";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  footerActionTypes,
  GetFooterFailureAction,
  GetFooterRequestAction,
  GetFooterSuccessAction,
} from "@/fe-core/meta/types/footer";

export const getFooterRequest = (): GetFooterRequestAction => ({
  type: footerActionTypes.GET_FOOTER_REQUEST,
});

export const getFooterSuccess = (
  payload: IFootersResponseModel
): GetFooterSuccessAction => ({
  type: footerActionTypes.GET_FOOTER_SUCCESS,
  payload,
});

export const getFooterFailure = (
  payload: IErrorPayload
): GetFooterFailureAction => ({
  type: footerActionTypes.GET_FOOTER_FAILURE,
  payload,
});

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetUserConsentsFailureAction,
  GetUserConsentsRequestAction,
  GetUserConsentsSuccessAction,
} from "@/fe-core/meta/types/user/register/userConsents";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { IConsentItem } from "@/fe-core/meta/interfaces/user/register/consentRequirements";

export const getUserConsentsRequest = (): GetUserConsentsRequestAction => ({
  type: userActionTypes.GET_USER_CONSENTS_REQUEST,
});

export const getUserConsentsSuccess = (
  payload: IConsentItem[]
): GetUserConsentsSuccessAction => ({
  type: userActionTypes.GET_USER_CONSENTS_SUCCESS,
  payload,
});

export const getUserConsentsFailure = (
  payload: IErrorPayload
): GetUserConsentsFailureAction => ({
  type: userActionTypes.GET_USER_CONSENTS_FAILURE,
  payload,
});

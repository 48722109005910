import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Modal";
import ActivateEmailProcessing from "@/components/Signup/ActivateEmailProcessing";
import { activateEmailModalSelector } from "../../../fe-core-omega/_redux/selectors/activateEmailSelectors";
import {
  setHideActivateEmailModal,
  setShowActivateEmailModal,
} from "../../../fe-core-omega/_redux/actions/activateEmailActions";
import { useRouter } from "next/navigation";
import { usePathname } from "../../../navigation";

const ActivateEmailModal: FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const pathname = usePathname();
  const showActivateEmail = useSelector(activateEmailModalSelector);
  const [isActivateEmailModal, setActivateEmailModal] =
    useState<boolean>(false);

  const closeActivateEmailModal = () => {
    setActivateEmailModal(true);
    router.push("/");
    dispatch(setHideActivateEmailModal());
  };

  useEffect(() => {
    if (isActivateEmailModal) return;
    if (pathname.includes("/activate-email")) {
      !showActivateEmail && dispatch(setShowActivateEmailModal());
    }
  }, [showActivateEmail, isActivateEmailModal]);

  return (
    <Modal
      className="!py-12 !px-8 sm:!p-15 rounded border border-white/10 !max-w-[calc(100dvw-20px)] sm:!max-w-lg"
      childClassName="!h-auto"
      onClose={closeActivateEmailModal}
      isOpen={showActivateEmail}
    >
      <ActivateEmailProcessing setActivateEmailModal={setActivateEmailModal} />
    </Modal>
  );
};

export default ActivateEmailModal;

import { IPromotionContentResponseViewModel } from "@/fe-core/meta/interfaces/promotionContent";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  promotionContentActionTypes,
  GetPromotionContentFailureAction,
  GetPromotionContentRequestAction,
  GetPromotionContentSuccessAction,
} from "@/fe-core/meta/types/promotionContent";

export const getPromotionContentRequest =
  (): GetPromotionContentRequestAction => ({
    type: promotionContentActionTypes.GET_PROMOTION_CONTENT_REQUEST,
  });

export const getPromotionContentSuccess = (
  payload: IPromotionContentResponseViewModel
): GetPromotionContentSuccessAction => ({
  type: promotionContentActionTypes.GET_PROMOTION_CONTENT_SUCCESS,
  payload,
});

export const getPromotionContentFailure = (
  payload: IErrorPayload
): GetPromotionContentFailureAction => ({
  type: promotionContentActionTypes.GET_PROMOTION_CONTENT_FAILURE,
  payload,
});

export function buildBannersQueryString(
  theme: string,
  userRoles: string[],
  bonusCodes: string[],
  btagCodes: string[],
  isAuthenticated: string = "0",
  isIdentified: string = "0"
): string {
  const queryParams = new URLSearchParams();
  queryParams.append("theme", theme);
  queryParams.append("userRoles", userRoles?.join(","));
  queryParams.append("bonusCodes", bonusCodes?.join(","));
  queryParams.append("btagCodes", btagCodes?.join(","));
  queryParams.append("isAuthenticated", isAuthenticated);
  queryParams.append("isIdentified", isIdentified);
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}
import {
  IBottomPromotionPayload,
  IBottomPromotionsResponsePayload,
  IPromotionGamesRequestPayload,
  IPromotionGamesSuccessPayload,
  IPromotionPayload,
  IPromotionViewModel,
  IPromotionsPayload,
  IPromotionsViewModel,
} from "@/fe-core/meta/interfaces/promotions";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  promotionsActionTypes,
  GetPromotionsFailureAction,
  GetPromotionsRequestAction,
  GetPromotionsSuccessAction,
  GetPromotionRequestAction,
  GetPromotionSuccessAction,
  GetPromotionFailureAction,
  GetBottomPromotionRequestAction,
  GetBottomPromotionSuccessAction,
  GetBottomPromotionFailureAction,
  GetPromotionGamesRequestAction,
  GetPromotionGamesSuccessAction,
  GetPromotionGamesFailureAction,
} from "@/fe-core/meta/types/promotions";

export const getPromotionsRequest = (
  payload: IPromotionsPayload
): GetPromotionsRequestAction => ({
  type: promotionsActionTypes.GET_PROMOTIONS_REQUEST,
  payload,
});

export const getPromotionsSuccess = (
  payload: IPromotionsViewModel
): GetPromotionsSuccessAction => ({
  type: promotionsActionTypes.GET_PROMOTIONS_SUCCESS,
  payload,
});

export const getPromotionsFailure = (
  payload: IErrorPayload
): GetPromotionsFailureAction => ({
  type: promotionsActionTypes.GET_PROMOTIONS_FAILURE,
  payload,
});

export const getPromotionRequest = (
  payload: IPromotionPayload
): GetPromotionRequestAction => ({
  type: promotionsActionTypes.GET_PROMOTION_REQUEST,
  payload,
});

export const getPromotionGamesRequest = (
  payload: IPromotionGamesRequestPayload
): GetPromotionGamesRequestAction => ({
  type: promotionsActionTypes.GET_PROMOTION_GAMES_REQUEST,
  payload,
});

export const getPromotionGamesSuccess = (
  payload: IPromotionGamesSuccessPayload
): GetPromotionGamesSuccessAction => ({
  type: promotionsActionTypes.GET_PROMOTION_GAMES_SUCCESS,
  payload,
});

export const getPromotionGamesFailure = (
  payload: string
): GetPromotionGamesFailureAction => ({
  type: promotionsActionTypes.GET_PROMOTION_GAMES_FAILURE,
  payload,
});

export const getPromotionSuccess = (
  payload: IPromotionViewModel
): GetPromotionSuccessAction => ({
  type: promotionsActionTypes.GET_PROMOTION_SUCCESS,
  payload,
});

export const getPromotionFailure = (
  payload: IErrorPayload
): GetPromotionFailureAction => ({
  type: promotionsActionTypes.GET_PROMOTION_FAILURE,
  payload,
});

export const getBottomPromotionRequest = (
  payload: IBottomPromotionPayload
): GetBottomPromotionRequestAction => ({
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_REQUEST,
  payload,
});

export const getBottomPromotionSuccess = (
  payload: IBottomPromotionsResponsePayload
): GetBottomPromotionSuccessAction => ({
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_SUCCESS,
  payload,
});

export const getBottomPromotionFailure = (
  payload: IErrorPayload
): GetBottomPromotionFailureAction => ({
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_FAILURE,
  payload,
});

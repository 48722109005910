/* eslint-disable @next/next/no-async-client-component */
"use client";
import { FC, useEffect, useCallback, useState } from "react";
import { Link } from "../../navigation";
import Image from "next/image";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import ToggleTheme from "../ToggleTheme";
import SVGIcon from "../SVGIcon";
import { useTheme } from "next-themes";
import { isDrawerOpenSelector } from "@/fe-core/_redux/selectors/drawerSelectors";
import { toggleDrawer } from "@/fe-core/_redux/actions/drawerActions";
import { IViewNavigationResponse } from "@/fe-core/meta/interfaces/navigation";
import {
  setActiveLocaleCurrenyTab,
  toggleLocaleCurrencyModal,
} from "@/fe-core/_redux/actions/localeActions";
import { toggleSupportModal } from "@/fe-core/_redux/actions/supportActions";
import { usePathname } from "next/navigation";
import { useScreen } from "../../context/ScreenContext";
import { I18N_NAMESPACE } from "@/config/i18n";
import { useLocale, useTranslations } from "next-intl";
import { bool } from "prop-types";
import { useRouter } from "next/navigation";
import { userBalanceDataSelector } from "@/fe-core/_redux/selectors/userSelectors";
import { DEFAULT_CURRENCY, currencies } from "@/config/general";
import promoIcon from "/public/img/icons/promo-icon.png";
import { isUserAuthenticatedSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { openLoginMenu } from "@/fe-core/_redux/actions/menusActions";
import useTracking from "../Hooks/useTracking";
import Clock from "../Clock";

const LayoutDrawerMenuItem: FC<{
  index: number;
  item: object;
}> = ({ index, item }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const isDrawerOpen = useSelector(isDrawerOpenSelector);
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);
  const { isMobile, isTablet } = useScreen();
  const { navigate, click } = useTracking();

  const toggleDrawerMenu = () => {
    dispatch(toggleDrawer());
  };

  const handleSubMenu = (e) => {
    e.stopPropagation();
    e.nativeEvent.preventDefault();
    setOpenMenu((prevState) => !prevState);
  };
  const handleNavMenuClick = (item) => {
    // console.log(
    //   'e.target.classList.contains("arrow")',
    //   e.target.classList.contains("arrow")
    // );
    // if (e.target.classList.contains("arrow")) {
    //
    //   return false;
    // }
    // console.log("router.push");
    // console.log("router.push", getHrefPath(item));
    // router.push(getHrefPath(item));
    // put your code here
    if (isDrawerOpen && isMobile) {
      toggleDrawerMenu();
    }

    // if (item?.authenticated && !isUserAuthenticated) {
    //   return dispatch(openLoginMenu());
    // }

    if (item.path === "/contact") {
      click("toggle Contact Us Modal", {
        buttonLabel: "Contact Us",
        component: "MainSidebar - LayoutDrawer",
      });
      dispatch(toggleSupportModal({ isOpen: true }));
    }
    return false;
  };

  const getHrefPath = (item) => {
    if (
      item.path === "/contact" 
      // || (item?.authenticated && !isUserAuthenticated)
    ) {
      return "";
    }
    return item?.path ?? "/casino";
  };

  const pathname = usePathname() ?? "";

  function removeLanguageCode(inputString) {
    const regex = /^\/([a-z]{2,3})(-[a-z]{2})?/i;

    // Replace the matched language code with an empty string
    return inputString.replace(regex, "");
  }

  return (
    <>
      <div
        className="flex items-center w-full "
        key={`${index}_navigation_segment`}
      >
        <div className=" w-full">
          <Link
            href={getHrefPath(item)}
            // passHref
            onClick={() => {
              getHrefPath(item) &&
                navigate(getHrefPath(item), {
                  component: "MainSidebar - LayoutDrawer",
                  label: item?.title,
                  navigateFrom: "sideBar",
                });
              handleNavMenuClick(item);
            }}
            className={`flex items-center w-full space-x-2 overflow-hidden text-center transition-all group   rounded hover:bg-tertiary-50 dark:hover:bg-tertiary-600   ${
              removeLanguageCode(pathname) === item?.path
                ? "bg-tertiary-100 dark:bg-tertiary-600"
                : ""
            }`}
            target={getHrefPath(item)?.indexOf("http") > -1 ? "_blank" : null}
          >
            <div className={`  `}>
              <div className="relative flex items-center justify-center text-center transition-all group-hover:scale-125 w-11 h-11">
                {item?.icon && (
                  <Image
                    width={20}
                    height={20}
                    src={item?.icon}
                    alt=""
                    className="block mx-auto  "
                    loading="lazy"
                  />
                )}
              </div>
            </div>{" "}
            <Transition
              appear={true}
              unmount={false}
              show={isDrawerOpen}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              className={
                "flex items-center justify-between w-full ml-0 pr-4 origin-left transform"
              }
            >
              <span className="block text-sm font-bold md:font-semibold text-tertiary dark:text-white/50 whitespace-nowrap text-ellipsis overflow-hidden ...">
                {item?.title}
              </span>
              {item?.items.length > 0 && (
                <div
                  onClick={(e) => {
                    handleSubMenu(e);
                    click("toggle SubMenu", {
                      menuName: item?.title,
                      component: "MainSidebar - LayoutDrawer",
                      menuAction: openMenu ? "close submenu" : "open submenu",
                      isMobileOrTablet: isMobile || isTablet ? "Yes" : "No",
                    });
                  }}
                  className="arrow"
                >
                  <SVGIcon
                    icon={"angleArrow"}
                    className={`transition-all block w-3 h-3 text-tertiary/70 dark:text-white -mr-0.5 arrow ${
                      openMenu ? `rotate-90` : ``
                    }`}
                  />
                </div>
              )}
            </Transition>
          </Link>
        </div>
      </div>

      {item?.items.map(
        (subItem, index) =>
          openMenu && (
            <div
              className="flex items-center w-full"
              key={`${index}_navigation_segment`}
            >
              <div className="w-full">
                <Link
                  href={getHrefPath(subItem)}
                  passHref
                  onClick={() => {
                    getHrefPath(item) &&
                      navigate(getHrefPath(subItem), {
                        component: "MainSidebar - LayoutDrawer",
                        label: subItem?.title,
                        navigateFrom: "sideBar",
                      });
                    handleNavMenuClick(subItem);
                  }}
                  className={`flex items-center w-full space-x-2 overflow-hidden text-center transition-all group   rounded hover:bg-tertiary-50 dark:hover:bg-tertiary-800  ${
                    !isDrawerOpen ? "" : "px-2"
                  }  ${
                    removeLanguageCode(pathname) === subItem?.path
                      ? "bg-tertiary-100 dark:bg-tertiary-900"
                      : ""
                  }`}
                  target={
                    getHrefPath(subItem)?.indexOf("http") > -1 ? "_blank" : null
                  }
                >
                  <div className={` `}>
                    <div className="relative flex items-center justify-center text-center transition-all group-hover:scale-125 w-11 h-11">
                      {subItem?.icon && (
                        <Image
                          width={20}
                          height={20}
                          src={subItem?.icon}
                          alt=""
                          className="block mx-auto  "
                          loading="lazy"
                        />
                      )}
                    </div>
                  </div>
                  <Transition
                    appear={true}
                    unmount={false}
                    show={isDrawerOpen}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    className={
                      "flex items-center justify-between w-full ml-0 pr-4"
                    }
                  >
                    <span className="block text-sm font-bold md:font-semibold text-tertiary dark:text-white whitespace-nowrap text-ellipsis overflow-hidden ...">
                      {subItem?.title}
                    </span>
                  </Transition>
                </Link>
              </div>
            </div>
          )
      )}
    </>
  );
};

const LayoutDrawer: FC<{
  navigation: IViewNavigationResponse[] | undefined;
}> = ({ navigation }) => {
  const dispatch = useDispatch();

  const isDrawerOpen = useSelector(isDrawerOpenSelector);
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector);

  const { totalCurrency: currency } =
    useSelector(userBalanceDataSelector) || {};
  const { isMobile, isTablet } = useScreen();
  const toggleDrawerMenu = () => {
    dispatch(toggleDrawer());
  };
  const { navigate, click } = useTracking();
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isDrawerOpen) {
        document.querySelector("#app")?.classList.remove("lg:pl-16");
        document.querySelector("#app")?.classList.add("lg:pl-[280px]");
      } else {
        document.querySelector("#app")?.classList.remove("lg:pl-[280px]");
        document.querySelector("#app")?.classList.add("lg:pl-16");
      }
    }

    return () => {};
  }, [isDrawerOpen]);

  const { theme } = useTheme();

  const locale = useLocale();
  const setLocaleCurrencySelectorIsOpen = (isOpen: boolean) => {
    dispatch(toggleLocaleCurrencyModal({ isOpen }));
  };
  const openLocaleCurrencyModal = (_tabIndex: number) => {
    setLocaleCurrencySelectorIsOpen(true);
    dispatch(setActiveLocaleCurrenyTab({ tabIndex: _tabIndex }));
  };
  const router = useRouter();
  const getNavigation = useCallback(() => {
    return navigation?.map((segment, index) => (
      <>
        {index === 1 && (
          <div className="relative text-center flex justify-center w-full !m-0">
            <div
              className={` ${
                isDrawerOpen ? "" : "opacity-0"
              } !h-5 overflow-hidden transition-all duration-300`}
            >
              <p className="text-xxs text-sm font-semibold text-white/50 !m-0 bg-tertiary-300 px-2 relative z-[1]">
                {generalT("quick-links")}
              </p>
            </div>
            <hr className="border-white/10 border-t w-full absolute top-1/2 transform  " />
          </div>
        )}
        <div
          className="pb-4 space-y-2   "
          key={`${index}_navigation_segment_layoutdrawer`}
        >
          {segment?.items.map((item, index) => (
            <LayoutDrawerMenuItem item={item} index={index} />
          ))}
        </div>
      </>
    ));
  }, [navigation, isDrawerOpen]);

  return (
    <div
      className={`fixed top-0 left-0 z-50 flex-col h-full overflow-auto min-h-[100svh] text-center duration-150 lg:shadow-sidebar px-2.5 sm:px-2.5 py-5 pt-0 transition-all lg:bg-tertiary-300 bg-tertiary-700 flex ${
        isDrawerOpen
          ? `lg:w-[280px] w-full px-4  ${
              isMobile || isTablet ? "left-0" : "!-left-full lg:!left-0"
            }`
          : "lg:w-16 lg:p-0 px-4 w-full lg:!left-0 !-left-full"
      } `}
    >
      <div
        className={`flex flex-col justify-center space-y-4     ${
          isDrawerOpen ? "-mx-2.5 px-2.5" : ""
        } `}
      >
        <div className="flex items-center w-full py-2.5">
          <div
            className={`flex items-center justify-between w-full space-x-2 text-center md:justify-center   transition-all ${
              isDrawerOpen ? "px-0" : "px-2"
            } group`}
          >
            {/* <button
              className="hidden text-center transition-all w-11 min-w-[44px] h-11  md:flex justify-center items-center  "
              onClick={() => {
                toggleDrawerMenu();
                click("toggle Sidebar", {
                  component: "MainSidebar - LayoutDrawer",
                  menuAction: isDrawerOpen ? "close Sidebar" : "open Sidebar",
                });
              }}
            >
              <SVGIcon
                icon="hamburger"
                className="block w-5 h-5 mx-auto text-tertiary/70 dark:text-primary-500"
              />
            </button> */}
            <div className="h-14 relative w-full">
              <div
                className={`opacity-0 transition-all duration-300 scale-75 absolute w-full h-full flex items-center ${
                  isDrawerOpen ? "!scale-100 opacity-100 delay-150" : " "
                } `}
              >
                <span className="block text-xs font-normal md:font-semibold text-primary-100 whitespace-nowrap">
                  <Link
                    href="/"
                    passHref
                    className="flex justify-start"
                    onClick={() => {
                      click(`Main Logo Img`, {
                        component: "MainHeader - LayoutDrawer",
                        clickFrom: "Main SideBar Navigation",
                      });
                    }}
                  >
                    <Image
                      src={
                        theme == "dark"
                          ? "/img/logo-light.svg"
                          : "/img/logo-light.svg"
                      }
                      fill
                      alt=""
                      objectPosition="left"
                      className="!w-auto !h-[30px] sm:!h-12 !relative"
                      loading="eager"
                    />
                  </Link>
                </span>
              </div>
              <div
                className={`opacity-0 transition-all duration-300 scale-0 absolute w-full h-full ${
                  !isDrawerOpen ? "!scale-100 opacity-100 delay-150" : ""
                } `}
              >
                <span className="block text-xs font-normal md:font-semibold text-primary-100 whitespace-nowrap">
                  <Link href="/" passHref className="block text-left">
                    <Image
                      src={theme == "dark" ? "/img/logo.svg" : "/img/logo.svg"}
                      fill
                      alt=""
                      className="w-auto !h-10 sm:!h-12 !relative"
                      loading="eager"
                    />
                  </Link>
                </span>
              </div>
            </div>

            <div className="flex gap-2 md:hidden items-center">
              {/* <Link
                href={"/"}
                className="btn btn--tertiary btn--sm !py-2.5 h-fit !bg-[#F5F5F5] dark:!bg-tertiary group dark:text-white/70"
                onClick={() => {
                  navigate("home", {
                    component: "MainSidebar - LayoutDrawer",
                    buttonLable: generalT("home"),
                  });
                  toggleDrawerMenu();
                }}
              >
                <SVGIcon
                  icon="home"
                  className="w-4 h-4 text-tertiary/70 group-hover:text-tertiary/100 dark:text-white/70"
                />

                {generalT("home")}
              </Link> */}
              <button
                className="transition-all w-11 min-w-[44px] h-11 dark:text-primary-500 flex justify-center items-center text-center"
                onClick={() => {
                  click("toggle Sidebar", {
                    component: "MainSidebar - LayoutDrawer",
                    menuName: "Close Menu",
                  });
                  toggleDrawerMenu();
                }}
              >
                <SVGIcon icon="close" className="w-5 h-5 text-primary-500" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col justify-center py-4 space-y-4 transition-all ${
          isDrawerOpen ? "px-0.5" : "px-2"
        }`}
      >
        {/*<div*/}
        {/*  onClick={() => {*/}
        {/*    click("Bonus Wheel", {*/}
        {/*      buttonLabel: generalT("bonus"),*/}
        {/*      component: "MainSidebar - LayoutDrawer",*/}
        {/*    });*/}
        {/*    isUserAuthenticated*/}
        {/*      ? router.push("/promotions")*/}
        {/*      : dispatch(openLoginMenu());*/}
        {/*  }}*/}
        {/*  className="flex items-center gap-3 overflow-hidden rounded   px-0.5 cursor-pointer"*/}
        {/*>*/}
        {/*  <Image*/}
        {/*    src={promoIcon}*/}
        {/*    alt=""*/}
        {/*    width={40}*/}
        {/*    height={40}*/}
        {/*    className="block min-w-[40px] "*/}
        {/*    style={{*/}
        {/*      maxWidth: "100%",*/}
        {/*      height: "auto",*/}
        {/*    }}*/}
        {/*  />*/}
        {/*  <Transition*/}
        {/*    appear={true}*/}
        {/*    unmount={false}*/}
        {/*    show={isDrawerOpen}*/}
        {/*    enter="transition duration-100 ease-out"*/}
        {/*    enterFrom="transform scale-95 opacity-0"*/}
        {/*    enterTo="transform scale-100 opacity-100"*/}
        {/*    leave="transition duration-75 ease-out"*/}
        {/*    leaveFrom="transform scale-100 opacity-100"*/}
        {/*    leaveTo="transform scale-95 opacity-0"*/}
        {/*    className={"flex items-center justify-between w-full ml-0 pr-4"}*/}
        {/*  >*/}
        {/*    <span className="block text-sm font-semibold text-white/50 whitespace-nowrap text-ellipsis overflow-hidden ...">*/}
        {/*      {generalT("bonus")}*/}
        {/*    </span>*/}
        {/*  </Transition>*/}
        {/*</div>*/}
        {getNavigation()}
      </div>
    </div>
  );
};

export default LayoutDrawer;

import {
  all,
  AllEffect,
  delay,
  fork,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import {
  NOT_LOGGED_IN_ERROR_STATUS,
  OMEGA_STATUS_TYPES,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import {
  ICashbackBalanceViewModel,
  IRedeemBonusViewModel,
  IRedeemCashbackViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  RedeemBonusRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  clearRedeemCashbackMessage,
  getCashbackBalanceFailure,
  getCashbackBalanceSuccess,
  redeemBonusFailure,
  redeemBonusSuccess,
  redeemCashbackSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";

function* getCashbackCashbackBalance() {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<ICashbackBalanceViewModel | IErrorPayload> =
      yield fetch("/api/user/cashback", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
      });

    const viewModel: ICashbackBalanceViewModel = yield response.json();
    if (response.status == 200) {
      yield put(getCashbackBalanceSuccess(viewModel));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          viewModel?.status === NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getCashbackBalanceFailure(errorPayload));
    }
  } catch (error) {
    yield put(getCashbackBalanceFailure(parseFrontendError(error)));
  }
}

function* redeemCashbackRequestSaga({ payload }: RedeemBonusRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IRedeemBonusViewModel | IErrorPayload> =
      yield fetch("/api/user/cashback/redeem", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload),
      });

    const responsePayload: IRedeemCashbackViewModel | IErrorPayload =
      yield response.json();

    if (response.status === 200) {
      const viewModel = responsePayload as IErrorPayload;
      yield put(redeemCashbackSuccess(viewModel));
    } else {
      if (
        response.status === 401 ||
        (response.status === 400 &&
          (responsePayload as IRedeemBonusViewModel)?.status ===
            OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }
      yield put(redeemBonusFailure(responsePayload as IErrorPayload));
    }
    yield fork(clearRedeemCashbackMessageSaga, 5000);
  } catch (error) {
    yield put(redeemBonusFailure(parseFrontendError(error)));
    yield fork(clearRedeemCashbackMessageSaga, 5000);
  }
}

function* clearRedeemCashbackMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearRedeemCashbackMessage());
}
export default function* cashbackSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.GET_CASHBACK_BALANCE_REQUEST,
      getCashbackCashbackBalance
    ),
  ]);
  yield all([
    takeLatest(
      userActionTypes.REDEEM_CASHBACK_REQUEST,
      redeemCashbackRequestSaga
    ),
  ]);
}

import { ILoyaltyLevelsResponseModel } from "@/fe-core/meta/interfaces/loyaltyLevels";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  loyaltyLevelsActionTypes,
  GetLoyaltyLevelsFailureAction,
  GetLoyaltyLevelsRequestAction,
  GetLoyaltyLevelsSuccessAction,
} from "@/fe-core/meta/types/loyaltyLevels";

export const getLoyaltyLevelsRequest = (): GetLoyaltyLevelsRequestAction => ({
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_REQUEST,
});

export const getLoyaltyLevelsSuccess = (
  payload: ILoyaltyLevelsResponseModel
): GetLoyaltyLevelsSuccessAction => ({
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_SUCCESS,
  payload,
});

export const getLoyaltyLevelsFailure = (
  payload: IErrorPayload
): GetLoyaltyLevelsFailureAction => ({
  type: loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_FAILURE,
  payload,
});
import { IErrorPayload, IStatus } from '../interfaces/root'
import { TournamentItem, SetEligibleTournamentByGameSlugPendingPayload } from '../interfaces/tournaments';
import {
  TournamentsRequestPayload,
  TournamentsListModel,
  TournamentRequestPayload,
  LeaderboardDataModel,
  LeaderboardRequestPayload,
  LeaderboardSuccessPayload,
  LeaderboardFailurePayload,
  LeaderboardPendingModel,
  selectedFilterPayload,
  TournamentSuccessPayload,
  TournamentDataModel,
  TournamentPendingModel,
  TournamentMoreGamesSuccessPayload,
  ISetTournamentPendingViewModel,
  GetEligibleTournamentByGameSlugSuccessPayload,
} from "../interfaces/tournaments";

export enum tournamentsActionTypes {
  TOURNAMENTS_LIST_REQUEST = "TOURNAMENTS_LIST_REQUEST",
  TOURNAMENTS_LIST_SUCCESS = "TOURNAMENTS_LIST_SUCCESS",
  TOURNAMENTS_LIST_FAILURE = "TOURNAMENTS_LIST_FAILURE",
  SET_TOURNAMENTS_LIST_PENDING = "SET_TOURNAMENTS_LIST_PENDING",
  //single tournament
  TOURNAMENT_REQUEST = "TOURNAMENT_REQUEST",
  SET_TOURNAMENT_PENDING = "SET_TOURNAMENT_PENDING",
  TOURNAMENT_SUCCESS = "TOURNAMENT_SUCCESS",
  TOURNAMENT_FAILURE = "TOURNAMENT_FAILURE",
  //leaderboard
  LEADERBOARD_REQUEST = "LEADERBOARD_REQUEST",
  SET_LEADERBOARD_PENDING = "SET_LEADERBOARD_PENDING",
  LEADERBOARD_SUCCESS = "LEADERBOARD_SUCCESS",
  LEADERBOARD_FAILURE = "LEADERBOARD_FAILURE",
  //selected tournament filter
  SET_SELECTED_FILTER_STATE = "SET_SELECTED_FILTER_STATE",
  //single tournament more games
  GET_TOURNAMENT_MORE_GAMES_REQUEST = "GET_TOURNAMENT_MORE_GAMES_REQUEST",
  GET_TOURNAMENT_MORE_GAMES_SUCCESS = "GET_TOURNAMENT_MORE_GAMES_SUCCESS",
  GET_TOURNAMENT_MORE_GAMES_FAILURE = "GET_TOURNAMENT_MORE_GAMES_FAILURE",
  START_LEADERBOARD_INTERVAL = 'START_LEADERBOARD_INTERVAL',
  STOP_LEADERBOARD_INTERVAL = 'STOP_LEADERBOARD_INTERVAL',
  //single tournament by game slug
  SINGLE_TOURNAMENT_BY_GAME_SLUG_SUCCESS = "SINGLE_TOURNAMENT_BY_GAME_SLUG_SUCCESS",
  SET_SINGLE_TOURNAMENT_BY_GAME_SLUG_PENDING = "SET_SINGLE_TOURNAMENT_BY_GAME_SLUG_PENDING"
}

export type TournamentRequestAction = {
  type: tournamentsActionTypes.TOURNAMENT_REQUEST;
  payload: TournamentRequestPayload;
};

export type SetTournamentPendingAction = {
  type: tournamentsActionTypes.SET_TOURNAMENT_PENDING;
  payload: ISetTournamentPendingViewModel;
};

export type TournamentSuccessAction = {
  type: tournamentsActionTypes.TOURNAMENT_SUCCESS;
  payload: TournamentSuccessPayload;
};

export type TournamentFailureAction = {
  type: tournamentsActionTypes.TOURNAMENT_FAILURE;
  payload: IErrorPayload;
};

export type TournamentsRequestAction = {
  type: tournamentsActionTypes.TOURNAMENTS_LIST_REQUEST;
  payload: Partial<TournamentsRequestPayload>;
};

export type TournamentsSuccessAction = {
  type: tournamentsActionTypes.TOURNAMENTS_LIST_SUCCESS;
  payload: TournamentsListModel;
};

export type TournamentsFailureAction = {
  type: tournamentsActionTypes.TOURNAMENTS_LIST_FAILURE;
  payload: IErrorPayload;
};

export type LeaderboardRequestAction = {
  type: tournamentsActionTypes.LEADERBOARD_REQUEST;
  payload: LeaderboardRequestPayload;
};

export type SetLeaderboardPendingAction = {
  type: tournamentsActionTypes.SET_LEADERBOARD_PENDING;
  payload: ISetTournamentPendingViewModel;
};

export type SetTournamentListPendingAction = {
  type: tournamentsActionTypes.SET_TOURNAMENTS_LIST_PENDING;
  payload: boolean;
};

export type LeaderboardSuccessAction = {
  type: tournamentsActionTypes.LEADERBOARD_SUCCESS;
  payload: LeaderboardSuccessPayload;
};

export type LeaderboardFailureAction = {
  type: tournamentsActionTypes.LEADERBOARD_FAILURE;
  payload: LeaderboardFailurePayload;
};

export type setSelectedFilterStateAction = {
  type: tournamentsActionTypes.SET_SELECTED_FILTER_STATE;
  payload: selectedFilterPayload;
};

export type GetTournamentMoreGamesRequestAction = {
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_REQUEST;
  payload: TournamentRequestPayload;
};

export type GetTournamentMoreGamesSuccessAction = {
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_SUCCESS;
  payload: TournamentMoreGamesSuccessPayload;
};

export type GetTournamentMoreGamesFailureAction = {
  type: tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_FAILURE;
  payload: IErrorPayload;
};

export type GetTournamentLeaderBoardIntervalAction = {
  type: tournamentsActionTypes.START_LEADERBOARD_INTERVAL;
  payload: LeaderboardRequestPayload;
};

export type GetEligibleTournamentByGameSlugSuccessAction = {
  type: tournamentsActionTypes.SINGLE_TOURNAMENT_BY_GAME_SLUG_SUCCESS;
  payload: GetEligibleTournamentByGameSlugSuccessPayload;
};
export type SetEligibleTournamentByGameSlugPendingAction = {
  type: tournamentsActionTypes.SET_SINGLE_TOURNAMENT_BY_GAME_SLUG_PENDING;
  payload: SetEligibleTournamentByGameSlugPendingPayload;
};

export type TournamentsActions =
  | TournamentsRequestAction
  | TournamentsSuccessAction
  | TournamentsFailureAction
  | TournamentRequestAction
  | TournamentSuccessAction
  | TournamentFailureAction
  | LeaderboardRequestAction
  | LeaderboardSuccessAction
  | LeaderboardFailureAction
  | GetTournamentLeaderBoardIntervalAction
  | setSelectedFilterStateAction
  | GetTournamentMoreGamesRequestAction
  | GetTournamentMoreGamesSuccessAction
  | GetTournamentMoreGamesFailureAction
  | SetTournamentPendingAction
  | SetLeaderboardPendingAction
  | SetTournamentListPendingAction
  | GetEligibleTournamentByGameSlugSuccessAction
  | SetEligibleTournamentByGameSlugPendingAction;

export type TournamentsState = {
  tournamentPending: TournamentPendingModel
  tournament: TournamentDataModel
  tournamentStatus: IStatus | null;

  pending: boolean
  tournamentsList: TournamentsListModel | null
  status: IStatus | null;

  leaderboardPending: LeaderboardPendingModel
  leaderboardData: LeaderboardDataModel | {};
  leaderboardStatus: IStatus | null;

  selectedFilterState: selectedFilterPayload

  eligibleTournamentByGameSlug: TournamentItem | null | undefined;
  isEligibleTournament: boolean | null;
  isEligibleTournamentByGameSlugPending: boolean;
}

export enum routingActionTypes {
  SET_BACK_ROUTE = 'SET_BACK_ROUTE',
  SET_LOGIN_NEXT_ROUTE = 'SET_LOGIN_NEXT_ROUTE'
}

export type SetBackRouteAction = {
  type: routingActionTypes.SET_BACK_ROUTE
  payload: string
}

export type SetLoginNextRouteAction = {
  type: routingActionTypes.SET_LOGIN_NEXT_ROUTE
  payload: string
}

export type RoutingActions =
  | SetBackRouteAction
  | SetLoginNextRouteAction

export type RoutingState = {
  backRoute: string
  loginNextRoute: string
}

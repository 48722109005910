import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { OMEGA_STATUS_TYPES, parseFrontendError } from "@/fe-core/helpers/general";
import { ITransactionHistoryViewModel } from "@/fe-core/meta/interfaces/user";
import { TransactionHistoryRequestAction, userActionTypes } from "@/fe-core/meta/types/user";
import {
  transactionHistoryFailure,
  transactionHistorySuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { headerKeys } from "@/config/general";
import { methodTypes } from "@/fe-core/meta/types/root";
import { clearState } from "@/fe-core/_redux/actions/rootActions";

function* transactionHistoryRequestSaga({ payload }: TransactionHistoryRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/transactionHistory`,
      <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId
        }),
        body: JSON.stringify(payload),
      }
    );

    const viewModel: ITransactionHistoryViewModel | IErrorPayload = yield response.json();
    if (response.status == 200) {
      yield put(transactionHistorySuccess(viewModel as ITransactionHistoryViewModel));
    } else {
      if (response.status === 401 || (response.status === 400 && (viewModel as ITransactionHistoryViewModel)?.status === OMEGA_STATUS_TYPES.NOT_LOGGED_IN_ERROR_STATUS)) {
        yield put(clearState());
      }
      yield put(transactionHistoryFailure(parseFrontendError(viewModel as IErrorPayload)));
    }
  } catch (error) {
    yield put(transactionHistoryFailure(parseFrontendError(error)));
  }
}

function* transactionHistorySaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.TRANSACTION_HISTORY_REQUEST,
      transactionHistoryRequestSaga
    ),
  ]);
}

export default transactionHistorySaga;

import {
  ALL_GAMES_SLUG,
  LIVE_CASINO_DATA_SOURCE,
  MAX_GAME_COUNT_ON_GAME_SWIPER,
} from "@/config/casino";
import { filterGroups } from "@/fe-core/helpers/casino";
import {
  CasinoActions,
  casinoActionTypes,
  LiveCasinoGroupsState,
} from "@/fe-core/meta/types/casino";

const initialState: LiveCasinoGroupsState = {
  pending: false,
  err: "",
  groups: null,
  moreGamesLoading: false,
};

const liveCasinoGroupsReducer = (
  state: LiveCasinoGroupsState = initialState,
  action: CasinoActions
): LiveCasinoGroupsState => {
  switch (action.type) {
    case casinoActionTypes.SET_ALL_GAMES_GROUP:
      return {
        ...state,
        groups: filterGroups(
          state?.groups?.map((group) =>
            group?.slug === ALL_GAMES_SLUG
              ? {
                  ...group,
                  games: action.payload,
                  featuredGames: action.payload?.items,
                }
              : group
          ) || null,
          MAX_GAME_COUNT_ON_GAME_SWIPER
        ),
      };
    case casinoActionTypes.GET_LIVE_CASINO_GROUPS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case casinoActionTypes.GET_LIVE_CASINO_GROUPS_SUCCESS:
      return {
        ...state,
        pending: false,
        groups: action.payload,
      };
    case casinoActionTypes.GET_LIVE_CASINO_GROUPS_FAILURE:
    case casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_REQUEST:
      return {
        ...state,
        moreGamesLoading: true,
      };
    case casinoActionTypes.GET_CASINO_MORE_GAMES_GROUPS_REQUEST:
      return {
        ...state,
        moreGamesLoading: false,
      };
    case casinoActionTypes.GET_LIVE_CASINO_MORE_GAMES_GROUPS_SUCCESS: {
      const {
        slug,
        dataSource,
        games: { count, items },
      } = action.payload;

      if (dataSource === LIVE_CASINO_DATA_SOURCE && state?.groups) {
        const groupToUpdate = state?.groups?.find(
          (group) => group?.slug === slug
        );

        if (!groupToUpdate) {
          return { ...state, moreGamesLoading: false };
        }

        return {
          ...state,
          groups: state.groups.map((group) =>
            group.slug === slug
              ? {
                  ...group,
                  games: {
                    total: group.games.total,
                    items: group.games.items.concat(items),
                    count: group.games.count + count,
                  },
                }
              : group
          ),
          moreGamesLoading: false,
        };
      }
    }
    default:
      return {
        ...state,
      };
  }
};

export default liveCasinoGroupsReducer;

import {
  LatestBetsActions,
  latestBetsActionTypes,
  LatestBetsState,
} from "@/fe-core/meta/types/latestBets";
import { webPubSubActionTypes } from "@/fe-core/meta/types/webPubSub";

const initialState: LatestBetsState = {
  latestBets: [],
  pending: false,
  status: null,
};

const latestBetsReducer = (
  state: LatestBetsState = initialState,
  action: LatestBetsActions
): LatestBetsState => {
  switch (action.type) {
    case webPubSubActionTypes.WEBPUBSUB_HUB_BET:
      const { payload } = action;

      console.log("webPubSubActionTypes.WEBPUBSUB_HUB_BET", payload);
      return {
        ...state,
        latestBets: [payload, ...state.latestBets],
      };
    case latestBetsActionTypes.GET_LATEST_BETS_REQUEST:
      return {
        ...state,
        pending: true,
        latestBets: [],
        status: null,
      };
    case latestBetsActionTypes.GET_LATEST_BETS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        latestBets: payload,
        status: {
          success: true,
        },
      };
    }
    case latestBetsActionTypes.GET_LATEST_BETS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        latestBets: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default latestBetsReducer;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  PaymentIqTransactionHistoryState,
  userActionTypes,
  PaymentIqTransactionHistoryActions,
} from "@/fe-core/meta/types/user";
import dayjs from "dayjs";

const initialState: PaymentIqTransactionHistoryState = {
  pending: false,
  data: null,
  pendingWithdrawal: [],
  pendingWithdrawalCount: 0,
  withdrawal: [],
  deposit: [],
  status: null,
  timeInterval: {
    startDate: dayjs().subtract(3, "days").toDate(),
    endDate: new Date(),
  },
  cancelTransactionData: null,
  cancelTransactionPending: false,
  cancelTransactionStatus: null
};

const paymentIqTransactionHistoryReducer = (
  state: PaymentIqTransactionHistoryState = initialState,
  action: PaymentIqTransactionHistoryActions | RootActions
): PaymentIqTransactionHistoryState => {
  switch (action.type) {
    case userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        pendingWithdrawal: payload.pendingWithdrawal,
        pendingWithdrawalCount: payload?.pendingWithdrawal?.length || 0,
        withdrawal: payload.withdrawal,
        deposit: payload.deposit,
        status: {
          success: true,
        },
        cancelTransactionPending: false,
        cancelTransactionData: null,
        cancelTransactionStatus: null
      };
    }
    case userActionTypes.SET_PAYMENT_IQ_TRANSACTION_TIME_INTERVAL: {
      const { payload } = action;
      return {
        ...state,
        pending: true,
        timeInterval: payload,
      };
    }
    case userActionTypes.SET_PAYMENT_IQ_TRANSACTION_HISTORY_PENDING: {
      const { payload } = action;
      return {
        ...state,
        pending: payload.isPending,
      };
    }
    case userActionTypes.PAYMENT_IQ_TRANSACTION_HISTORY_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_REQUEST: {
      return {
        ...state,
        cancelTransactionPending: true,
        cancelTransactionData: null,
        cancelTransactionStatus: null
      };
    }
    case userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        cancelTransactionPending: false,
        cancelTransactionData: payload,
        cancelTransactionStatus: {
          success: true,
        }
      };
    }
    case userActionTypes.CANCEL_PAYMENT_IQ_TRANSACTION_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        cancelTransactionPending: false,
        cancelTransactionData: null,
        cancelTransactionStatus: {
          success: false,
          message,
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
    case userActionTypes.CLEAR_PAYMENT_IQ_TRANSACTION_HISTORY:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentIqTransactionHistoryReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { casinoLiveRTPActionTypes } from "@/fe-core/meta/types/casinoLiveRTP";
import { ICasinoLiveRTPViewModel } from "@/fe-core/meta/interfaces/casinoLiveRTP";
import {
  getCasinoLiveRTPHourlySuccess,
  getCasinoLiveRTPHourlyFailure,
  getCasinoLiveRTPDailySuccess,
  getCasinoLiveRTPDailyFailure,
} from "@/fe-core/_redux/actions/casinoLiveRTPActions";

function* getCasinoLiveRTPHourlySaga() {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/rtpHourly`,
      { method: "GET" }
    );

    if (response.status == 200) {
      const casinoLiveRTP: ICasinoLiveRTPViewModel = yield response.json();
      yield put(getCasinoLiveRTPHourlySuccess(casinoLiveRTP));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getCasinoLiveRTPHourlyFailure(errorPayload));
    }
  } catch (error) {
    yield put(getCasinoLiveRTPHourlyFailure(parseFrontendError(error)));
  }
}

function* getCasinoLiveRTPDailySaga() {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/rtpDaily`,
      { method: "GET" }
    );

    if (response.status == 200) {
      const casinoLiveRTP: ICasinoLiveRTPViewModel = yield response.json();
      yield put(getCasinoLiveRTPDailySuccess(casinoLiveRTP));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getCasinoLiveRTPDailyFailure(errorPayload));
    }
  } catch (error) {
    yield put(getCasinoLiveRTPDailyFailure(parseFrontendError(error)));
  }
}

function* casinoLiveRTPSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_REQUEST,
      getCasinoLiveRTPHourlySaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_REQUEST,
      getCasinoLiveRTPDailySaga
    ),
  ]);
}

export default casinoLiveRTPSaga;

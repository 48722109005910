import { ValidCountriesDetailsViewOption, ValidCountriesViewOption } from "@/fe-core/meta/interfaces/user/register/validCountries";
import { registerSelector } from ".";
import { RootState } from "@/fe-core/meta/types/root";
import { ValidCountriesState } from "@/fe-core/meta/types/user/register/validCountries";

export const mainValidCountriesSelector = (
  state: RootState
): ValidCountriesState => {
  const { validCountries } = registerSelector(state);
  return validCountries;
};

export const isPendingValidCountriesSelector = (state: RootState): boolean => {
  const { pending } = mainValidCountriesSelector(state);
  return pending;
};

export const validCountriesSelector = (state: RootState): string[] | null => {
  const { countries } = mainValidCountriesSelector(state);
  return countries;
};

export const validCountriesDetailsSelector = (state: RootState): ValidCountriesDetailsViewOption[] | null => {
  const { countryDetails } = mainValidCountriesSelector(state);
  return countryDetails;
};

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IConsentsViewModel } from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import {
  SetConsentsRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  getConsentsFailure,
  getConsentsSuccess,
  setConsentsFailure,
  setConsentsSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getConsentsRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IConsentsViewModel | IErrorPayload> =
      yield fetch("/api/user/consents", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: IConsentsViewModel = yield response.json();
      yield put(getConsentsSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getConsentsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getConsentsFailure(parseFrontendError(error)));
  }
}
function* setConsentsRequestSaga({ payload }: SetConsentsRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IConsentsViewModel | IErrorPayload> =
      yield fetch("/api/user/consents", <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
        body: JSON.stringify(payload),
      });

    if (response.status == 200) {
      const viewModel: IConsentsViewModel = yield response.json();
      yield put(setConsentsSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(setConsentsFailure(errorPayload));
    }
  } catch (error) {
    yield put(setConsentsFailure(parseFrontendError(error)));
  }
}

export default function* _consentsSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_CONSENTS_REQUEST, getConsentsRequestSaga),
    takeLatest(userActionTypes.SET_CONSENTS_REQUEST, setConsentsRequestSaga),
    takeLatest(userActionTypes.SET_CONSENTS_SUCCESS, getConsentsRequestSaga),
  ]);
}

import { IErrorPayload } from "../interfaces/root";
import {
  IPromotionContentResponseViewModel,
  IPromotionContentViewModel,
} from "../interfaces/promotionContent";

export enum promotionContentActionTypes {
  GET_PROMOTION_CONTENT_REQUEST = "GET_PROMOTION_CONTENT_REQUEST",
  GET_PROMOTION_CONTENT_SUCCESS = "GET_PROMOTION_CONTENT_SUCCESS",
  GET_PROMOTION_CONTENT_FAILURE = "GET_PROMOTION_CONTENT_FAILURE",
}

export type GetPromotionContentRequestAction = {
  type: promotionContentActionTypes.GET_PROMOTION_CONTENT_REQUEST;
};

export type GetPromotionContentSuccessAction = {
  type: promotionContentActionTypes.GET_PROMOTION_CONTENT_SUCCESS;
  payload: IPromotionContentResponseViewModel;
};

export type GetPromotionContentFailureAction = {
  type: promotionContentActionTypes.GET_PROMOTION_CONTENT_FAILURE;
  payload: IErrorPayload;
};

export type PromotionContentActions =
  | GetPromotionContentRequestAction
  | GetPromotionContentSuccessAction
  | GetPromotionContentFailureAction;

export type PromotionContentState = {
  pending: boolean;
  promotionContent: IPromotionContentViewModel[] | null;
};

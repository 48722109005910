import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  ProcessDepositActions,
  ProcessDepositState,
} from "@/fe-core/meta/types/user/cashier/processDeposit";

const initialState: ProcessDepositState = {
  pending: false,
  paymentMethod: null,
  status: null,
};

const processDepositReducer = (
  state: ProcessDepositState = initialState,
  action: ProcessDepositActions | RootActions
): ProcessDepositState => {
  switch (action.type) {
    case userActionTypes.RESET_PROCESS_DEPOSIT_STATUS:
      return {
        ...state,
        status: null,
      };
    case userActionTypes.OPEN_PROCESS_DEPOSIT_MODAL:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case userActionTypes.CLOSE_PROCESS_DEPOSIT_MODAL: {
      return {
        ...state,
        paymentMethod: null,
      };
    }
    case userActionTypes.PROCESS_DEPOSIT_REQUEST: {
      return {
        ...state,
        pending: true,
        status: null,
      };
    }
    case userActionTypes.PROCESS_DEPOSIT_REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
        ...action.payload,
      };
    }
    case userActionTypes.PROCESS_DEPOSIT_REQUEST_FAILURE: {
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message: action.payload.message,
          status: action.payload.status,
        },
      };
    }
    case userActionTypes.CLEAR_PROCESS_DEPOSIT_MESSAGE: {
      const newStatus = state.status

      if (newStatus) {
        delete newStatus?.message;
        delete newStatus?.success;
      }

      return {
        ...state,
        pending: false,
        status: newStatus,
      };
    }
    case userActionTypes.CLEAR_PROCESS_DEPOSIT:
    case rootActionTypes.CLEAR_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default processDepositReducer;

import { RootState } from "@/fe-core/meta/types/root";
import { DrawerState } from "@/fe-core/meta/types/drawer";

export const mainDrawerSelector = ({ drawer }: RootState): DrawerState =>
  drawer;

export const isDrawerOpenSelector = (state: RootState): boolean => {
  const { isDrawerOpen } = mainDrawerSelector(state);
  return isDrawerOpen;
};

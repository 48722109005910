import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { navFailure } from "@/fe-core/_redux/actions/navActions";
import {
  GetCasinoJackpotMoreGamesGroupsRequestAction,
  casinoJackpotActionTypes,
} from "@/fe-core/meta/types/casinoJackpot";
import { ICasinoJackpotViewModel } from "@/fe-core/meta/interfaces/casinoJackpot";
import {
  casinoEQJackpotSuccess,
  casinoJackpotSuccess,
  getCasinoJackpotGroupsFailure,
  getCasinoJackpotGroupsSuccess,
  getCasinoJackpotMoreGamesGroupsFailure,
  getCasinoJackpotMoreGamesGroupsSuccess,
} from "@/fe-core/_redux/actions/casinoJackpotActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildCasinoGroupsQueryString } from "@/fe-core/helpers/casino";
import {
  CASINO_JACKPOTS_DATA_SOURCE,
  CASINO_URL,
  groupExpand,
  groupFields,
  groupGameFields,
  platform,
} from "@/config/casino";
import { ICasinoGroupViewModel } from "@/fe-core/meta/interfaces/casino";

function* casinoEGTJackpotRequestSaga() {
  try {
    const response: IJsonResponse<ICasinoJackpotViewModel> = yield fetch(
      `/api/egtJackpot`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoJackpotViewModel = yield response.json();
        yield put(casinoJackpotSuccess(payload));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(navFailure(parseFrontendError(error)));
  }
}

function* casinoEQJackpotRequestSaga() {
  try {
    const response: IJsonResponse<ICasinoJackpotViewModel> = yield fetch(
      `/api/eqJackpot`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoJackpotViewModel = yield response.json();
        yield put(casinoEQJackpotSuccess(payload));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(navFailure(parseFrontendError(error)));
  }
}

function* getCasinoJackpotGroupsSaga() {
  try {
    const queryString: string = buildCasinoGroupsQueryString(
      groupExpand,
      groupFields,
      platform,
      groupGameFields
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${CASINO_URL}/groups/${CASINO_JACKPOTS_DATA_SOURCE}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const groups: { items: ICasinoGroupViewModel[] } =
          yield response.json();

        groups.items = groups.items.filter((group) => {
          return group.games.count > 0;
        });

        yield put(getCasinoJackpotGroupsSuccess(groups.items));

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoJackpotGroupsFailure(parseFrontendError(error)));
  }
}

function* getCasinoJackpotMoreGamesGroupsSaga({
  payload,
}: GetCasinoJackpotMoreGamesGroupsRequestAction) {
  try {
    const { id, pagination } = payload;

    const queryString: string = buildCasinoGroupsQueryString(
      groupExpand,
      groupFields,
      platform,
      groupGameFields,
      pagination
    );

    const response: IJsonResponse<ICasinoGroupViewModel[]> = yield fetch(
      `${CASINO_URL}/groups/${CASINO_JACKPOTS_DATA_SOURCE}/${id}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const group: ICasinoGroupViewModel = yield response.json();

        if (group.games.count) {
          yield put(getCasinoJackpotMoreGamesGroupsSuccess(group));
        }

        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(
      getCasinoJackpotMoreGamesGroupsFailure(parseFrontendError(error))
    );
  }
}

function* casinoJackpotSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      casinoJackpotActionTypes.CASINO_JACKPOT_REQUEST,
      casinoEGTJackpotRequestSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoJackpotActionTypes.CASINO_EQ_JACKPOT_REQUEST,
      casinoEQJackpotRequestSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_REQUEST,
      getCasinoJackpotGroupsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_REQUEST,
      getCasinoJackpotMoreGamesGroupsSaga
    ),
  ]);
}

export default casinoJackpotSaga;

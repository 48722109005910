import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  BonusActions,
  BonusState,
  UserActions,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: BonusState = {
  data: null,
  totalClaimedAmount: 0,
  // claimableBonuses: null,
  getBonuses: {
    pending: false,
    status: null,
  },
  getClaimableBonuses: {
    pending: false,
    status: null,
  },
  applyBonus: {
    pending: false,
    status: null,
  },
  cancelBonus: {
    pending: false,
    status: null,
  },
  showConfirmationModal: false,
  // confirmationBonus: null,
  getEligibleOptInBonusPlans: {
    data: null,
    pending: false,
    status: null,
  },
  optInBonus: {
    pending: {},
    status: null,
  },
  depositEligibleBonusPlans: {
    data: null,
    pending: false,
    status: null,
  },
  redeemBonus: {
    pending: false,
    status: null,
  },
};

const bonusReducer = (
  state: BonusState = initialState,
  action: BonusActions | RootActions
): BonusState => {
  switch (action.type) {
    case userActionTypes.GET_BONUSES_REQUEST:
      return {
        ...state,
        getBonuses: { ...state.getBonuses, pending: true, status: null },
      };
    case userActionTypes.GET_BONUSES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        data: payload?.bonuses,
        totalClaimedAmount: payload?.totalClaimedAmount,
        getBonuses: {
          ...state.getBonuses,
          pending: false,
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.GET_BONUSES_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        getBonuses: {
          ...state.getBonuses,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_REQUEST:
      return {
        ...state,
        getEligibleOptInBonusPlans: {
          ...state.getEligibleOptInBonusPlans,
          pending: true,
          status: null,
        },
      };
    case userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        getEligibleOptInBonusPlans: {
          ...state.getEligibleOptInBonusPlans,
          data: payload,
          pending: false,
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.GET_ELIGIBLE_OPT_IN_BONUS_PLANS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        getEligibleOptInBonusPlans: {
          ...state.getEligibleOptInBonusPlans,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_REQUEST:
      return {
        ...state,
        depositEligibleBonusPlans: {
          ...state.depositEligibleBonusPlans,
          pending: true,
          status: null,
        },
      };
    case userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        depositEligibleBonusPlans: {
          ...state.depositEligibleBonusPlans,
          data: payload,
          pending: false,
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.DEPOSIT_ELIGIBLE_BONUS_PLANS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        depositEligibleBonusPlans: {
          ...state.depositEligibleBonusPlans,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.OPT_IN_BONUS_REQUEST:
      return {
        ...state,
        optInBonus: {
          ...state.optInBonus,
          pending: {
            ...state.optInBonus.pending,
            [action?.payload?.bonusPlanId]: true,
          },
          status: null,
        },
      };
    case userActionTypes.OPT_IN_BONUS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        optInBonus: {
          ...state.optInBonus,
          pending: {
            ...state.optInBonus.pending,
            [payload?.bonusPlanId]: false,
          },
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.CLEAR_OPT_IN_BONUS_MESSAGE_REQUEST: {
      return {
        ...state,
        optInBonus: {
          ...state.optInBonus,
          status: null,
        },
      };
    }
    case userActionTypes.OPT_IN_BONUS_FAILURE: {
      const {
        payload: { message, bonusPlanId },
      } = action;
      return {
        ...state,
        optInBonus: {
          ...state.optInBonus,
          pending: {
            ...state.optInBonus.pending,
            [bonusPlanId]: false,
          },
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.REDEEM_BONUS_REQUEST:
      return {
        ...state,
        redeemBonus: {
          ...state.redeemBonus,
          pending: true,
          status: null,
        },
      };
    case userActionTypes.REDEEM_BONUS_SUCCESS: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        redeemBonus: {
          ...state.redeemBonus,
          pending: false,
          status: {
            success: true,
            message,
          },
        },
      };
    }
    case userActionTypes.REDEEM_BONUS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        redeemBonus: {
          ...state.redeemBonus,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.GET_CLAIMABLE_BONUSES_REQUEST:
      return {
        ...state,
        getClaimableBonuses: {
          ...state.getClaimableBonuses,
          pending: true,
          status: null,
        },
      };
    // case userActionTypes.GET_CLAIMABLE_BONUSES_SUCCESS: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     claimableBonuses: payload,
    //     getClaimableBonuses: {
    //       ...state.getClaimableBonuses,
    //       pending: false,
    //       status: {
    //         success: true,
    //       },
    //     },
    //   };
    // }
    case userActionTypes.GET_CLAIMABLE_BONUSES_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        getClaimableBonuses: {
          ...state.getClaimableBonuses,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.APPLY_BONUS_REQUEST:
      return {
        ...state,
        applyBonus: { ...state.applyBonus, pending: true, status: null },
      };

    case userActionTypes.APPLY_BONUS_SUCCESS: {
      return {
        ...state,
        applyBonus: {
          ...state.applyBonus,
          pending: false,
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.APPLY_BONUS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        applyBonus: {
          ...state.applyBonus,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }

    case userActionTypes.CLEAR_BONUS_MESSAGE_REQUEST: {
      return {
        ...state,
        applyBonus: {
          ...state.applyBonus,
          status: null,
        },
      };
    }

    case userActionTypes.CLEAR_REDEEM_BONUS_MESSAGE: {
      return {
        ...state,
        redeemBonus: {
          ...state.redeemBonus,
          status: null,
        },
      };
    }
    case userActionTypes.CLEAR_CANCEL_BONUS_MESSAGE: {
      return {
        ...state,
        cancelBonus: {
          ...state.cancelBonus,
          status: null,
        },
      };
    }

    case userActionTypes.CANCEL_BONUS_REQUEST:
      return {
        ...state,
        cancelBonus: { ...state.cancelBonus, pending: true, status: null },
      };

    case userActionTypes.CANCEL_BONUS_SUCCESS: {
      return {
        ...state,
        cancelBonus: {
          ...state.cancelBonus,
          pending: false,
          status: {
            success: true,
          },
        },
      };
    }
    case userActionTypes.CANCEL_BONUS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        cancelBonus: {
          ...state.cancelBonus,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }
    // case userActionTypes.OPEN_BONUS_CONFIRMATION_MODAL: {
    //   const { payload } = action;
    //   return {
    //     ...state,
    //     showConfirmationModal: true,
    //     confirmationBonus: payload,
    //   };
    // }

    // case userActionTypes.CLOSE_BONUS_CONFIRMATION_MODAL: {
    //   return {
    //     ...state,
    //     showConfirmationModal: false,
    //     confirmationBonus: null,
    //   };
    // }

    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default bonusReducer;

import { Session } from "autobahn";
import { ICMSLatestBetViewModel } from "../interfaces/latestBets";

export enum webPubSubActionTypes {
  INITIALIZE_WEBPUBSUB = "INITIALIZE_WEBPUBSUB",
  WEBPUBSUB_CONNECTED = "WEBPUBSUB_CONNECTED",
  WEBPUBSUB_DISCONNECTED = "SOCKET_DISCONNECTED",
  RECONNECT_WEBPUBSUB = "RECONNECT_WEBPUBSUB",
  WEBPUBSUB_CONNECTION_FAILED = "webPubSub/WEBPUBSUB_CONNECTION_FAILED",
  SUBSCRIBE_SESSION_STATE = "webPubSub/SUBSCRIBE_SESSION_STATE",
  SESSION_STATE_CHANGED = "webPubSub/SESSION_STATE_CHANGED",
  SUBSCRIBE_WATCH_BALANCE = "webPubSub/SUBSCRIBE_WATCH_BALANCE",
  SESSION_BALANCE_CHANGED = "webPubSub/SESSION_BALANCE_CHANGED",
  SESSION_BALANCE_UPDATE = "webPubSub/SESSION_BALANCE_UPDATE",
  WEBPUBSUB_HUB_BET = "webPubSub/WEBPUBSUB_HUB_BET",
}

export type InitializeWebPubSubAction = {
  type: webPubSubActionTypes.INITIALIZE_WEBPUBSUB;
};

export type WebPubSubConnectedAction = {
  type: webPubSubActionTypes.WEBPUBSUB_CONNECTED;
  payload: Session;
};

export type WebPubSubDisconnectedAction = {
  type: webPubSubActionTypes.WEBPUBSUB_DISCONNECTED;
};

export type ReconnectWebPubSubAction = {
  type: webPubSubActionTypes.RECONNECT_WEBPUBSUB;
};

export type WebPubSubConnectionFailedAction = {
  type: webPubSubActionTypes.WEBPUBSUB_CONNECTION_FAILED;
};

export type WebPubSubSubscribeSessionStateAction = {
  type: webPubSubActionTypes.SUBSCRIBE_SESSION_STATE;
};

export type WebPubSubSessionStateChangedAction = {
  type: webPubSubActionTypes.SESSION_STATE_CHANGED;
  payload: number;
};

export type WebPubSubSubscribeWatchBalanceAction = {
  type: webPubSubActionTypes.SUBSCRIBE_WATCH_BALANCE;
};

export type WebPubSubSessionBalanceChangedAction = {
  type: webPubSubActionTypes.SESSION_BALANCE_CHANGED;
  payload: WebPubSubBalanceChangedPayload;
};

export type WebPubFeFunctionBetAction = {
  type: webPubSubActionTypes.WEBPUBSUB_HUB_BET;
  payload: ICMSLatestBetViewModel;
};

export type WebPubSubSessionBalanceUpdateAction = {
  type: webPubSubActionTypes.SESSION_BALANCE_UPDATE;
  payload: WebPubSubBalanceChangedPayload;
};

export type WebPubSubSessionStatePayload = {
  code: number;
  description: string;
};

export type WebPubSubBalanceChangedPayload = {
  id: number;
  vendor: string;
  type: string;
  amount: number;
  bonusAmount: number;
  isFirstDeposit: boolean;
};

export type WebPubSubLevelProgressPayload = {
  percentage: number;
  currentLevel: number;
  isLastLevel: boolean;
  threshold: number;
};

export type WebPubSubLevelFinishedPayload = {
  id: number;
  vendor: string;
  type: string;
  amount: number;
  bonusAmount: number;
  isFirstDeposit: boolean;
  rewards: ILevelsFinishedRewardsViewModel[];
};

export type WebPubSubActions =
  | WebPubSubConnectedAction
  | WebPubSubDisconnectedAction
  | ReconnectWebPubSubAction
  | InitializeWebPubSubAction
  | WebPubSubConnectionFailedAction
  | WebPubSubSubscribeSessionStateAction
  | WebPubSubSessionStateChangedAction
  | WebPubSubSubscribeWatchBalanceAction
  | WebPubSubSessionBalanceChangedAction
  | WebPubSubSessionBalanceUpdateAction
  | WebPubFeFunctionBetAction;

export type WebPubSubState = {
  sessionStatus: number;
  session: Session | null;
  sessionConnectionAttempts: number;
  webPubSubConnectionFailed: boolean;
};

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  DocumentsActions,
  DocumentsState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: DocumentsState = {
  pending: false,
  data: null,
  status: null,
  selectedDocument: null,
  uploadDocument: {
    pending: false,
    status: null,
  },
  userDocuments: {
    pending: false,
    status: null,
    data: [],
  },
};

const documentsReducer = (
  state: DocumentsState = initialState,
  action: DocumentsActions | RootActions
): DocumentsState => {
  switch (action.type) {
    case userActionTypes.GET_DOCUMENTS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };

    case userActionTypes.GET_DOCUMENTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }

    case userActionTypes.GET_DOCUMENTS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    case userActionTypes.CLEAR_UPLOAD_DOCUMENT_STATUS:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          status: null,
        },
      };

      case userActionTypes.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          pending: true,
          status: null,
        },
      };

    case userActionTypes.UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          pending: false,
          status: { success: true, message: "" },
        },
        selectedDocument: null,
      };
    }

    case userActionTypes.UPLOAD_DOCUMENT_FAILURE: {
      return {
        ...state,
        uploadDocument: {
          ...state.uploadDocument,
          pending: false,
          status: {
            success: false,
            message: action.payload.message,
          },
        },
      };
    }

    case userActionTypes.GET_USER_DOCUMENTS_REQUEST:
      return {
        ...state,
        userDocuments: {
          ...state.userDocuments,
          pending: true,
          status: null,
        },
      };

    case userActionTypes.GET_USER_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        userDocuments: {
          ...state.userDocuments,
          pending: false,
          status: { success: true, message: "" },
          data: action.payload,
        },
      };
    }

    case userActionTypes.GET_USER_DOCUMENTS_FAILURE: {
      return {
        ...state,
        userDocuments: {
          ...state.userDocuments,
          pending: false,
          status: {
            success: false,
            message: action.payload.message,
          },
        },
      };
    }

    case userActionTypes.OPEN_UPLOAD_DOCUMENT_MODAL: {
      return {
        ...state,
        selectedDocument: action.payload,
      };
    }

    case userActionTypes.CLOSE_UPLOAD_DOCUMENT_MODAL: {
      return {
        ...state,
        selectedDocument: null,
      };
    }

    case userActionTypes.CLEAR_DOCUMENTS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default documentsReducer;

import {
  userActionTypes,
  UserActions,
  RealityCheckState,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: RealityCheckState = {
  showRealityCheck: false,
  loggedinHours: null,
};

const realityCheckReducer = (
  state: RealityCheckState = initialState,
  action: UserActions | RootActions
): RealityCheckState => {
  switch (action.type) {
    case userActionTypes.SET_REALITY_CHECK:
      const { payload } = action;
      return {
        ...state,
        showRealityCheck: payload.showRealityCheck,
        loggedinHours: payload.loggedinHours,
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default realityCheckReducer;

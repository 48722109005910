"use client";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSignupScreen,
  signupEmailConfirmationRequest,
  signupEmailResendRequest,
} from "../../fe-core-omega/_redux/actions/userActions";
import { ISignupPayload } from "../../fe-core-omega/meta/interfaces/user";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import useTracking from "../Hooks/useTracking";
import Button from "../Button";
import {
  signupActiveModalSelector,
  signupEmailConfirmationPendingSelector,
  signupEmailConfirmationStatusSelector,
  signupResendEmailStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import ResendTimer from "../Layout/LoginMenu/ResendTimer";
import { toast } from "react-toastify";
import { loginMenuStatusSelector } from "@/fe-core/_redux/selectors/menusSelectors";
import {
  closeLoginMenu,
  setLoginMenuScreen,
} from "@/fe-core/_redux/actions/menusActions";
import { loginMenuScreens } from "@/fe-core/meta/types/menus";
import { signupScreens } from "@/fe-core/meta/types/user";

interface PayloadType {
  email: string;
  username: string;
  password: string;
}

const EmailConfirmationScreen: FC<{
  payload: PayloadType;
}> = ({ payload }) => {
  const { click, track } = useTracking();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState<string>("");
  const [otpErr, setOtpErr] = useState<string | null>("");
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  const t = useTranslations(I18N_NAMESPACE.REGISTER);
  const profileT = useTranslations(I18N_NAMESPACE.PROFILE);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const isEmailConfirmationPending = useSelector(
    signupEmailConfirmationPendingSelector
  );
  const emailConfirmationStatus = useSelector(
    signupEmailConfirmationStatusSelector
  );
  const resendEmailStatus = useSelector(signupResendEmailStatusSelector);
  const loginMenuStatus = useSelector(loginMenuStatusSelector);
  const signupActiveModal = useSelector(signupActiveModalSelector);
  const [registrationEventSent, setRegistrationEventSent] = useState(false);

  const handleDoneButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      signupEmailConfirmationRequest({
        email: payload.email,
        confirmationCode: otp,
        username: payload.username,
        password: payload.password,
      })
    );
    click(`${generalT("continue")}`, {
      buttonLabel: generalT("continue"),
      component: "EmailConfirmationScreen",
    });
  };

  useEffect(() => {
    if (!payload.email) {
      if (loginMenuStatus === "open")
        dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
      if (signupActiveModal)
        dispatch(setActiveSignupScreen(signupScreens.ACCOUNT_INFORMATION));
    }
  }, [payload?.email, signupActiveModal, loginMenuStatus]);

  useEffect(() => {
    if (!registrationEventSent) {
      setRegistrationEventSent(true);
      track(`Registration Successful`, {
        registeredEmail: payload.email,
        component: "EmailConfirmationScreen",
      });
    }
  }, [registrationEventSent]);

  useEffect(() => {
    if (resendEmailStatus && resendEmailStatus.success && buttonClicked)
      toast.success(generalT("otp-sent"));
  }, [resendEmailStatus]);

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
    setOtpErr(event.target.value?.length !== 4 ? t("invalid-otp") : "");
  };

  const resendOTP = () => {
    setButtonClicked(true);
    dispatch(
      signupEmailResendRequest({
        username: payload.username,
        verificationTarget: "email",
      })
    );
  };

  useEffect(() => {
    if (!!emailConfirmationStatus?.success) {
      dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
      dispatch(closeLoginMenu());
    }
  }, [emailConfirmationStatus]);

  const getLoginStatusMessage = () => {
    if (!emailConfirmationStatus || emailConfirmationStatus.success) {
      return null;
    }
    return (
      <div className="mb-4 text-xs text-center text-red">
        {emailConfirmationStatus.message}
      </div>
    );
  };

  return (
    <div className="">
      <form onSubmit={handleDoneButtonClick}>
        <div className="">
          <h5 className="mt-2 mb-6 text-base font-bold text-center dark:text-white">
            {t("verify-your-email")}
          </h5>
          <div className="flex flex-col items-center justify-center mb-3 text-center">
            <div className="relative w-full">
              <input
                className={`w-full px-4 text-2xl font-bold leading-5.5 py-2.5 border rounded text-tertiary/50 border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0 tracking-widest text-center`}
                onChange={handleOtpChange}
                type="number"
                id="input-otp"
                value={otp}
                placeholder={"_ _ _ _"}
                disabled={isEmailConfirmationPending}
              />
            </div>
            {otpErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {otpErr}
              </div>
            )}

            <div className="mt-4 text-xs text-center">
              {t("access-the-link-sent-to")}{" "}
              <span className="underline text-primary-500">
                {payload.email}
              </span>{" "}
              {t("and-ignite-the-passion-for-fun")}
            </div>
          </div>
          <ResendTimer onResend={resendOTP} />
          <div className="mt-4">
            <div className="mb-10 text-xs text-center">
              {t("receiving-the-email")}
              <br />
              {t("address-correct")}
            </div>
          </div>
        </div>
        {getLoginStatusMessage()}
        <div className="px-5">
          <Button
            size="lg"
            type="primary"
            buttonType="submit"
            className="w-full"
            label={profileT("verify")}
            disabled={!!otpErr || isEmailConfirmationPending || !otp}
            loading={isEmailConfirmationPending}
          />
        </div>
      </form>
    </div>
  );
};

export default EmailConfirmationScreen;

import { RootState } from "@/fe-core/meta/types/root";
import { ChatboxState } from "@/fe-core/meta/types/chatbox";

export const mainChatboxSelector = ({ chatbox }: RootState): ChatboxState =>
  chatbox;

export const isChatboxOpenSelector = (state: RootState): boolean => {
  const { isChatboxOpen } = mainChatboxSelector(state);
  return isChatboxOpen;
};

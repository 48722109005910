/* eslint-disable @next/next/no-async-client-component */
"use client";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { navMenuStatusSelector } from "@/fe-core/_redux/selectors/menusSelectors";
import {
  closeLoginMenu,
  closeNavMenu,
  openLoginMenu,
} from "@/fe-core/_redux/actions/menusActions";

import Obfuscator from "../../Obfuscator";
import { navTitleSelector } from "@/fe-core/_redux/selectors/navSelectors";
import StaticNavMenu from "./StaticNavMenu";
import { useScreen } from "../../../context/ScreenContext";
import { Dialog, Transition } from "@headlessui/react";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { replaceLocale } from "@/fe-core/helpers/utils";
import SVGIcon from "../../SVGIcon";
import Hamburger from "../../NavPage/Hamburger";
import { I18N_NAMESPACE } from "@/config/i18n";
import { useLocale, useTranslations } from "next-intl";
import { isUserAuthenticatedSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { setShowSignup } from "@/fe-core/_redux/actions/userActions";
import Script from "next/script";
import {
  MOBILEBUTTONS,
  LIVE_AGENT_ID,
  LIVE_AGENT_URL,
} from "@/config/liveagent";
import { toggleChatbox } from "@/fe-core/_redux/actions/chatboxActions";
import useTracking from "@/components/Hooks/useTracking";
import { Link, usePathname } from "../../../navigation";
// import CasinoNavMenu from './CasinoNavMenu'
// import SportsNavMenu from './SportsNavMenu'
// import Icon from '../../Icon/Icon'

const NavMenu: FC = () => {
  const { navigate } = useTracking();
  const dispatch = useDispatch();
  const navMenuStatus = useSelector(navMenuStatusSelector);
  const navTitle = useSelector(navTitleSelector);
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const { isDesktop } = useScreen();
  const router = useRouter();
  const locale = useLocale();
  const LIVE_AGENT_BUTTON_ID = MOBILEBUTTONS[locale];

  const pathname = usePathname() ?? "";
  // const [isBack, setIsBack] = useState(false)

  const handleNavMenuClick = () => {
    // setIsBack(false)
    dispatch(closeNavMenu());
  };

  const getNavMenuStatusClass = () => {
    if (navMenuStatus === "open" && typeof document !== "undefined") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return navMenuStatus === "open";
  };

  // const handleBackClick = () => {
  //   setIsBack(true)
  // }

  const handleRegisterClick = () => {
    dispatch(closeLoginMenu());
    dispatch(setShowSignup());
  };

  const handleWalletClick = () => {
    router.push("/profile/totalbalance");
  };

  const toggleChatboxMenu = () => {
    dispatch(toggleChatbox());
  };

  const handleLogin = () => {
    dispatch(openLoginMenu());
  };

  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  if (isDesktop) {
    return;
  }
  const NavLink = ({
    href,
    icon,
    label,
    active,
    navigatePath,
  }: {
    href: string;
    icon: string;
    label: string;
    active?: boolean;
    navigatePath: string;
  }) => (
    <div>
      <Link
        href={href}
        className={`flex flex-col items-center justify-center p-1 text-center ${
          active ? "text-primary-500" : ""
        }`}
        onClick={() => {
          navigate(navigatePath, {
            component: "NavMenu",
            buttonLabel: generalT(label),
          });
        }}
      >
        <SVGIcon icon={icon} className="w-6 h-5 text-primary-500" />
        <span className="font-semibold text-xxs block leading-[12px] mt-1">
          {generalT(label)}
        </span>
      </Link>
    </div>
  );
  return (
    <>
      <div className="fixed bottom-0 left-0 z-[49] grid w-full grid-cols-5 gap-2 py-2 overflow-visible bg-white drop-shadow-nav text-tertiary/70 dark:bg-tertiary-700 dark:text-white/70 lg:hidden">
        <NavLink
          href="/casino"
          icon="casino"
          label="casino"
          navigatePath="/casino"
          active={pathname === "/casino"}
        />
        <NavLink
          href="/casino/live"
          icon="liveCasino"
          label="live-casino"
          navigatePath="/casino/live"
          active={pathname === "/casino/live"}
        />
        <NavLink
          href="/promotions"
          icon="promotion"
          label="promotions"
          navigatePath="/promotions"
          active={pathname === "/promotions"}
        />
        <NavLink
          href="/sports"
          icon="sports"
          label="sports"
          navigatePath="/sports"
          active={pathname === "/sports"}
        />
        <NavLink
          href="/sports/live-sports"
          icon="liveSports"
          label="live-sports"
          navigatePath="/sports/live-sports"
          active={pathname === "/sports/live-sports"}
        />
      </div>
    </>
  );
};
// active={pathname === "/profile/global-settings"}
export default NavMenu;

import { Session } from "autobahn";
import {
  socketActionTypes,
  InitializeSocketAction,
  SocketConnectedAction,
  ReconnectSocketAction,
  SocketConnectionFailedAction,
  SocketSubscribeSessionStateAction,
  SocketSessionStateChangedAction,
  SocketSubscribeWatchBalanceAction,
  SocketSessionBalanceChangedAction,
  SocketBalanceChangedPayload,
  SocketSessionBalanceUpdateAction,
} from "@/fe-core/meta/types/socket";

// export const socketConnected = (session: Session): SocketConnectedAction => ({
//   type: socketActionTypes.SOCKET_CONNECTED,
//   payload: session,
// });

export const initializeSocket = (): InitializeSocketAction => ({
  type: socketActionTypes.INITIALIZE_SOCKET,
});

// export const reconnectSocket = (): ReconnectSocketAction => ({
//   type: socketActionTypes.RECONNECT_SOCKET,
// });

export const socketConnectionFailed = (): SocketConnectionFailedAction => ({
  type: socketActionTypes.SOCKET_CONNECTION_FAILED,
});

export const socketSubscribeSessionState =
  (): SocketSubscribeSessionStateAction => ({
    type: socketActionTypes.SUBSCRIBE_SESSION_STATE,
  });

export const socketSessionStateChanged = (
  code: number
): SocketSessionStateChangedAction => ({
  type: socketActionTypes.SESSION_STATE_CHANGED,
  payload: code,
});

export const socketSubscribeWatchBalance =
  (): SocketSubscribeWatchBalanceAction => ({
    type: socketActionTypes.SUBSCRIBE_WATCH_BALANCE,
  });

export const socketSessionBalanceChanged = (
  balance: SocketBalanceChangedPayload
): SocketSessionBalanceChangedAction => ({
  type: socketActionTypes.SESSION_BALANCE_CHANGED,
  payload: balance,
});

export const socketSessionBalanceUpdate = (
  balance: SocketBalanceChangedPayload
): SocketSessionBalanceUpdateAction => ({
  type: socketActionTypes.SESSION_BALANCE_UPDATE,
  payload: balance,
});

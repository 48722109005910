"use client";
import React, { ReactNode } from "react";
import cx from "classnames";
import Image from "next/image";
import { sendGTMEvent } from "@next/third-parties/google";

interface ButtonProps {
  label: string | ReactNode;
  type?: string;
  size?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  square?: boolean;
  onClick?: (event: any) => void;
  buttonType?: "button" | "submit" | "reset" | undefined;
  trackName?: any;
  trackParams?: any;
}

const Button: React.FC<ButtonProps> = ({
  label,
  type = "primary",
  size,
  onClick,
  className,
  loading,
  disabled,
  square,
  buttonType = "button",

  trackName,
  trackParams = {},
}) => {
  return (
    <button
      type={buttonType}
      className={cx(
        "btn",
        {
          "btn--primary": type === "primary",
          "btn--secondary": type === "secondary",
          "btn--tertiary": type === "tertiary",
          "btn--transparent": type === "transparent",
          "btn--danger": type === "danger",
          "btn--green": type === "green",
          "btn--white": type === "white",
          "btn--lg": size === "lg",
          "btn--sm": size === "sm",
          "btn--disabled": disabled,
          "btn--loading": loading,
          "btn--square": square,
        },
        className
      )}
      onClick={(event) => {
        onClick && onClick(event);
        sendGTMEvent({
          event: trackName ? trackName : `${label} button clicked`,
          buttonLabel: label,
          ...trackParams,
        });
      }}
      disabled={disabled}
    >
      {loading && (
        <span className="absolute inset-0 flex items-center justify-center w-full h-full z-[1]">
          <Image
            src="/img/icons/loading-dots.svg"
            width={40}
            height={30}
            alt="spinner"
            className="!h-7"
            loading="lazy"
          />
        </span>
      )}
      {type === "primary" ? <span>{label}</span> : label}
    </button>
  );
};

export default Button;

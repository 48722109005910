"use client";
import { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter, useSearchParams } from "next/navigation";
import {
  activateEmailRequest,
  clearActivateEmailData,
  setHideActivateEmailModal,
} from "../../../fe-core-omega/_redux/actions/activateEmailActions";
import { activateEmailSelector } from "../../../fe-core-omega/_redux/selectors/activateEmailSelectors";
import Link from "next/link";
import { I18N_NAMESPACE } from "@/config/i18n";
import { useTranslations } from "next-intl";
import useTracking from "@/components/Hooks/useTracking";

type Props = {
  setActivateEmailModal?: any;
};
const ActivateEmailProcessing: FC<Props> = ({
  setActivateEmailModal,
}): ReactNode => {
  const { navigate } = useTracking();
  const dispatch = useDispatch();
  const router = useRouter();
  const activateEmail = useSelector(activateEmailSelector);
  const query = useSearchParams() as URLSearchParams;
  const t = useTranslations(I18N_NAMESPACE.ACTIVATE_EMAIL);
  const [isActivateEmailLoading, setActivateEmailLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (isActivateEmailLoading) return;
    if (query.get("key")) {
      setActivateEmailLoading(true);
      dispatch(
        activateEmailRequest({ verificationCode: query.get("key") as string })
      );
    } else {
      setActivateEmailLoading(false);
      dispatch(clearActivateEmailData());
      router.push("/");
      return;
    }
  }, [dispatch, router, query, isActivateEmailLoading]);

  return (
    <div className="text-center">
      <h3 className="mb-4 text-xl font-bold uppercase">
        {activateEmail.pending
          ? t("validating-msg")
          : activateEmail.status && activateEmail.status.success
          ? t("title")
          : t("title-failed")}
      </h3>
      {!activateEmail.pending && (
        <p
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html:
              activateEmail.status && activateEmail.status.success
                ? t("description")
                : activateEmail.status?.message || t("account-not-exist"),
          }}
        />
      )}

      {!activateEmail.pending &&
        activateEmail.status &&
        activateEmail.status.success && (
          <Link
            href="/casino"
            className="btn btn--secondary"
            onClick={() => {
              setActivateEmailModal && setActivateEmailModal(true);
              dispatch(setHideActivateEmailModal());
              dispatch(clearActivateEmailData());
              navigate(`/casino`, {
                isEmailActivated: "yes",
                component: "ActivateEmailProcessing",
              });
            }}
          >
            {t("casino-cta-btn")}
          </Link>
        )}
    </div>
  );
};

export default ActivateEmailProcessing;

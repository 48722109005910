import { IErrorPayload } from '../interfaces/root'
import { IBannersData, IBannersRequestViewModel, IBannersViewModel } from '../interfaces/banner'

export enum bannerActionTypes {
  GET_BANNERS_REQUEST = 'GET_BANNERS_REQUEST',
  GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS',
  GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE',
}

export type GetBannersRequestAction = {
  type: bannerActionTypes.GET_BANNERS_REQUEST;
  payload?: IBannersRequestViewModel
}

export type GetBannersSuccessAction = {
  type: bannerActionTypes.GET_BANNERS_SUCCESS
  payload: IBannersViewModel
}

export type GetBannersFailureAction = {
  type: bannerActionTypes.GET_BANNERS_FAILURE
  payload: IErrorPayload
}

export type BannerActions =
  | GetBannersRequestAction
  | GetBannersSuccessAction
  | GetBannersFailureAction

export type BannerState = {
  pending: boolean
  banners: IBannersData | null
}

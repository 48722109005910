import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  ValidCountriesActions,
  ValidCountriesState,
} from "@/fe-core/meta/types/user/register/validCountries";

const initialState: ValidCountriesState = {
  pending: false,
  countries: null,
};

const validCountriesReducer = (
  state: ValidCountriesState = initialState,
  action: ValidCountriesActions
): ValidCountriesState => {
  switch (action.type) {
    case userActionTypes.GET_VALID_COUNTRIES_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case userActionTypes.GET_VALID_COUNTRIES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case userActionTypes.GET_VALID_COUNTRIES_SUCCESS:
      return {
        ...state,
        pending: false,
        countries: action.payload.countries,
        countryDetails: action.payload.countryDetails,
      };
    default:
      return {
        ...state,
      };
  }
};

export default validCountriesReducer;

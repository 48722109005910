import { TournamentsRequestPayload } from "@/fe-core/meta/interfaces/tournaments";
import dayjs from 'dayjs';

export const STATE_ALL = "Unstarted|Running|Closing|Closed";
export const STATE_RUNNING = "Running";

export const TOURNAMENTS_PER_PAGE = 50;
export const LEADERBOARD_ITEM_PER_PAGE = 200;

export const DEFAULT_OFFSET = 0;
export const DEFAULT_CURRENT_PAGE = 0;

export const DEFAULT_OFFSET_TOURNAMENTS_GAMES = 0;
export const DEFAULT_GAMES_ITEMS_PER_PAGE = 14;

export const DEFAULT_EXHIBITION_END_TIME = dayjs().format(
  "YYYY-MM-DDTHH:mm:ss[Z]"
); //PAZ-170

export const DEFAULT_SORT_ORDER = "desc";
export const DEFAULT_SORT_TYPE = "type1";

export const getTournamentURLParams = (payload: TournamentsRequestPayload) => {
  const qs = Object.keys(payload)
    .map((key) => `${key}=${payload[key as keyof TournamentsRequestPayload]}`)
    .join("&");

  return qs ? `?${qs}` : "";
};

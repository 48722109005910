import {
  SocketActions,
  SocketState,
  socketActionTypes,
} from "@/fe-core/meta/types/socket";

const initialState: SocketState = {
  // statuses can be found here https://everymatrix.atlassian.net/wiki/spaces/WA1/pages/153976891/Session+State+Change
  sessionStatus: 2,
  session: null,
  sessionConnectionAttempts: 0,
  socketConnectionFailed: false,
};

const socketReducer = (
  state: SocketState = initialState,
  action: SocketActions
): SocketState => {
  switch (action.type) {
    case socketActionTypes.SOCKET_CONNECTED:
      return {
        ...state,
        session: action.payload,
        sessionConnectionAttempts: 0,
        socketConnectionFailed: false,
      };
    case socketActionTypes.SOCKET_DISCONNECTED:
      return {
        ...state,
        session: null,
      };
    case socketActionTypes.RECONNECT_SOCKET:
      const { sessionConnectionAttempts } = state;

      return {
        ...state,
        sessionConnectionAttempts: sessionConnectionAttempts + 1,
      };
    case socketActionTypes.SOCKET_CONNECTION_FAILED:
      return {
        ...state,
        socketConnectionFailed: true,
      };
    case socketActionTypes.SESSION_STATE_CHANGED:
      return {
        ...state,
        sessionStatus: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default socketReducer;

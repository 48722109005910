import { userActionTypes } from "@/fe-core/meta/types/user";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetRegionsFailureAction,
  GetRegionsRequestAction,
  GetRegionsSuccessAction,
} from "@/fe-core/meta/types/user/regions";
import {
  IGetRegionsPayload,
  IRegionsViewModel,
} from "@/fe-core/meta/interfaces/user/regions";

export const getRegionsRequest = (
  payload: IGetRegionsPayload
): GetRegionsRequestAction => ({
  type: userActionTypes.GET_REGIONS_REQUEST,
  payload,
});

export const getRegionsSuccess = (
  payload: IRegionsViewModel
): GetRegionsSuccessAction => ({
  type: userActionTypes.GET_REGIONS_SUCCESS,
  payload,
});

export const getRegionsFailure = (
  payload: IErrorPayload
): GetRegionsFailureAction => ({
  type: userActionTypes.GET_REGIONS_FAILURE,
  payload,
});

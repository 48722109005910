import {
  PromotionDrawerActions,
  promotionDrawerActionTypes,
  PromotionDrawerState,
} from "@/fe-core/meta/types/promotionDrawer";

const initialState: PromotionDrawerState = {
  isOpen: false,
};

const promotionDrawerReducer = (
  state: PromotionDrawerState = initialState,
  action: PromotionDrawerActions
): PromotionDrawerState => {
  switch (action.type) {
    case promotionDrawerActionTypes.OPEN_PROMOTION_DRAWER:
      return {
        ...state,
        isOpen: true,
      };
    case promotionDrawerActionTypes.CLOSE_PROMOTION_DRAWER:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default promotionDrawerReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IFavoritesViewModel } from "@/fe-core/meta/interfaces/user";
import { IRecentPlayedViewModel } from "@/fe-core/meta/interfaces/user/recentPlayed";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  getRecentPlayedFailure,
  getRecentPlayedSuccess,
} from "@/fe-core/_redux/actions/userActions/recentPlayedActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";

function* getRecentPlayedRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IFavoritesViewModel | IErrorPayload> =
      yield fetch("/api/user/recentPlayed", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
      });

    if (response.status == 200) {
      const viewModel: IRecentPlayedViewModel = yield response.json();
      yield put(getRecentPlayedSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getRecentPlayedFailure(errorPayload));
    }
  } catch (error) {
    yield put(getRecentPlayedFailure(parseFrontendError(error)));
  }
}

export default function* _recentPlayedSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      sessionActionTypes.AUTHENTICATE_SUCCESS,
      getRecentPlayedRequestSaga
    ),
    takeLatest(
      userActionTypes.GET_RECENT_PLAYED_REQUEST,
      getRecentPlayedRequestSaga
    ),
    takeLatest(
      localeActionTypes.SET_CURRENT_LOCALE,
      getRecentPlayedRequestSaga
    ),
  ]);
}

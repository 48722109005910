import { RootState } from "@/fe-core/meta/types/root";
import { NavState } from "@/fe-core/meta/types/nav";
import { INavViewModel } from "@/fe-core/meta/interfaces/nav";

export const navSelector = ({ nav }: RootState): NavState => nav;

export const navDataSelector = (state: RootState): INavViewModel | null => {
  const { data } = navSelector(state);

  return data;
};

export const navItemsSelector = (
  state: RootState
): { [key: string]: string[] } | null => {
  const data = navDataSelector(state);

  if (!data) {
    return null;
  }

  const { items } = data;
  return items;
};

export const navTitleSelector = (state: RootState): string | null => {
  const data = navDataSelector(state);

  if (!data) {
    return null;
  }

  const { title } = data;
  return title;
};

export const navCategoriesSelector = (state: RootState): string[] | null => {
  const data = navDataSelector(state);

  if (!data) {
    return null;
  }

  const { categories } = data;
  return categories;
};

export const getNavItemsByCategorySelector =
  (state: RootState): ((category: string) => string[] | null) =>
  (category: string): string[] | null => {
    const navItems = navItemsSelector(state);

    if (!navItems) {
      return null;
    }

    return navItems[category];
  };

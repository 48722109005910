import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IFavoritesViewModel } from "@/fe-core/meta/interfaces/user";
import { methodTypes } from "@/fe-core/meta/types/root";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import {
  AddFavoriteRequestAction,
  DeleteFavoriteRequestAction,
  GetFavoritesRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  addFavoriteFailure,
  addFavoriteSuccess,
  deleteFavoriteFailure,
  deleteFavoriteSuccess,
  getFavoritesFailure,
  getFavoritesSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { buildCasinoQueryString } from "@/fe-core/helpers/casino";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { localeSelector } from "../../selectors/localeSelectors";

function* getFavoritesRequestSaga({ payload }: GetFavoritesRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const favoritesQueryString: string = buildCasinoQueryString({
      platform: payload?.platform,
    });

    const response: IJsonResponse<IFavoritesViewModel | IErrorPayload> =
      yield fetch(`/api/user/favorites${favoritesQueryString}`, <RequestInit> {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: IFavoritesViewModel = yield response.json();
      yield put(getFavoritesSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getFavoritesFailure(errorPayload));
    }
  } catch (error) {
    yield put(getFavoritesFailure(parseFrontendError(error)));
  }
}

function* addFavoriteRequestSaga({ payload }: AddFavoriteRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IFavoritesViewModel | IErrorPayload> =
      yield fetch(`/api/user/favorites`, <RequestInit>{
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload)
      });

    if (response.status == 200) {
      yield put(addFavoriteSuccess());
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(addFavoriteFailure(errorPayload));
    }
  } catch (error) {
    yield put(addFavoriteFailure(parseFrontendError(error)));
  }
}

function* deleteFavoriteRequestSaga({ payload }: DeleteFavoriteRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IFavoritesViewModel | IErrorPayload> =
      yield fetch(`/api/user/favorites`, <RequestInit>{
        method: methodTypes.DELETE,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
        body: JSON.stringify(payload)
      });

    if (response.status == 200) {
      yield put(deleteFavoriteSuccess());
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(deleteFavoriteFailure(errorPayload));
    }
  } catch (error) {
    yield put(deleteFavoriteFailure(parseFrontendError(error)));
  }
}

export default function* _favoritesSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest([
      localeActionTypes.SET_CURRENT_LOCALE,
      userActionTypes.ADD_FAVORITE_SUCCESS,
      userActionTypes.DELETE_FAVORITE_SUCCESS,
      userActionTypes.GET_FAVORITES_REQUEST,
      sessionActionTypes.AUTHENTICATE_SUCCESS
    ], getFavoritesRequestSaga),

    takeLatest(userActionTypes.ADD_FAVORITE_REQUEST, addFavoriteRequestSaga),
    takeLatest(
      userActionTypes.DELETE_FAVORITE_REQUEST,
      deleteFavoriteRequestSaga
    ),
  ]);
}

export enum chatboxActionTypes {
  TOGGLE_CHATBOX = "TOGGLE_CHATBOX",
}

export type toggleChatboxAction = {
  type: chatboxActionTypes.TOGGLE_CHATBOX;
};

export type ChatboxActions = toggleChatboxAction;

export type ChatboxState = {
  isChatboxOpen: boolean;
};

export function buildVendorQueryString(): string {
  const queryParams = new URLSearchParams();
  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : "";
}

export const slugifyURL = (text: string): string => {
  if (text) {
    const slugifiedURL = text.replace(/[^a-zA-Z]/g, "").toLowerCase();
    return slugifiedURL;
  } else {
    return "";
  }
};

export const unSlugifyURL = (text: string): string => {
  if (text) {
    return text
      .replace(/\-/g, " ")
      .replace(
        /\w\S*/g,
        (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
      );
  } else {
    return "";
  }
};

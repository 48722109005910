import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { IUpdateTagRequestPayload } from "@/fe-core/meta/interfaces/user";
import {
  ClearTagsAction,
  UpdateTagsFailureAction,
  UpdateTagsRequestAction,
  UpdateTagsSuccessAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";

export const updateTagsRequest = (payload: IUpdateTagRequestPayload): UpdateTagsRequestAction => ({
  type: userActionTypes.UPDATE_TAGS_REQUEST,
  payload,
});

export const updateTagsSuccess = (
): UpdateTagsSuccessAction => ({
  type: userActionTypes.UPDATE_TAGS_SUCCESS,
});

export const updateTagsFailure = (
  payload: IErrorPayload
): UpdateTagsFailureAction => ({
  type: userActionTypes.UPDATE_TAGS_FAILURE,
  payload,
});

export const clearTags = (): ClearTagsAction => ({
  type: userActionTypes.CLEAR_TAGS,
});

export enum promotionDrawerActionTypes {
  OPEN_PROMOTION_DRAWER = "OPEN_PROMOTION_DRAWER",
  CLOSE_PROMOTION_DRAWER = "CLOSE_PROMOTION_DRAWER",
}

export type OpenPromotionDrawerRequestAction = {
  type: promotionDrawerActionTypes.OPEN_PROMOTION_DRAWER;
};

export type ClosePromotionDrawerRequestAction = {
  type: promotionDrawerActionTypes.CLOSE_PROMOTION_DRAWER;
};

export type PromotionDrawerActions =
  | OpenPromotionDrawerRequestAction
  | ClosePromotionDrawerRequestAction;

export type PromotionDrawerState = {
  isOpen: boolean;
};

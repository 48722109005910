import {
  ICmsBettingRulesPageData,
  ICmsCasinoGameRulesPageData,
  ICmsDepositMethodsPageData,
  IPageData,
  ICmsTermsAndConditionsPageData,
  ICmsWithdrawMethodsPageData,
  IShopItem,
} from "../interfaces/page";
import { IErrorPayload } from "../interfaces/root";

export enum pageActionTypes {
  GET_PAGE_REQUEST = "GET_PAGE_REQUEST",
  GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS",
  GET_PAGE_FAILURE = "GET_PAGE_FAILURE",
  GET_SHOPS_PAGE_REQUEST = "GET_SHOPS_PAGE_REQUEST",
  GET_SHOPS_PAGE_SUCCESS = "GET_SHOPS_PAGE_SUCCESS",
  GET_SHOPS_PAGE_FAILURE = "GET_SHOPS_PAGE_FAILURE",
}

export type GetPageRequestAction = {
  type: pageActionTypes.GET_PAGE_REQUEST;
  payload: { slug: string };
};

export type GetPageSuccessAction = {
  type: pageActionTypes.GET_PAGE_SUCCESS;
  payload: {
    slug: string;
    data:
      | IPageData
      | ICmsBettingRulesPageData
      | ICmsTermsAndConditionsPageData
      | ICmsDepositMethodsPageData
      | ICmsWithdrawMethodsPageData;
  };
};

export type GetPageFailureAction = {
  type: pageActionTypes.GET_PAGE_FAILURE;
  payload: IErrorPayload;
};

export type GetShopsPageRequestAction = {
  type: pageActionTypes.GET_SHOPS_PAGE_REQUEST;
};

export type GetShopsPageSuccessAction = {
  type: pageActionTypes.GET_SHOPS_PAGE_SUCCESS;
  payload: IShopItem[];
};

export type GetShopsPageFailureAction = {
  type: pageActionTypes.GET_SHOPS_PAGE_FAILURE;
  payload: IErrorPayload;
};

export type PageActions =
  | GetPageRequestAction
  | GetPageSuccessAction
  | GetPageFailureAction
  | GetShopsPageRequestAction
  | GetShopsPageSuccessAction
  | GetShopsPageFailureAction;

export type PageState = {
  pending: boolean;
  reguliDePariere: ICmsBettingRulesPageData | null;
  shopsLoaded: boolean;
  listaAgentii: IShopItem[] | null;
  casinoGameRules: ICmsCasinoGameRulesPageData | null;
};

export type PageViewModel = IPageData;

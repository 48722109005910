import { DYNAMIC_CMS_SLUG_MAP } from "@/config/cms";
import {
  ICmsBettingRulesPageData,
  ICmsCasinoGameRulesPageData,
} from "@/fe-core/meta/interfaces/page";
import {
  PageActions,
  pageActionTypes,
  PageState,
} from "@/fe-core/meta/types/page";

const initialState: PageState = {
  pending: false,
  reguliDePariere: null,
  shopsLoaded: false,
  listaAgentii: null,
  casinoGameRules: null,
};

const pageReducer = (
  state: PageState = initialState,
  action: PageActions
): PageState => {
  switch (action.type) {
    case pageActionTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case pageActionTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case pageActionTypes.GET_PAGE_SUCCESS:
      switch (action.payload.slug) {
        case DYNAMIC_CMS_SLUG_MAP["reguli-de-pariere"]:
          return {
            ...state,
            pending: false,
            reguliDePariere: action.payload.data as ICmsBettingRulesPageData,
          };
        case DYNAMIC_CMS_SLUG_MAP["regulamente-jocuri-casino"]:
          return {
            ...state,
            pending: false,
            //@ts-ignore
            casinoGameRules: action.payload.data as ICmsCasinoGameRulesPageData,
          };
      }
    case pageActionTypes.GET_SHOPS_PAGE_REQUEST:
      return {
        ...state,
        pending: true,
        shopsLoaded: false,
      };
    case pageActionTypes.GET_SHOPS_PAGE_FAILURE:
      return {
        ...state,
        pending: false,
        shopsLoaded: false,
      };
    case pageActionTypes.GET_SHOPS_PAGE_SUCCESS:
      return {
        ...state,
        pending: false,
        listaAgentii: action.payload,
        shopsLoaded: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default pageReducer;

import { ICMSTopWinnerViewModel } from '../interfaces/topWinners'
import { IErrorPayload, IStatus } from '../interfaces/root'

export enum topWinnersActionTypes {
  GET_TOP_WINNERS_REQUEST = 'GET_TOP_WINNERS_REQUEST',
  GET_TOP_WINNERS_SUCCESS = 'GET_TOP_WINNERS_SUCCESS',
  GET_TOP_WINNERS_FAILURE = 'GET_TOP_WINNERS_FAILURE',
}

export type GetTopWinnersRequestAction = {
  type: topWinnersActionTypes.GET_TOP_WINNERS_REQUEST
}

export type GetTopWinnersSuccessAction = {
  type: topWinnersActionTypes.GET_TOP_WINNERS_SUCCESS
  payload: ICMSTopWinnerViewModel[]
}

export type GetTopWinnersFailureAction = {
  type: topWinnersActionTypes.GET_TOP_WINNERS_FAILURE
  payload: IErrorPayload
}

export type TopWinnersActions =
  | GetTopWinnersRequestAction
  | GetTopWinnersSuccessAction
  | GetTopWinnersFailureAction

export type TopWinnersState = {
  topWinners: ICMSTopWinnerViewModel[]
  pending: boolean
  status: IStatus | null
}

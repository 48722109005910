import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { ISmarticoPublicPropsModel } from "@/fe-core/meta/interfaces/smartico/publicProps";
import {
  getSmarticoPublicPropsFailure,
  getSmarticoPublicPropsSuccess,
} from "@/fe-core/_redux/actions/smarticoActions/publicProps";
import { smarticoPublicPropsActionTypes } from "@/fe-core/meta/types/smartico/publicProps";


function* getSmarticoPublicPropsSaga() {
  try {
    const response: ISmarticoPublicPropsModel = window._smartico.getPublicProps();
      yield put(getSmarticoPublicPropsSuccess(response));
  } catch (error) {
    yield put(getSmarticoPublicPropsFailure(parseFrontendError(error)));
  }
}

function* smarticoPublicProps(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
        smarticoPublicPropsActionTypes.GET_SMARTICO_PUBLIC_PROPS_REQUEST,
        getSmarticoPublicPropsSaga
    ),
  ]);
}

export default smarticoPublicProps;

"use client";
import Script from "next/script";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import { deadSimpleChatSelector } from "../../fe-core-omega/_redux/selectors/deadSimpleChatSelector";
import { getDscAccessTokenRequest } from "../../fe-core-omega/_redux/actions/deadSimpleChatActions";
import { isUserAuthenticatedSelector, sessionAuthenticatePendingSelector } from "../../fe-core-omega/_redux/selectors/sessionSelectors";

const DeadSimpleChat = () => {
  const deadSimpleChat = useSelector(deadSimpleChatSelector);
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const frameRef = useRef(null);
  const sessionAuthenticatedPending = useSelector(
    sessionAuthenticatePendingSelector
  );
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.DEADSIMPLECHAT);
  useEffect(() => {
    window.addEventListener(
      "message",
      (e) => {
        console.log("INCOMING DATE", e);

        // ...
      },
      false
    );

    if (!sessionAuthenticatedPending && isAuthenticated) {
      dispatch(getDscAccessTokenRequest());
    }
  }, [sessionAuthenticatedPending, isAuthenticated]);

  if (deadSimpleChat.pending || !isAuthenticated) {
    return null;
  }
  return (
    <div className="z-10 w-full flex flex-1">
      <Script src="https://cdn.deadsimplechat.com/sdk/1.2.1/dschatsdk.min.js" />
      <iframe
        id="chat-frame"
        ref={frameRef}
        src={`https://deadsimplechat.com/o_TV3ZPUr?accessToken=${deadSimpleChat?.accessToken}`}
        width="100%"
        height="100%"
        onLoad={async () => {
          // DSChatSDK construction accepts two parameters:
          // 1. Chat Room Id
          // 2. ID of the iFrame tag
          // 3. Dead Simple Chat Public API Key.

          const sdk = new window.DSChatSDK(
            "o_TV3ZPUr",
            "chat-frame",
            "pub_584f4232765551427862734f52596a4f6950414e72356a306a365f39724c7a676f4a466344637569306967306674686a"
          );
          // Call the connect method to connect the SDK to the Chat iFrame.
          await sdk.connect();
          sdk.on("message", (message: { message: string }) => {
            if (message.message === "test") {
              sdk.sendMessage(
                `<a href="${window.location.href}#showModal" target="_top">Open Modal A</a>`
              );
            }
          });
          console.log({
            resumeAutoScroll: t("resumeAutoScroll"),
            autoScrollPaused: t("autoScrollPaused"),
            editProfile: t("editProfile"),
            claimProfile: t("claimProfile"),
            verifyProfile: t("verifyProfile"),
            channels: t("channels"),
            unread: t("unread"),
            privateChats: t("privateChats"),
            onlineUsers: t("onlineUsers"),
            ban: t("ban"),
            loadMore: t("loadMore"),
            logout: t("logout"),
            bannedUsers: t("bannedUsers"),
            newMessage: t("newMessage"),
            moderator: t("moderator"),
            download: t("download"),
            adminApprovalPending: t("adminApprovalPending"),
            approve: t("approve"),
            typeAMessage: t("typeAMessage"),
            joinChats: t("joinChats"),
            moderatorLogin: t("moderatorLogin"),
            errorUsernameNotSpecified: t("errorUsernameNotSpecified"),
            joinRoom: t("joinRoom"),
            alreadyHaveAnAccount: t("alreadyHaveAnAccount"),
            infoSuperAdmin: t("infoSuperAdmin"),
            infoModeratorLogin: t("infoModeratorLogin"),
            login: t("login"),
            setAUsername: t("setAUsername"),
            username: t("username"),
            yourEmail: t("yourEmail"),
            email: t("email"),
            password: t("password"),
            setSuperAdminUsername: t("setSuperAdminUsername"),
            superAdminUsernameMessage: t("superAdminUsernameMessage"),
            banUserHeading: t("banUserHeading"),
            banUserMessage: t("banUserMessage"),
            banUserConfirm: t("banUserConfirm"),
            banUserCancel: t("banUserCancel"),
            bannedUserHeading: t("bannedUserHeading"),
            bannedUserMessage: t("bannedUserMessage"),
            userBannedHeading: t("userBannedHeading"),
            userBannedMessage: t("userBannedMessage"),
            bannedIPs: t("bannedIPs"),
            add: t("add"),
            ipAddress: t("ipAddress"),
            unblock: t("unblock"),
            close: t("close"),
            addIPAddress: t("addIPAddress"),
            sendMessage: t("sendMessage"),
            languageCode: "ja",
          });
          await sdk.loadTranslation({
            resumeAutoScroll: t("resumeAutoScroll"),
            autoScrollPaused: t("autoScrollPaused"),
            editProfile: t("editProfile"),
            claimProfile: t("claimProfile"),
            verifyProfile: t("verifyProfile"),
            channels: t("channels"),
            unread: t("unread"),
            privateChats: t("privateChats"),
            onlineUsers: t("onlineUsers"),
            ban: t("ban"),
            loadMore: t("loadMore"),
            logout: t("logout"),
            bannedUsers: t("bannedUsers"),
            newMessage: t("newMessage"),
            moderator: t("moderator"),
            download: t("download"),
            adminApprovalPending: t("adminApprovalPending"),
            approve: t("approve"),
            typeAMessage: t("typeAMessage"),
            joinChats: t("joinChats"),
            moderatorLogin: t("moderatorLogin"),
            errorUsernameNotSpecified: t("errorUsernameNotSpecified"),
            joinRoom: t("joinRoom"),
            alreadyHaveAnAccount: t("alreadyHaveAnAccount"),
            infoSuperAdmin: t("infoSuperAdmin"),
            infoModeratorLogin: t("infoModeratorLogin"),
            login: t("login"),
            setAUsername: t("setAUsername"),
            username: t("username"),
            yourEmail: t("yourEmail"),
            email: t("email"),
            password: t("password"),
            setSuperAdminUsername: t("setSuperAdminUsername"),
            superAdminUsernameMessage: t("superAdminUsernameMessage"),
            banUserHeading: t("banUserHeading"),
            banUserMessage: t("banUserMessage"),
            banUserConfirm: t("banUserConfirm"),
            banUserCancel: t("banUserCancel"),
            bannedUserHeading: t("bannedUserHeading"),
            bannedUserMessage: t("bannedUserMessage"),
            userBannedHeading: t("userBannedHeading"),
            userBannedMessage: t("userBannedMessage"),
            bannedIPs: t("bannedIPs"),
            add: t("add"),
            ipAddress: t("ipAddress"),
            unblock: t("unblock"),
            close: t("close"),
            addIPAddress: t("addIPAddress"),
            sendMessage: t("sendMessage"),
            languageCode: "ja",
          });
        }}
      ></iframe>
    </div>
  );
};

export default DeadSimpleChat;

"use client";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeNavMenu,
  openNavMenu,
} from "@/fe-core/_redux/actions/menusActions";
import Icon from "../Icon/Icon";
import { navMenuStatusSelector } from "@/fe-core/_redux/selectors/menusSelectors";
import { menuStatus } from "@/fe-core/meta/types/menus";
import { toggleDrawer } from "@/fe-core/_redux/actions/drawerActions";
import SVGIcon from "../SVGIcon";

const MenuButton: FC = ({}) => {
  const dispatch = useDispatch();
  const toggleDrawerMenu = () => {
    dispatch(toggleDrawer());
  };

  return (
    <button
      className="  text-center transition-all w-8 md:w-11 md:min-w-[44px] md:h-11 h-8 flex md:justify-center items-center  "
      onClick={toggleDrawerMenu}
    >
      <SVGIcon
        icon="hamburger"
        className="block w-5 h-5 md:mx-auto text-tertiary/70 dark:text-tertiary-500"
      />
    </button>
  );
};

export default MenuButton;

import { IMAGE_SIZES } from '@/config/general'
import {
  ICasinoGameViewModel,
  ICasinoGroupGameViewModel,
} from '../interfaces/casino'
import { IFavoriteGameViewModel } from '../interfaces/user'

export enum casinoGamePlayModalTypes {
  SET_CASINO_GAME = 'SET_CASINO_GAME',
  REMOVE_CASINO_GAME = 'REMOVE_CASINO_GAME',
}

export type SetCasinoGameAction = {
  type: casinoGamePlayModalTypes.SET_CASINO_GAME
  payload:
    | ICasinoGroupGameViewModel
    | ICasinoGameViewModel
    | IFavoriteGameViewModel
}

export type RemoveCasinoGameAction = {
  type: casinoGamePlayModalTypes.REMOVE_CASINO_GAME
}

export type CasinoGamePlayModalActions =
  | SetCasinoGameAction
  | RemoveCasinoGameAction

export type CasinoGamePlayModalState = {
  game:
    | ICasinoGroupGameViewModel
    | ICasinoGameViewModel
    | IFavoriteGameViewModel
    | null
}

export type ImageSize = (typeof IMAGE_SIZES)[keyof typeof IMAGE_SIZES];

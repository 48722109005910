import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user/index";
import { IGetValidCountriesResponseViewModel } from "@/fe-core/meta/interfaces/user/register/validCountries";
import {
  getValidCountriesFailure,
  getValidCountriesSuccess,
} from "@/fe-core/_redux/actions/userActions/registerActions/validCountriesActions";

function* getValidCountriesSaga() {
  try {
    const response: IJsonResponse<IGetValidCountriesResponseViewModel> =
      yield fetch(`/api/user/validCountries`, {
        method: methodTypes.GET,
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IGetValidCountriesResponseViewModel =
          yield response.json();
        yield put(getValidCountriesSuccess(viewModel));
        break;
      default:
        throw new Error("Valid countries couldn't be fetched");
    }
  } catch (error) {
    yield put(getValidCountriesFailure(parseFrontendError(error)));
  }
}

function* validCountriesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.GET_VALID_COUNTRIES_REQUEST,
      getValidCountriesSaga
    ),
  ]);
}

export default validCountriesSaga;

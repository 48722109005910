import {
  userActionTypes,
  CashbackBalanceState,
  CashbackBalanceActions,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: CashbackBalanceState = {
  pending: false,
  data: null,
  status: null,
};

const cashbackReducer = (
  state: CashbackBalanceState = initialState,
  action: RootActions | CashbackBalanceActions
): CashbackBalanceState => {
  switch (action.type) {
    case userActionTypes.GET_CASHBACK_BALANCE_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_CASHBACK_BALANCE_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    case userActionTypes.GET_CASHBACK_BALANCE_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    case userActionTypes.CLEAR_CASHBACK_BALANCE:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default cashbackReducer;

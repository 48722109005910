import { IntlError, IntlErrorCode, useLocale } from "next-intl";
import * as Sentry from "@sentry/nextjs";

type FallbackProps = {
  error: IntlError;

  namespace: string;

  key: string;
};

let passed = [];
export function onError(error: IntlError) {
  return;
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.error(error);
  } else {
    // Other errors indicate a bug in the app and should be reported
    //@todo: report
    //reportToErrorTracking(error);
  }
}

export function getMessageFallback({ namespace, key, error }: FallbackProps) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  // console.error("custom IntlError 2", error);
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    //console.error(error);
    //reportMissingTranslation(namespace, key);
    // Sentry.captureMessage("Missing translation key", {
    //   tags: {
    //     section: "translations",
    //   },
    //   extra: { namespace, key },
    // });
    reportMissingTranslation(namespace, key);
    return `{{${namespace}.${key}}}`;
  } else {
    return `{{${path}}}`;
  }
}

function reportMissingTranslation(namespace: string, key: string) {
  if (passed.includes(`${namespace}.${key}`)) {
    return;
  }
  passed.push(`${namespace}.${key}`);

  if(process?.env?.NEXT_PUBLIC_REPORT_TRANSLATIONS === "YES") {


    fetch("/api/reportI18n", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({namespace, key}),
    })
        .then((response) => response.json())
        .then((data) => console.log("REPORTED", data)).catch((err => console.log("err--reportI18n", err)
    ));

    fetch("/api/reportI18n", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({namespace, key}),
    })
        .then((response) => response.json())
        .then((data) => console.log(data));
  }
}

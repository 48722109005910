import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import {
  getEMLanguageCode,
  NOT_LOGGED_IN_ERROR_STATUS,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import {
  IDepositRequestApiPayload,
  IDepositViewModel,
  IGetDepositMethodsResponse,
} from "@/fe-core/meta/interfaces/user";
import {
  DepositRequestAction,
  GetDepositMethodsRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  depositFailure,
  depositSuccess,
  getDepositMethodsFailure,
  getDepositMethodsSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { useSelector } from "react-redux";
import { userBalanceDataSelector } from "@/fe-core/_redux/selectors/userSelectors";
import { clearState } from "@/redux/actions/rootActions";

function* getDepositMethodsRequestSaga({ }: GetDepositMethodsRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/deposit`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId
        }),
      }
    );

    const { status } = response;
    const viewModel: IGetDepositMethodsResponse = yield response.json();
    if (response.status == 200) {
      yield put(getDepositMethodsSuccess(viewModel));
    } else {
      // @ts-ignore
      if (response.status === 401 || (response.status === 400 && viewModel?.status === NOT_LOGGED_IN_ERROR_STATUS)) {
        yield put(clearState());
      }
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getDepositMethodsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getDepositMethodsFailure(parseFrontendError(error)));
  }
}

function* depositSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([takeLatest(userActionTypes.GET_DEPOSIT_METHODS_REQUEST, getDepositMethodsRequestSaga)]);
}

export default depositSaga;

import { RootState } from "@/fe-core/meta/types/root";
import { supportState } from "@/fe-core/meta/types/support";

export const supportSelector = ({ support }: RootState): supportState => support;

export const isOpenSupportModalSelector = (
    state: RootState
): boolean => {
    const { isOpenSupportModal } = supportSelector(state);
    return isOpenSupportModal;
};

export const activeSupportTabSelector = (
    state: RootState
): number => {
    const { activeSupportTabIndex } = supportSelector(state);

    return activeSupportTabIndex;
};

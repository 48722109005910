import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import { sportsActionTypes } from "@/fe-core/meta/types/sports";
import {
  sportsFailure,
  sportsSuccess,
} from "@/fe-core/_redux/actions/sportsActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";

function* sportsRequestSaga() {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/sports#sports",
      { lang: "en", virtualStatus: "BOTH" }
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const sportsData = emResponse?.kwargs;

    yield put(sportsSuccess(sportsData.records));
  } catch (error) {
    yield put(sportsFailure(parseFrontendError(error)));
  }
}

function* sessionSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([takeLatest(sportsActionTypes.SPORTS_REQUEST, sportsRequestSaga)]);
}

export default sessionSaga;

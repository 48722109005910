import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  SignupActions,
  signupScreens,
  SignupState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: SignupState = {
  activeScreen: signupScreens.INITIAL_SCREEN,
  pending: false,
  showSignup: false,
  status: null,
  // @ts-ignore
  signUpPayload: {
    title: "Mr.",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    phoneNumber: "",
    userConsents: {
      termsandconditions: false,
      emailmarketing: false,
      sms: false,
      "3rdparty": false,
    },
    affiliateMarker: "",
    address: "",
    country: "",
    city: "",
  },
  usernameCheck: {
    pending: false,
    status: null,
  },
  checkCredential: {
    pending: false,
    status: null,
  },
  checkEmailCredential: {
    pending: false,
    status: null,
  },
  checkMobileCredential: {
    pending: false,
    status: null,
  },
};

const signupReducer = (
  state: SignupState = initialState,
  action: SignupActions | RootActions
): SignupState => {
  switch (action.type) {
    case userActionTypes.UPDATE_SIGNUP_PAYLOAD:
      return {
        ...state,
        signUpPayload: {
          ...state?.signUpPayload,
          [action.payload.propName as string]: action.payload.value,
        },
      };
    case userActionTypes.CLEAR_SIGNUP_PAYLOAD:
      return {
        ...state,
        signUpPayload: initialState?.signUpPayload,
      };
    case userActionTypes.CLEAR_SIGNUP_ERROR:
      return {
        ...state,
        status: null,
      };
    case userActionTypes.SET_SHOW_SIGNUP_MODAL:
      return {
        ...state,
        showSignup: true,
      };
    case userActionTypes.SET_HIDE_SIGNUP_MODAL:
      return {
        ...state,
        showSignup: false,
      };
    case userActionTypes.SET_ACTIVE_SIGNUP_SCREEN:
      return {
        ...state,
        activeScreen: action.payload,
      };
    case userActionTypes.SIGNUP_REQUEST:
    case userActionTypes.QUICK_SIGNUP_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.SIGNUP_USERNAME_CHECK_REQUEST:
      return {
        ...state,
        usernameCheck: {
          pending: true,
          status: null,
        },
      };
    case userActionTypes.SIGNUP_SUCCESS:
    case userActionTypes.QUICK_SIGNUP_SUCCESS:
      return {
        ...state,
        pending: false,
        status: {
          success: true,
          message: "User successfully signed up",
        },
      };
    case userActionTypes.SIGNUP_USERNAME_CHECK_SUCCESS:
      return {
        ...state,
        usernameCheck: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case userActionTypes.SIGNUP_FAILURE:
    case userActionTypes.QUICK_SIGNUP_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    case userActionTypes.SIGNUP_USERNAME_CHECK_FAILURE:
      const {
        payload: { message: failureMessage },
      } = action;
      return {
        ...state,
        usernameCheck: {
          pending: false,
          status: {
            success: false,
            message: failureMessage,
          },
        },
      };
    case userActionTypes.CHECK_CREDENTIAL_REQUEST:
      const { field } = action.payload;
      return {
        ...state,
        ...(field === "email" && {
          checkEmailCredential: {
            pending: true,
            status: null,
          },
        }),
        ...(field === "mobilePhone" && {
          checkMobileCredential: {
            pending: true,
            status: null,
          },
        }),
      };
    case userActionTypes.CHECK_CREDENTIAL_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmailCredential: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case userActionTypes.CHECK_CREDENTIAL_MOBILE_SUCCESS:
      return {
        ...state,
        checkMobileCredential: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case userActionTypes.CHECK_CREDENTIAL_EMAIL_FAILURE:
      return {
        ...state,
        checkEmailCredential: {
          pending: false,
          status: {
            success: false,
            message: action.payload?.message,
          },
        },
      };
    case userActionTypes.CHECK_CREDENTIAL_MOBILE_FAILURE:
      return {
        ...state,
        checkMobileCredential: {
          pending: false,
          status: {
            success: false,
            message: action.payload?.message,
          },
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default signupReducer;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  SignupEmailConfirmationState,
  SignupEmailConfirmationActions,
  SignupEmailActions,
} from "@/fe-core/meta/types/user";

const initialState: SignupEmailConfirmationState = {
  pending: false,
  status: null,
  resend: { pending: false, status: null },
};

const signupEmailConfirmationReducer = (
  state: SignupEmailConfirmationState = initialState,
  action: SignupEmailConfirmationActions | RootActions | SignupEmailActions
): SignupEmailConfirmationState => {
  switch (action.type) {
    case userActionTypes.SIGNUP_EMAIL_CONFIRMATION_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.SIGNUP_EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        pending: false,
        status: {
          success: true,
          message: "Email verified successfully",
        },
      };
    case userActionTypes.SIGNUP_EMAIL_CONFIRMATION_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    case userActionTypes.SIGNUP_RESEND_EMAIL_REQUEST:
      return {
        ...state,
        resend: { pending: true, status: null },
      };
    case userActionTypes.SIGNUP_RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        resend: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case userActionTypes.SIGNUP_RESEND_EMAIL_FAILURE:
      const {
        payload: { message: resendMessage },
      } = action;
      return {
        ...state,
        resend: {
          pending: false,
          status: {
            success: false,
            message: resendMessage,
          },
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    case userActionTypes.SIGNUP_EMAIL_CONFIRMATION_CLEAR_ERROR:
      return {
        ...state,
        status: null
      };
    default:
      return {
        ...state,
      };
  }
};

export default signupEmailConfirmationReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { promotionContentActionTypes } from "@/fe-core/meta/types/promotionContent";
import { IPromotionContentResponseViewModel } from "@/fe-core/meta/interfaces/promotionContent";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getPromotionContentFailure,
  getPromotionContentSuccess,
} from "@/fe-core/_redux/actions/promotionContentActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildPromotionQueryString } from "@/fe-core/helpers/promotionContent";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { themeActionTypes } from "@/fe-core/meta/types/theme";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { isUserAuthenticatedSelector } from "../selectors/sessionSelectors";

function* getPromotionContentSaga() {
  try {
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);
    const isAuthenticated: boolean = yield select(isUserAuthenticatedSelector);

    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);
    const queryString: string = buildPromotionQueryString(
      theme,
      btag ? [btag] : [],
      isAuthenticated,
    );

    const response: IJsonResponse<IPromotionContentResponseViewModel[]> =
      yield fetch(`/api/promotionContent${queryString}`, {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPromotionContentResponseViewModel =
          yield response.json();
        yield put(getPromotionContentSuccess(viewModel));
        break;
      default:
        throw new Error("Promotion Content couldn't be fetched");
    }
  } catch (error) {
    yield put(getPromotionContentFailure(parseFrontendError(error)));
  }
}

function* promotionContentSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      promotionContentActionTypes.GET_PROMOTION_CONTENT_REQUEST,
      getPromotionContentSaga
    ),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getPromotionContentSaga),
    takeLatest(themeActionTypes.SET_CURRENT_THEME, getPromotionContentSaga),
  ]);
}

export default promotionContentSaga;

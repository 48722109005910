import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import depositBonusesSaga from "./_depositBonusesSaga_nwa";
import depositSaga from "./_depositSaga_nwa";
import depositSagaPaymentiq from "./_depositSaga_paymentiq";
import pendingWithdrawalsSaga from "./_pendingWithdrawalsSaga_nwa";
import transactionHistorySaga from "./_transactionHistorySaga_nwa";
import withdrawSaga from "./_withdrawSaga_nwa";
import withdrawSagaPaymentiq from "./_withdrawSaga_paymentiq";
import processDepositSaga from "./processDepositSaga";
import transactionSummarySaga from "./transactionSummarySaga";
import prepareWithdrawSaga from "./prepareWithdrawSaga";

function* cashierSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    fork(depositBonusesSaga),
    ...(process.env.NEXT_PUBLIC_CASHIER === "PAYMENTIQ"
      ? [fork(depositSagaPaymentiq)]
      : [fork(depositSaga)]),
    ...(process.env.NEXT_PUBLIC_CASHIER === "PAYMENTIQ"
      ? [fork(withdrawSagaPaymentiq)]
      : [fork(withdrawSaga)]),
    fork(pendingWithdrawalsSaga),
    fork(prepareWithdrawSaga),
    fork(processDepositSaga),
    fork(transactionHistorySaga),
    fork(transactionSummarySaga),
  ]);
}

export default cashierSaga;

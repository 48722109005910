import { smarticoSelector } from ".";
import { RootState } from "@/fe-core/meta/types/root";
import { SmarticoPublicPropsState } from "@/fe-core/meta/types/smartico";
import { ISmarticoPublicPropsModel } from "@/fe-core/meta/interfaces/smartico/publicProps";

export const smarticoPublicPropsSelector = (state: RootState): SmarticoPublicPropsState => {
    const { publicProps } = smarticoSelector(state);
    return publicProps;
};

export const smarticoAvatarSelector = (
    state: RootState
): string | null => {
    const { avatar_id } = smarticoPublicPropsSelector(state) || {};
    return avatar_id;
};

export const smarticoUnreadInboxSelector = (
    state: RootState
): number | null => {
    const { core_inbox_unread_count } = smarticoPublicPropsSelector(state) || {};

    return core_inbox_unread_count;
};
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  SessionActions,
  sessionActionTypes,
  SessionState,
} from "@/fe-core/meta/types/session";
import { ceSessionActionTypes } from "@/fe-core/meta/types/ceSession";
import { MenuActions, menuActionTypes } from "@/fe-core/meta/types/menus";

const initialState: SessionState = {
  data: {
    currency: {
      code: "USD",
      fiat: true,
      fractionDigits: 4,
      symbol: "$",
    },
    locale: "en",
    theme: "Dark",
  },
  login: {
    pending: false,
    status: null,
  },
  prepareLogin: {
    pending: false,
    status: null,
    data: {}
  },
  authenticate: {
    pending: true,
    status: null,
    isUserIdentified: null
  },
  ceSession: {
    pending: false,
    status: null,
    ceSessionId: null,
  },
  logout: {
    pending: false,
    status: null,
  },
  isFirstLogin: false,
};

const sessionReducer = (
  state: SessionState = initialState,
  action: SessionActions | RootActions | MenuActions
): SessionState => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: action.payload
      };
    case sessionActionTypes.PREPARE_LOGIN_REQUEST:
      return {
        ...state,
        prepareLogin: {
          pending: true,
          status: null,
        },
      };
    case sessionActionTypes.PREPARE_LOGIN_SUCCESS:
      const {
        payload: { status, data },
      } = action;
      return {
        ...state,
        prepareLogin: {
          pending: false,
          status: {
            success: true,
            code: status
          },
          data
        },
      };
    case sessionActionTypes.PREPARE_LOGIN_FAILURE: {
      const {
        payload: { message, code },
      } = action;
      return {
        ...state,
        prepareLogin: {
          pending: false,
          status: {
            success: false,
            message,
            code
          },
        },
      };
    }

    case sessionActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          pending: true,
          status: null,
        },
      };
    case sessionActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case sessionActionTypes.LOGIN_FAILURE: {
      const {
        payload: { message, status },
      } = action;
      return {
        ...state,
        login: {
          pending: false,
          status: {
            success: false,
            code: status,
            message,
          },
        },
      };
    }
    case sessionActionTypes.SESSION_INFO_SUCCESS: {
      const { payload } = action;
      if (state.data) {
        return {
          ...state,
          data: {
            sessionId: state.data.sessionId,
            ...payload,
          },
        };
      }
      return {
        ...state,
      };
    }
    case sessionActionTypes.AUTHENTICATE_REQUEST:
      return {
        ...state,
        authenticate: {
          pending: true,
          status: null,
          isUserIdentified: false
        },
      };
    case sessionActionTypes.CLOSE_SESSION_EXPIRED_MODAL:
      return {
        ...state,
        authenticate: {
          pending: false,
          status: null,
          isUserIdentified: true
        },
      };
    case sessionActionTypes.AUTHENTICATE_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        data: payload,
        login: {
          pending: false,
          status: {
            code: payload?.status,
            success: true,
          },
          //status: payload?.status
          // status: {
          //   success: true,
          // },
        },
        authenticate: {
          pending: false,
          status: {
            code: payload?.status,
            success: true,
          },
          isUserIdentified: true
        },
      };
    }
    case sessionActionTypes.AUTHENTICATE_FAILURE: {
      const {
        payload
      } = action;
      return {
        ...state,
        authenticate: {
          pending: false,
          status: {
            code: payload?.status,
            success: false,
          },
          isUserIdentified: true
        },
        login: {
          pending: false,
          status: null,
        }
      };
    }
    case ceSessionActionTypes.GET_CE_SESSION_REQUEST:
      return {
        ...state,
        ceSession: {
          pending: true,
          status: null,
          ceSessionId: null,
        },
      };
    case ceSessionActionTypes.GET_CE_SESSION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        ceSession: {
          pending: false,
          ceSessionId: payload.ceSession,
          status: {
            success: true,
          },
        },
      };
    }
    case ceSessionActionTypes.GET_CE_SESSION_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        ceSession: {
          pending: false,
          ceSessionId: null,
          status: {
            success: false,
            message,
          },
        },
      };
    }
    case sessionActionTypes.ANONYMOUS_SESSION_REQUEST:
      return {
        ...state,
        authenticate: {
          pending: true,
          status: null,
          isUserIdentified: false
        },
      };
    case sessionActionTypes.ANONYMOUS_SESSION_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        data: payload,
        authenticate: {
          pending: false,
          status: null,
          isUserIdentified: true
        },
      };
    }
    case sessionActionTypes.ANONYMOUS_SESSION_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        authenticate: {
          pending: false,
          status: {
            success: false,
            message,
          },
          isUserIdentified: true
        },
      };
    }
    case sessionActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logout: {
          pending: true,
          status: null,
        },
      };
    case sessionActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        data: null,
        logout: {
          pending: false,
          status: {
            success: true,
          },
        },
      };
    case sessionActionTypes.LOGOUT_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        logout: {
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }
    case menuActionTypes.CLOSE_LOGIN_MENU:
      return {
        ...state,
        login: {
          pending: false,
          status: null,
        },
      }
    case sessionActionTypes.CLEAR_SESSION_STATE: {
      return {
        ...initialState,
      };
    }
    case sessionActionTypes.CLEAR_LOGIN_STATE: {
      return {
        ...state,
        login: {
          pending: false,
          status: null,
        },
      };
    }
    case sessionActionTypes.CLEAR_PREPARE_LOGIN_STATE: {
      return {
        ...state,
        prepareLogin: {
          pending: false,
          status: null,
          data: null
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
        data: {
          currency: {
            code: "USD",
            fiat: true,
            fractionDigits: 4,
            symbol: "$",
          },
          locale: "en",
          theme: "Dark",
        },
        logout: {
          pending: false,
          status: {
            success: true,
          },
        },
        authenticate: {
          ...initialState.authenticate,
          isUserIdentified: true
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default sessionReducer;

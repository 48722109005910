import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root"
import {
  CampaignActions,
  CampaignsState,
  userActionTypes,
} from "@/fe-core/meta/types/user"


const initialState: CampaignsState = {
  pending: false,
  data: null,
  status: null,
}

const campaignsReducer = (
  state: CampaignsState = initialState,
  action: CampaignActions | RootActions
): CampaignsState => {

  switch (action.type) {
    case userActionTypes.GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      }
    case userActionTypes.GET_CAMPAIGNS_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      }
    }
    case userActionTypes.GET_CAMPAIGNS_FAILURE: {
      const {
        payload: { message },
      } = action
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      }
    }
    case userActionTypes.SET_CAMPAIGN_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      }
    case userActionTypes.SET_CAMPAIGN_SUCCESS: {
      const { payload } = action;

      const existingCampaigns = Array.isArray(state?.data?.campaigns) ? state?.data?.campaigns : [];

      return {
        ...state,
        pending: false,
        data: { campaigns: [...existingCampaigns, payload] },
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.SET_CAMPAIGN_FAILURE: {
      const {
        payload: { message },
      } = action
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      }
    }
    case userActionTypes.CLEAR_CAMPAIGNS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}

export default campaignsReducer

import { ICasinoGameViewModel } from '../interfaces/casino'
import {
  IBottomPromotionPayload,
  IBottomPromotionsResponsePayload,
  IPromotionGamesRequestPayload,
  IPromotionGamesSuccessPayload,
  IPromotionPayload,
  IPromotionViewModel,
  IPromotionsCategoryViewModel,
  IPromotionsPayload,
  IPromotionsViewModel,
} from '../interfaces/promotions'
import { IErrorPayload, IStatus } from '../interfaces/root'

export enum promotionsActionTypes {
  GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST",
  GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS",
  GET_PROMOTIONS_FAILURE = "GET_PROMOTIONS_FAILURE",
  GET_PROMOTION_REQUEST = "GET_PROMOTION_REQUEST",
  GET_PROMOTION_SUCCESS = "GET_PROMOTION_SUCCESS",
  GET_PROMOTION_FAILURE = "GET_PROMOTION_FAILURE",
  GET_BOTTOM_PROMOTIONS_REQUEST = "GET_BOTTOM_PROMOTIONS_REQUEST",
  GET_BOTTOM_PROMOTIONS_SUCCESS = "GET_BOTTOM_PROMOTIONS_SUCCESS",
  GET_BOTTOM_PROMOTIONS_FAILURE = "GET_BOTTOM_PROMOTIONS_FAILURE",

  GET_PROMOTION_GAMES_REQUEST = "GET_PROMOTION_GAMES_REQUEST",
  GET_PROMOTION_GAMES_SUCCESS = "GET_PROMOTION_GAMES_SUCCESS",
  GET_PROMOTION_GAMES_FAILURE = "GET_PROMOTION_GAMES_FAILURE",
}

export type GetPromotionsRequestAction = {
  type: promotionsActionTypes.GET_PROMOTIONS_REQUEST
  payload: IPromotionsPayload
}

export type GetPromotionsSuccessAction = {
  type: promotionsActionTypes.GET_PROMOTIONS_SUCCESS
  payload: IPromotionsViewModel
}

export type GetPromotionsFailureAction = {
  type: promotionsActionTypes.GET_PROMOTIONS_FAILURE
  payload: IErrorPayload
}

export type GetPromotionRequestAction = {
  type: promotionsActionTypes.GET_PROMOTION_REQUEST
  payload: IPromotionPayload
}

export type GetPromotionSuccessAction = {
  type: promotionsActionTypes.GET_PROMOTION_SUCCESS
  payload: IPromotionViewModel
}

export type GetPromotionFailureAction = {
  type: promotionsActionTypes.GET_PROMOTION_FAILURE
  payload: IErrorPayload
}

export type GetBottomPromotionRequestAction = {
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_REQUEST
  payload: IBottomPromotionPayload
}

export type GetBottomPromotionSuccessAction = {
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_SUCCESS
  payload: IBottomPromotionsResponsePayload
}

export type GetBottomPromotionFailureAction = {
  type: promotionsActionTypes.GET_BOTTOM_PROMOTIONS_FAILURE
  payload: IErrorPayload
}

export type GetPromotionGamesRequestAction = {
  type: promotionsActionTypes.GET_PROMOTION_GAMES_REQUEST;
  payload: IPromotionGamesRequestPayload;
};

export type GetPromotionGamesSuccessAction = {
  type: promotionsActionTypes.GET_PROMOTION_GAMES_SUCCESS;
  payload: IPromotionGamesSuccessPayload;
};

export type GetPromotionGamesFailureAction = {
  type: promotionsActionTypes.GET_PROMOTION_GAMES_FAILURE;
  payload: string;
};


export type PromotionsActions =
  | GetPromotionsRequestAction
  | GetPromotionsSuccessAction
  | GetPromotionsFailureAction
  | GetPromotionRequestAction
  | GetPromotionSuccessAction
  | GetPromotionFailureAction
  | GetBottomPromotionRequestAction
  | GetBottomPromotionSuccessAction
  | GetBottomPromotionFailureAction
  | GetPromotionGamesRequestAction
  | GetPromotionGamesSuccessAction
  | GetPromotionGamesFailureAction;

export type PromotionsState = {
  pending: boolean
  data: IPromotionsViewModel | null
  categories: IPromotionsCategoryViewModel[] | null
  status: IStatus | null

  pendingBottomPromotion: boolean
  bottomPromotionData: IBottomPromotionsResponsePayload | null
  bottomPromotionStatus: IStatus | null

  promotionGames: {
    pending: { [key: string]: boolean },
    games: { [key: string]: ICasinoGameViewModel[] | null },
  }
}

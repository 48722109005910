import { all, AllEffect, fork, ForkEffect } from "redux-saga/effects";
import paymentIqTransactionHistorySaga from "./paymentIq/transactionHistorySaga";

function* externalCashierSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  if (process.env.NEXT_PUBLIC_CASHIER === "PAYMENTIQ") {
    yield all([fork(paymentIqTransactionHistorySaga)]);
  }
}

export default externalCashierSaga;



import { headerKeys } from "@/config/general";
import { getBalanceRequest } from "@/fe-core/_redux/actions/userActions";
import {
  clearProcessDepositMessage,
  processDepositFailure,
  processDepositSuccess,
} from "@/fe-core/_redux/actions/userActions/cashierActions/processDepositActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import {
  NOT_LOGGED_IN_ERROR_STATUS,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IProcessDepositResponsePayload } from "@/fe-core/meta/interfaces/user/cashier/processDeposit";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { depositPaymentMethodTypes, ProcessDepositRequestAction } from "@/fe-core/meta/types/user/cashier/processDeposit";
import { clearState } from "@/redux/actions/rootActions";
import {
  all,
  AllEffect,
  delay,
  fork,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

function* clearDepositMessageSaga(interval: number) {
  yield delay(interval);
  yield put(clearProcessDepositMessage());
}

function* processDepositRequestSaga({ payload }: ProcessDepositRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const isPraxisCall = payload?.paymentMethod === depositPaymentMethodTypes.PRAXIS_ALL;
    const { requestedBonusPlanId, ...restPayload } = payload || {};
    const bonusKey = isPraxisCall ? "bonusPlanId" : "requestedBonusPlanId";
    const newPayload = { ...restPayload, [bonusKey]: requestedBonusPlanId };

    const response: IJsonResponse<{ status: number }> = yield fetch(
      isPraxisCall
        ? `/api/user/cashier/prepareCashier`
        : `/api/user/cashier/processDeposit`,
      <RequestInit>{
        method: methodTypes.POST,
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          ...(sessionId ? { [headerKeys.SESSION_ID]: sessionId } : {}),
        }),
        body: JSON.stringify(newPayload),
      }
    );

    const { status } = response;
    const responsePayload: IProcessDepositResponsePayload | IErrorPayload =
      yield response.json();

    const viewModel = responsePayload as IProcessDepositResponsePayload;

    if (status == 200) {
      yield put(processDepositSuccess(viewModel));
      yield put(getBalanceRequest());
    } else {
      if (
        status === 401 ||
        (status === 400 && viewModel?.status === NOT_LOGGED_IN_ERROR_STATUS)
      ) {
        yield put(clearState());
      }

      const errorPayload = responsePayload as IErrorPayload;
      yield put(processDepositFailure(errorPayload));
    }
    yield fork(clearDepositMessageSaga, 5000);
  } catch (error) {
    yield put(processDepositFailure(parseFrontendError(error)));
    yield fork(clearDepositMessageSaga, 5000);
  }
}

function* processDepositSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.PROCESS_DEPOSIT_REQUEST,
      processDepositRequestSaga
    ),
  ]);
}

export default processDepositSaga;

import {
  userActionTypes,
  BalanceState,
  UserActions,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: BalanceState = {
  pending: false,
  data: null,
  status: null,
};

const balanceReducer = (
  state: BalanceState = initialState,
  action: UserActions | RootActions
): BalanceState => {
  switch (action.type) {
    case userActionTypes.GET_BALANCE_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_BALANCE_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    case userActionTypes.GET_BALANCE_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    case userActionTypes.CLEAR_BALANCE:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    case userActionTypes.BALANCE_UPDATE:
      if (state.data) {
        const realAfterAmount = action?.payload?.Real?.afterAmount;
        const bonusAfterAmount = action?.payload?.Bonus?.afterAmount;
        return {
          ...state,
          data: {
            ...state.data,
            total: (realAfterAmount !== undefined && realAfterAmount !== null ? Number(realAfterAmount) : Number(state.data.balance)) +
              (bonusAfterAmount !== undefined && bonusAfterAmount !== null ? Number(bonusAfterAmount) : Number(state.data.bonus)),
            balance: realAfterAmount !== undefined && realAfterAmount !== null ? realAfterAmount : state.data.balance,
            bonus: bonusAfterAmount !== undefined && bonusAfterAmount !== null ? bonusAfterAmount : state.data.bonus,
          },
        };
      }

    default:
      return {
        ...state,
      };
  }
};

export default balanceReducer;

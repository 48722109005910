import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import { IValidateCnpResponsePayload } from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateCnpRequestAction,
} from "@/fe-core/meta/types/user";
import {
  validateCnpFailure,
  validateCnpSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";

function* validateCnpRequestSaga({ payload }: ValidateCnpRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/account#isPersonalIDRegistered",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const validateCnpData: IValidateCnpResponsePayload = emResponse?.kwargs;

    yield put(
      validateCnpSuccess({ isAvailable: !validateCnpData.isRegistered })
    );
  } catch (error) {
    yield put(validateCnpFailure(parseFrontendError(error)));
  }
}

function* validateCnpSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.VALIDATE_CNP_REQUEST, validateCnpRequestSaga),
  ]);
}

export default validateCnpSaga;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { paymentMethodsActionTypes } from "@/fe-core/meta/types/paymentMethods";
import { IPaymentMethodsViewModel } from "@/fe-core/meta/interfaces/paymentMethods";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
} from "@/fe-core/_redux/actions/paymentMethodsActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildPaymentMethodsQueryString } from "@/fe-core/helpers/paymentMethods";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { themeActionTypes } from "@/fe-core/meta/types/theme";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getPaymentMethodsSaga() {
  try {
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);

    const queryString: string = buildPaymentMethodsQueryString(theme);

    const response: IJsonResponse<IPaymentMethodsViewModel[]> = yield fetch(
      `/api/payment-method${queryString}`,
      {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPaymentMethodsViewModel = yield response.json();
        yield put(getPaymentMethodsSuccess(viewModel));
        break;
      default:
        throw new Error("Payment Methods couldn't be fetched");
    }
  } catch (error) {
    yield put(getPaymentMethodsFailure(parseFrontendError(error)));
  }
}

function* paymentMethodsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      paymentMethodsActionTypes.GET_PAYMENT_METHODS_REQUEST,
      getPaymentMethodsSaga
    ),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getPaymentMethodsSaga),
    takeLatest(themeActionTypes.GET_CURRENT_THEME, getPaymentMethodsSaga),
  ]);
}

export default paymentMethodsSaga;

import {
  DrawerState,
  closeDrawerAction,
  drawerActionTypes,
  initDrawerAction,
  toggleDrawerAction,
} from "@/fe-core/meta/types/drawer";

export const initDrawer = (payload: DrawerState): initDrawerAction => ({
  type: drawerActionTypes.INIT_DRAWER,
  payload,
});
export const toggleDrawer = (): toggleDrawerAction => ({
  type: drawerActionTypes.TOGGLE_DRAWER,
});
export const closeDrawer = (): closeDrawerAction => ({
  type: drawerActionTypes.CLOSE_DRAWER,
});
import {
  RecentWinnersActions,
  recentWinnersActionTypes,
  RecentWinnersState,
} from "@/fe-core/meta/types/recentWinners";

const initialState: RecentWinnersState = {
  recentWinners: [],
  pending: false,
  status: null,
};

const recentWinnersReducer = (
  state: RecentWinnersState = initialState,
  action: RecentWinnersActions
): RecentWinnersState => {
  switch (action.type) {
    case recentWinnersActionTypes.GET_RECENT_WINNERS_REQUEST:
      return {
        ...state,
        pending: true,
        recentWinners: [],
        status: null,
      };
    case recentWinnersActionTypes.GET_RECENT_WINNERS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        recentWinners: payload,
        status: {
          success: true,
        },
      };
    }
    case recentWinnersActionTypes.GET_RECENT_WINNERS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        recentWinners: [],
        status: {
          success: false,
          message,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default recentWinnersReducer;

export function buildRegistrationQueryString(
    btagCodes: string[],
    theme: string
): string {
    const queryParams = new URLSearchParams();
    queryParams.append("btagCodes", btagCodes?.join(","));
    queryParams.append("theme", theme);
    const queryString = queryParams.toString();
    return queryString ? `?${queryString}` : "";
}

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  ICancelPendingWithdrawalViewModel,
  IPendingWithdrawalResponseViewModel
} from "@/fe-core/meta/interfaces/user";
import { CancelPendingWithdrawalRequestAction, userActionTypes } from "@/fe-core/meta/types/user";
import {
  cancelPendingWithdrawalFailure,
  cancelPendingWithdrawalSuccess,
  pendingWithdrawalsFailure,
  pendingWithdrawalsSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { headerKeys } from "@/config/general";
import { sessionActionTypes } from "@/fe-core/meta/types/session";

function* pendingWithdrawalsRequestSaga() {
  try {

    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/pendingWithdraw`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const pendingWithdrawalsViewModel: IPendingWithdrawalResponseViewModel = yield response.json();
        yield put(pendingWithdrawalsSuccess(pendingWithdrawalsViewModel?.withdrawals));
        break;
      default:
        throw new Error("Could init cashier");
    }

  } catch (error) {
    yield put(pendingWithdrawalsFailure(parseFrontendError(error)));
  }
}

function* cancelPendingWithdrawalRequestSaga({ payload }: CancelPendingWithdrawalRequestAction) {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/pendingWithdraw/cancel`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
          "paymentId": payload,
        }),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const cancelPendingWithdrawalViewModel: ICancelPendingWithdrawalViewModel = yield response.json();
        yield put(cancelPendingWithdrawalSuccess(cancelPendingWithdrawalViewModel));
        break;
      default:
        throw new Error("Could init cashier");
    }
  } catch (error) {
    yield put(cancelPendingWithdrawalFailure(parseFrontendError(error)));
  }
}

function* pendingWithdrawalsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      [userActionTypes.PENDING_WITHDRAWALS_REQUEST, userActionTypes.CANCEL_PENDING_WITHDRAWAL_SUCCESS, userActionTypes.PROCESS_WITHDRAW_REQUEST_SUCCESS, sessionActionTypes.AUTHENTICATE_SUCCESS],
      pendingWithdrawalsRequestSaga
    ),
  ]);
  yield all([
    takeLatest(
      [userActionTypes.CANCEL_PENDING_WITHDRAWAL_REQUEST],
      cancelPendingWithdrawalRequestSaga
    ),
  ]);
}

export default pendingWithdrawalsSaga;

import {
  DrawerActions,
  DrawerState,
  drawerActionTypes,
} from "@/fe-core/meta/types/drawer";
import { DEFAULT_DRAWER_OPEN } from "@/config/theme";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";

const initialState: DrawerState = {
  isDrawerOpen: !!DEFAULT_DRAWER_OPEN,
  isInitial: true,
  isMobile: true,
  isTablet: true,
};

const drawerReducer = (
  state: DrawerState = initialState,
  action: DrawerActions
): DrawerState => {
  switch (action.type) {
    case drawerActionTypes.INIT_DRAWER:
      const isPhoneOrTablet = action.payload.isMobile || action.payload.isTablet;
      const isForceFullyValue = action.payload.isForceFullyValue;
      const hasDrawerEntry = localStorage.getItem(localStorageKeys.IS_DRAWER_OPEN);
      let mainValue = !!(hasDrawerEntry === "true");
      if ((!Boolean(hasDrawerEntry) && !isPhoneOrTablet)) {
        mainValue = !!DEFAULT_DRAWER_OPEN;
        localStorage.setItem(localStorageKeys.IS_DRAWER_OPEN, DEFAULT_DRAWER_OPEN?.toString())
      } else if ((!Boolean(hasDrawerEntry) && isPhoneOrTablet) || isPhoneOrTablet) {
        mainValue = false;
        localStorage.setItem(localStorageKeys.IS_DRAWER_OPEN, "false")
      } else if (Boolean(hasDrawerEntry)) {
        mainValue = mainValue;
        localStorage.setItem(localStorageKeys.IS_DRAWER_OPEN, mainValue?.toString())
      }
      return {
        ...state,
        isDrawerOpen: isForceFullyValue !== undefined ? isForceFullyValue : isPhoneOrTablet ? false : !!mainValue,
      };
    case drawerActionTypes.TOGGLE_DRAWER:
      localStorage.setItem(localStorageKeys.IS_DRAWER_OPEN, (!state.isDrawerOpen).toString());
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
        isInitial: false,
      };
    case drawerActionTypes.CLOSE_DRAWER:
      localStorage.setItem(localStorageKeys.IS_DRAWER_OPEN, "false");
      return {
        ...state,
        isDrawerOpen: false,
        isInitial: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default drawerReducer;

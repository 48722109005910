import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loginMenuScreenSelector,
  loginMenuStatusSelector,
} from "../../../fe-core-omega/_redux/selectors/menusSelectors";

import {
  closeLoginMenu,
  openLoginMenu,
  setLoginMenuScreen,
} from "../../../fe-core-omega/_redux/actions/menusActions";

import LoginScreen from "./LoginScreen";
import ForgotPasswordScreen from "./ForgotPasswordScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";
import PasswordConfirmationScreen from "./PasswordConfirmationScreen";
import { loginMenuScreens } from "../../../fe-core-omega/meta/types/menus";
import Modal from "../../Modal";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Transition } from "@headlessui/react";
import EmailConfirmationScreen from "@/components/Signup/EmailConfirmationScreen";
import VerifyEmailInputScreen from "./VerifyEmailInputScreen";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "@/fe-core/_redux/selectors/registrationContentSelectors";
import { useScreen } from "../../../context/ScreenContext";
import { IImageAttributesViewModel } from "@/fe-core/meta/interfaces/root";
import Image from "next/image";
import { clearLoginState } from "@/fe-core/_redux/actions/sessionActions";
import { EMAIL_CHECK_REGEX, storageKeys } from "@/config/general";
import {
  isUserAuthenticatedSelector,
  sessionLoginPendingSelector,
} from "@/fe-core/_redux/selectors/sessionSelectors";
import { useRouter } from "../../../navigation";
import { CookiesStorage } from "@/fe-core/helpers/storage";

const LoginMenu: FC = () => {
  const { isMobile } = useScreen();
  const dispatch = useDispatch();
  const router = useRouter();
  const loginMenuStatus = useSelector(loginMenuStatusSelector);
  const activeScreen = useSelector(loginMenuScreenSelector);
  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  const loginPending = useSelector(sessionLoginPendingSelector);
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);

  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const clearPayload = () => {
    setEmail("");
    setUsername("");
    setPassword("");
  };

  const handleLoginMenuClick = () => {
    dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
    dispatch(closeLoginMenu());
    clearPayload();
  };

  const leftBannerImg = useMemo(() => {
    const attributes =
      registrationContent && registrationContent[0]?.attributes;
    const leftBannerImg = isMobile
      ? attributes
        ? (attributes?.mobileImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined
      : attributes
      ? (attributes?.desktopImage?.data
          ?.attributes as IImageAttributesViewModel)
      : undefined;
    return leftBannerImg;
  }, [isMobile, registrationContent]);

  useEffect(() => {
    if (activeScreen === loginMenuScreens.VERIFY_EMAIL_INPUT) {
      if (EMAIL_CHECK_REGEX.test(username)) {
        setEmail(username);
        setUsername("");
      }
    }

    if (activeScreen === loginMenuScreens.LOGIN) {
      clearPayload();
      dispatch(clearLoginState());
    }
  }, [activeScreen]);

  useEffect(() => {
    const returnUrl = CookiesStorage.getItem(storageKeys.RETURN_URL);
    if (isAuthenticated && !loginPending && returnUrl) {
      return router.push(returnUrl);
    }
    if (!isAuthenticated && !loginPending && returnUrl) {
      dispatch(openLoginMenu());
    }
  }, [isAuthenticated, loginPending]);

  return (
    <Modal
      className="!p-0 overflow-auto rounded-none sm:rounded max-w-full sm:max-w-[calc(100dvw-64px)] lg:max-w-[760px] "
      onClose={handleLoginMenuClick}
      size="large"
      isOpen={loginMenuStatus === "open"}
    >
      <Transition show={activeScreen === loginMenuScreens.LOGIN}>
        <LoginScreen
          username={username}
          password={password}
          setUsername={setUsername}
          setPassword={setPassword}
        />
      </Transition>
      <Transition show={activeScreen === loginMenuScreens.FORGOT_PASSWORD}>
        <GoogleReCaptchaProvider
          reCaptchaKey={(process.env.NEXT_PUBLIC_CAPTCHA_KEY as string) || ""}
        >
          <ForgotPasswordScreen email={email} setEmail={setEmail} />
        </GoogleReCaptchaProvider>
      </Transition>
      <Transition show={activeScreen === loginMenuScreens.RESET_PASSWORD}>
        <ResetPasswordScreen />
      </Transition>
      <Transition show={activeScreen === loginMenuScreens.VERIFY_EMAIL_INPUT}>
        <VerifyEmailInputScreen
          email={email}
          setEmail={setEmail}
          username={username}
          setUsername={setUsername}
        />
      </Transition>
      <Transition
        show={
          activeScreen === loginMenuScreens.VERIFY_EMAIL ||
          activeScreen === loginMenuScreens.PASSWORD_CONFIRMATION
        }
      >
        <div className="grid h-screen grid-cols-1 md:grid-cols-2 sm:h-auto">
          <div className="relative hidden md:block">
            {isPendingRegistrationContent ? (
              <Image
                src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
                objectFit="cover"
                fill
                alt="reg-banner"
                className="w-full h-full"
                loading="lazy"
              />
            ) : (
              <Image
                src={
                  leftBannerImg
                    ? leftBannerImg.url
                    : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
                }
                objectFit="cover"
                fill
                alt={
                  leftBannerImg ? leftBannerImg?.name : "verify email banner"
                }
                className="w-full h-full"
                loading="lazy"
              />
            )}
          </div>
          <div className="relative flex flex-col justify-between gap-10 p-7 lg:p-8 pt-16 sm:pt-8 min-h-[640px] h-full dark:bg-tertiary-600">
            {activeScreen === loginMenuScreens.VERIFY_EMAIL && (
              <EmailConfirmationScreen
                payload={{ email, username, password }}
              />
            )}
            {activeScreen === loginMenuScreens.PASSWORD_CONFIRMATION && (
              <PasswordConfirmationScreen email={email} />
            )}
          </div>
        </div>
      </Transition>
    </Modal>
  );
};

export default LoginMenu;

import {
  userActionTypes,
  UserActions,
  BalanceWarningState,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: BalanceWarningState = {
  showBalanceWarning: true,
};

const balanceWarningReducer = (
  state: BalanceWarningState = initialState,
  action: UserActions | RootActions
): BalanceWarningState => {
  switch (action.type) {
    case userActionTypes.SET_BALANCE_WARNING:
      const { payload } = action;
      return {
        ...state,
        showBalanceWarning: payload.showBalanceWarning,
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default balanceWarningReducer;

"use client";

import { FC, useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { resetPasswordRequest } from "../../../fe-core-omega/_redux/actions/userActions";
import { loginMenuScreens } from "../../../fe-core-omega/meta/types/menus";
import {
  openLoginMenu,
  closeLoginMenu,
  setLoginMenuScreen,
} from "../../../fe-core-omega/_redux/actions/menusActions";
import {
  userPasswordRuleSelector,
  userResetPasswordIsKeyAvailableSelector,
  userResetPasswordPendingSelector,
  userResetPasswordStatusSelector,
} from "../../../fe-core-omega/_redux/selectors/userSelectors";
import {
  validateAccountInformationConfirmPassword,
  validateAccountInformationPassword,
} from "../../../fe-core-omega/helpers/general";
import { useScreen } from "../../../context/ScreenContext";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { IPasswordPolicyViewModel } from "../../../fe-core-omega/meta/interfaces/user";
import { socketSessionSelector } from "../../../fe-core-omega/_redux/selectors/socketSelectors";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "../../../fe-core-omega/_redux/selectors/registrationContentSelectors";
import { IImageAttributesViewModel } from "../../../fe-core-omega/meta/interfaces/root";
import { PASSWORD_TEST_REGEX } from "@/config/general";
import useTracking from "@/components/Hooks/useTracking";

const ForgotPasswordScreen: FC = () => {
  const query = useSearchParams() as URLSearchParams;
  const dispatch = useDispatch();
  const { click } = useTracking();
  const { isMobile } = useScreen();
  const router = useRouter();
  const t = useTranslations(I18N_NAMESPACE.RESET_PASSWORD);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationError, setValidationError] = useState<string | null>("");

  const {
    pending: isKeyAvailablePending,
    status: isKeyAvailableStatus,
    isAvailable: isKeyAvailable,
  } = useSelector(userResetPasswordIsKeyAvailableSelector);
  const { regularExpression }: IPasswordPolicyViewModel = useSelector(
    userPasswordRuleSelector
  );
  const changePasswordPending = useSelector(userResetPasswordPendingSelector);
  const changePasswordStatus = useSelector(userResetPasswordStatusSelector);

  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  useEffect(() => {
    if (changePasswordStatus) {
      const { success } = changePasswordStatus;
      if (success) {
        router.push("/");
        dispatch(closeLoginMenu());
        dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
        dispatch(openLoginMenu());
      }
    }
  }, [dispatch, changePasswordStatus]);
  const socketSession = useSelector(socketSessionSelector);
  const key = query.get("key") as string;

  const handleSendAgainClick = () => {
    dispatch(setLoginMenuScreen(loginMenuScreens.FORGOT_PASSWORD));
    dispatch(openLoginMenu());
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidationError(null);
    setPassword(event.target.value);
  };

  const handlePasswordBlur = () => {
    const passwordValidationError = validateAccountInformationPassword(
      password,
      PASSWORD_TEST_REGEX,
      registerT
    );
    setValidationError(passwordValidationError);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValidationError(null);
    setConfirmPassword(event.target.value);
  };
  const handleConfirmPasswordBlur = () => {
    const confirmPasswordValidationError =
      validateAccountInformationConfirmPassword(
        password,
        confirmPassword,
        PASSWORD_TEST_REGEX,
        registerT
      );
    setValidationError(confirmPasswordValidationError);
  };

  const handleResetPasswordClick = () => {
    const passwordValidationError = validateAccountInformationPassword(
      password,
      PASSWORD_TEST_REGEX,
      registerT
    );
    setValidationError(passwordValidationError);

    if (validationError) {
      return;
    }

    const confirmPasswordValidationError =
      validateAccountInformationConfirmPassword(
        password,
        confirmPassword,
        PASSWORD_TEST_REGEX,
        registerT
      );
    setValidationError(confirmPasswordValidationError);

    if (validationError) {
      return;
    }

    dispatch(
      resetPasswordRequest({
        key: query.get("key") as string,
        password,
      })
    );
    click(`reset password`, {
      buttonLabel: t("reset-password"),
      component: "ResetPasswordScreen",
    });
  };

  const getResetPasswordContent = () => {
    // if (isKeyAvailablePending || !isKeyAvailableStatus) {
    //   return <Loader />;
    // }

    // if (!isKeyAvailableStatus?.success || !isKeyAvailable) {
    //   return (
    //     <div className="reset-password-container">
    //       <div className="text-lg reset-password-section text-danger">
    //         {t("password-reset-url-expired")}
    //       </div>
    //       <div className="reset-password-section">
    //         <Link href="/" passHref legacyBehavior>
    //           <span className="text-primary" onClick={handleSendAgainClick}>
    //             {t("resend-password-reset-request")}
    //           </span>
    //         </Link>
    //       </div>
    //       <div className="reset-password-section">
    //         <Link href="/casino" passHref legacyBehavior>
    //           <span className="text-primary">{t("back-to-the-main-page")}</span>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // }

    const getErrorComponent = () => {
      if (validationError) {
        return (
          <div className="text-center feedback error text-red text-sm">
            {validationError}
          </div>
        );
      }

      if (!changePasswordStatus) {
        return null;
      }

      const { success } = changePasswordStatus;

      return (
        !success && (
          <div className="text-center feedback error">
            {t("issue-with-password-reset")}
          </div>
        )
      );
    };
    const leftBannerImg = useMemo(() => {
      const attributes =
        registrationContent && registrationContent[0]?.attributes;
      const leftBannerImg = isMobile
        ? attributes
          ? (attributes?.mobileImage?.data
              ?.attributes as IImageAttributesViewModel)
          : undefined
        : attributes
        ? (attributes?.desktopImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined;
      return leftBannerImg;
    }, [isMobile, registrationContent]);

    return (
      <div className="grid h-screen grid-cols-1 md:grid-cols-2 sm:h-auto">
        <div className="relative hidden md:block">
          {isPendingRegistrationContent ? (
            <Image
              src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
              objectFit="cover"
              fill
              alt="reg-banner"
              className="w-full h-full"
              loading="lazy"
            />
          ) : (
            <Image
              src={
                leftBannerImg
                  ? leftBannerImg.url
                  : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
              }
              objectFit="cover"
              fill
              alt={leftBannerImg ? leftBannerImg?.name : " registration banner"}
              className="w-full h-full"
              loading="lazy"
            />
          )}
        </div>
        <div className="relative flex flex-col justify-between gap-10 p-8 pt-16 sm:pt-8 min-h-[640px] h-full dark:bg-tertiary-600">
          <div className="flex flex-col gap-8">
            <h5 className="text-base font-bold dark:text-white">
              {t("new-password-and-confirm")}
            </h5>
            <div className="flex flex-col gap-4">
              <div className="">
                <input
                  className="w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0"
                  type="password"
                  placeholder={t("password-placeholder")}
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordBlur}
                  id="new-password"
                  name="new-password"
                  autoComplete="new-password"
                />
              </div>
              <div className="">
                <input
                  className="w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0"
                  type="password"
                  placeholder={t("confirm-password-placeholder")}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onBlur={handleConfirmPasswordBlur}
                  id="new-password-confirmation"
                  name="new-password-confirmation"
                />
              </div>
              {getErrorComponent()}
            </div>
            <div className="flex flex-col gap-4">
              <button
                className="btn btn--lg btn--primary min-w-[230px] w-full"
                onClick={handleResetPasswordClick}
                disabled={changePasswordPending || !!validationError}
              >
                {t("reset-password")}
              </button>

              {/*<div className="flex items-center gap-3">*/}
              {/*  <p className="text-xs font-normal text-tertiary dark:text-white">*/}
              {/*    {registerT("already-have-account")}*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{getResetPasswordContent()}</div>;
};

export default ForgotPasswordScreen;

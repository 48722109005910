import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  userActionTypes,
  FavoritesState,
  LoginsState,
  LoginsActions,
} from "@/fe-core/meta/types/user";

const initialState: LoginsState = {
  pending: false,
  data: null,
  status: null,
};

const loginsReducer = (
  state: LoginsState = initialState,
  action: LoginsActions | RootActions
): FavoritesState => {
  switch (action.type) {
    case userActionTypes.GET_LOGINS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_LOGINS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: {
          ...payload,
        },
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_LOGINS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }

    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default loginsReducer;

import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  validateAccountInformationConfirmPassword,
  validateAccountInformationEmail,
  validateAccountInformationPassword,
  validateAccountInformationUsernameWithNoSpecialChar,
} from "../../fe-core-omega/helpers/general";
import {
  IPasswordPolicyViewModel,
  ISignupPayload,
} from "../../fe-core-omega/meta/interfaces/user";
import {
  checkCredentialRequest,
  getPasswordPolicyRequest,
  setActiveSignupScreen,
  usernameAvailability,
} from "../../fe-core-omega/_redux/actions/userActions";
import {
  checkEmailCredentialPendingSelector,
  checkEmailCredentialStatusSelector,
  signupPendingSelector,
  signupStatusSelector,
  signupUsernameCheckPendingSelector,
  signupUsernameCheckStatusSelector,
  userPasswordRuleSelector,
} from "../../fe-core-omega/_redux/selectors/userSelectors";
import TagManager from "react-gtm-module";
import { INPUT_STATUSES, currencies } from "@/config/general";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import SVGIcon from "../SVGIcon";
import { CookiesStorage } from "../../fe-core-omega/helpers/storage";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import Button from "../Button";
import countryToCurrency from "country-to-currency";
import { USERNAME_TEST_REGEX } from "@/config/profile";
import useTracking from "../Hooks/useTracking";
import { signupScreens } from "@/fe-core/meta/types/user";
import "react-phone-number-input/style.css";
import Image from "next/image";
import debounce from "lodash.debounce";

const AccountInformationScreen: FC<{
  payload: ISignupPayload;
  setPayload: (payload: ISignupPayload) => void;
  handleLoginClick: () => void;
}> = ({ payload, setPayload, handleLoginClick }) => {
  const { click, track } = useTracking();
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.REGISTER);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);

  const signupPending = useSelector(signupPendingSelector);
  const signupStatus = useSelector(signupStatusSelector);
  const usernameCheckPending = useSelector(signupUsernameCheckPendingSelector);
  const usernameCheckStatus = useSelector(signupUsernameCheckStatusSelector);
  const checkEmailCredentialPending = useSelector(checkEmailCredentialPendingSelector);
  const checkEmailCredentialStatus = useSelector(
    checkEmailCredentialStatusSelector
  );

  const { regularExpression }: IPasswordPolicyViewModel = useSelector(
    userPasswordRuleSelector
  );

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [usernameErr, setUsernameErr] = useState<string | null>("");
  const [apiUsernameErr, setApiUsernameErr] = useState<string | null>("");
  const [apiEmailErr, setApiEmailErr] = useState<string | null>("");
  const [emailErr, setEmailErr] = useState<string | null>("");
  const [passwordErr, setPasswordErr] = useState<string | null>("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<string | null>(
    ""
  );
  const [startRegistrationEventSent, setStartRegistrationEventSent] =
    useState(false);
  const [isRegFailedEventSent, setIsRegFailedEventSent] = useState(false);

  const hasEmptyFields = !(
    payload.email &&
    payload.username &&
    payload.password &&
    payload.confirmPassword
  );

  const hasValidationError = useMemo(() => {
    return (
      !!usernameErr ||
      !!emailErr ||
      !!passwordErr ||
      !!confirmPasswordErr ||
      !!apiUsernameErr ||
      !!apiEmailErr
    );
  }, [usernameErr, emailErr, passwordErr, confirmPasswordErr, apiUsernameErr, apiEmailErr]);

  useEffect(() => {
    dispatch(getPasswordPolicyRequest());
  }, [dispatch]);

  useEffect(() => {
    if (usernameCheckStatus?.success === false && payload.username)
      setApiUsernameErr(t("username-not-available"));
    else setApiUsernameErr("");
  }, [usernameCheckStatus]);
  
  useEffect(() => {
    if (checkEmailCredentialStatus?.success === false && payload.email)
      setApiEmailErr(t("email-not-available"));
    else setApiEmailErr("");
  }, [checkEmailCredentialStatus]);

  useEffect(() => {
    const getCountry = async () => {
      const response = await fetch(
        `https://www.ftvclub.com/cdn-cgi/trace`
      );
      const data = await response.text();
      const arr = data.split("\n");
      const country =
        arr.find((v) => v.includes("loc="))?.replace("loc=", "") || "";

      const newCountryPayload = {
        ...payload,
        country: country
      };
      setPayload(newCountryPayload);

      const detectedCurrency = (countryToCurrency as any)[
        country?.toUpperCase()
      ];

      if (currencies[detectedCurrency]) {
        const newPayload = {
          ...payload,
          country: country,
          currency: detectedCurrency,
        };
        setPayload(newPayload);
      }
    };
    getCountry();
  }, [dispatch]);

  useEffect(() => {
    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const affiliateMarkerValue = CookiesStorage.getItem(key as string);
    if (affiliateMarkerValue && !payload.affiliateMarker) {
      setPayload({
        ...payload,
        affiliateMarker: affiliateMarkerValue,
      });
    }
  }, [payload.affiliateMarker, dispatch]);

  const debouncedSearch = useCallback(
    debounce(
      (username: string) => dispatch(usernameAvailability({ username })),
      1000
    ),
    []
  );

  const debouncedEmailSearch = useCallback(
    debounce(
      (email: string) => dispatch(checkCredentialRequest({ field: "email", value: email })),
      1000
    ),
    []
  );

  const sendStartRegistrationEvent = () => {
    if (!startRegistrationEventSent) {
      TagManager.dataLayer({
        dataLayer: {
          event: "registrationStarted",
          stepLabel: t("start-registration"),
        },
      });
      setStartRegistrationEventSent(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (signupStatus?.success === false && !isRegFailedEventSent) {
      setIsRegFailedEventSent(true);
      track(`Registration Failied`, {
        errorMsg: signupStatus?.message,
        userDetails: { ...payload, password: "****" },
        component: "RegisterModal - AccountInformationScreen",
      });
    }
  }, [signupStatus, payload, isRegFailedEventSent]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s+/g, "");
    setPayload({
      ...payload,
      username: value,
    });

    const userNameValidationError =
      validateAccountInformationUsernameWithNoSpecialChar(
        value,
        USERNAME_TEST_REGEX,
        t,
        8
      );
    setUsernameErr(userNameValidationError);
    if (!userNameValidationError) debouncedSearch(value);

    sendStartRegistrationEvent();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s+/g, "");
    setPayload({
      ...payload,
      email: value,
    });
    const emailValidationError = validateAccountInformationEmail(value, t);
    setEmailErr(emailValidationError);

    if (!emailValidationError) debouncedEmailSearch(value);

    sendStartRegistrationEvent();
  };

  const handleUsernameBlur = () => {
    let userNameValidationError =
      validateAccountInformationUsernameWithNoSpecialChar(
        payload.username,
        USERNAME_TEST_REGEX,
        t,
        8
      );

    if (
      usernameCheckStatus?.success === false &&
      !usernameCheckPending &&
      !userNameValidationError
    )
      userNameValidationError = t("username-not-available");

    setUsernameErr(userNameValidationError);

    return userNameValidationError;
  };
  const handleEmailBlur = () => {
    let emailValidationError = validateAccountInformationEmail(
      payload.email,
      t
    );

    if (
      checkEmailCredentialStatus?.success === false &&
      !checkEmailCredentialPending &&
      !emailValidationError
    )
      emailValidationError = t("email-not-available");

    setEmailErr(emailValidationError);
    return emailValidationError;
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      password: event.target.value.split(" ").join(""),
    });
    setPasswordErr("");
    sendStartRegistrationEvent();
  };

  const handlePasswordBlur = () => {
    const passwordValidationError = validateAccountInformationPassword(
      payload.password,
      regularExpression,
      t
    );
    setPasswordErr(passwordValidationError);
    return passwordValidationError;
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayload({
      ...payload,
      confirmPassword: event.target.value.split(" ").join(""),
    });
    setConfirmPasswordErr("");
    sendStartRegistrationEvent();
  };

  const handleConfirmPasswordBlur = () => {
    const confirmPasswordValidationError =
      validateAccountInformationConfirmPassword(
        payload.password,
        payload.confirmPassword,
        regularExpression,
        t
      );
    setConfirmPasswordErr(confirmPasswordValidationError);
    return confirmPasswordValidationError;
  };

  const verifyPassword = () => {
    const letterRegex = /[a-zA-Z]/;
    const numberRegex = /\d/;

    const hasLetter = letterRegex.test(payload.password);
    const hasNumber = numberRegex.test(payload.password);
    const hasLength = payload.password.length > 7;

    return (
      <>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasLength ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasLength
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {t("password-validation-min-8-char")}
        </div>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasLetter ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasLetter
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {t("password-validation-min-1-letter")}
        </div>
        <div className="flex items-center gap-0.5 lg:gap-1 dark:text-white/70">
          <SVGIcon
            icon={hasNumber ? "circle-valid" : "circle-error"}
            className={`w-2.5 h-2.5 lg:w-3.5 lg:h-3.5 ${
              hasNumber
                ? "text-success dark:text-success-500"
                : "text-error dark:text-error-500"
            }`}
          />
          {t("password-validation-min-1-number")}
        </div>
      </>
    );
  };

  function getInputStatus(inputValue: string, validationErr: string | null) {
    if (validationErr) {
      return INPUT_STATUSES.ERROR;
    } else {
      if (inputValue) {
        return INPUT_STATUSES.VALID;
      }
    }
    return INPUT_STATUSES.NOT_VALIDATED;
  }

  const validateForm = () => {
    const usernameErr = handleUsernameBlur();
    const emailErr = handleEmailBlur();
    const passwordErr = handlePasswordBlur();
    const confirmPasswordErr = handleConfirmPasswordBlur();
    return usernameErr || emailErr || passwordErr || confirmPasswordErr;
  };

  const onSubmitForm = (e: SyntheticEvent) => {
    e.preventDefault();

    const isInvalidForm = validateForm();
    if (isInvalidForm) return;

    dispatch(setActiveSignupScreen(signupScreens.PERSONAL_INFORMATION));
  };

  return (
    <form onSubmit={onSubmitForm}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center flex-1 w-full gap-4">
          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {(usernameErr || apiUsernameErr) && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {usernameErr || apiUsernameErr}
              </div>
            )}
            <div className="relative w-full">
              <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                {usernameCheckPending ? (
                  <Image
                    src="/img/icons/loading-dots.svg"
                    width={40}
                    height={30}
                    alt="spinner"
                    className="!h-7"
                    loading="lazy"
                  />
                ) : (
                  <>
                    {getInputStatus(
                      payload.username,
                      usernameErr || apiUsernameErr
                    ) === "valid" && (
                      <SVGIcon
                        icon="circle-valid"
                        className="w-6 h-5 text-green"
                      />
                    )}
                    {getInputStatus(
                      payload.username,
                      usernameErr || apiUsernameErr
                    ) === "error" && (
                      <SVGIcon
                        icon="circle-error"
                        className="w-6 h-5 text-red"
                      />
                    )}
                  </>
                )}
              </div>
              <input
                className={`form-input !text-base sm:!text-sm ${getInputStatus(
                  payload.username,
                  usernameErr || apiUsernameErr
                )}`}
                required
                onChange={handleUsernameChange}
                onBlur={handleUsernameBlur}
                type="text"
                name="username"
                id="username"
                value={payload.username}
                placeholder={t("username")}
                disabled={signupPending}
              />
            </div>
          </div>
          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {(emailErr || apiEmailErr) && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {emailErr || apiEmailErr}
              </div>
            )}
            <div className="relative w-full">
              <div className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-4 h-fit">
                {checkEmailCredentialPending ? (
                  <Image
                    src="/img/icons/loading-dots.svg"
                    width={40}
                    height={30}
                    alt="spinner"
                    className="!h-7"
                    loading="lazy"
                  />
                ) : (
                  <>
                    {getInputStatus(payload.email, emailErr || apiEmailErr) ===
                      "valid" && (
                      <SVGIcon
                        icon="circle-valid"
                        className="w-6 h-5 text-green"
                      />
                    )}
                    {getInputStatus(payload.email, emailErr || apiEmailErr) ===
                      "error" && (
                      <SVGIcon
                        icon="circle-error"
                        className="w-6 h-5 text-red"
                      />
                    )}
                  </>
                )}
              </div>
              <input
                className={`form-input !text-base sm:!text-sm ${getInputStatus(
                  payload.email,
                  emailErr || apiEmailErr
                )}`}
                required
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                type="text"
                name="email"
                id="email"
                value={payload.email}
                placeholder={t("email")}
                disabled={signupPending}
              />
            </div>
          </div>
          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            <div className="flex items-center gap-1 lg:gap-2 mt-1 text-[11px] lg:text-xs flex-wrap">
              {verifyPassword()}
            </div>
            <div className="relative w-full">
              <input
                className={`form-input !text-base sm:!text-sm ${getInputStatus(
                  payload.password,
                  passwordErr
                )}`}
                required
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={payload.password}
                autoComplete="new-password"
                placeholder={t("password")}
                disabled={signupPending}
              />
              <button
                type="button"
                className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-1 w-10 h-10"
                onClick={toggleShowPassword}
              >
                <SVGIcon
                  className="w-4 h-4 m-auto text-tertiary/50 dark:text-white/50"
                  icon={!showPassword ? "eye" : "blind"}
                />
              </button>
            </div>
          </div>
          <div className="relative flex flex-col-reverse items-start w-full text-left max-w-none">
            {confirmPasswordErr && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {confirmPasswordErr}
              </div>
            )}
            <div className="relative w-full">
              <input
                className={`form-input !text-base sm:!text-sm ${getInputStatus(
                  payload.confirmPassword,
                  confirmPasswordErr
                )}`}
                required
                onChange={handleConfirmPasswordChange}
                onBlur={handleConfirmPasswordBlur}
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                value={payload.confirmPassword}
                autoComplete="confirm-password"
                placeholder={t("confirm-password")}
                disabled={signupPending}
              />
              <button
                type="button"
                className="absolute top-0 bottom-0 flex gap-2 m-auto cursor-pointer right-1 w-10 h-10"
                onClick={toggleShowConfirmPassword}
              >
                <SVGIcon
                  className="w-4 h-4 m-auto text-tertiary/50 dark:text-white/50"
                  icon={!showConfirmPassword ? "eye" : "blind"}
                />
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <Button
            label={generalT("continue")}
            disabled={
              hasEmptyFields ||
              hasValidationError ||
              signupPending ||
              usernameCheckPending ||
              checkEmailCredentialPending
            }
            loading={signupPending}
            buttonType="submit"
            className="btn--lg"
            trackParams={{
              component: "RegisterModal - AccountinformationScreen",
            }}
          />

          <div className="flex gap-3 items-center">
            <p className="text-xs font-normal text-tertiary dark:text-white">
              {t("do-already-have-account")}{" "}
              <button
                className=" text-primary-500 font-bold"
                onClick={() => {
                  handleLoginClick();
                  click(t("login"), {
                    text: t("login"),
                    from: "RegisterModal",
                    component: "AccountinformationScreen",
                  });
                }}
              >
                {t("login")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountInformationScreen;

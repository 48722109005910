import {
  casinoLiveRTPActionTypes,
  casinoLiveRTPState,
  CasinoLiveRTPActions,
} from "@/fe-core/meta/types/casinoLiveRTP";

const initialState: casinoLiveRTPState = {
  hourly: { pending: false, status: null, casinoLiveRTP: null },
  daily: { pending: false, status: null, casinoLiveRTP: null },
};

const casinoLiveRTPReducer = (
  state: casinoLiveRTPState = initialState,
  action: CasinoLiveRTPActions
): casinoLiveRTPState => {
  switch (action.type) {
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_REQUEST:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          pending: true,
          status: null,
        },
      };
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_FAILURE:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          pending: false,
          status: action.payload,
        },
      };
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_SUCCESS:
      return {
        ...state,
        hourly: {
          ...state.hourly,
          pending: false,
          casinoLiveRTP: action.payload,
        },
      };
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_REQUEST:
      return {
        ...state,
        daily: {
          ...state.daily,
          pending: true,
          status: null,
        },
      };
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_FAILURE:
      return {
        ...state,
        daily: {
          ...state.daily,
          pending: false,
          status: action.payload,
        },
      };
    case casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_SUCCESS:
      return {
        ...state,
        daily: {
          ...state.daily,
          pending: false,
          casinoLiveRTP: action.payload,
        },
      };
    default:
      return state;
  }
};

export default casinoLiveRTPReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";

import {
  IBalanceChangeResponsePayload,
  IBalanceChangeViewModel,
  IBalanceViewModel,
} from "@/fe-core/meta/interfaces/user";

import {
  userActionTypes,
  BalanceChangedAction,
} from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  getBalanceFailure,
  getBalanceSuccess,
  balanceUpdate,
  getBalanceRequest,
} from "@/fe-core/_redux/actions/userActions";
import { headerKeys } from "@/config/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { buffers, eventChannel } from "redux-saga";
import { NotUndefined, Buffer } from "@redux-saga/types";
import { Action } from "redux";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import {
  fetchEventSource,
  EventStreamContentType,
} from "@microsoft/fetch-event-source";
import { clearStorage } from "@/fe-core/helpers/register";
const balanceChangedPayloadToViewModel = ({
  balanceChange,
}: IBalanceChangeResponsePayload): IBalanceChangeViewModel => {
  const result: IBalanceChangeViewModel = {};
  if (
    balanceChange?.Real?.affectedAmount !== undefined &&
    balanceChange?.Real?.afterAmount !== undefined
  ) {
    result.Real = {
      affectedAmount: balanceChange.Real.affectedAmount,
      afterAmount: balanceChange.Real.afterAmount,
    };
  }
  if (
    balanceChange?.Bonus?.affectedAmount !== undefined &&
    balanceChange?.Bonus?.afterAmount !== undefined
  ) {
    result.Bonus = {
      affectedAmount: balanceChange.Bonus.affectedAmount,
      afterAmount: balanceChange.Bonus.afterAmount
    };
  }
  return result;
};

function* getBalanceRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    if(!sessionId) return;

    const response: IJsonResponse<IBalanceViewModel | IErrorPayload> =
      yield fetch("/api/user/balance", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    const viewModel: IBalanceViewModel = yield response.json();
    if (response.status == 200) {
      yield put(getBalanceSuccess(viewModel));
    } else {
      // @ts-ignore
      if (response.status === 401 || (response.status === 400 && viewModel?.message === "Session not found")) {
        yield put(clearState());
        clearStorage();
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getBalanceFailure(errorPayload));
    }
  } catch (error) {
    yield put(getBalanceFailure(parseFrontendError(error)));
  }
}

function* watchBalanceSaga() {
  const buffer: Buffer<NotUndefined> = buffers.expanding();
  const { sessionId, userId } = yield select(sessionDataSelector);
  const controller = new AbortController()
  let timeOut;
  const channel = eventChannel((emitter) => {
    const subscribe = async (): Promise<void> => {
      class RetriableError extends Error { }
      class FatalError extends Error { }
      const signal = controller.signal;
      await fetchEventSource(
        `${process.env.NEXT_PUBLIC_NORWAY_API_URL}/v1/player/${userId}/balance/updates`,
        {
          signal: signal,
          openWhenHidden: true,
          headers: { [headerKeys.SESSION_ID]: sessionId },
          async onopen(response) {
            if (
              response.ok &&
              response.headers
                .get("content-type")
                .includes(EventStreamContentType)
            ) {
              return; // everything's good
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              // client-side errors are usually non-retriable:
              throw new FatalError();
            } else {
              throw new RetriableError();
            }
          },
          onmessage(msg) {
            // if the server emits an error message, throw an exception
            // so it gets handled by the onerror callback below:

            if (msg.event === "FatalError") {
              console.log("FatalError--FatalError", msg.data);
              // throw new FatalError(msg.data);
            }

            try {
              const balanceChangedPayload = JSON.parse(msg.data);
              // console.log("balanceChangedPayload", balanceChangedPayload);

              if (process.env.NEXT_PUBLIC_GM_CORE === "GM1") {
                if (!balanceChangedPayload?.error) {
                  emitter(getBalanceRequest());
                } else if (balanceChangedPayload?.error?.includes('Session is expired')) {
                  controller.abort();
                  emitter(clearState());
                }
              } else {
                const viewModel: IBalanceChangeViewModel =
                  balanceChangedPayloadToViewModel(balanceChangedPayload);
                emitter(
                  {
                    type: userActionTypes.BALANCE_CHANGED,
                    payload: viewModel,
                  }
                );
              }
            } catch (error) {
              console.log("EVENT", error, msg);
            }
          },
          onclose() {
            console.log("CLOSE");
            // if the server closes the connection unexpectedly, retry:
            throw new RetriableError();
          },
          onerror(err) {
            console.log("ERROR", err);
            if (err instanceof FatalError) {
              throw err; // rethrow to stop the operation
            } else {
              // do nothing to automatically retry. You can also
              // return a specific retry interval here.
            }
          },
        }
      );

      // await fetchEventSource(
      //   `${process.env.NEXT_PUBLIC_NORWAY_API_URL}/v1/player/${userId}/balance/updates`,
      //
      //   {
      //     openWhenHidden: true,
      //     headers: { [headerKeys.SESSION_ID]: sessionId },
      //     onmessage(event) {
      //       console.log("EVENT", event);
      //       // try {
      //       //   const balanceChangedPayload = JSON.parse(event.data);
      //       //   const viewModel: IBalanceChangeViewModel =
      //       //     balanceChangedPayloadToViewModel(balanceChangedPayload);
      //       //   if (
      //       //     balanceChangedPayload.balanceChange?.Real.affectedAmount < 0 ||
      //       //     balanceChangedPayload.balanceChange?.Bonus.affectedAmount < 0
      //       //   ) {
      //       //     timeOut = window.setTimeout(() => {
      //       //       emitter(
      //       //         {
      //       //           type: userActionTypes.BALANCE_CHANGED,
      //       //           payload: viewModel,
      //       //         },
      //       //         2000
      //       //       );
      //       //     });
      //       //   }
      //       // } catch (error) { }
      //     },
      //   }
      // );
    };
    subscribe();
    return () => {
      timeOut && clearTimeout(timeOut);
      return true;
    };
  }, buffer);

  while (true) {
    const action: Action = yield take(channel);
    yield put(action);
  }
}

function* balanceChangedSaga({ payload }: BalanceChangedAction) {
  // const balance: IBalanceViewModel = yield select(userBalanceDataSelector);
  // const userData: ISessionViewModel | null = yield select(sessionDataSelector);
  //
  // if (payload.type === "Deposit") {
  //   const eventType = payload.isFirstDeposit ? "first-deposit" : "deposit";
  //
  //   const depositValue = payload.amount - balance.balance;
  //
  //   TagManager.dataLayer({
  //     dataLayer: {
  //       event: eventType,
  //       userId: userData?.userId,
  //       username: encodeToB64(userData?.username || ""),
  //       email: userData?.email || "",
  //       depositValue: parseFloat(depositValue.toFixed(2)),
  //     },
  //   });
  // }

  yield put(balanceUpdate(payload));
}

export default function* balanceSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeEvery(userActionTypes.GET_BALANCE_REQUEST, getBalanceRequestSaga),
    takeLatest(userActionTypes.APPLY_BONUS_SUCCESS, getBalanceRequestSaga),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getBalanceRequestSaga),
    takeLatest(userActionTypes.GET_CLAIM_SUCCESS, getBalanceRequestSaga),
    // takeLatest(userActionTypes.SUBSCRIBE_WATCH_BALANCE, watchBalanceSaga),
    takeLatest(userActionTypes.BALANCE_CHANGED, balanceChangedSaga),
  ]);
}

import {
  LoyaltyLevelsActions,
  LoyaltyLevelsState,
  loyaltyLevelsActionTypes,
} from "@/fe-core/meta/types/loyaltyLevels";

const initialState: LoyaltyLevelsState = {
  pending: false,
  loyaltyLevels: null,
  status: null
};

const loyaltyLevelsReducer = (
  state: LoyaltyLevelsState = initialState,
  action: LoyaltyLevelsActions
): LoyaltyLevelsState => {
  switch (action.type) {
    case loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case loyaltyLevelsActionTypes.GET_LOYALTY_LEVELS_SUCCESS:
      return {
        ...state,
        pending: false,
        loyaltyLevels: action.payload.data,
        status: {
          success: true,
        }
      };
    default:
      return {
        ...state,
      };
  }
};

export default loyaltyLevelsReducer;

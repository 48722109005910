import Button from "@/components/Button";
import Modal from "@/components/Modal";
import { I18N_NAMESPACE } from "@/config/i18n";
import { openLoginMenu } from "@/fe-core/_redux/actions/menusActions";
import { closeSessionExpiredModal } from "@/fe-core/_redux/actions/sessionActions";
import { sessionAuthenticateStatusSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { useTranslations } from "next-intl";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const SessionExpiredModal = () => {
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const loginT = useTranslations(I18N_NAMESPACE.LOGIN);

  const authenticateStatus = useSelector(sessionAuthenticateStatusSelector);

  const onModalClose = () => {
    dispatch(closeSessionExpiredModal());
  };

  const handleLoginClick = () => {
    onModalClose();
    dispatch(openLoginMenu());
  };

  return (
    <Modal
      className="!p-0 overflow-auto rounded-none sm:rounded max-w-full sm:max-w-[calc(100dvw-64px)] lg:max-w-sm"
      onClose={onModalClose}
      isOpen={authenticateStatus?.success === false}
    >
      <div className="p-5 space-y-5 text-center">
        <h4 className="m-0 text-xl font-bold sm:text-base text-tertiary dark:text-white">
          {t("session-expired")}
        </h4>

        <Button
          type="primary"
          onClick={handleLoginClick}
          label={loginT("login-button")}
        />
      </div>
    </Modal>
  );
};

export default SessionExpiredModal;

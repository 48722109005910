
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  IDepositViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  DepositRequestAction,
  userActionTypes,
} from "@/fe-core/meta/types/user";
import {
  depositFailure,
  depositSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { DEFAULT_CURRENCY } from "@/config/general";


function* depositRequestSaga({
  payload: { amount, bonusCode, isShortCashier },
}: DepositRequestAction) {
  try {
    const depositViewModel: IDepositViewModel = {
      currency: DEFAULT_CURRENCY,
      amount,
      BonusCode: bonusCode,
    };
    yield put(depositSuccess(depositViewModel));

  } catch (error) {
    yield put(depositFailure(parseFrontendError(error)));
  }
}

function* depositSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([takeLatest(userActionTypes.DEPOSIT_REQUEST, depositRequestSaga)]);
}

export default depositSaga;

"use client";

import { FC, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSendEmailRequest } from "../../../fe-core-omega/_redux/actions/userActions";
import {
  openLoginMenu,
  setLoginMenuScreen,
} from "../../../fe-core-omega/_redux/actions/menusActions";
import {
  userResetPasswordSendEmailPendingSelector,
  userResetPasswordSendEmailStatusSelector,
} from "../../../fe-core-omega/_redux/selectors/userSelectors";
import { validateAccountInformationEmail } from "../../../fe-core-omega/helpers/general";
import { useScreen } from "../../../context/ScreenContext";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import Image from "next/image";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "../../../fe-core-omega/_redux/selectors/registrationContentSelectors";
import { IImageAttributesViewModel } from "../../../fe-core-omega/meta/interfaces/root";
import useTracking from "@/components/Hooks/useTracking";
import Button from "@/components/Button";
import { loginMenuScreens } from "@/fe-core/meta/types/menus";
interface Props {
  email: string;
  setEmail: (email: string | ((prevEmail: string) => string)) => void;
}

const ForgotPasswordScreen: FC<Props> = ({ email, setEmail }) => {
  const { navigate, click } = useTracking();
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.FORGOT_PASSWORD);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);
  const { isMobile } = useScreen();

  const pending = useSelector(userResetPasswordSendEmailPendingSelector);
  const status = useSelector(userResetPasswordSendEmailStatusSelector);
  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  const [validationError, setValidationError] = useState<string | null>("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s+/g, "");
    setValidationError(null);
    setEmail(value);

    const emailValidationError = validateAccountInformationEmail(
      value,
      registerT
    );
    setValidationError(emailValidationError);
  };

  const handleSendClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailValidationError = validateAccountInformationEmail(
      email,
      registerT
    );
    setValidationError(emailValidationError);

    if (validationError || emailValidationError) return;

    click(`${t("send")} forgotpassword`, {
      buttonLabel: t("send"),
      component: "ForgotPasswordScreen",
    });

    dispatch(resetPasswordSendEmailRequest({ email }));
  };

  const getErrorComponent = () => {
    if (validationError) {
      return (
        <div className="m-0 mt-0.5 text-xs font-medium text-error text-red">
          {validationError}
        </div>
      );
    }

    if (!status) return null;
    const { success } = status;

    return (
      !success && (
        <div className="text-sm text-center text-red">
          {t("sendind-email-error")}
        </div>
      )
    );
  };

  const handleLoginClick = () => {
    dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
  };
  const leftBannerImg = useMemo(() => {
    const attributes =
      registrationContent && registrationContent[0]?.attributes;
    const leftBannerImg = isMobile
      ? attributes
        ? (attributes?.mobileImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined
      : attributes
      ? (attributes?.desktopImage?.data
          ?.attributes as IImageAttributesViewModel)
      : undefined;
    return leftBannerImg;
  }, [isMobile, registrationContent]);

  return (
    <div className="grid h-screen grid-cols-1 md:grid-cols-2 sm:h-auto">
      <div className="relative hidden md:block">
        {isPendingRegistrationContent ? (
          <Image
            src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
            objectFit="cover"
            fill
            alt="reg-banner"
            className="w-full h-full"
            loading="lazy"
          />
        ) : (
          <Image
            src={
              leftBannerImg
                ? leftBannerImg.url
                : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
            }
            objectFit="cover"
            fill
            alt={leftBannerImg ? leftBannerImg?.name : " registration banner"}
            className="w-full h-full"
            loading="lazy"
          />
        )}
      </div>
      <div className="relative flex flex-col justify-between gap-10 p-8 pt-16 sm:pt-8 min-h-[640px] h-full dark:bg-tertiary-600">
        <div className="flex flex-col gap-8">
          <h5 className="text-base font-bold dark:text-white">
            {t("reset-password")}
          </h5>
          <form onSubmit={handleSendClick}>
            <div className="">
              <input
                className="w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0"
                type="text"
                id="login-email"
                onChange={handleEmailChange}
                placeholder={registerT("email")}
              />
              {getErrorComponent()}
            </div>

            <div className="flex flex-col gap-4 mt-5">
              <Button
                size="lg"
                buttonType="submit"
                className="min-w-[230px] w-full"
                disabled={!email || pending || !!validationError}
                label={t("send")}
                loading={pending}
              />
              <div className="flex items-center gap-2">
                <p className="text-xs font-normal text-tertiary dark:text-white">
                  {registerT("already-have-account")}
                </p>
                <button
                  type="button"
                  className=" text-xs text-primary-500 font-bold"
                  onClick={handleLoginClick}
                >
                  {registerT("login")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;

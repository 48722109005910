import { IErrorPayload } from "../interfaces/root";
import { IDeadSimpleChatViewModel } from "../interfaces/deadSimpleChat";

export enum deadSimpleChatActionTypes {
  GET_DSC_ACCESS_TOKEN_REQUEST = "GET_DSC_ACCESS_TOKEN_REQUEST",
  GET_DSC_ACCESS_TOKEN_SUCCESS = "GET_DSC_ACCESS_TOKEN_SUCCESS",
  GET_DSC_ACCESS_TOKEN_FAILURE = "GET_DSC_ACCESS_TOKEN_FAILURE",
}

export type GetDscAccessTokenRequestAction = {
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_REQUEST;
};

export type GetDscAccessTokenSuccessAction = {
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_SUCCESS;
  payload: IDeadSimpleChatViewModel;
};

export type GetDscAccessTokenFailureAction = {
  type: deadSimpleChatActionTypes.GET_DSC_ACCESS_TOKEN_FAILURE;
  payload: IErrorPayload;
};

export type DeadSimpleChatActionsActions =
  | GetDscAccessTokenRequestAction
  | GetDscAccessTokenSuccessAction
  | GetDscAccessTokenFailureAction;

export type DeadSimpleChatActionsState = {
  pending: boolean;
  accessToken: string | null;
};

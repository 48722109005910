export const LIVE_AGENT_URL = "pazuru.ladesk.com";

export const LIVE_AGENT_BUTTON_ID = "yq5bvy8g";

export const LIVE_AGENT_ID = "la_x2s6df8d";

export const DESKTOPBUTTONS = {
  zh: "95fmrhe2",
  ja: "yq5bvy8g",
  en: "uidr0g50",
};

export const MOBILEBUTTONS = {
  zh: "utoov2pz",
  ja: "w8mh60np",
  en: "45vsgh3p",
};

export const SUPPORTCHATBUTTONS = {
  zh: "pc7fyn33",
  ja: "0ecwdqw6",
  en: "i4lvehj9",
};

export const SUPPORTVIDEOBUTTONS = {
  zh: "lvhou4jd",
  ja: "agfp25r7",
  en: "pkzco7bg",
};

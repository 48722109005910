import { IErrorPayload, IStatus } from "@/fe-core/meta/interfaces/root";
import {
  GetAffiliateFailureAction,
  GetAffiliateRequestAction,
  GetAffiliateSuccessAction,
  IAffiliateViewModel,
} from "@/fe-core/meta/interfaces/user/affiliate/affiliate";
import { userActionTypes } from "@/fe-core/meta/types/user";

export const getAffiliateRequest = (): GetAffiliateRequestAction => ({
  type: userActionTypes.GET_AFFILIATE_REQUEST,
});

export const getAffiliateSuccess = (
  payload: IAffiliateViewModel
): GetAffiliateSuccessAction => ({
  type: userActionTypes.GET_AFFILIATE_SUCCESS,
  payload,
});

export const getAffiliateFailure = (
  payload: IErrorPayload
): GetAffiliateFailureAction => ({
  type: userActionTypes.GET_AFFILIATE_FAILURE,
  payload,
});

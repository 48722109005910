export enum drawerActionTypes {
  TOGGLE_DRAWER = "TOGGLE_DRAWER",
  INIT_DRAWER = "INIT_DRAWER",
  CLOSE_DRAWER = "CLOSE_DRAWER",
}

export type initDrawerAction = {
  type: drawerActionTypes.INIT_DRAWER;
  payload: DrawerState;
};

export type toggleDrawerAction = {
  type: drawerActionTypes.TOGGLE_DRAWER;
};

export type closeDrawerAction = {
  type: drawerActionTypes.CLOSE_DRAWER;
};

export type DrawerActions = toggleDrawerAction | initDrawerAction | closeDrawerAction;

export type DrawerState = {
  isDrawerOpen?: boolean;
  isMobile?: boolean;
  isInitial?: boolean;
  isTablet?: boolean;
  isForceFullyValue?: boolean;
};

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getCurrenciesFailure,
  getCurrenciesSuccess,
  setDetectedCurrency,
} from "@/fe-core/_redux/actions/userActions/registerActions/currenciesActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user/index";
import {
  IGetCurrenciesResponsePayload,
  IGetCurrenciesViewModel,
} from "@/fe-core/meta/interfaces/user/register/currencies";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { storageKeys } from "@/config/general";
import countryToCurrency from "country-to-currency";

function* getCurrenciesSaga() {
  try {
    const response: IJsonResponse<IGetCurrenciesResponsePayload> = yield fetch(
      `/api/user/currencies`,
      {
        method: methodTypes.GET,
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IGetCurrenciesViewModel = yield response.json();
        const detectedCountry = CookiesStorage.getItem(
          storageKeys.COUNTRY
        ) as string;
        const detectedCurrency = (countryToCurrency as any)[
          detectedCountry?.toUpperCase()
        ];
        if (viewModel?.currencies?.includes(detectedCurrency)) {
          yield put(setDetectedCurrency({ currency: detectedCurrency }));
        }
        yield put(getCurrenciesSuccess(viewModel));
        break;
      default:
        throw new Error("Currencies couldn't be fetched");
    }
  } catch (error) {
    yield put(getCurrenciesFailure(parseFrontendError(error)));
  }
}

function* currenciesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_CURRENCIES_REQUEST, getCurrenciesSaga),
  ]);
}

export default currenciesSaga;

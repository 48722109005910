import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IWithdrawViewModel } from "@/fe-core/meta/interfaces/user";
import {
  WithdrawRequestAction, userActionTypes,
} from "@/fe-core/meta/types/user";
import { withdrawFailure, withdrawSuccess } from "@/fe-core/_redux/actions/userActions";
import { DEFAULT_CURRENCY } from "@/config/general";

function* withdrawRequestSaga({ payload }: WithdrawRequestAction) {
  try {
    const withdrawViewModel: IWithdrawViewModel = {
      currency: DEFAULT_CURRENCY,
      amount: payload,
    };
    yield put(withdrawSuccess(withdrawViewModel));

  } catch (error) {
    yield put(withdrawFailure(parseFrontendError(error)));
  }
}

function* withdrawSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.WITHDRAW_REQUEST, withdrawRequestSaga),
  ]);
}

export default withdrawSaga;

import { userActionTypes } from "@/fe-core/meta/types/user";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetRecentPlayedFailureAction,
  GetRecentPlayedRequestAction,
  GetRecentPlayedSuccessAction,
} from "@/fe-core/meta/types/user/recentPlayed";
import { IRecentPlayedViewModel } from "@/fe-core/meta/interfaces/user/recentPlayed";

export const getRecentPlayedRequest = (): GetRecentPlayedRequestAction => ({
  type: userActionTypes.GET_RECENT_PLAYED_REQUEST,
});

export const getRecentPlayedSuccess = (
  payload: IRecentPlayedViewModel
): GetRecentPlayedSuccessAction => ({
  type: userActionTypes.GET_RECENT_PLAYED_SUCCESS,
  payload,
});

export const getRecentPlayedFailure = (
  payload: IErrorPayload
): GetRecentPlayedFailureAction => ({
  type: userActionTypes.GET_RECENT_PLAYED_FAILURE,
  payload,
});

"use client";
import React from "react";
import { motion } from "framer-motion";
import SVGIcon from "@/components/SVGIcon";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import { INewsViewModel } from "@/fe-core/meta/interfaces/news";

type Props = {
  news: INewsViewModel[] | undefined;
};
const NewsBar = ({ news }: Props) => {
  const t = useTranslations(I18N_NAMESPACE.GENERAL);

  if (!news || news?.length === 0) return <></>;

  return (
    <div className="bg-tertiary-300 flex items-center text-xs">
      <div className="flex items-center gap-2 text-primary py-2 px-2 bg-tertiary-300">
        <SVGIcon icon={"news"} className="w-4.5 h-4.5" />
        <p className="font-bold">{t("news-label")}</p>
      </div>

      <div className="overflow-hidden whitespace-nowrap">
        <motion.div
          initial={{ x: "0%" }}
          animate={{ x: "-100%" }}
          transition={{
            repeat: Infinity,
            duration: 80,
            delay: 0,
            ease: "linear",
          }}
          className="text-[#868686] text-nowrap"
        >
          {news?.map((newsItem, index) => newsItem?.attributes?.title).join(" | ")}
        </motion.div>
      </div>
    </div>
  );
};

export default NewsBar;

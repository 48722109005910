import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { bannerActionTypes, GetBannersRequestAction } from "@/fe-core/meta/types/banner";
import { IBannersViewModel } from "@/fe-core/meta/interfaces/banner";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getBannersFailure,
  getBannersSuccess,
} from "@/fe-core/_redux/actions/bannerActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { headerKeys } from "@/config/general";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { themeActionTypes } from "@/fe-core/meta/types/theme";
import { useSelector } from "react-redux";
import { isUserAuthenticatedSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { buildBannersQueryString } from '@/fe-core/helpers/banners';

function* getBannersSaga({ payload }: GetBannersRequestAction) {
  try {
    const { userRoles = [], userBonuses = [] } = payload || {};
    const { locale } = yield select(localeSelector);
    const { theme } = yield select(themeSelector);
    const isIdentified =
      localStorage.getItem(localStorageKeys.IDENTIFIED) || "0";

    const isAuthenticated: boolean = yield select(isUserAuthenticatedSelector);
    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);

    const queryString: string = buildBannersQueryString(
      theme,
      userRoles,
      userBonuses,
      btag ? [btag] : [],
      isAuthenticated ? "1" : "0",
      isIdentified ? "1" : "0"
    );
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/bannersv2${queryString}`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        const viewModel: IBannersViewModel = yield response.json();
        yield put(getBannersSuccess(viewModel));
        break;
      default:
        throw new Error("Sliders couldn't be fetched");
    }
  } catch (error) {
    yield put(getBannersFailure(parseFrontendError(error)));
  }
}

function* bannerSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(bannerActionTypes.GET_BANNERS_REQUEST, getBannersSaga),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getBannersSaga),
    takeLatest(themeActionTypes.SET_CURRENT_THEME, getBannersSaga),
    takeLatest(sessionActionTypes.LOGOUT_SUCCESS, getBannersSaga),
    takeLatest(sessionActionTypes.AUTHENTICATE_SUCCESS, getBannersSaga),
    takeLatest(sessionActionTypes.LOGIN_SUCCESS, getBannersSaga),
  ]);
}

export default bannerSaga;

import { PWAActions, PWAState, pwaActionTypes } from "@/fe-core/meta/types/pwa";

const initialState: PWAState = {
  showPwaModal: false,
};

const pwaReducer = (
  state: PWAState = initialState,
  action: PWAActions
): PWAState => {
  switch (action.type) {
    case pwaActionTypes.SHOW_PWA_MODAL:
      return {
        ...state,
        showPwaModal: true,
      };

    case pwaActionTypes.HIDE_PWA_MODAL:
      return {
        ...state,
        showPwaModal: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default pwaReducer;

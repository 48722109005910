import {
  all,
  AllEffect,
  fork,
  ForkEffect,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys, storageKeys } from "@/config/general";
import { encryptedJsonData, parseFrontendError } from "@/fe-core/helpers/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  IGetProfileViewModel,
  IProfileUpdatePayload,
  IProfileViewModel,
  IRoleViewModel,
} from "@/fe-core/meta/interfaces/user";
import { methodTypes, RootState } from "@/fe-core/meta/types/root";
import { GetProfileSuccessAction, userActionTypes } from "@/fe-core/meta/types/user";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import {
  getProfileFailure,
  getProfileRequest,
  getProfileSuccess,
  getRolesSuccess,
  profileUpdateFailure,
  profileUpdateSuccess,
  setIsUserProfileUpdated,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { sessionActionTypes } from "@/fe-core/meta/types/session";
import { isUserHasPlaceHolderValues } from "@/fe-core/helpers/profile";
import Storage, {CookiesStorage, SessionStorage} from "@/fe-core/helpers/storage";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";
import {
  AFFILIATE_MARKER_QUERY_KEYS,
  DEFAULT_AFFILIATE_MARKER_COOKIES_EXPIRES_DAYS,
} from "@/config/affiliate";

function* checkIfProfileNeedToUpdated() {
  try {
    const profileData: IProfileViewModel | null = yield select(
      (state: RootState) => state.user.profile.data
    );
    if (profileData) {
      const isUserNeedstoUpdateProfile = isUserHasPlaceHolderValues(profileData);
      yield put(setIsUserProfileUpdated(isUserNeedstoUpdateProfile));
    } else {
      const action: GetProfileSuccessAction = yield take(userActionTypes.GET_PROFILE_SUCCESS);
      const isUserNeedstoUpdateProfile = isUserHasPlaceHolderValues(action.payload);
      yield put(setIsUserProfileUpdated(isUserNeedstoUpdateProfile));
    }
  } catch (error) {

  }
}

function* getProfileRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IGetProfileViewModel | IErrorPayload> =
      yield fetch("/api/user/profile", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: IGetProfileViewModel = yield response.json();

      if (process.env.NEXT_PUBLIC_HAS_AFFILIATE_SYNC === "YES") {
        const affiliateMarker = viewModel?.userTrackingCodes?.find((code) =>
          AFFILIATE_MARKER_QUERY_KEYS?.includes(code?.codeKey)
        )?.codeValue;

        const getCookieOptions = () => {
          const cookieOptions: { expires: number | null; domain?: string } = {
            expires: DEFAULT_AFFILIATE_MARKER_COOKIES_EXPIRES_DAYS,
          };
          if (process.env.NODE_ENV !== "development") {
            const url =
              process.env.NEXT_PUBLIC_BASE_URL &&
              new URL(process.env.NEXT_PUBLIC_BASE_URL);
            cookieOptions.domain = url && url.hostname;
          }
          return cookieOptions;
        };

        CookiesStorage.setItem(
          AFFILIATE_MARKER_QUERY_KEYS?.[0],
          affiliateMarker as string,
          getCookieOptions()
        );

        Storage.set(storageKeys.AFFILIATE_MARKER, affiliateMarker as string);
        SessionStorage.setItem(storageKeys.AFFILIATE_MARKER, affiliateMarker as string);
      }

      try {
        Storage.set(localStorageKeys.PROFILE_DATA, encryptedJsonData(viewModel))
      } catch (error) { }

      yield put(getRolesSuccess(viewModel.tags as IRoleViewModel[]));
      yield put(getProfileSuccess(viewModel as IProfileViewModel));
      yield fork(checkIfProfileNeedToUpdated);
      //@todo, fix the consents
      //yield put(getConsentsSuccess(viewModel.consents as IConsentsViewModel))
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getProfileFailure(errorPayload));
    }
  } catch (error) {
    yield put(getProfileFailure(parseFrontendError(error)));
  }
}

function* getProfileUpdateSaga({ payload }: IProfileUpdatePayload) {
  try {
    const { sessionId } = yield select(sessionDataSelector);

    const response: IJsonResponse<IGetProfileViewModel | IErrorPayload> =
      yield fetch("/api/user/profile-update", <RequestInit>{
        body: JSON.stringify(payload),
        method: methodTypes.PUT,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
      });

    if (response.status == 200) {
      const viewModel: IGetProfileViewModel = yield response.json();
      Storage.set(localStorageKeys.PROFILE_DATA, encryptedJsonData(viewModel))

      yield put(profileUpdateSuccess());
      yield put(getProfileRequest());
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }
      const errorPayload: IErrorPayload = yield response.json();
      yield put(profileUpdateFailure(errorPayload));
    }
  } catch (error) {
    yield put(profileUpdateFailure(parseFrontendError(error)));
  }
}

export default function* _profileSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_PROFILE_REQUEST, getProfileRequestSaga),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getProfileRequestSaga),
    takeLatest(sessionActionTypes.AUTHENTICATE_SUCCESS, checkIfProfileNeedToUpdated),
    takeLatest(sessionActionTypes.LOGIN_SUCCESS, checkIfProfileNeedToUpdated),
    takeLatest(userActionTypes.PROFILE_UPDATE_REQUEST, getProfileUpdateSaga),
  ]);
}

import { IRegistrationcontentViewModel } from "@/fe-core/meta/interfaces/registrationContent";
import { RegistrationContentState } from "@/fe-core/meta/types/registrationContent";
import { RootState } from "@/fe-core/meta/types/root";

export const mainRegistrationContentSelector = ({
  registrationContent,
}: RootState): RegistrationContentState => registrationContent;

export const registrationContentSelector = (
  state: RootState
): IRegistrationcontentViewModel[] | null => {
  const { registrationContent } = mainRegistrationContentSelector(state);
  return registrationContent;
};

export const isPendingRegistrationContentSelector = (
  state: RootState
): boolean | null => {
  const { pending } = mainRegistrationContentSelector(state);
  return pending;
};

import {
  MenusState,
  MenuActions,
  menuActionTypes,
  menuStatus,
  loginMenuScreens,
} from "@/fe-core/meta/types/menus";

const initialState: MenusState = {
  loginMenuStatus: null,
  loginMenuScreen: loginMenuScreens.LOGIN,
  navMenuStatus: null,
  searchMenuStatus: null,
  updateProfileMenuStatus: null,
};

const menusReducer = (
  state: MenusState = initialState,
  action: MenuActions
): MenusState => {
  switch (action.type) {
    case menuActionTypes.OPEN_LOGIN_MENU:
      return {
        ...state,
        loginMenuStatus: menuStatus.OPEN,
        navMenuStatus: null,
      };

    case menuActionTypes.CLOSE_LOGIN_MENU:
      return {
        ...state,
        loginMenuStatus: menuStatus.CLOSED,
      };

    case menuActionTypes.SET_LOGIN_MENU_SCREEN: {
      const { payload } = action;
      return {
        ...state,
        loginMenuScreen: payload,
      };
    }

    case menuActionTypes.OPEN_NAV_MENU:
      return {
        ...state,
        loginMenuStatus: null,
        navMenuStatus: menuStatus.OPEN,
      };

    case menuActionTypes.CLOSE_NAV_MENU:
      return {
        ...state,
        navMenuStatus: menuStatus.CLOSED,
      };
    case menuActionTypes.OPEN_SEARCH_MENU:
      return {
        ...state,
        searchMenuStatus: menuStatus.OPEN,
      };

    case menuActionTypes.CLOSE_SEARCH_MENU:
      return {
        ...state,
        searchMenuStatus: menuStatus.CLOSED,
      };

    case menuActionTypes.OPEN_UPDATE_PROFILE_MENU:
      return {
        ...state,
        updateProfileMenuStatus: menuStatus.OPEN,
      };

    case menuActionTypes.CLOSE_UPDATE_PROFILE_MENU:
      return {
        ...state,
        updateProfileMenuStatus: menuStatus.CLOSED,
      };

    case menuActionTypes.CLOSE_ALL_MENUS:
      return {
        ...state,
        loginMenuStatus: null,
        navMenuStatus: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default menusReducer;

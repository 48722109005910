import { ICMSRecentWinnerViewModel, IGetRecentWinnerRequestPayload } from "@/fe-core/meta/interfaces/recentWinners";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  GetRecentWinnersFailureAction,
  GetRecentWinnersRequestAction,
  GetRecentWinnersSuccessAction,
  recentWinnersActionTypes,
} from "@/fe-core/meta/types/recentWinners";

export const getRecentWinnersRequest = (
  payload: IGetRecentWinnerRequestPayload
): GetRecentWinnersRequestAction => ({
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_REQUEST,
  payload
});

export const getRecentWinnersSuccess = (
  payload: ICMSRecentWinnerViewModel[]
): GetRecentWinnersSuccessAction => ({
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_SUCCESS,
  payload,
});

export const getRecentWinnersFailure = (
  payload: IErrorPayload
): GetRecentWinnersFailureAction => ({
  type: recentWinnersActionTypes.GET_RECENT_WINNERS_FAILURE,
  payload,
});

import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  IValidateUsernameResponsePayload,
  IValidateUsernameViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateUsernameRequestAction,
} from "@/fe-core/meta/types/user";
import {
  validateUsernameFailure,
  validateUsernameSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";

function* validateUsernameRequestSaga({
  payload,
}: ValidateUsernameRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/account#validateUsername",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const validateUsernameData: IValidateUsernameResponsePayload =
      emResponse?.kwargs;

    yield put(
      validateUsernameSuccess(
        validateUsernameData as IValidateUsernameViewModel
      )
    );
  } catch (error) {
    yield put(validateUsernameFailure(parseFrontendError(error)));
  }
}

function* validateUsernameSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.VALIDATE_USERNAME_REQUEST,
      validateUsernameRequestSaga
    ),
  ]);
}

export default validateUsernameSaga;

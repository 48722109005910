import {
  RegistrationContentActions,
  RegistrationContentState,
  registrationContentActionTypes,
} from "@/fe-core/meta/types/registrationContent";

const initialState: RegistrationContentState = {
  pending: false,
  registrationContent: null,
};

const registrationContentReducer = (
  state: RegistrationContentState = initialState,
  action: RegistrationContentActions
): RegistrationContentState => {
  switch (action.type) {
    case registrationContentActionTypes.GET_REGISTRATION_CONTENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case registrationContentActionTypes.GET_REGISTRATION_CONTENT_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case registrationContentActionTypes.GET_REGISTRATION_CONTENT_SUCCESS:
      return {
        ...state,
        pending: false,
        registrationContent: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default registrationContentReducer;

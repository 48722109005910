import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import {
  pageActionTypes,
  GetPageRequestAction,
} from "@/fe-core/meta/types/page";
import {
  ICmsBettingRulesPageData,
  ICmsCasinoGameRulesPageData,
  IShopItem,
} from "@/fe-core/meta/interfaces/page";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  getPageFailure,
  getPageSuccess,
  getShopsPageFailure,
  getShopsPageSuccess,
} from "@/fe-core/_redux/actions/pageActions";
import { parseFrontendError } from "@/fe-core/helpers/general";

function* getPageSaga(action: GetPageRequestAction) {
  const { slug } = action.payload;
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/page/${slug}`,
      {
        method: "GET",
      }
    );
    if (response.status === 200) {
      const page: ICmsBettingRulesPageData | ICmsCasinoGameRulesPageData =
        yield response.json();

      //@ts-ignore
      yield put(getPageSuccess(slug, page));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getPageFailure(errorPayload));
    }
  } catch (error) {
    yield put(getPageFailure(parseFrontendError(error)));
  }
}

function* getShopsPageSaga() {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/shops`,
      {
        method: "GET",
      }
    );
    if (response.status === 200) {
      const shopsData: IShopItem[] = yield response.json();

      yield put(getShopsPageSuccess(shopsData));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getShopsPageFailure(errorPayload));
    }
  } catch (error) {
    yield put(getPageFailure(parseFrontendError(error)));
  }
}

function* pageSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([takeLatest(pageActionTypes.GET_PAGE_REQUEST, getPageSaga)]);
  yield all([
    takeLatest(pageActionTypes.GET_SHOPS_PAGE_REQUEST, getShopsPageSaga),
  ]);
}
export default pageSaga;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  CasinoHistoryActions,
  CasinoHistoryState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: CasinoHistoryState = {
  pending: false,
  data: null,
  status: null,
  historyMoreDatapending: false
};

const casinoHistoryReducer = (
  state: CasinoHistoryState = initialState,
  action: CasinoHistoryActions | RootActions
): CasinoHistoryState => {
  switch (action.type) {
    case userActionTypes.CASINO_HISTORY_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.CASINO_HISTORY_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_CASINO_HISTORY_MORE_REQUEST:
      return {
        ...state,
        historyMoreDatapending: true,
      };
    case userActionTypes.GET_CASINO_HISTORY_MORE_SUCCESS:
      const {
        pagination,
        transactions
      } = action.payload;
      if (state?.data?.transactions) {
        return {
          ...state,
          pending: false,
          data: {
            pagination: pagination,
            transactions: state.data.transactions.concat(transactions),
          },
          historyMoreDatapending: false,
        };
      }
    case userActionTypes.GET_CASINO_HISTORY_MORE_FAILURE:
    case userActionTypes.CASINO_HISTORY_FAILURE:
      {
        const {
          payload: { message },
        } = action;
        return {
          ...state,
          pending: false,
          status: {
            success: false,
            message,
          },
          historyMoreDatapending: false,
        };
      }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default casinoHistoryReducer;

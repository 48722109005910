import { io, Socket } from "socket.io-client";
let socket: Socket | null = null;
export function getWebPubSub(userId: string | null, sessionId: string | null) {
  if (!socket) {
    socket = io(process.env.NEXT_PUBLIC_PUBSUB_URL, {
      path: process.env.NEXT_PUBLIC_PUBSUB_PATH,
      transports: ["websocket"],
      query: { userId, sessionId },
    });
  }

  return socket;
}

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { registrationContentActionTypes } from "@/fe-core/meta/types/registrationContent";
import { IRegistrationContentResponseViewModel } from "@/fe-core/meta/interfaces/registrationContent";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  getRegistrationContentFailure,
  getRegistrationContentSuccess,
} from "@/fe-core/_redux/actions/registrationContentActions";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildRegistrationQueryString } from "@/fe-core/helpers/registrationContent";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { CookiesStorage } from "@/fe-core/helpers/storage";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { localeActionTypes } from "@/fe-core/meta/types/locale";
import { themeActionTypes } from "@/fe-core/meta/types/theme";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";

function* getRegistrationContentSaga() {
  try {
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);

    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);
    const queryString: string = buildRegistrationQueryString(
      btag ? [btag] : [],
      theme
    );

    const response: IJsonResponse<IRegistrationContentResponseViewModel[]> =
      yield fetch(`/api/registrationcontent${queryString}`, {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IRegistrationContentResponseViewModel =
          yield response.json();
        yield put(getRegistrationContentSuccess(viewModel));
        break;
      default:
        throw new Error("Registration Content couldn't be fetched");
    }
  } catch (error) {
    yield put(getRegistrationContentFailure(parseFrontendError(error)));
  }
}

function* registrationContentSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      registrationContentActionTypes.GET_REGISTRATION_CONTENT_REQUEST,
      getRegistrationContentSaga
    ),
    takeLatest(
      localeActionTypes.SET_CURRENT_LOCALE,
      getRegistrationContentSaga
    ),
    takeLatest(themeActionTypes.SET_CURRENT_THEME, getRegistrationContentSaga),
  ]);
}

export default registrationContentSaga;

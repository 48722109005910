export function BuildECTransactionQueryString(
    startTime: string,
    endTime: string
): string {
    const queryParams = new URLSearchParams();
    queryParams.append("startTime", startTime);
    queryParams.append("endTime", endTime);
    const queryString = queryParams.toString();
    return queryString ? `?${queryString}` : "";
}

export function BuildCancelECTransactionQueryString(
    transactionId: string,
): string {
    const queryParams = new URLSearchParams();
    queryParams.append("transactionId", transactionId);
    const queryString = queryParams.toString();
    return queryString ? `?${queryString}` : "";
}

import { ISetLocalePayloadModel } from '../interfaces/locale'

export enum localeActionTypes {
  SET_CURRENT_LOCALE = "SET_CURRENT_LOCALE",
  GET_CURRENT_LOCALE = "GET_CURRENT_LOCALE",
  TOGGLE_LOCALE_CURRENCY_MODAL = "TOGGLE_LOCALE_CURRENCY_MODAL",
  SET_ACTIVE_LOCALE_CURRENCY_TAB = "SET_ACTIVE_LOCALE_CURRENCY_TAB",
}

export type GetCurrentLocaleAction = {
  type: localeActionTypes.GET_CURRENT_LOCALE
}

export type SetCurrentLocaleAction = {
  type: localeActionTypes.SET_CURRENT_LOCALE,
  payload: ISetLocalePayloadModel
}

export type ToggleLocaleCurrenyModalAction = {
  type: localeActionTypes.TOGGLE_LOCALE_CURRENCY_MODAL,
  payload: boolean
}

export type SetActiveLocaleCurrenyTabAction = {
  type: localeActionTypes.SET_ACTIVE_LOCALE_CURRENCY_TAB,
  payload: number
}

export type localeActions =
  | GetCurrentLocaleAction
  | SetCurrentLocaleAction
  | SetActiveLocaleCurrenyTabAction
  | ToggleLocaleCurrenyModalAction

export type localeState = {
  locale: string
  isOpenLocaleCurrenyModal: boolean
  activeLocaleCurrenyTabIndex: number
}

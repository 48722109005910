import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  CasinoEQJackpotFailureAction,
  CasinoEQJackpotRequestAction,
  CasinoEQJackpotSuccessAction,
  casinoJackpotActionTypes,
  CasinoJackpotFailureAction,
  CasinoJackpotMoreGamesGroupsPayload,
  CasinoJackpotRequestAction,
  CasinoJackpotSuccessAction,
  GetCasinoJackpotGroupsFailureAction,
  GetCasinoJackpotGroupsRequestAction,
  GetCasinoJackpotGroupsSuccessAction,
  GetCasinoJackpotMoreGamesGroupsFailureAction,
  GetCasinoJackpotMoreGamesGroupsRequestAction,
  GetCasinoJackpotMoreGamesGroupsSuccessAction,
} from "@/fe-core/meta/types/casinoJackpot";
import { ICasinoJackpotViewModel } from "@/fe-core/meta/interfaces/casinoJackpot";
import { ICasinoGroupViewModel } from "@/fe-core/meta/interfaces/casino";

export const casinoJackpotRequest = (): CasinoJackpotRequestAction => ({
  type: casinoJackpotActionTypes.CASINO_JACKPOT_REQUEST,
});

export const casinoJackpotSuccess = (
  payload: ICasinoJackpotViewModel
): CasinoJackpotSuccessAction => ({
  type: casinoJackpotActionTypes.CASINO_JACKPOT_SUCCESS,
  payload,
});

export const casinoJackpotFailure = (
  payload: IErrorPayload
): CasinoJackpotFailureAction => ({
  type: casinoJackpotActionTypes.CASINO_JACKPOT_FAILURE,
  payload,
});

export const casinoEQJackpotRequest = (): CasinoEQJackpotRequestAction => ({
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_REQUEST,
});

export const casinoEQJackpotSuccess = (
  payload: ICasinoJackpotViewModel
): CasinoEQJackpotSuccessAction => ({
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_SUCCESS,
  payload,
});

export const casinoEQJackpotFailure = (
  payload: IErrorPayload
): CasinoEQJackpotFailureAction => ({
  type: casinoJackpotActionTypes.CASINO_EQ_JACKPOT_FAILURE,
  payload,
});

export const getCasinoJackpotGroupsRequest =
  (): GetCasinoJackpotGroupsRequestAction => ({
    type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_REQUEST,
  });

export const getCasinoJackpotGroupsSuccess = (
  payload: ICasinoGroupViewModel[]
): GetCasinoJackpotGroupsSuccessAction => ({
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_SUCCESS,
  payload,
});

export const getCasinoJackpotGroupsFailure = (
  payload: IErrorPayload
): GetCasinoJackpotGroupsFailureAction => ({
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_GROUPS_FAILURE,
  payload,
});

export const getCasinoJackpotMoreGamesGroupsRequest = (
  payload: CasinoJackpotMoreGamesGroupsPayload
): GetCasinoJackpotMoreGamesGroupsRequestAction => ({
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_REQUEST,
  payload,
});

export const getCasinoJackpotMoreGamesGroupsSuccess = (
  payload: ICasinoGroupViewModel
): GetCasinoJackpotMoreGamesGroupsSuccessAction => ({
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_SUCCESS,
  payload,
});

export const getCasinoJackpotMoreGamesGroupsFailure = (
  payload: IErrorPayload
): GetCasinoJackpotMoreGamesGroupsFailureAction => ({
  type: casinoJackpotActionTypes.GET_CASINO_JACKPOT_MORE_GAMES_GROUPS_FAILURE,
  payload,
});

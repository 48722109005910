import { RootState } from "@/fe-core/meta/types/root";
import {
  loginMenuScreens,
  MenusState,
  menuStatus,
} from "@/fe-core/meta/types/menus";

export const menusSelector = ({ menus }: RootState): MenusState => menus;

export const loginMenuStatusSelector = (
  state: RootState
): menuStatus | null => {
  const { loginMenuStatus } = menusSelector(state);

  return loginMenuStatus;
};

export const searchModalSelector = (state: RootState): menuStatus | null => {
  const { searchMenuStatus } = menusSelector(state);

  return searchMenuStatus;
};

export const updateProfileModalSelector = (state: RootState): menuStatus | null => {
  const { updateProfileMenuStatus } = menusSelector(state);

  return updateProfileMenuStatus;
};

export const loginMenuScreenSelector = (state: RootState): loginMenuScreens => {
  const { loginMenuScreen } = menusSelector(state);

  return loginMenuScreen;
};

export const navMenuStatusSelector = (state: RootState): menuStatus | null => {
  const { navMenuStatus } = menusSelector(state);

  return navMenuStatus;
};

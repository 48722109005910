import {
  all,
  AllEffect,
  ForkEffect,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { headerKeys } from "@/config/general";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { IAffiliateViewModel } from "@/fe-core/meta/interfaces/user/affiliate/affiliate";
import { methodTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import { parseFrontendError } from "@/fe-core/helpers/general";
import {
  getAffiliateFailure,
  getAffiliateSuccess,
} from "@/fe-core/_redux/actions/userActions/affiliateActions/affiliateActions";
import { clearState } from "@/redux/actions/rootActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";

const ENDPOINT = "/api/user/affiliate";

function* getAffiliateRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<IAffiliateViewModel | IErrorPayload> =
      yield fetch(ENDPOINT, <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      });
    if (response.status == 200) {
      const viewModel: IAffiliateViewModel = yield response.json();
      yield put(getAffiliateSuccess(viewModel));
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(getAffiliateFailure(errorPayload));
    }
  } catch (error) {
    yield put(getAffiliateFailure(parseFrontendError(error)));
  }
}

export default function* _affiliateSaga_nwa(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.GET_CAMPAIGNS_REQUEST, getAffiliateRequestSaga),
  ]);
}

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
} from "redux-saga/effects";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  ICMSLatestBetViewModel,
  ILatestBetViewModel,
} from "@/fe-core/meta/interfaces/latestBets";
import {
  latestBetsActionTypes,
  GetLatestBetsRequestAction,
} from "@/fe-core/meta/types/latestBets";
import {
  getLatestBetsFailure,
  getLatestBetsSuccess,
} from "@/fe-core/_redux/actions/latestBetsActions";

import { parseFrontendError } from "@/fe-core/helpers/general";
import { methodTypes } from "@/fe-core/meta/types/root";

function* getLatestBetsSaga({}: GetLatestBetsRequestAction) {
  try {
    const response: IJsonResponse<ILatestBetViewModel[] | IErrorPayload> =
      yield fetch(`/api/latestBets`, {
        method: methodTypes.GET,
      });

    if (response.status == 200) {
      const bets: ILatestBetViewModel[] = yield response.json();

      const cmsWinners: ICMSLatestBetViewModel[] = bets.map(
        ({ gameName, username, wagered, winning, currency }) => {
          return {
            gameName,
            username,
            wagered,
            winning,
            currency,
          };
        }
      );

      yield put(getLatestBetsSuccess(cmsWinners));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(getLatestBetsFailure(errorPayload));
    }
  } catch (error) {
    yield put(getLatestBetsFailure(parseFrontendError(error)));
  }
}

function* casinoSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(
      latestBetsActionTypes.GET_LATEST_BETS_REQUEST,
      getLatestBetsSaga
    ),
  ]);
}

export default casinoSaga;

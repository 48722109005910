import { IStatus, IErrorPayload } from '../interfaces/root'
import { INavViewModel } from '../interfaces/nav'

export enum navActionTypes {
  NAV_REQUEST = 'NAV_REQUEST',
  NAV_SUCCESS = 'NAV_SUCCESS',
  NAV_FAILURE = 'NAV_FAILURE',
  CLEAR_NAV = 'CLEAR_NAV',
}

export type NavRequestAction = {
  type: navActionTypes.NAV_REQUEST
  payload: string
}

export type NavSuccessAction = {
  type: navActionTypes.NAV_SUCCESS
  payload: INavViewModel
}

export type NavFailureAction = {
  type: navActionTypes.NAV_FAILURE
  payload: IErrorPayload
}

export type ClearNavAction = {
  type: navActionTypes.CLEAR_NAV
}

export type NavActions =
  | NavRequestAction
  | NavSuccessAction
  | NavFailureAction
  | ClearNavAction

export type NavState = {
  pending: boolean
  data: INavViewModel | null
  status: IStatus | null
}

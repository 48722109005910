import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_OFFSET,
  STATE_ALL,
} from "@/config/tournaments";
import {
  TournamentsActions,
  tournamentsActionTypes,
  TournamentsState,
} from "@/fe-core/meta/types/tournaments";

const initialState: TournamentsState = {
  pending: false,
  tournamentsList: null,
  status: null,

  tournamentPending: {},
  tournament: {},
  tournamentStatus: null,

  leaderboardPending: {},
  leaderboardData: {},
  leaderboardStatus: null,

  selectedFilterState: {
    status: STATE_ALL,
    offset: DEFAULT_OFFSET,
    currentPage: DEFAULT_CURRENT_PAGE,
  },

  eligibleTournamentByGameSlug: null,
  isEligibleTournament: null,
  isEligibleTournamentByGameSlugPending: false
};

const tournamentsReducer = (
  state: TournamentsState = initialState,
  action: TournamentsActions
): TournamentsState => {
  switch (action.type) {
    case tournamentsActionTypes.SET_TOURNAMENTS_LIST_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
    case tournamentsActionTypes.TOURNAMENTS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        // tournamentsList: null,
        // status: null,
      };
    case tournamentsActionTypes.TOURNAMENTS_LIST_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        tournamentsList: payload,
        status: {
          success: true,
        },
      };
    }
    case tournamentsActionTypes.TOURNAMENTS_LIST_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        tournamentsList: null,
        status: {
          success: false,
          message,
        },
      };
    }
    case tournamentsActionTypes.TOURNAMENT_REQUEST: {
      const { id } = action.payload;
      return {
        ...state,
        tournamentPending: {
          ...state.tournamentPending,
          [id]: true,
        },
        // tournament: {},
        tournamentStatus: null,
      };
    }
    case tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_REQUEST:
      const { id } = action.payload;
      return {
        ...state,
        tournamentPending: {
          ...state.tournamentPending,
          [id]: false,
        },
        // tournament: {},
        tournamentStatus: null,
      };
    case tournamentsActionTypes.SET_TOURNAMENT_PENDING:
      const { isPending, tournamentId: newTournamentId } = action.payload;
      return {
        ...state,
        tournamentPending: {
          ...state.tournamentPending,
          [newTournamentId]: isPending,
        },
      };
    case tournamentsActionTypes.TOURNAMENT_SUCCESS: {
      const { tournamentId, data } = action.payload;
      return {
        ...state,
        tournamentPending: {
          ...state.tournamentPending,
          [tournamentId]: false,
        },
        tournament: {
          ...state.tournament,
          [tournamentId]: data,
        },
        tournamentStatus: {
          success: true,
        },
      };
    }
    case tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_SUCCESS:
      const { tournamentId, games } = action.payload;
      return {
        ...state,
        tournamentPending: {
          ...state.tournamentPending,
          [tournamentId]: false,
        },
        tournament: {
          ...state.tournament,
          [tournamentId]: {
            ...state.tournament[tournamentId],
            item: {
              ...state.tournament[tournamentId].item,
              games: {
                total: state.tournament[tournamentId].item.games.total,
                count:
                  state.tournament[tournamentId].item.games.count + games.count,
                items: state.tournament[tournamentId].item.games.items.concat(
                  games.items
                ),
              },
            },
          },
        },
        tournamentStatus: {
          success: true,
        },
      };
    case tournamentsActionTypes.TOURNAMENT_FAILURE:
    case tournamentsActionTypes.GET_TOURNAMENT_MORE_GAMES_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        tournamentPending: {},
        tournament: {},
        tournamentStatus: {
          success: false,
          message,
        },
      };
    }
    case tournamentsActionTypes.LEADERBOARD_REQUEST: {
      const { tournamentId } = action.payload;
      return {
        ...state,
        leaderboardPending: {
          ...state.leaderboardPending,
          [tournamentId]: true,
        },
        leaderboardData: {
          ...state.leaderboardData,
          [tournamentId]: null,
        },
      };
    }
    case tournamentsActionTypes.SET_LEADERBOARD_PENDING:
      const { isPending: isPendingLeaderboard, tournamentId: leaderboardTournamentId } = action.payload;
      return {
        ...state,
        leaderboardPending: {
          ...state.leaderboardPending,
          [leaderboardTournamentId]: isPendingLeaderboard,
        },
      };
    case tournamentsActionTypes.LEADERBOARD_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        leaderboardPending: {
          ...state.leaderboardPending,
          [payload.tournamentId]: false,
        },
        leaderboardData: {
          ...state.leaderboardData,
          [payload.tournamentId]: payload.data,
        },
      };
    }
    case tournamentsActionTypes.LEADERBOARD_FAILURE: {
      const { tournamentId } = action.payload;
      return {
        ...state,
        leaderboardPending: {},
        leaderboardData: {
          ...state.leaderboardData,
          [tournamentId]: null,
        },
        leaderboardStatus: {
          success: false,
          message: action.payload.data.message,
        },
      };
    }
    case tournamentsActionTypes.SET_SINGLE_TOURNAMENT_BY_GAME_SLUG_PENDING:
      const { isEligibleTournamentByGameSlugPending } = action?.payload;
      return {
        ...state,
        isEligibleTournamentByGameSlugPending,
      };
    case tournamentsActionTypes.SINGLE_TOURNAMENT_BY_GAME_SLUG_SUCCESS:
      const { eligibleTournamentByGameSlug, isEligibleTournament } = action?.payload;
      return {
        ...state,
        isEligibleTournament,
        eligibleTournamentByGameSlug,
        isEligibleTournamentByGameSlugPending: false
      };
    case tournamentsActionTypes.SET_SELECTED_FILTER_STATE:
      const { payload } = action;
      return {
        ...state,
        selectedFilterState: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tournamentsReducer;

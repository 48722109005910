import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
  fork,
  delay,
  cancel,
} from "redux-saga/effects";
import {
  AnonymousSessionRequestAction,
  AuthenticateRequestAction,
  ImpersonateAction,
  LoginRequestAction,
  PrepareLoginRequestAction,
  sessionActionTypes,
} from "@/fe-core/meta/types/session";
import {
  IAuthenticateRequestPayload,
  IAuthenticateViewModel,
  IAnonymousSessionViewModel,
  ILoginViewModel,
  ISessionViewModel,
  IPrepareLoginViewModel,
} from "@/fe-core/meta/interfaces/session";
import { IErrorPayload, IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { methodTypes } from "@/fe-core/meta/types/root";
import { DEFAULT_CURRENCY, headerKeys, storageKeys } from "@/config/general";
import Storage, { CookiesStorage } from "@/fe-core/helpers/storage";
import {
  authenticateFailure,
  authenticateRequest,
  authenticateSuccess,
  anonymousSessionSuccess,
  loginFailure,
  loginSuccess,
  prepareLoginFailure,
  prepareLoginSuccess,
  logoutFailure,
  logoutSuccess,
  updateIsFirstLogin,
} from "@/fe-core/_redux/actions/sessionActions";
import {
  decryptedJsonData,
  decryptedStringData,
  encryptedStringData,
  OMEGA_STATUS_TYPES,
  parseFrontendError,
} from "@/fe-core/helpers/general";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { clearState } from "@/fe-core/_redux/actions/rootActions";
import { RoutingState } from "@/fe-core/meta/types/routing";
import { loginNextRouteSelector } from "@/fe-core/_redux/selectors/routingSelectors";
import Router from "next/router";
import {
  openLoginMenu,
  setLoginMenuScreen,
} from "@/fe-core/_redux/actions/menusActions";
import countryToCurrency from "country-to-currency";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  getBalanceRequest,
  getProfileRequest,
  getProfileSuccess,
  getRolesSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { currencies } from "@/config/general";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { themeSelector } from "../selectors/themeSelectors";
import { loginMenuScreens } from "@/fe-core/meta/types/menus";
import {
  IProfileViewModel,
  IRoleViewModel,
} from "@/fe-core/meta/interfaces/user";
import { clearStorage } from "@/fe-core/helpers/register";
import { getCashbackBalanceRequest } from "../actions/userActions/cashbackActions";
import { getLevelupDetailsRequest } from "../actions/userActions/levelupDetailsActions";
function* loginRequestSaga({ payload }: LoginRequestAction) {
  try {
    const { locale } = yield select(localeSelector);
    const { theme } = yield select(themeSelector);
    const response: IJsonResponse<ILoginViewModel | IErrorPayload> =
      yield fetch("/api/session/login", <RequestInit>{
        method: methodTypes.POST,
        body: JSON.stringify(payload),
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      });

    if (response.status == 200) {
      const viewModel: ILoginViewModel = yield response.json();
      CookiesStorage.setItem(storageKeys.SESSION_ID, viewModel.sessionKey);
      Storage.set(storageKeys.SESSION_ID, viewModel.sessionKey);
      Storage.set(localStorageKeys.IDENTIFIED, "1");
      Storage.set(localStorageKeys.IS_LOGGED_IN, encryptedStringData("true"));
      yield put(updateIsFirstLogin(!!(viewModel?.isFirstLogin === "true")));
      yield put(
        authenticateRequest({
          sessionId: viewModel.sessionKey,
          isAutoLogin: true,
          locale,
          theme,
        })
      );
      yield put(loginSuccess());
      yield put(getProfileRequest());

      const loginNextRoute: RoutingState = yield select(loginNextRouteSelector);
      if (loginNextRoute) {
        Router.push(String(loginNextRoute));
      }
    } else {
      if (response.status === 401)
        yield put(setLoginMenuScreen(loginMenuScreens.VERIFY_EMAIL_INPUT));

      const errorPayload: IErrorPayload = yield response.json();
      yield put(loginFailure(errorPayload));
      clearStorage();
    }
  } catch (error) {
    clearStorage();
    yield put(clearState());
    yield put(loginFailure(parseFrontendError(error)));
    yield put(openLoginMenu());
  }
}

function* prepareLoginRequestSaga({ payload }: PrepareLoginRequestAction) {
  try {
    const response: IJsonResponse<ILoginViewModel | IErrorPayload> =
      yield fetch("/api/session/prepareLogin", <RequestInit>{
        method: methodTypes.POST,
        body: JSON.stringify(payload),
      });

    if (response.status == 200) {
      const viewModel: IPrepareLoginViewModel = yield response.json();

      console.log("prepareLoginRequestSaga", viewModel);
      yield put(prepareLoginSuccess(viewModel));
    } else {
      const errorPayload: IErrorPayload = yield response.json();
      yield put(prepareLoginFailure(errorPayload));
      clearStorage();
    }
  } catch (error) {
    clearStorage();
    yield put(clearState());
    yield put(prepareLoginFailure(parseFrontendError(error)));
  }
}

function* balanceInterval() {
  while (true) {
    yield put(getBalanceRequest());
    yield put(getCashbackBalanceRequest());
    yield delay(5000);
  }
}

function* levelupDetailsInterval() {
  while (true) {
    if (process.env.NEXT_PUBLIC_HAS_LEVELUP === "YES")
      yield put(getLevelupDetailsRequest());
    yield delay(2000);
  }
}

let intervalTask: any;
let levelupIntervalTask: any;

function* authenticateRequestSaga({ payload }: AuthenticateRequestAction) {
  try {
    const { sessionId } = payload;

    if (decryptedStringData(localStorageKeys.IS_LOGGED_IN) === "true") {
      const userData: ISessionViewModel = decryptedJsonData(
        localStorageKeys.USER_DATA
      );
      const profileData: IProfileViewModel = decryptedJsonData(
        localStorageKeys.PROFILE_DATA
      );
      if (userData) yield put(authenticateSuccess(userData));
      if (profileData) {
        yield put(getProfileSuccess(profileData));
        yield put(getRolesSuccess(profileData.tags as IRoleViewModel[]));
      }
    }
    const response: IJsonResponse<IAuthenticateViewModel | IErrorPayload> =
      yield fetch("/api/session/authenticate", <RequestInit>{
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.SESSION_ID]: sessionId,
        }),
      });

    if (response.status === 200) {
      Storage.set(localStorageKeys.IS_LOGGED_IN, encryptedStringData("true"));

      yield put(
        authenticateSuccess({ sessionId, status: OMEGA_STATUS_TYPES.SUCCESS })
      );
      // yield put(setRestrictionsUserDetails({ userId, sessionId }));

      intervalTask = yield fork(balanceInterval);
      levelupIntervalTask = yield fork(levelupDetailsInterval);

      yield all([put(getBalanceRequest()), put(getProfileRequest())]);
    } else {
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(authenticateFailure(errorPayload));
      clearStorage();
    }
  } catch (error) {
    clearStorage();
    yield put(authenticateFailure(parseFrontendError(error)));
  }
}

function* anonymousSessionRequestSaga({
  payload,
}: AnonymousSessionRequestAction) {
  try {
    const { locale, theme } = payload;
    let countryCookie = CookiesStorage.getItem(storageKeys.COUNTRY) as string;
    const [lang, country] = locale.split("-");
    const checkCountry = countryCookie || country;
    //@ todo, fetch from supported Currencies
    const currencyCode = checkCountry
      ? (countryToCurrency as any)[checkCountry.toUpperCase()]
      : DEFAULT_CURRENCY;

    const isIdentified = !!localStorage.getItem(localStorageKeys.IDENTIFIED);
    const sessionViewModel: IAnonymousSessionViewModel = {
      currency: {
        code: currencies[currencyCode] ?? DEFAULT_CURRENCY,
        fiat: true,
        fractionDigits: 2,
        symbol: currencyCode && getSymbolFromCurrency(currencyCode),
      },
      locale,
      isIdentified,
      theme:
        theme && theme.charAt(0).toUpperCase() + theme.slice(1).toLowerCase(),
    };

    yield put(anonymousSessionSuccess(sessionViewModel));
  } catch (error) {
    yield put(authenticateFailure(parseFrontendError(error)));
  }
}

function* logoutRequestSaga({ payload }: LoginRequestAction) {
  try {
    const sessionData: ISessionViewModel | null = yield select(
      sessionDataSelector
    );
    const sessionId = sessionData?.sessionId;
    if (!sessionId) {
      yield put(logoutSuccess());
      yield put(clearState());
      return;
    }
    const headers = new Headers();
    if (sessionId) {
      headers.append(headerKeys.SESSION_ID, sessionId);
    }
    const response: IJsonResponse<void | IErrorPayload> = yield fetch(
      "/api/session/logout",
      <RequestInit>{
        method: methodTypes.DELETE,
        body: JSON.stringify(payload),
        headers: headers,
      }
    );
    clearStorage();
    if (response.status == 204) {
      if (intervalTask) {
        yield cancel(intervalTask);
      }
      if (levelupIntervalTask) {
        yield cancel(levelupIntervalTask);
      }
      yield put(logoutSuccess());
      yield put(clearState());
    } else {
      window.location.href = "/";
      if (response.status === 401) {
        yield put(clearState());
      }

      const errorPayload: IErrorPayload = yield response.json();
      yield put(logoutFailure(errorPayload));
    }
  } catch (error) {
    yield put(logoutFailure(parseFrontendError(error)));
  }
}

function* logiWithImpersonateSaga({ payload }: ImpersonateAction) {
  yield put(
    authenticateRequest({
      sessionId: payload,
    } as IAuthenticateRequestPayload)
  );
  yield put(loginSuccess());
  yield put(getProfileRequest());
}

function* clearStorageSaga() {
  try {
    clearStorage();
  } catch (error) {}
}

function* sessionSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(sessionActionTypes.LOGIN_REQUEST, loginRequestSaga),
    takeLatest(
      sessionActionTypes.PREPARE_LOGIN_REQUEST,
      prepareLoginRequestSaga
    ),
    takeLatest(
      sessionActionTypes.AUTHENTICATE_REQUEST,
      authenticateRequestSaga
    ),
    takeLatest(
      sessionActionTypes.ANONYMOUS_SESSION_REQUEST,
      anonymousSessionRequestSaga
    ),

    takeLatest(sessionActionTypes.IMPERSONATE, logiWithImpersonateSaga),
    takeLatest(sessionActionTypes.LOGOUT_REQUEST, logoutRequestSaga),
    takeLatest(
      [
        sessionActionTypes.AUTHENTICATE_FAILURE,
        sessionActionTypes.LOGIN_FAILURE,
        sessionActionTypes.CLOSE_SESSION_EXPIRED_MODAL,
        sessionActionTypes.LOGOUT_SUCCESS,
      ],
      clearStorageSaga
    ),
  ]);
}

export default sessionSaga;

import { MAX_FILE_SIZE } from "@/config/general";
import { I18N_NAMESPACE } from "@/config/i18n";
import {
  closeUploadDocumentModal,
  uploadDocumentRequest,
} from "@/fe-core/_redux/actions/userActions";
import {
  uploadDocumentPendingSelector,
  uploadDocumentShowModalSelector,
} from "@/fe-core/_redux/selectors/userSelectors";
import {
  fileSize,
  validateDocumentExpiryDate,
  validateDocumentFile,
  validateDocumentIssueDate,
  validateDocumentNumber,
} from "@/fe-core/helpers/general";
import { documentTypes } from "@/fe-core/meta/interfaces/user";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslations } from "next-intl";
import { FormEvent, memo, useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import Modal from "../Modal";
import SVGIcon from "../SVGIcon";

interface IDocumentData {
  documentType?: documentTypes | null;
  issueDate?: string;
  expiryDate?: string;
  documentNumber?: string;
  file?: FileList | null;
}

interface IDocumentError {
  documentType?: string | null;
  issueDate?: string | null;
  expiryDate?: string | null;
  documentNumber?: string | null;
  file?: string | null;
}

const UploadDocumentModal = ({}) => {
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.DOCUMENTS);

  const selectedDocument = useSelector(uploadDocumentShowModalSelector);
  const pending = useSelector(uploadDocumentPendingSelector);
  const [documentData, setDocumentData] = useState<IDocumentData>({});
  const [documentError, setDocumentError] = useState<IDocumentError>({});

  const fileInputRef = useRef(null);

  const isInValidForm = useMemo(() => {
    return (
      Object.values(documentError).some((value) => value) ||
      (selectedDocument?.documentNumberRequired &&
        !documentData?.documentNumber) ||
      (selectedDocument?.issueDateRequired && !documentData?.issueDate) ||
      (selectedDocument?.expiryDateRequired && !documentData?.expiryDate)
    );
  }, [documentError, selectedDocument, documentData]);

  const closeUploadModal = () => {
    dispatch(closeUploadDocumentModal());
  };

  useEffect(() => {
    setDocumentData({});
    setDocumentError({});
  }, [selectedDocument]);

  useEffect(() => {
    //@ts-ignore
    if (fileInputRef?.current) fileInputRef.current.files = documentData?.file;
  }, [documentData?.file]);

  const onDocumentNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const documentNumber = event.target.value;
    setDocumentData((documentData) => ({
      ...documentData,
      documentNumber,
    }));
    setDocumentError((documentError) => ({
      ...documentError,
      documentNumber: validateDocumentNumber(documentNumber as string, t),
    }));
  };

  const onDocumentNumberBlur = () => {
    const error = validateDocumentNumber(
      documentData?.documentNumber as string,
      t
    );
    setDocumentError((documentError) => ({
      ...documentError,
      documentNumber: error,
    }));
  };

  const onIssueDateChange = (value: Date) => {
    const date = value?.toISOString();
    setDocumentData((documentData) => ({
      ...documentData,
      issueDate: date,
    }));
    setDocumentError((documentError) => ({
      ...documentError,
      issueDate: validateDocumentIssueDate(date, t),
    }));
  };

  const onIssueDateBlur = () => {
    setDocumentError((documentError) => ({
      ...documentError,
      issueDate: validateDocumentIssueDate(documentData.issueDate as string, t),
    }));
  };

  const onExpiryDateChange = (value: Date) => {
    const date = value?.toISOString();
    setDocumentData((documentData) => ({
      ...documentData,
      expiryDate: date,
    }));
    setDocumentError((documentError) => ({
      ...documentError,
      expiryDate: validateDocumentExpiryDate(date, t),
    }));
  };

  const onExpiryDateBlur = () => {
    setDocumentError((documentError) => ({
      ...documentError,
      expiryDate: validateDocumentExpiryDate(
        documentData.expiryDate as string,
        t
      ),
    }));
  };

  const uploadDocument = (event: FormEvent) => {
    event.preventDefault();

    if (selectedDocument?.issueDateRequired && !documentData.issueDate) {
      onIssueDateBlur();
      return;
    }

    if (selectedDocument?.expiryDateRequired && !documentData.expiryDate) {
      onExpiryDateBlur();
      return;
    }

    if (
      selectedDocument?.documentNumberRequired &&
      !documentData.documentNumber
    ) {
      onDocumentNumberBlur();
      return;
    }

    const error = validateDocumentFile(
      documentData.file,
      acceptedFileTypes,
      !!selectedDocument?.multipleFileRequired,
      t
    );

    setDocumentError((documentError) => ({ ...documentError, file: error }));
    if (error) return;

    dispatch(
      uploadDocumentRequest({
        file: documentData.file as FileList,
        documentType: selectedDocument?.documentType as documentTypes,
        documentNumber: documentData?.documentNumber,
        issueDate: documentData.issueDate,
        expiryDate: documentData.expiryDate,
        multipleFileRequired: !!selectedDocument?.multipleFileRequired,
        id: selectedDocument?.userDocumentId,
      })
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const dataTransfer = new DataTransfer();
    const allFiles = [
      ...Array.from(documentData.file || []),
      ...Array.from(files || []),
    ].filter(
      (file, index, self) =>
        index === self.findIndex((f) => f.name === file.name)
    );
    allFiles.forEach((file) => dataTransfer.items.add(file));

    const selectedFiles = selectedDocument?.multipleFileRequired
      ? dataTransfer.files
      : files;
    const error = validateDocumentFile(
      selectedDocument?.multipleFileRequired ? selectedFiles : files,
      acceptedFileTypes,
      !!selectedDocument?.multipleFileRequired,
      t
    );

    setDocumentData((documentData) => {
      return {
        ...documentData,
        file: selectedFiles,
      };
    });
    setDocumentError((documentError) => ({ ...documentError, file: error }));
  };

  const acceptedFileTypes = [
    "application/pdf",
    "image/png",
    "image/jpeg",
    "image/jpg",
  ];

  const deleteFile = (file: number) => {
    setDocumentData((documentData) => {
      if (documentData?.file) {
        {
          const dataTransfer = new DataTransfer();
          const updatedFiles = Array.from(documentData?.file)?.filter(
            (_, i) => i !== file
          );
          updatedFiles.forEach((file) => dataTransfer.items.add(file));

          const error = validateDocumentFile(
            dataTransfer.files,
            acceptedFileTypes,
            !!selectedDocument?.multipleFileRequired,
            t
          );

          setDocumentError((documentError) => ({
            ...documentError,
            file: error,
          }));
          return {
            ...documentData,
            file: dataTransfer.files as FileList,
          };
        }
      } else return documentData;
    });
  };

  return (
    <Modal
      isOpen={!!selectedDocument}
      onClose={closeUploadModal}
      overFlowVisible
    >
      <div className="h-full ">
        <h5 className="text-base font-bold uppercase mb-6">
          {selectedDocument?.documentType}
        </h5>

        <form onSubmit={uploadDocument}>
          <div className="!grid gap-5 birthdate-picker-group">
            {selectedDocument?.documentNumberRequired && (
              <div className=" ">
                <input
                  onChange={onDocumentNumberChange}
                  onBlur={onDocumentNumberBlur}
                  value={documentData?.documentNumber}
                  placeholder={t("document-number-placeholder")}
                  disabled={pending}
                  className="font-bold form-input placeholder:font-normal"
                  autoFocus
                />
                {documentError.documentNumber && (
                  <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                    {documentError.documentNumber}
                  </div>
                )}
              </div>
            )}
            {selectedDocument?.issueDateRequired && (
              <>
                <DatePicker
                  wrapperClassName="block w-full"
                  placeholderText={t("issue-date-placeholder")}
                  className="w-full block px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white focus:border-primary-500 focus:shadow-none focus:ring-0 placeholder:text-tertiary placeholder:font-bold"
                  onChange={onIssueDateChange}
                  onBlur={onIssueDateBlur}
                  maxDate={dayjs().subtract(1, "day").toDate()}
                  yearDropdownItemNumber={100}
                  showYearDropdown
                  scrollableYearDropdown
                  selected={
                    documentData?.issueDate
                      ? new Date(documentData?.issueDate)
                      : null
                  }
                  onSelect={onIssueDateChange}
                  disabled={pending}
                />
                {documentError.issueDate && (
                  <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                    {documentError.issueDate}
                  </div>
                )}
              </>
            )}
            {selectedDocument?.expiryDateRequired && (
              <>
                <DatePicker
                  wrapperClassName="block w-full"
                  placeholderText={t("expiry-date-placeholder")}
                  className="w-full block px-4 text-sm font-normal leading-5.5 py-2.5 border rounded-lg text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white focus:border-primary-500 focus:shadow-none focus:ring-0 placeholder:text-tertiary placeholder:font-bold"
                  onChange={onExpiryDateChange}
                  onBlur={onExpiryDateBlur}
                  minDate={dayjs().add(1, "day").toDate()}
                  yearDropdownItemNumber={100}
                  showYearDropdown
                  scrollableYearDropdown
                  selected={
                    documentData?.expiryDate
                      ? new Date(documentData?.expiryDate)
                      : null
                  }
                  onSelect={onExpiryDateChange}
                  disabled={pending}
                />
                {documentError.expiryDate && (
                  <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                    {documentError.expiryDate}
                  </div>
                )}
              </>
            )}
          </div>
          <div>
            <div className="col-span-full mb-4">
              <p className="block text-sm/6 font-medium text-white/80 mt-4">
                {t("document-files")}
              </p>
              <label
                htmlFor="file-upload"
                className="mt-2 flex justify-center rounded-lg border border-dashed border-sec px-6 py-5 border-tertiary-400 cusror-pointer hover:border-tertiary-200"
              >
                <div className="text-center">
                  <SVGIcon
                    icon="kyc"
                    aria-hidden="true"
                    className="mx-auto size-8 text-white/20"
                  />
                  <div className="mt-4 flex text-center justify-center">
                    <input
                      className="sr-only"
                      type="file"
                      multiple={!!selectedDocument?.multipleFileRequired}
                      accept={acceptedFileTypes.join(",")}
                      onChange={handleFileChange}
                      disabled={pending}
                      ref={fileInputRef}
                      id="file-upload"
                    />
                  </div>
                  <p className="text-xs/5 text-white/50">
                    Upload file <br /> PNG, JPG, GIF up to 10MB
                  </p>
                </div>
              </label>
            </div>

            {documentError?.file && (
              <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                {documentError?.file}
              </div>
            )}

            {documentData?.file?.length > 0 && (
              <div>
                <p className="font-bold uppercase text-base mb-2 mt-5">
                  {t("uploaded-files")}:
                </p>
                <ul className="space-y-1">
                  {Array.from(documentData?.file).map((file, index) => (
                    <li key={index}>
                      <div
                        className={classNames(
                          "flex p-4 py-3 rounded bg-tertiary-700 text-xs justify-between items-center",
                          {
                            "text-error dark:text-error-500":
                              file.size > MAX_FILE_SIZE,
                          }
                        )}
                      >
                        <div>
                          <p>
                            {file.name} <span>({fileSize(file.size)})</span>
                          </p>
                        </div>

                        {selectedDocument?.multipleFileRequired && (
                          <Button
                            disabled={pending}
                            onClick={() => deleteFile(index)}
                            size="small"
                            square
                            label={
                              <SVGIcon icon="delete" className="w-6 h-5" />
                            }
                          />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="text-center mt-6">
            <Button
              label={t("upload-button")}
              disabled={!!isInValidForm || pending}
              loading={pending}
              buttonType="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default memo(UploadDocumentModal);

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import { ISportViewModel } from "@/fe-core/meta/interfaces/sports";
import {
  SportsFailureAction,
  SportsRequestAction,
  SportsSuccessAction,
  sportsActionTypes,
} from "@/fe-core/meta/types/sports";

export const sportsRequest = (): SportsRequestAction => ({
  type: sportsActionTypes.SPORTS_REQUEST,
});

export const sportsSuccess = (
  payload: ISportViewModel[]
): SportsSuccessAction => ({
  type: sportsActionTypes.SPORTS_SUCCESS,
  payload,
});

export const sportsFailure = (payload: IErrorPayload): SportsFailureAction => ({
  type: sportsActionTypes.SPORTS_FAILURE,
  payload,
});

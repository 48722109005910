"use client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useRouter } from "next/navigation";
import {
  clearDeposit,
  depositRequest,
} from "../../fe-core-omega/_redux/actions/userActions";
const Postmessage = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const getIframeMessage = (e: MessageEvent) => {
      {
        const message = e.data;
        if (message.hasOwnProperty("redirect")) {
          if (message.redirect === "mm-wm-hc-init-deposit-quick") {
            dispatch(clearDeposit());
            dispatch(depositRequest({ amount: 0, isShortCashier: true }));
          } else if (message.redirect === "mm-hc-contact") {
            router.push("/wiki/contact");
          } else if (message.redirect === "mm-hc-sports") {
            router.push("/sports");
          } else if (message.redirect === "mm-hc-casino") {
            router.push("/casino");
          }
        } else if (message.hasOwnProperty("MMFE:openFullCashier")) {
          if (message["MMFE:openFullCashier"]) {
            router.push("/profile/transactions/depositmoney");
          }
        }
      }
    };

    window.addEventListener("message", getIframeMessage);
    return () => {
      window.removeEventListener("message", getIframeMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Postmessage;

import { fork } from "redux-saga/effects";
import navSaga from "./navSaga";
import userSaga from "./userSaga";
import casinoSaga from "./_casinoSaga_nwa";
import sessionSaga from "./_sessionSaga_nwa";
import socketSaga from "./socketSaga";
import webPubSubSaga from "./webPubSubSaga";
import bannerSaga from "./bannerSaga";
import handshakeSaga from "./_ceSessionSaga_nwa";
import deadSimpleChatSaga from "./deadSimpleChatSaga";
import casinoJackpotSaga from "./casinoJackpotSaga";
import sportsSaga from "./sportsSaga";
import activateEmailSaga from "./_activateEmailSaga_nwa";
import realityCheckSaga from "./userSaga/realityCheckSaga";
import recentWinnersSaga from "./recentWinnersSaga";
import latestBetsSaga from "./latestBetsSaga";
import promotionsSaga from "./promotionsSaga";
import casinoGamesHighlightedSaga from "./casinoGamesHighlightedSaga";
import pageSaga from "./pageSaga";
import casinoLiveRTPSaga from "./casinoLiveRTPSaga";
import tournamentsSaga from "./tournaments/_tournamentsSaga_nwa";
import topWinnersSaga from "./topWinnersSaga";
import jackpotWinnersSaga from "./jackpotWinnersSaga";
import popUpsSaga from "./popUpsSaga";
import registrationContentSaga from "./registrationContentSaga";
import footerSaga from "./footerSaga";
import paymentMethodsSaga from "./paymentMethodsSaga";
import promotionContentSaga from "./promotionContentSaga";
import promotionBannersSaga from "./promotionBannersSaga";
import casinoVendorsSaga from "./casinoVendorsSaga";
import smarticoSaga from "@/fe-core/_redux/sagas/smartico";
import loyaltyLevelsSaga from "./loyaltyLevelsSaga";

export default [
  fork(navSaga),
  fork(userSaga),
  fork(casinoSaga),
  fork(bannerSaga),
  process.env.NEXT_PUBLIC_HAS_WEB_PUB_SUB === "YES" ? fork(webPubSubSaga) : null,
  fork(sessionSaga),
  fork(socketSaga),
  fork(handshakeSaga),
  fork(casinoJackpotSaga),
  // fork(popularCompetitionsSaga),
  fork(sportsSaga),
  fork(activateEmailSaga),
  // fork(realityCheckSaga),
  fork(recentWinnersSaga),
  fork(latestBetsSaga),
  fork(topWinnersSaga),
  fork(jackpotWinnersSaga),
  fork(promotionsSaga),
  fork(casinoGamesHighlightedSaga),
  // fork(adventCalendarSaga),
  // fork(maintenanceBarSaga),
  fork(pageSaga),
  fork(casinoLiveRTPSaga),
  // fork(gameOfTheWeekSaga),
  process.env.NEXT_PUBLIC_HAS_TOURNAMENTS === "YES" ? fork(tournamentsSaga) : null,
  fork(popUpsSaga),
  fork(registrationContentSaga),
  fork(footerSaga),
  fork(paymentMethodsSaga),
  fork(promotionContentSaga),
  fork(promotionBannersSaga),
  fork(casinoVendorsSaga),
  process.env.NEXT_PUBLIC_HAS_DEAD_SIMPLE_CHAT === "YES" ? fork(deadSimpleChatSaga) : null,
  process.env.NEXT_PUBLIC_HAS_SMARTICO === "YES" ? fork(smarticoSaga) : null,
  process.env.NEXT_PUBLIC_HAS_LOYALTY_LEVEL === "YES"
    ? fork(loyaltyLevelsSaga)
    : null,
];

import {
  CoolOffSelectOption,
  IEnableCoolOffPayload,
  IPeriod,
} from "@/fe-core/meta/interfaces/user";

export type CoolOffLabelMapper = {
  [key: string]: { label: string; translationId: string };
};

const coolOffLabelMapper: CoolOffLabelMapper = {
  "24 hours": { label: "24 hours", translationId: "24-hours" },
  "7 days": { label: "7 days", translationId: "7-days" },
  "30 days": { label: "30 days", translationId: "30-days" },
  "3 months": { label: "3 months", translationId: "3-months" },
};

export const coolOffMapper = (
  periods: IPeriod[],
  t: any
): CoolOffSelectOption[] =>
  periods.map((period) => ({
    value: period,
    // label: t(coolOffLabelMapper[period.text].translationId),
    label: period.text,
  }));

export function buildCoolOffPayload(_payloadData: IEnableCoolOffPayload): {
  coolOff: IEnableCoolOffPayload;
} {
  let payload: IEnableCoolOffPayload = {
    period: _payloadData.period,
    sendNotificationEmail: true,
  };
  if (_payloadData.coolOffReason) {
    payload.coolOffReason = _payloadData.coolOffReason;
  }
  if (_payloadData.coolOffDescription) {
    payload.coolOffDescription = _payloadData.coolOffDescription;
  }
  if (_payloadData.unsatisfiedReason) {
    payload.unsatisfiedReason = _payloadData.unsatisfiedReason;
  }
  if (_payloadData.unsatisfiedDescription) {
    payload.unsatisfiedDescription = _payloadData.unsatisfiedDescription;
  }

  return { coolOff: payload };
}

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  UserActions,
  ConsentsState,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: ConsentsState = {
  pending: false,
  data: { userConsents: [] },
  status: null,
  isUpdatePending: false
};

const consentsReducer = (
  state: ConsentsState = initialState,
  action: UserActions | RootActions
): ConsentsState => {
  switch (action.type) {
    case userActionTypes.GET_CONSENTS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_CONSENTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.GET_CONSENTS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.SET_CONSENTS_REQUEST:
      return {
        ...state,
        pending: true,
        isUpdatePending: true,
        status: null,
      };
    case userActionTypes.SET_CONSENTS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        pending: false,
        isUpdatePending: false,
        // data: payload,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.SET_CONSENTS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        isUpdatePending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CLEAR_CONSENTS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default consentsReducer;

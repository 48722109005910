import {
  supportActions,
  supportState,
  supportActionTypes,
} from "@/fe-core/meta/types/support";

const initialState: supportState = {
  isOpenSupportModal: false,
  activeSupportTabIndex: 0
};

const supportReducer = (
  state: supportState = initialState,
  action: supportActions
): supportState => {
  switch (action.type) {
    case supportActionTypes.TOGGLE_SUPPORT_MODAL:
      return {
        ...state,
        isOpenSupportModal: action.payload,
      };
    case supportActionTypes.SET_ACTIVE_SUPPORT_TAB:
      return {
        ...state,
        activeSupportTabIndex: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default supportReducer;

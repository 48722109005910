import {
  PromotionContentActions,
  PromotionContentState,
  promotionContentActionTypes,
} from "@/fe-core/meta/types/promotionContent";

const initialState: PromotionContentState = {
  pending: false,
  promotionContent: null,
};

const promotionContentReducer = (
  state: PromotionContentState = initialState,
  action: PromotionContentActions
): PromotionContentState => {
  switch (action.type) {
    case promotionContentActionTypes.GET_PROMOTION_CONTENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case promotionContentActionTypes.GET_PROMOTION_CONTENT_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case promotionContentActionTypes.GET_PROMOTION_CONTENT_SUCCESS:
      return {
        ...state,
        pending: false,
        promotionContent: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default promotionContentReducer;

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { promotionBannersActionTypes, GetPromotionBannersRequestAction } from '../../meta/types/promotionBanners';
import { IPromotionBannersResponseViewModel } from "../../meta/interfaces/promotionBanners";
import { IJsonResponse } from "../../meta/interfaces/root";
import {
  getPromotionBannersFailure,
  getPromotionBannersSuccess,
} from "../actions/promotionBannersActions";
import { methodTypes } from "../../meta/types/root";
import { CookiesStorage } from "../../helpers/storage";
import { themeSelector } from "../selectors/themeSelectors";
import { localeActionTypes } from "../../meta/types/locale";
import { themeActionTypes } from "../../meta/types/theme";
import { localeSelector } from "../selectors/localeSelectors";
import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import { headerKeys } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { buildPromotionBannersQueryString } from "@/fe-core/helpers/promotions";
import { isUserAuthenticatedSelector } from "../selectors/sessionSelectors";
import { localStorageKeys } from "@/fe-core/meta/types/localStorage";

function* getPromotionBannersSaga({ payload }: GetPromotionBannersRequestAction) {
  try {
    const { userRoles = [], userBonuses = [] } = payload || {};
    const { theme } = yield select(themeSelector);
    const { locale } = yield select(localeSelector);

    const isIdentified =
      localStorage.getItem(localStorageKeys.IDENTIFIED);
    const isAuthenticated: boolean = yield select(isUserAuthenticatedSelector);

    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );
    const btag = CookiesStorage.getItem(key as string);

    const queryString: string = buildPromotionBannersQueryString(
      theme,
      userRoles,
      userBonuses,
      btag ? [btag] : [],
      isAuthenticated ? "1" : "0",
      isIdentified ? "1" : "0"
    );

    const response: IJsonResponse<IPromotionBannersResponseViewModel[]> =
      yield fetch(`/api/promotionBanners${queryString}`, {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      });
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IPromotionBannersResponseViewModel =
          yield response.json();
        yield put(getPromotionBannersSuccess(viewModel));
        break;
      default:
        throw new Error("Promotion Banners couldn't be fetched");
    }
  } catch (error) {
    yield put(getPromotionBannersFailure(parseFrontendError(error)));
  }
}

function* promotionBannersSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      promotionBannersActionTypes.GET_PROMOTION_BANNERS_REQUEST,
      getPromotionBannersSaga
    ),
    takeLatest(localeActionTypes.SET_CURRENT_LOCALE, getPromotionBannersSaga),
    takeLatest(themeActionTypes.SET_CURRENT_THEME, getPromotionBannersSaga),
  ]);
}

export default promotionBannersSaga;

export enum rewardsDrawerActionTypes {
  OPEN_REWARDS_DRAWER = "OPEN_REWARDS_DRAWER",
  CLOSE_REWARDS_DRAWER = "CLOSE_REWARDS_DRAWER",
}

export type OpenRewardsDrawerRequestAction = {
  type: rewardsDrawerActionTypes.OPEN_REWARDS_DRAWER;
};

export type CloseRewardsDrawerRequestAction = {
  type: rewardsDrawerActionTypes.CLOSE_REWARDS_DRAWER;
};

export type RewardsDrawerActions =
  | OpenRewardsDrawerRequestAction
  | CloseRewardsDrawerRequestAction;

export type RewardsDrawerState = {
  isOpen: boolean;
};

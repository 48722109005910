import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { ITransactionSummaryViewModel } from "@/fe-core/meta/interfaces/user";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  transactionSummaryFailure,
  transactionSummarySuccess,
} from "@/fe-core/_redux/actions/userActions";
import { sessionDataSelector } from "@/fe-core/_redux/selectors/sessionSelectors";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { headerKeys } from "@/config/general";

function* transactionSummaryRequestSaga() {
  try {
    const { sessionId } = yield select(sessionDataSelector);
    const { locale } = yield select(localeSelector);

    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/cashier/transactionSummary`,
      <RequestInit>{
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          [headerKeys.SESSION_ID]: sessionId,
          [headerKeys.LOCALE]: locale,
        }),
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const transactionSummaryViewModel: ITransactionSummaryViewModel =
          yield response.json();
        yield put(transactionSummarySuccess(transactionSummaryViewModel));
        break;
      default:
        throw new Error("Could init cashier");
    }
  } catch (error) {
    yield put(transactionSummaryFailure(parseFrontendError(error)));
  }
}

function* transactionSummarySaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.TRANSACTION_SUMMARY_REQUEST,
      transactionSummaryRequestSaga
    ),
  ]);
}

export default transactionSummarySaga;

import TagManager from "react-gtm-module";
import {
  all,
  AllEffect,
  ForkEffect,
  put,
  takeLatest,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { ISmsViewModel } from "@/fe-core/meta/interfaces/user/register/signupSms";

import {
  userActionTypes,
  SignupSmsRequestAction,
  signupScreens,
  SignupSmsResendRequestAction,
} from "@/fe-core/meta/types/user";
import {
  setActiveSignupScreen,
  signupSmsFailure,
  signupSmsResendFailure,
  signupSmsResendSuccess,
  signupSmsSuccess,
} from "@/fe-core/_redux/actions/userActions";
function* signupSmsRequestSaga({ payload }: SignupSmsRequestAction) {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/sms`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    const { status } = response;

    switch (status) {
      case 200:
        TagManager.dataLayer({
          dataLayer: {
            event: "registrationStep",
            step: 1,
            stepLabel: "Informatii cont",
          },
        });
        const viewModel: ISmsViewModel = yield response.json();
        yield put(signupSmsSuccess(viewModel));
        yield put(setActiveSignupScreen(signupScreens.SMS_CONFIRMATION));
        break;
      default:
        const errModel: { message: string } = yield response.json();

        throw new Error(errModel.message);
    }
  } catch (error) {
    yield put(signupSmsFailure(parseFrontendError(error)));
  }
}

function* signupSmsResendRequestSaga({
  payload,
}: SignupSmsResendRequestAction) {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/sms/resend`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    const { status } = response;

    switch (status) {
      case 200:
      case 204:
        yield put(signupSmsResendSuccess());
        break;
      default:
        const errModel: { message: string } = yield response.json();

        throw new Error(errModel.message);
    }
  } catch (error) {
    yield put(signupSmsResendFailure(parseFrontendError(error)));
  }
}

function* signupSmsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(userActionTypes.SIGNUP_SMS_REQUEST, signupSmsRequestSaga),
  ]);
  yield all([
    takeLatest(
      userActionTypes.SIGNUP_SMS_RESEND_REQUEST,
      signupSmsResendRequestSaga
    ),
  ]);
}

export default signupSmsSaga;

"use client"
import {useEffect} from "react";
import {usePathname} from "next/navigation";


const BetByHandlers = () => {

    const path = usePathname()
    useEffect(() => {
        var elementExists = document.getElementById("bt-inner-page");
        console.log('Execute BetByHandlers', elementExists);
        if(!elementExists &&  path.indexOf('/sports') > -1) {
            return;
        }
        if(path.indexOf('/sports') === -1) {
            window?.bt?.kill();
            window.bt = null;
        }
        if(path.indexOf('/sports/live-sports') > -1 &&  window?.bt) {
            window.bt.updateOptions({url: '/live'})
            window?.bt?.kill();
        } else if(path.indexOf('/sports') > -1 &&  window?.bt) {
            window.bt.updateOptions({ url: '/' })
            window?.bt?.kill();
        }
        console.log('path change', path, path.indexOf('/sports'), path.indexOf('/sports') === -1)
    }, [path])

}

export default BetByHandlers
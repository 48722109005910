import {
  IBottomLogoViewModal,
  IComplaintsBookViewModal,
  IFootersModel,
  IHelperViewModal,
  IPaymentsViewModal,
  IProvidersViewModal,
  ISealsViewModal,
  ISocialViewModal,
} from "@/fe-core/meta/interfaces/footer";
import { FooterState } from "@/fe-core/meta/types/footer";
import { RootState } from "@/fe-core/meta/types/root";

export const mainFooterSelector = ({ footer }: RootState): FooterState =>
  footer;

export const footerSelector = (state: RootState): IFootersModel | null => {
  const { footer } = mainFooterSelector(state);
  return footer;
};

export const footerPendingSelector = (state: RootState): boolean => {
  const { pending } = mainFooterSelector(state);
  return pending;
};

export const sealsSelector = (state: RootState): ISealsViewModal[] | null => {
  const sealsData = mainFooterSelector(state).footer?.attributes?.seals;
  return sealsData || null;
};

export const socialSelector = (state: RootState): ISocialViewModal[] | null => {
  const socialData = mainFooterSelector(state).footer?.attributes?.social;
  return socialData || null;
};

export const providersSelector = (
  state: RootState
): IProvidersViewModal[] | null => {
  const providersData = mainFooterSelector(state).footer?.attributes?.providers;
  return providersData || null;
};

export const paymentsSelector = (
  state: RootState
): IPaymentsViewModal[] | null => {
  const paymentsData = mainFooterSelector(state).footer?.attributes?.payments;
  return paymentsData || null;
};

export const licenseSelector = (state: RootState): string | undefined => {
  const licenseData = mainFooterSelector(state).footer?.attributes?.license;
  return licenseData;
};

export const bottomLogoSelector = (
  state: RootState
): IBottomLogoViewModal | undefined => {
  const licenseData = mainFooterSelector(state).footer?.attributes?.bottomLogo;
  return licenseData;
};

export const helperSelector = (state: RootState): IHelperViewModal[] | null => {
  const helperData = mainFooterSelector(state).footer?.attributes?.helper;
  return helperData || null;
};

export const complaintsBookSelector = (
  state: RootState
): IComplaintsBookViewModal[] | null => {
  const complaintsBookData =
    mainFooterSelector(state).footer?.attributes?.complaintsBook;
  return complaintsBookData || null;
};

export const sealsDescriptionSelector = (
  state: RootState
): string | undefined => {
  const sealsDescriptionData =
    mainFooterSelector(state).footer?.attributes?.sealsDescription;
  return sealsDescriptionData;
};
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signupActiveModalSelector } from "../../../fe-core-omega/_redux/selectors/userSelectors/registerSelectors/signupSelectors";
import Modal from "../../Modal";
import { ISignupPayload } from "../../../fe-core-omega/meta/interfaces/user";
import { signupActiveScreenSelector } from "../../../fe-core-omega/_redux/selectors/userSelectors";
import {
  clearSignUpError,
  clearSignUpPayload,
  setActiveSignupScreen,
  setHideSignup,
  signupEmailConfirmationClearError,
} from "../../../fe-core-omega/_redux/actions/userActions";
import { signupScreens } from "../../../fe-core-omega/meta/types/user";
import AccountInformationScreen from "../../Signup/AccountInformationScreen";
import EmailConfirmationScreen from "../../Signup/EmailConfirmationScreen";

import Image from "next/image";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "../../../fe-core-omega/_redux/selectors/registrationContentSelectors";
import { useScreen } from "../../../context/ScreenContext";
import { IImageAttributesViewModel } from "../../../fe-core-omega/meta/interfaces/root";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import {
  validateSignupIpPendingSelector,
  validateSignupIpStatusSelector,
} from "@/fe-core/_redux/selectors/userSelectors/registerSelectors/validateSelectors/signupIpSelectors";
import { initialState } from "@/config/signup";
import { stepNumber } from "@/fe-core/helpers/register";
import PersonalInformationScreen from "@/components/Signup/PersonalInformationScreen";
import { openLoginMenu } from "@/fe-core/_redux/actions/menusActions";
import { getCurrenciesRequest } from "@/fe-core/_redux/actions/userActions/registerActions/currenciesActions";
import { getUserConsentsRequest } from "@/fe-core/_redux/actions/userActions/registerActions/userConsentsActions";
import { getValidCountriesRequest } from "@/fe-core/_redux/actions/userActions/registerActions/validCountriesActions";

const SignupModal: FC = () => {
  const signupActiveModal = useSelector(signupActiveModalSelector);

  const dispatch = useDispatch();
  const { isMobile } = useScreen();
  const t = useTranslations(I18N_NAMESPACE.GENERAL);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);
  const [payload, setPayload] = useState<ISignupPayload>(initialState);

  const isSignupPreCheckPending = useSelector(validateSignupIpPendingSelector);
  const signupPreCheckStatus = useSelector(validateSignupIpStatusSelector);
  const activeScreen = useSelector(signupActiveScreenSelector);
  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  const leftBannerImg = useMemo(() => {
    const attributes =
      registrationContent && registrationContent[0]?.attributes;
    const leftBannerImg = isMobile
      ? attributes
        ? (attributes?.mobileImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined
      : attributes
      ? (attributes?.desktopImage?.data
          ?.attributes as IImageAttributesViewModel)
      : undefined;
    return leftBannerImg;
  }, [isMobile, registrationContent, activeScreen]);

  useEffect(() => {
    dispatch(getCurrenciesRequest());
    dispatch(getValidCountriesRequest());
    dispatch(getUserConsentsRequest());
    return () => {
      dispatch(setActiveSignupScreen(signupScreens.ACCOUNT_INFORMATION));
    };
  }, [dispatch]);

  const clearPayload = () => setPayload(initialState);

  const handleLoginClick = () => {
    dispatch(setHideSignup());
    dispatch(clearSignUpPayload());
    dispatch(setActiveSignupScreen(signupScreens.ACCOUNT_INFORMATION));
    dispatch(openLoginMenu());
    clearPayload();
  };

  const signupHeader = () => {
    const activeStep = stepNumber(activeScreen) || 1;

    return (
      <>
        <div className="mb-0 text-base font-bold">{t("register")}</div>
        <div className="  signup-header">
          <div className="step-info">
            <div className={`step ${activeStep === 1 ? "active" : ""}`}>1</div>
            <div className="spacer"></div>
            <div className={`step ${activeStep === 2 ? "active" : ""}`}>2</div>
            <div className="spacer"></div>
            <div className={`step ${activeStep === 3 ? "active" : ""}`}>3</div>
          </div>
        </div>
      </>
    );
  };

  const getActiveScreenComponent = () => {
    switch (activeScreen) {
      case signupScreens.INITIAL_SCREEN:
      case signupScreens.ACCOUNT_INFORMATION:
        return (
          <>
            <div className="flex flex-col gap-6">
              <AccountInformationScreen
                payload={payload}
                setPayload={setPayload}
                handleLoginClick={handleLoginClick}
              />
            </div>
          </>
        );
      case signupScreens.PERSONAL_INFORMATION:
        return (
          <>
            <div className="flex flex-col gap-6">
              <PersonalInformationScreen
                payload={payload}
                setPayload={setPayload}
                handleLoginClick={handleLoginClick}
              />
            </div>
          </>
        );
      case signupScreens.EMAIL_CONFIRMATION:
        return <EmailConfirmationScreen payload={payload} />;
    }
  };

  const handleSignupCloseClick = () => {
    dispatch(setActiveSignupScreen(signupScreens.ACCOUNT_INFORMATION));
    dispatch(setHideSignup());
    dispatch(clearSignUpError());
    dispatch(signupEmailConfirmationClearError());
    clearPayload();
  };

  return (
    <Modal
      className="!p-0 rounded-none sm:rounded max-w-full sm:max-w-[calc(100dvw-64px)] xl:max-w-[760px]"
      size="large"
      onClose={handleSignupCloseClick}
      isOpen={signupActiveModal}
    >
      <div className="grid h-full grid-cols-1 md:grid-cols-2 sm:min-h-[640px]">
        <div className="relative hidden md:block">
          {isPendingRegistrationContent ? (
            <Image
              src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
              objectFit="cover"
              fill
              alt="reg-banner"
              className="w-full h-full"
              loading="lazy"
            />
          ) : (
            <Image
              src={
                leftBannerImg
                  ? leftBannerImg.url
                  : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
              }
              objectFit="cover"
              fill
              alt={leftBannerImg ? leftBannerImg?.name : " registration banner"}
              className="w-full h-full"
              loading="lazy"
            />
          )}
        </div>
        <div className="relative flex flex-col gap-5 p-7 lg:p-8 pt-16 sm:pt-8 dark:bg-tertiary-600">
          {isSignupPreCheckPending ? (
            <div>{registerT("pre-check-loading")}</div>
          ) : !signupPreCheckStatus?.success ? (
            <div>{registerT("pre-check-failed")}</div>
          ) : (
            <>
              {signupHeader()}
              {getActiveScreenComponent()}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SignupModal;

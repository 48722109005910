import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  IValidateEmailResponsePayload,
  IValidateEmailViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateEmailRequestAction,
} from "@/fe-core/meta/types/user";
import {
  validateEmailFailure,
  validateEmailSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";

function* validateEmailRequestSaga({ payload }: ValidateEmailRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/account#validateEmail",
      payload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const validateEmailData: IValidateEmailResponsePayload = emResponse?.kwargs;

    yield put(
      validateEmailSuccess(validateEmailData as IValidateEmailViewModel)
    );
  } catch (error) {
    yield put(validateEmailFailure(parseFrontendError(error)));
  }
}

function* validateEmailSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.VALIDATE_EMAIL_REQUEST,
      validateEmailRequestSaga
    ),
  ]);
}

export default validateEmailSaga;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import {
  CoolOffState,
  UserActions,
  userActionTypes,
} from "@/fe-core/meta/types/user";

const initialState: CoolOffState = {
  data: null,
  getPeriods: {
    pending: false,
    status: null,
  },
  applyCoolOff: {
    pending: false,
    status: null,
  },
};

const coolOffReducer = (
  state: CoolOffState = initialState,
  action: UserActions | RootActions
): CoolOffState => {
  switch (action.type) {
    case userActionTypes.ENABLE_COOL_OFF_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        applyCoolOff: {
          ...state.applyCoolOff,
          pending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    }
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default coolOffReducer;

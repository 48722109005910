import {
  PaymentMethodsActions,
  PaymentMethodsState,
  paymentMethodsActionTypes,
} from "@/fe-core/meta/types/paymentMethods";

const initialState: PaymentMethodsState = {
  pending: false,
  paymentMethods: null,
};

const paymentMethodsReducer = (
  state: PaymentMethodsState = initialState,
  action: PaymentMethodsActions
): PaymentMethodsState => {
  switch (action.type) {
    case paymentMethodsActionTypes.GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case paymentMethodsActionTypes.GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case paymentMethodsActionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        pending: false,
        paymentMethods: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentMethodsReducer;

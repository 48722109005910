import {
  userActionTypes,
  RolesState,
  UserActions,
  TagsState,
} from "@/fe-core/meta/types/user";
import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";

const initialState: RolesState = {
  pending: false,
  data: null,
  status: null,
};

const tagsReducer = (
  state: TagsState = initialState,
  action: UserActions | RootActions
): RolesState => {
  switch (action.type) {
    case userActionTypes.UPDATE_TAGS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.UPDATE_TAGS_SUCCESS: {
      return {
        ...state,
        pending: false,
        status: {
          success: true,
        },
      };
    }
    case userActionTypes.UPDATE_TAGS_FAILURE: {
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message,
        },
      };
    }
    case userActionTypes.CLEAR_TAGS:
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tagsReducer;

import { registerSelector } from ".";
import { RootState } from "@/fe-core/meta/types/root";
import { CurrenciesState } from "@/fe-core/meta/types/user/register/currencies";

export const mainCurrenciesSelector = (state: RootState): CurrenciesState => {
  const { currencies } = registerSelector(state);
  return currencies;
};

export const isPendingCurrenciesSelector = (state: RootState): boolean => {
  const { pending } = mainCurrenciesSelector(state);
  return pending;
};

export const currenciesSelector = (state: RootState): string[] | null => {
  const { currencies } = mainCurrenciesSelector(state);
  return currencies;
};

export const detectedCurrencySelector = (state: RootState): string | null => {
  const { detectedCurrency } = mainCurrenciesSelector(state);
  return detectedCurrency;
};

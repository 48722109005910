//@ts-nocheck
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRealityCheck } from "../../../fe-core-omega/_redux/actions/userActions";
import { realityCheckSelector } from "../../../fe-core-omega/_redux/selectors/userSelectors/realityCheckSelectors";
import Modal from "../../Modal";
import "react-toastify/dist/ReactToastify.css";
import Icon from "../../Icon/Icon";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import useTracking from "@/components/Hooks/useTracking";

const RealityCheckModal: FC = () => {
  const { click } = useTracking();
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.LAYOUT);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const realityCheck = useSelector(realityCheckSelector);
  const { showRealityCheck, loggedinHours } = realityCheck;

  const { isOpen, setIsOpen } = useState(
    showRealityCheck && loggedinHours && loggedinHours > 0
  );
  const cancelRealityCheck = () => {
    dispatch(setRealityCheck({ showRealityCheck: false, loggedinHours: null }));
    click(`confirm reality check`, {
      buttonLabel: generalT("ok"),
      component: "RealityCheckModal",
    });
  };

  const getRealityCheckText = () => {
    let rcText = `${t("your-logged-time")} ${loggedinHours} ${generalT(
      "hours"
    )}.`;

    if (loggedinHours && loggedinHours < 6) {
      rcText = `${t("your-logged-time")} ${loggedinHours * 60} ${generalT(
        "minutes"
      )}.`;
    }

    return rcText;
  };

  return (
    <Modal
      className="logged-time-modal"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="modal-title">
        <Icon name="circle-info" size="large" color="primary"></Icon>
      </div>
      <div className="modal-content">{getRealityCheckText()}</div>
      <div className="modal-actions">
        <button className="btn-large primary" onClick={cancelRealityCheck}>
          {generalT("ok")}
        </button>
      </div>
    </Modal>
  );
};

export default RealityCheckModal;

"use client";
import { Link } from "../../navigation";
import { FC } from "react";
import Image from "next/image";
import { Logo } from "../Logo";
import { IViewNavigationResponse } from "@/fe-core/meta/interfaces/navigation";
import SVGIcon from "@/components/SVGIcon";
import { usePathname } from "../../navigation";
import { toggleDrawer } from "@/fe-core/_redux/actions/drawerActions";
import { useDispatch } from "react-redux";
import useTracking from "../Hooks/useTracking";
import { useTheme } from "next-themes";

type Props = {
  navigation: IViewNavigationResponse[] | undefined;
};

const LayoutHeaderNav: FC<Props> = ({ navigation }) => {
  const pathname = usePathname();
  const dispatch = useDispatch();
  const { navigate } = useTracking();
  const { theme } = useTheme();

  const toggleDrawerMenu = () => {
    dispatch(toggleDrawer());
  };

  return (
    <>
      <div className="lg:flex hidden items-center justify-center">
        {/* <div className="lg:hidden">
          <Link href="/" passHref className="block text-left">
            <Image
              src={theme == "dark" ? "/img/logo.svg" : "/img/logo.svg"}
              fill
              alt=""
              className="w-auto !h-10 sm:!h-12 !relative"
              loading="eager"
            />
          </Link>
        </div> */}
        <div className="hidden gap-12 md:gap-4 lg:flex">
          {navigation?.map((item, i) => (
            <Link
              href={item?.path ?? "/casino"}
              onClick={() => {
                navigate(item?.path ?? "/casino", {
                  component: "LayoutHeaderNav",
                  label: item.title,
                  navigateFrom: "Main Header",
                });
              }}
              passHref
              key={`${i}__LayoutHeaderNav`}
            >
              <span
                className={`group rounded flex items-center gap-2 transition-all ${
                  pathname === item?.path
                    ? "border-primary-400 bg-primary-400"
                    : "border-transparent"
                }  hover:bg-primary-400 border-2 p-3 `}
              >
                {item?.icon && (
                  <Image
                    src={item.icon}
                    alt=""
                    width={20}
                    height={20}
                    className="block mx-auto min-w-[20px]  "
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                    }}
                    loading="lazy"
                  />
                )}
                <span
                  className={`block text-sm font-semibold ${
                    pathname === item?.path
                      ? "text-tertiary"
                      : "  dark:text-tertiary"
                  }   whitespace-nowrap text-ellipsis overflow-hidden  ...`}
                >
                  {item.title}
                </span>
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default LayoutHeaderNav;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  PrepareWithdrawActions,
  PrepareWithdrawState,
} from "@/fe-core/meta/types/user/cashier/prepareWithdraw";

const initialState: PrepareWithdrawState = {
  pending: false,
  data: null,
  status: null,
};

const prepareWithdrawReducer = (
  state: PrepareWithdrawState = initialState,
  action: PrepareWithdrawActions | RootActions
): PrepareWithdrawState => {
  switch (action.type) {
    case userActionTypes.PREPARE_WITHDRAW_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.PREPARE_WITHDRAW_REQUEST_SUCCESS: {
      return {
        ...state,
        pending: false,
        data: action.payload,
        status: {
          success: true,
          message: "",
        },
      };
    }
    case userActionTypes.PREPARE_WITHDRAW_REQUEST_FAILURE: {
      return {
        ...state,
        pending: false,
        status: {
          success: false,
          message: action.payload.message,
        },
      };
    }

    case rootActionTypes.CLEAR_STATE:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default prepareWithdrawReducer;

"use client";
import { FC, useEffect, Fragment, useRef } from "react";
import Icon from "./Icon/Icon";
import { Dialog, Transition } from "@headlessui/react";
import SVGIcon from "./SVGIcon";
import classNames from "classnames";

type Props = {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  childClassName?: string;
  size?: string;
  onClose?: () => void;
  isOpen?: boolean;
  closeButtonClassName?: string;
  showCloseButton?: boolean;
  isBackDropBlur?: boolean;
  isDisableBackDropClose?: boolean;
  overFlowVisible?: boolean;
};

const Modal: FC<Props> = ({
  children,
  className,
  childClassName,
  size,
  onClose = () => {},
  isOpen = false,
  closeButtonClassName,
  showCloseButton = true,
  isBackDropBlur = false,
  isDisableBackDropClose = false,
  overFlowVisible,
}) => {
  // // Lock the body scroll when the modal is open
  // useEffect(() => {
  //   // Get the width of the window including the scrollbar
  //   const totalWidth = window.innerWidth;
  //   // Get the width of the window excluding the scrollbar
  //   const widthWithoutScroll = document.documentElement.clientWidth;
  //   // Calculate the width of the scrollbar
  //   const scrollbarWidth = totalWidth - widthWithoutScroll;
  //   // Disable scrolling and reserve space for the scrollbar
  //   document.body.style.overflow = "hidden";
  //   document.body.style.paddingRight = `${scrollbarWidth}px`;
  //   return () => {
  //     // Enable scrolling and remove the reserved space for the scrollbar
  //     document.body.style.overflow = "";
  //     document.body.style.paddingRight = "";
  //   };
  // }, []);
  let refDiv = useRef(null);
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => {
          !isDisableBackDropClose && onClose();
        }}
        initialFocus={refDiv}
      >
        {/*
          Use one Transition.Child to apply one transition to the backdrop...
        */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              "fixed inset-0 z-50 bg-tertiary/80 backdrop-blur-[2px]",
              {
                "backdrop-blur-lg": isBackDropBlur,
              }
            )}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel
            className={`fixed z-50 w-full px-10 py-6 transform -translate-x-1/2 -translate-y-1/2 bg-white text-tertiary dark:text-white dark:bg-tertiary-600 border-tertiary-500 border rounded top-1/2 left-1/2 lg:max-h-[90vh] ${
              className ? " " + className : ""
            } ${overFlowVisible ? "  " : " overflow-auto "}  ${
              size === "large" ? "max-w-4xl" : "max-w-md"
            }`}
          >
            <>
              {onClose && showCloseButton && (
                <span
                  className={classNames(
                    "absolute transition-all cursor-pointer top-4 right-4 hover:rotate-90 z-[3] opacity-70",
                    closeButtonClassName
                  )}
                  onClick={onClose}
                >
                  <SVGIcon
                    icon="close"
                    className="w-5 h-5 text-tertiary dark:text-white"
                  />
                </span>
              )}
              <Transition.Child
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className={classNames(
                  `origin-center transform delay-150 h-svh   lg:h-full  ${overFlowVisible ? "  " : " overflow-auto "}`,
                  childClassName
                )}
                ref={refDiv}
              >
                {children}
              </Transition.Child>
            </>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );

  // return (
  //   <div className={`modal-wrapper${className ? " " + className : ""}`}>
  //     <div className="modal">
  //       {onClose && (
  //         <Icon
  //           className="modal-close"
  //           name="close"
  //           size="medium"
  //           color="neutral-8"
  //           onClick={onClose}
  //         ></Icon>
  //       )}
  //       {children}
  //     </div>
  //   </div>
  // );
};

export default Modal;

import { Result, Session, Error as AutoBahnError } from "autobahn";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { DEFAULT_PREFIX } from "@/config/general";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { doEMCall } from "@/fe-core/helpers/socket";
import {
  IValidateMobileRequestApiPayload,
  IValidateMobileResponsePayload,
  IValidateMobileViewModel,
} from "@/fe-core/meta/interfaces/user";
import {
  userActionTypes,
  ValidateMobileRequestAction,
} from "@/fe-core/meta/types/user";
import {
  validateMobileFailure,
  validateMobileSuccess,
} from "@/fe-core/_redux/actions/userActions";
import { socketSessionSelector } from "@/fe-core/_redux/selectors/socketSelectors";

function* validateMobileRequestSaga({ payload }: ValidateMobileRequestAction) {
  try {
    const socketSession: Session = yield select(socketSessionSelector);

    const emResponse: Result | AutoBahnError = yield doEMCall(
      socketSession,
      "/user/account#validateMobilePhone",
      {
        mobilePhone: payload.mobile,
        mobilePhonePrefix: DEFAULT_PREFIX,
      } as IValidateMobileRequestApiPayload
    );

    if (emResponse instanceof AutoBahnError) {
      throw new Error("User is unauthorized");
    }

    const validateMobileData: IValidateMobileResponsePayload =
      emResponse?.kwargs;

    yield put(
      validateMobileSuccess(validateMobileData as IValidateMobileViewModel)
    );
  } catch (error) {
    yield put(validateMobileFailure(parseFrontendError(error)));
  }
}

function* validateMobileSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.VALIDATE_MOBILE_REQUEST,
      validateMobileRequestSaga
    ),
  ]);
}

export default validateMobileSaga;

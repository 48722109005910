import { IErrorPayload } from '../interfaces/root'
import { IFootersModel, IFootersResponseModel } from '../interfaces/footer'

export enum footerActionTypes {
  GET_FOOTER_REQUEST = 'GET_FOOTER_REQUEST',
  GET_FOOTER_SUCCESS = 'GET_FOOTER_SUCCESS',
  GET_FOOTER_FAILURE = 'GET_FOOTER_FAILURE',
}

export type GetFooterRequestAction = {
  type: footerActionTypes.GET_FOOTER_REQUEST
}

export type GetFooterSuccessAction = {
  type: footerActionTypes.GET_FOOTER_SUCCESS
  payload: IFootersResponseModel
}

export type GetFooterFailureAction = {
  type: footerActionTypes.GET_FOOTER_FAILURE
  payload: IErrorPayload
}

export type FooterActions =
  | GetFooterRequestAction
  | GetFooterSuccessAction
  | GetFooterFailureAction

export type FooterState = {
  pending: boolean
  footer: IFootersModel | null
}

import {
  CasinoActions,
  casinoActionTypes,
  CasinoState,
} from "@/fe-core/meta/types/casino";

const initialState: CasinoState = {
  pending: false,
  categories: null,
  games: [],
  singleGame: null,
  totalCount: 0,
  filters: [],
  gameCardTooltip: null,
  mobileGamePlayModalDetails: {
    isOpen: false,
    selectedGameMode: null,
  },
  isShowGameAlterModal: false,
  alterGameHasFunMode: false,
  singleGamePending: false,
  casinoAllGames: {
    data: null,
    pending: false,
    status: null,
    moreGamesPending: false,
  },
  isShowGameFullScreenButton: false,
  isShowGameInFullScreen: false,
};

const casinoReducer = (
  state: CasinoState = initialState,
  action: CasinoActions
): CasinoState => {
  switch (action.type) {
    case casinoActionTypes.SET_SHOW_GAME_ALTER_MODAL:
      return {
        ...state,
        isShowGameAlterModal: true,
        alterGameHasFunMode: action?.payload?.hasFunMode,
      };
    case casinoActionTypes.SET_HIDE_GAME_ALTER_MODAL:
      return {
        ...state,
        isShowGameAlterModal: false,
      };
    case casinoActionTypes.GET_CATEGORIES_REQUEST:
    case casinoActionTypes.GET_SINGLE_GAME_REQUEST:
      return {
        ...state,
        singleGamePending: true,
      };
    case casinoActionTypes.GET_MORE_GAMES_REQUEST:
      return {
        ...state,
        pending: false,
      };
    case casinoActionTypes.GET_GAMES_REQUEST:
      return {
        ...state,
        pending: true,
        ...(action.payload.pagination.offset === 0 && {
          games: [],
          totalCount: 0,
        }),
        filters: action.payload.filters,
      };
    case casinoActionTypes.GET_CATEGORIES_FAILURE:
    case casinoActionTypes.GET_GAMES_FAILURE:
      return {
        ...state,
        pending: false,
      };
    case casinoActionTypes.GET_SINGLE_GAME_FAILURE:
      return {
        ...state,
        singleGamePending: false,
      };
    case casinoActionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        pending: false,
        categories: action.payload.items,
      };
    case casinoActionTypes.GET_GAMES_SUCCESS:
      return {
        ...state,
        pending: false,
        games: [...state.games, ...action.payload.items],
        totalCount: action.payload.total,
      };
    case casinoActionTypes.GET_SINGLE_GAME_SUCCESS:
      return {
        ...state,
        singleGamePending: false,
        singleGame: action.payload,
      };
    case casinoActionTypes.SET_GAME_CARD_TOOLTIP:
      return {
        ...state,
        gameCardTooltip: action.payload,
      };
    case casinoActionTypes.SET_MOBILE_GAME_PLAY_MODAL_DETAILS:
      return {
        ...state,
        mobileGamePlayModalDetails: action.payload,
      };
    case casinoActionTypes.GET_CASINO_ALL_GAMES_REQUEST:
      const { pagination } = action.payload;
      return {
        ...state,
        casinoAllGames: {
          ...state.casinoAllGames,
          pending: pagination?.offset === 0 ? true : false,
          moreGamesPending: pagination?.offset === 0 ? false : true,
          status: null,
        },
      };
    case casinoActionTypes.GET_CASINO_ALL_GAMES_SUCCESS:
      return {
        ...state,
        casinoAllGames: {
          ...state.casinoAllGames,
          data: {
            ...state.casinoAllGames.data,
            ...action.payload,
            items: [
              ...(state.casinoAllGames?.data?.items || []),
              ...(action.payload.items || []),
            ],
            count:
              (state.casinoAllGames.data?.count || 0) +
              (action.payload.count || 0),
          },
          pending: false,
          moreGamesPending: false,
          status: {
            success: true,
            message: "",
          },
        },
      };
    case casinoActionTypes.GET_CASINO_ALL_GAMES_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        casinoAllGames: {
          ...state.casinoAllGames,
          pending: false,
          moreGamesPending: false,
          status: {
            success: false,
            message,
          },
        },
      };
    
    case casinoActionTypes.SET_SHOW_GAME_FULL_SCREEN_BUTTON:
      return {
        ...state,
        isShowGameFullScreenButton: true,
      };
    case casinoActionTypes.SET_HIDE_GAME_FULL_SCREEN_BUTTON:
      return {
        ...state,
        isShowGameFullScreenButton: false,
      };
    case casinoActionTypes.TOGGLE_GAME_FULL_SCREEN:
      return {
        ...state,
        isShowGameInFullScreen: !state.isShowGameInFullScreen,
      };
    default:
      return {
        ...state,
      };
  }
};

export default casinoReducer;

import { ICasinoLiveRTPViewModel, IHotColdGamesResponseModel } from '../interfaces/casinoLiveRTP'
import { IErrorPayload } from '../interfaces/root'

export enum casinoLiveRTPActionTypes {
  GET_CASINO_LIVE_RTP_HOURLY_REQUEST = 'GET_CASINO_LIVE_RTP_HOURLY_REQUEST',
  GET_CASINO_LIVE_RTP_HOURLY_SUCCESS = ' GET_CASINO_LIVE_RTP_HOURLY_SUCCESS',
  GET_CASINO_LIVE_RTP_HOURLY_FAILURE = ' GET_CASINO_LIVE_RTP_HOURLY_FAILURE',
  GET_CASINO_LIVE_RTP_DAILY_REQUEST = 'GET_CASINO_LIVE_RTP_DAILY_REQUEST',
  GET_CASINO_LIVE_RTP_DAILY_SUCCESS = ' GET_CASINO_LIVE_RTP_DAILY_SUCCESS',
  GET_CASINO_LIVE_RTP_DAILY_FAILURE = ' GET_CASINO_LIVE_RTP_DAILY_FAILURE',
}

export type GetCasinoLiveRTPHourlyRequestAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_REQUEST
}

export type GetCasinoLiveRTPHourlySuccessAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_SUCCESS
  payload: IHotColdGamesResponseModel
}

export type GetCasinoLiveRTPHourlyFailureAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_HOURLY_FAILURE
  payload: IErrorPayload
}

export type GetCasinoLiveRTPDailyRequestAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_REQUEST
}

export type GetCasinoLiveRTPDailySuccessAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_SUCCESS
  payload: ICasinoLiveRTPViewModel
}

export type GetCasinoLiveRTPDailyFailureAction = {
  type: casinoLiveRTPActionTypes.GET_CASINO_LIVE_RTP_DAILY_FAILURE
  payload: IErrorPayload
}

export type CasinoLiveRTPActions =
  | GetCasinoLiveRTPHourlyRequestAction
  | GetCasinoLiveRTPHourlySuccessAction
  | GetCasinoLiveRTPHourlyFailureAction
  | GetCasinoLiveRTPDailyRequestAction
  | GetCasinoLiveRTPDailySuccessAction
  | GetCasinoLiveRTPDailyFailureAction

export type casinoLiveRTPState = {
  daily: {
    pending: boolean
    status?: IErrorPayload | null
    casinoLiveRTP: ICasinoLiveRTPViewModel | null
  }
  hourly: {
    pending: boolean
    status?: IErrorPayload | null
    casinoLiveRTP: IHotColdGamesResponseModel | null
  }
}

import { IErrorPayload, IStatus } from '../interfaces/root'
import { ISportViewModel } from '../interfaces/sports'

export enum sportsActionTypes {
  SPORTS_REQUEST = 'SPORTS_REQUEST',
  SPORTS_SUCCESS = 'SPORTS_SUCCESS',
  SPORTS_FAILURE = 'SPORTS_FAILURE',
}

export type SportsRequestAction = {
  type: sportsActionTypes.SPORTS_REQUEST
}

export type SportsSuccessAction = {
  type: sportsActionTypes.SPORTS_SUCCESS
  payload: ISportViewModel[]
}

export type SportsFailureAction = {
  type: sportsActionTypes.SPORTS_FAILURE
  payload: IErrorPayload
}

export type SportsActions =
  | SportsRequestAction
  | SportsSuccessAction
  | SportsFailureAction

export type SportsState = {
  sports: ISportViewModel[]
  pending: boolean
  status: IStatus | null
}

import {
  RoutingActions,
  routingActionTypes,
  RoutingState,
} from "@/fe-core/meta/types/routing";

const initialState: RoutingState = {
  backRoute: "",
  loginNextRoute: "",
};

const routingReducer = (
  state: RoutingState = initialState,
  action: RoutingActions
): RoutingState => {
  switch (action.type) {
    case routingActionTypes.SET_BACK_ROUTE: {
      const { payload } = action;
      return {
        ...state,
        backRoute: payload,
      };
    }

    case routingActionTypes.SET_LOGIN_NEXT_ROUTE: {
      const { payload } = action;
      return {
        ...state,
        loginNextRoute: payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default routingReducer;

import { ISetActiveSupportTabPayloadModel, IToggleSupportModalPayloadModel } from "@/fe-core/meta/interfaces/support";
import {
  SetActiveSupportTabAction,
  ToggleSupportModalAction,
  supportActionTypes,
} from "@/fe-core/meta/types/support";


export const toggleSupportModal = (
  payload: IToggleSupportModalPayloadModel
): ToggleSupportModalAction => ({
  type: supportActionTypes.TOGGLE_SUPPORT_MODAL,
  payload: payload.isOpen,
});

export const setActiveSupportTab = (
  payload: ISetActiveSupportTabPayloadModel
): SetActiveSupportTabAction => ({
  type: supportActionTypes.SET_SUPPORT_CURRENCY_TAB,
  payload: payload.tabIndex,
});


import { Session } from "autobahn";

import { RootState } from "@/fe-core/meta/types/root";
import { SocketState } from "@/fe-core/meta/types/socket";

export const socketSelector = ({ socket }: RootState): SocketState => socket;

export const socketSessionSelector = (state: RootState): Session | null => {
  const data = socketSelector(state);

  if (!data) {
    return null;
  }

  const { session } = data;
  return session;
};

export const socketSessionConnectionAttemptsSelector = (
  state: RootState
): number => {
  const data = socketSelector(state);

  if (!data) {
    return 0;
  }

  const { sessionConnectionAttempts } = data;
  return sessionConnectionAttempts;
};

"use client";

import { createStore, applyMiddleware, Store } from "redux";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";
import rootReducer from "./reducers/rootReducer";
import { rootSaga } from "./sagas/rootSaga";
import { createLogger } from "redux-logger";

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

// if (process.env.VERCEL_ENV !== "production") {
//   middleware.push(<SagaMiddleware>logger);
// }

const store: Store = createStore(rootReducer, applyMiddleware(...middleware));
sagaMiddleware.run(rootSaga);

export default store;

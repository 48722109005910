import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects'

import validateUsernameSaga from './usernameSaga'
import validateEmailSaga from './emailSaga'
import validateMobileSaga from './mobileSaga'
import validateCnpSaga from './cnpSaga'
import validateSignupIpSaga from './signupIpSaga'

function* validateSaga():
  | Generator<AllEffect<ForkEffect<void>>, void, unknown>
  | Generator {
  yield all([
    fork(validateCnpSaga),
    fork(validateUsernameSaga),
    fork(validateEmailSaga),
    fork(validateSignupIpSaga),
    fork(validateMobileSaga),
  ])
}

export default validateSaga

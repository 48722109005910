"use client";

import { FC, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { signupEmailResendRequest } from "../../../fe-core-omega/_redux/actions/userActions";
import { setLoginMenuScreen } from "../../../fe-core-omega/_redux/actions/menusActions";
import {
  signupResendEmailPendingSelector,
  signupResendEmailStatusSelector,
} from "../../../fe-core-omega/_redux/selectors/userSelectors";
import {
  validateAccountInformationEmail,
  validateAccountInformationUsernameWithNoSpecialChar,
} from "../../../fe-core-omega/helpers/general";
import { useScreen } from "../../../context/ScreenContext";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import Image from "next/image";
import {
  isPendingRegistrationContentSelector,
  registrationContentSelector,
} from "../../../fe-core-omega/_redux/selectors/registrationContentSelectors";
import { IImageAttributesViewModel } from "../../../fe-core-omega/meta/interfaces/root";
import useTracking from "@/components/Hooks/useTracking";
import Button from "@/components/Button";
import { loginMenuScreens } from "@/fe-core/meta/types/menus";
import { USERNAME_TEST_REGEX } from "@/config/profile";

interface Props {
  email: string;
  username: string;
  setUsername: (username: string | ((prevUsername: string) => string)) => void;
  setEmail: (email: string | ((prevEmail: string) => string)) => void;
}

const VerifyEmailInputScreen: FC<Props> = ({
  email,
  username,
  setUsername,
  setEmail,
}) => {
  const { click } = useTracking();
  const dispatch = useDispatch();
  const t = useTranslations(I18N_NAMESPACE.FORGOT_PASSWORD);
  const registerT = useTranslations(I18N_NAMESPACE.REGISTER);
  const generalT = useTranslations(I18N_NAMESPACE.GENERAL);
  const { isMobile } = useScreen();

  const pending = useSelector(signupResendEmailPendingSelector);
  const status = useSelector(signupResendEmailStatusSelector);
  const registrationContent = useSelector(registrationContentSelector);
  const isPendingRegistrationContent = useSelector(
    isPendingRegistrationContentSelector
  );

  const [emailErr, setEmailErr] = useState<string | null>("");
  const [usernameErr, setUsernameErr] = useState<string | null>("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailErr(null);
    setEmail(event.target.value.replace(/\s+/g, ""));
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsernameErr(null);
    setUsername(event.target.value.replace(/\s+/g, ""));
  };

  const handleEmailBlur = () => {
    const emailValidationError = validateAccountInformationEmail(
      email,
      registerT
    );
    setEmailErr(emailValidationError);
    return emailValidationError;
  };

  const handleUsernameBlur = () => {
    const usernameValidationError =
      validateAccountInformationUsernameWithNoSpecialChar(
        username,
        USERNAME_TEST_REGEX,
        registerT
      );
    setUsernameErr(usernameValidationError);
    return usernameValidationError;
  };

  const handleSendClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailValidationError = handleEmailBlur();
    const usernameValidationError = handleUsernameBlur();

    if (emailValidationError || usernameValidationError) return;

    click(`${t("send")} verify email`, {
      buttonLabel: t("send"),
      component: "VerifyEmailInputScreen",
    });

    dispatch(
      signupEmailResendRequest({ username, verificationTarget: "email" })
    );
  };

  const getErrorComponent = () => {
    if (!status) return null;
    const { success } = status;

    return (
      !success && (
        <div className="text-sm text-center text-red">
          {t("sendind-email-error")}
        </div>
      )
    );
  };

  const handleLoginClick = () => {
    dispatch(setLoginMenuScreen(loginMenuScreens.LOGIN));
  };

  const leftBannerImg = useMemo(() => {
    const attributes =
      registrationContent && registrationContent[0]?.attributes;
    const leftBannerImg = isMobile
      ? attributes
        ? (attributes?.mobileImage?.data
            ?.attributes as IImageAttributesViewModel)
        : undefined
      : attributes
      ? (attributes?.desktopImage?.data
          ?.attributes as IImageAttributesViewModel)
      : undefined;
    return leftBannerImg;
  }, [isMobile, registrationContent]);

  return (
    <div className="grid h-screen grid-cols-1 md:grid-cols-2 sm:h-auto">
      <div className="relative hidden md:block">
        {isPendingRegistrationContent ? (
          <Image
            src={"https://dummyimage.com/360x640/e3e3e3/000000.jpg"}
            objectFit="cover"
            fill
            alt="reg-banner"
            className="w-full h-full"
            loading="lazy"
          />
        ) : (
          <Image
            src={
              leftBannerImg
                ? leftBannerImg.url
                : "https://dummyimage.com/360x640/e3e3e3/000000.jpg"
            }
            objectFit="cover"
            fill
            alt={leftBannerImg ? leftBannerImg?.name : " registration banner"}
            className="w-full h-full"
            loading="lazy"
          />
        )}
      </div>
      <div className="relative flex flex-col justify-between gap-10 p-8 pt-16 sm:pt-8 min-h-[640px] h-full dark:bg-tertiary-600">
        <div className="flex flex-col gap-8">
          <h5 className="text-base font-bold dark:text-white">
            {registerT("verify-email")}
          </h5>
          <p>{registerT("need-to-verify-email")}</p>
          <form onSubmit={handleSendClick}>
            <div className="">
              <input
                className="w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0"
                type="text"
                id="login-email"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                value={email}
                placeholder={registerT("email")}
                disabled={!!pending}
              />
              {emailErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {emailErr}
                </div>
              )}
              <input
                className="w-full px-4 text-sm font-normal leading-5.5 py-2.5 border rounded text-tertiary border-tertiary-100 bg-tertiary-50 dark:bg-tertiary-800 dark:text-white dark:border-tertiary-400 dark:placeholder:text-white/50 focus:border-primary-500 focus:shadow-none focus:ring-0"
                type="text"
                id="login-username"
                onChange={handleUsernameChange}
                onBlur={handleUsernameBlur}
                value={username}
                placeholder={registerT("username")}
                disabled={!!pending}
              />
              {usernameErr && (
                <div className="m-0 mt-0.5 text-[10px] font-medium text-error dark:text-error-500">
                  {usernameErr}
                </div>
              )}
              {getErrorComponent()}
            </div>

            <div className="flex flex-col gap-4">
              <Button
                size="lg"
                buttonType="submit"
                className="min-w-[230px] w-full"
                disabled={!email || pending || !!emailErr || !!usernameErr}
                label={registerT("get-otp")}
                loading={!!pending}
              />
              <div className="flex items-center gap-3">
                <a
                  className="text-sm font-bold text-primary-500 dark:text-primary-200 focus-visible:outline-offset-0 focus-visible:ring-0 focus-visible:outline-none"
                  onClick={handleLoginClick}
                >
                  {generalT("goback")}
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailInputScreen;

import { RootActions, rootActionTypes } from "@/fe-core/meta/types/root";
import { userActionTypes } from "@/fe-core/meta/types/user";
import {
  LevelupDetailsActions,
  LevelupDetailsState,
} from "@/fe-core/meta/types/user/levelupDetails";

const initialState: LevelupDetailsState = {
  pending: false,
  data: null,
  status: null,
};

const levelupDetailsReducer = (
  state: LevelupDetailsState = initialState,
  action: RootActions | LevelupDetailsActions
): LevelupDetailsState => {
  switch (action.type) {
    case userActionTypes.GET_LEVEL_UP_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
        status: null,
      };
    case userActionTypes.GET_LEVEL_UP_DETAILS_SUCCESS:
      const { payload } = action;
      return {
        ...state,
        pending: false,
        data: payload,
        status: {
          success: true,
        },
      };
    case userActionTypes.GET_LEVEL_UP_DETAILS_FAILURE:
      const {
        payload: { message },
      } = action;
      return {
        ...state,
        pending: false,
        data: null,
        status: {
          success: false,
          message,
        },
      };
    case rootActionTypes.CLEAR_STATE:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};

export default levelupDetailsReducer;

import { IErrorPayload } from "../interfaces/root";
import { ICeSessionViewModel } from "../interfaces/ceSession";

export enum ceSessionActionTypes {
  GET_CE_SESSION_REQUEST = "GET_CE_SESSION_REQUEST",
  GET_CE_SESSION_SUCCESS = "GET_CE_SESSION_SUCCESS",
  GET_CE_SESSION_FAILURE = "GET_CE_SESSION_FAILURE",
}

export type GetCeSessionRequestAction = {
  type: ceSessionActionTypes.GET_CE_SESSION_REQUEST;
};

export type GetCeSessionSuccessAction = {
  type: ceSessionActionTypes.GET_CE_SESSION_SUCCESS;
  payload: ICeSessionViewModel;
};

export type GetCeSessionFailureAction = {
  type: ceSessionActionTypes.GET_CE_SESSION_FAILURE;
  payload: IErrorPayload;
};

export type ceSessionActions =
  | GetCeSessionRequestAction
  | GetCeSessionSuccessAction
  | GetCeSessionFailureAction;

export type CeSessionState = {
  pending: boolean;
  ceSession: string | null;
};

import TagManager from "react-gtm-module";
import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import { SignupEmailConfirmationRequestAction } from "@/fe-core/meta/types/user";

import {
  userActionTypes,
} from "@/fe-core/meta/types/user";
import { getProfileRequest, setHideSignup, signupEmailConfirmationFailure, signupEmailConfirmationSuccess } from "@/fe-core/_redux/actions/userActions";
import Storage from "@/fe-core/helpers/storage";
import { storageKeys } from "@/config/general";
import { IEmailConfirmationApiResponsePayload } from "@/fe-core/meta/interfaces/user/register/signupEmailConfirmation";
import { authenticateRequest, loginRequest } from "@/fe-core/_redux/actions/sessionActions";
import { localeSelector } from "@/fe-core/_redux/selectors/localeSelectors";
import { themeSelector } from "@/fe-core/_redux/selectors/themeSelectors";
import { closeLoginMenu } from "@/fe-core/_redux/actions/menusActions";

function* signupEmailConfirmationRequestSaga({
  payload: { username, password, ...verificationPayload},
}: SignupEmailConfirmationRequestAction) {
  try {
    const response: IJsonResponse<{ status: number }> = yield fetch(
      `/api/user/signup/email/confirmation`,
      {
        method: "POST",
        body: JSON.stringify(verificationPayload),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        yield put(signupEmailConfirmationSuccess());
        const { locale } = yield select(localeSelector);
        const { theme } = yield select(themeSelector);
        TagManager.dataLayer({
          dataLayer: {
            event: "registrationStep",
            step: 2,
            stepLabel: "Verificare SMS",
          },
        });
        const viewModel: IEmailConfirmationApiResponsePayload = yield response.json();
        Storage.set(storageKeys.SESSION_ID, viewModel.sessionKey);

        yield put(loginRequest({ username, password }))
        yield put(
          authenticateRequest({
            sessionId: viewModel.sessionKey,
            locale,
            theme,
            isAutoLogin: true,
          })
        );

        yield put(getProfileRequest());
        yield put(setHideSignup())
        yield put(closeLoginMenu())
        break;
      default:
        throw new Error("Code invalid");
    }
  } catch (error) {
    yield put(signupEmailConfirmationFailure(parseFrontendError(error)));
  }
}

function* signupEmailConfirmationSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(
      userActionTypes.SIGNUP_EMAIL_CONFIRMATION_REQUEST,
      signupEmailConfirmationRequestSaga
    ),
  ]);
}

export default signupEmailConfirmationSaga;

import { IPopUpsViewModel } from "@/fe-core/meta/interfaces/popUps";
import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  popUpsActionTypes,
  GetPopUpsFailureAction,
  GetPopUpsRequestAction,
  GetPopUpsSuccessAction,
} from "@/fe-core/meta/types/popups";

export const getPopUpsRequest = (): GetPopUpsRequestAction => ({
  type: popUpsActionTypes.GET_POPUPS_REQUEST,
});

export const getPopUpsSuccess = (
  payload: IPopUpsViewModel
): GetPopUpsSuccessAction => ({
  type: popUpsActionTypes.GET_POPUPS_SUCCESS,
  payload,
});

export const getPopUpsFailure = (
  payload: IErrorPayload
): GetPopUpsFailureAction => ({
  type: popUpsActionTypes.GET_POPUPS_FAILURE,
  payload,
});

export const getNewsQueryObject = (
  locale: string
): {
  fields: [];
  populate: string;
  filters: object;
  locale: string;
} => {
  const dateFilters = {
    $or: [
      {
        $and: [
          {
            dateFrom: {
              $lt: new Date(),
            },
          },
          {
            dateTo: {
              $gt: new Date(),
            },
          },
        ],
      },
      {
        $and: [
          {
            dateFrom: {
              $null: true,
            },
          },
          {
            dateTo: {
              $null: true,
            },
          },
        ],
      },
    ],
  };
  return {
    fields: [],
    populate: "*",
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
        dateFilters,
      ],
    },
    locale,
  };
};

export const getPromotionBannersQueryObject = (
  locale: string
): {
  fields: [];
  populate: string;
  filters: object | {} | null;
  locale: string;
  pagination: object;
  sort: string;
} => {
  const dateFilters = {
    $or: [
      {
        $and: [
          {
            dateFrom: {
              $lt: new Date(),
            },
          },
          {
            dateTo: {
              $gt: new Date(),
            },
          },
        ],
      },
      {
        $and: [
          {
            dateFrom: {
              $null: true,
            },
          },
          {
            dateTo: {
              $null: true,
            },
          },
        ],
      },
    ],
  };
  return {
    fields: [],
    populate: "*",
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
        dateFilters,
      ],
    },
    sort: "rank",
    pagination: {},
    locale,
  };
};

export const getPromotionContentQueryObject = (
  locale: string,
  theme: string,
  limit: number,
  authenticated: string
): {
  fields: [];
  populate: object;
  filters: object | {} | null;
  locale: string;
  pagination: object;
} => {
  return {
    fields: [],
    populate: {
      [`mobileImage${theme}`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      [`desktopImage${theme}`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      CTA: {
        fields: ["name", "rank", "link", "content", "locale"],
      },
      ["btagCode"]: {
        fields: [],
        populate: "*",
      },
    },
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
        {
          audience: {
            $contains: authenticated === "true" ? "Authenticated" : "Public",
          },
        },
        // btagFilter,
      ],
    },
    pagination: {
      limit,
    },
    locale,
  };
};

export const getBannersQueryObject = (
  locale,
  theme
): {
  fields: [];
  populate: object;
  filters: object | null;
  publicationState: string;
} => {
  return {
    fields: [],
    populate: {
      [`mobileImage${theme}`]: {
        fields: ["name", "width", "height", "url", "placeholder"],
      },
      [`desktopImage${theme}`]: {
        fields: ["name", "width", "height", "url", "placeholder"],
      },
      // fixture: {
      //   fields: ["name", "fixtureId"],
      // },
    },
    publicationState: "live",
    // filters: {
    //   $and: [
    //     {
    //       isActive: {
    //         $eq: true,
    //       },
    //     },
    //     {
    //       dateFrom: {
    //         $lt: new Date(),
    //       },
    //     },
    //     {
    //       dateTo: {
    //         $gt: new Date(),
    //       },
    //     },
    //   ],
    // },
  };
};

export const getBannersV2QueryObject = (
  locale: string
): {
  fields: [];
  populate: object;
  filters: object | {} | null;
  locale: string;
  pagination: object;
  publicationState: string;
} => {
  const dateFilters = {
    $or: [
      {
        $and: [
          {
            dateFrom: {
              $lt: new Date(),
            },
          },
          {
            dateTo: {
              $gt: new Date(),
            },
          },
        ],
      },
      {
        $and: [
          {
            dateFrom: {
              $null: true,
            },
          },
          {
            dateTo: {
              $null: true,
            },
          },
        ],
      },
    ],
  };
  return {
    fields: [],
    populate: {
      [`slide`]: {
        fields: ["tagline", "headline", "cta", "sliderType"],
        populate: "*",
      },
      ["userRole"]: {
        fields: [],
        populate: "*",
      },
      ["bonusCode"]: {
        fields: [],
        populate: "*",
      },
      ["btagCode"]: {
        fields: [],
        populate: "*",
      },

      // [`mobileImage${theme}`]: {
      //   fields: ["name", "width", "height", "url", "placeholder"],
      // },
      // [`desktopImage${theme}`]: {
      //   fields: ["name", "width", "height", "url", "placeholder"],
      // },
      // fixture: {
      //   fields: ["name", "fixtureId"],
      // },
    },
    publicationState: "live",
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
        dateFilters,
      ],
    },
    locale,
    pagination: {
      start: 0,
      limit: 500,
    },
  };
};
export const getSocialsQueryObject = (
  locale,
  theme
): {
  fields: [];
  populate: object;
  filters: object;
  pagination: object;
} => {
  return {
    fields: [],
    populate: {
      link: {
        fields: ["name"],
      },
      target: {
        fields: ["name"],
      },
      name: {
        fields: ["name"],
      },
      follow: {
        fields: ["name"],
      },
      social: {
        populate: {
          [`icon${theme}`]: {
            fields: ["name", "width", "height", "url"],
          },
        },
      },
    },
    filters: {
      $and: [
        // {
        //   $or: [
        //     {
        //       isHidden: {
        //         $eq: false,
        //       },
        //     },
        //     {
        //       isHidden: {
        //         $null: true,
        //       },
        //     },
        //   ],
        // },
        // {
        //   isActive: {
        //     $eq: true,
        //   },
        // },
        // {
        //   validFrom: {
        //     $lt: new Date(),
        //   },
        // },
        // {
        //   validUntil: {
        //     $gt: new Date(),
        //   },
        // },
        // {
        //   $or: [
        //     {
        //       userTypes: {
        //         name: {
        //           $in: userRoles,
        //         },
        //       },
        //     },
        //     {
        //       userTypes: {
        //         name: {
        //           $null: true,
        //         },
        //       },
        //     },
        //   ],
        // },
        // {
        //   $or: [
        //     {
        //       excludedUserTypes: {
        //         name: {
        //           $notIn: userRoles,
        //         },
        //       },
        //     },
        //     {
        //       excludedUserTypes: {
        //         name: {
        //           $null: true,
        //         },
        //       },
        //     },
        //   ],
        // },
      ],
    },
    pagination: {
      start: 0,
      limit: 500,
    },
    locale,
  };
};
export const getPromotionsQueryObject = (
  locale: string
): {
  fields: string[];
  populate: string;
  filters: object;
  pagination: object;
  locale: string;
  sort: string;
} => {
  const dateFilters = {
    $or: [
      {
        $and: [
          {
            publishPeriods: {
              dateFrom: {
                $lt: new Date(),
              },
            },
          },
          {
            publishPeriods: {
              dateTo: {
                $gt: new Date(),
              },
            },
          },
        ],
      },
      {
        $and: [
          {
            publishPeriods: {
              dateFrom: {
                $null: true,
              },
            },
          },
          {
            publishPeriods: {
              dateTo: {
                $null: true,
              },
            },
          },
        ],
      },
    ],
  };
  return {
    fields: [
      "title",
      "snippet",
      "rank",
      "slug",
      "category",
      "userState",
      "publishDays",
      "eligibleGameIds",
    ], //"timezone"
    populate: "*",
    filters: {
      $and: [dateFilters],
    },
    pagination: {
      start: 0,
      limit: 500,
    },
    sort: "rank",
    locale,
  };
};

export const getPromotionQueryObject = (
  slug: string,
  locale: string
): {
  fields: string;
  populate: object;
  filters: object;
  pagination: object;
  publicationState: string;
} => {
  return {
    fields: "*",
    populate: {
      promotionDuration: {
        fields: ["dateTo", "dateFrom"],
      },
      [`desktopImageDark`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      [`mobileImageDark`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      [`desktopImageLight`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      [`mobileImageLight`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      // promotionCategories: {
      //   fields: ["id", "name", "order", "slug"],
      // },
      // bonusCards: {
      //   fields: [
      //     "title",
      //     "description",
      //     "code",
      //     "buttonText",
      //     "position",
      //     "buttonType",
      //   ],
      // },
      // userTypes: {
      //   fields: ["name"],
      // },
      // excludedUserTypes: {
      //   fields: ["name"],
      // },
    },
    publicationState: "live",
    filters: {
      $and: [
        // {
        //   isActive: {
        //     $eq: true,
        //   },
        // },
        // {
        //   validFrom: {
        //     $lt: new Date(),
        //   },
        // },
        // {
        //   validUntil: {
        //     $gt: new Date(),
        //   },
        // },
        {
          slug: {
            $eq: slug,
          },
        },
        // {
        //   promotionCategories: {
        //     slug: {
        //       $in: [categorySlug],
        //     },
        //   },
        // },
      ],
    },
    pagination: {
      start: 0,
      limit: 500,
    },
    locale,
  };
};

export const getAdventCalendarQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
  pagination: object;
} => {
  return {
    fields: [],
    populate: {
      desktopPhoto: {
        fields: ["name", "width", "height", "url"],
      },
      mobilePhoto: {
        fields: ["name", "width", "height", "url"],
      },
    },
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
      ],
    },
    pagination: {
      start: 0,
      limit: 500,
    },
  };
};

export const getBettingRulesCMSPageQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      file: {
        fields: [
          "name",
          "width",
          "height",
          "url",
          "size",
          "caption",
          "ext",
          "name",
        ],
      },
      reguli: {
        fields: ["description", "title"],
      },
    },
    filters: {},
  };
};

export const getTermsAndConditionsCMSPageQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      file: {
        fields: [
          "name",
          "width",
          "height",
          "url",
          "size",
          "caption",
          "ext",
          "name",
        ],
      },
    },
    filters: {},
  };
};

export const getDepositMethodsCMSPageQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      depositMethods: {
        fields: ["*"],
        populate: {
          methodIcon: {
            fields: ["name", "width", "height", "url"],
          },
        },
      },
    },
    filters: {},
  };
};

export const getWithdrawMethodsCMSPageQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      withdrawMethods: {
        fields: ["*"],
        populate: {
          methodIcon: {
            fields: ["name", "width", "height", "url"],
          },
        },
      },
    },
    filters: {},
  };
};

export const getCasinoGameRulesCMSPageQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      games: {
        fields: ["*"],
        populate: {
          file: {
            fields: [
              "name",
              "width",
              "height",
              "url",
              "size",
              "caption",
              "ext",
              "name",
            ],
          },
        },
      },
    },
    filters: {},
  };
};

export const getGameOfTheWeekQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      casino: {
        populate: {
          desktopImage: {
            fields: ["name", "width", "height", "url"],
          },
          mobileImage: {
            fields: ["name", "width", "height", "url"],
          },
        },
      },
      liveCasino: {
        populate: {
          desktopImage: {
            fields: ["name", "width", "height", "url"],
          },
          mobileImage: {
            fields: ["name", "width", "height", "url"],
          },
        },
      },
    },
    filters: {},
  };
};

export const getAllLandingPagesSlugsQueryObject = (): {
  fields: string[];
  populate: object;
  filters: object;
} => {
  return {
    fields: ["slug"],
    populate: {},
    filters: {
      $and: [
        {
          isActive: {
            $eq: true,
          },
        },
        {
          dateFrom: {
            $lt: new Date(),
          },
        },
        {
          dateTo: {
            $gt: new Date(),
          },
        },
      ],
    },
  };
};

export const getLandingPageBySlugQueryObject = (
  slug: string
): {
  fields: [];
  populate: object;
  filters: object;
} => {
  return {
    fields: [],
    populate: {
      image: {
        fields: ["name", "width", "height", "url"],
      },
      steps: "*",
    },
    filters: {
      $and: [
        {
          slug: {
            $eq: slug,
          },
        },
        {
          isActive: {
            $eq: true,
          },
        },
        {
          dateFrom: {
            $lt: new Date(),
          },
        },
        {
          dateTo: {
            $gt: new Date(),
          },
        },
      ],
    },
  };
};

export const getPopUpsQueryObject = (): {
  fields: [];
  populate: object;
  filters: object;
  publicationState: string;
} => {
  return {
    fields: [],
    populate: {},
    publicationState: "live",
    filters: {
      $and: [
        // {
        //   isActive: {
        //     $eq: true,
        //   },
        // },
        // {
        //   dateFrom: {
        //     $lt: new Date(),
        //   },
        // },
        // {
        //   dateTo: {
        //     $gt: new Date(),
        //   },
        // },
      ],
    },
  };
};
export const getPaymentMethodsQueryObject = (
  locale: string,
  theme: string
): {
  locale: string;
  populate: {
    [key: string]: {
      fields: string[];
    };
  };
} => {
  return {
    populate: {
      [`images${theme}`]: {
        fields: ["name", "width", "height", "url"],
      },
    },
    locale,
  };
};
export const getFooterQueryObject = (
  locale: string,
  theme: string
): {
  fields: [];
  populate: object;
  filters: object;
  locale: string;
} => {
  return {
    fields: [],
    populate: {
      bottomLogo: {
        fields: ["name", "width", "height", "url", "link", "target"],
      },
      social: {
        fields: ["*"],
        populate: {
          [`icon${theme}`]: {
            fields: ["name", "width", "height", "url", "link", "target"],
          },
        },
      },
      seals: {
        fields: ["*"],
        populate: {
          [`icon${theme}`]: {
            fields: ["name", "width", "height", "url", "link", "target"],
          },
        },
      },
      providers: {
        fields: ["*"],
        populate: {
          [`icon${theme}`]: {
            fields: ["name", "width", "height", "url", "link", "target"],
          },
        },
      },
      payments: {
        fields: ["*"],
        populate: {
          [`icon${theme}`]: {
            fields: ["name", "width", "height", "url", "link", "target"],
          },
        },
      },
    },
    locale,
    filters: {
      $and: [
        // {
        //   isActive: {
        //     $eq: true,
        //   },
        // },
        // {
        //   dateFrom: {
        //     $lt: new Date(),
        //   },
        // },
        // {
        //   dateTo: {
        //     $gt: new Date(),
        //   },
        // },
      ],
    },
  };
};
export const getVendorDetailsQueryObject = (
  locale: string,
  id: string
): {
  fields: [];
  populate: object;
  locale: string;
  filters: object;
} => {
  return {
    fields: [],
    populate: {},
    filters: {
      gamevendor_id: {
        $eq: id,
      },
    },
    locale,
  };
};

export const getRegistrationContentQueryObject = (
  locale: string,
  theme: string,
  limit: number
): {
  fields: [];
  populate: object;
  locale: string;
  pagination: object;
  sort: string;
} => {
  return {
    fields: [],
    populate: {
      [`mobileImage${theme}`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      [`desktopImage${theme}`]: {
        fields: ["name", "width", "height", "url", "alternativeText"],
      },
      ["btagCode"]: {
        fields: [],
        populate: "*",
      },
    },
    pagination: {
      limit,
    },
    sort: "rank",
    locale,
  };
};

export const BannerPages = {
  HOME_PAGE: "HomePage",
  CASINO_PAGE: "CasinoPage",
  CASINO_LIVE_PAGE: "CasinoLivePage",
};

export const SLIDER_THEME = {
  MAIN: "main",
  JACKPOT: "jackpot",
};

export const SLIDE_TYPE = {
  CTA_SLIDE: "cta-slide",
  ACTION_SLIDE: "action-slide",
  LINK_SLIDE: "link-slide",
  EVENT_SLIDE: "event-slide",
  JACKPOT_SLIDE: "jackpot-slide",
};

export const DYNAMIC_CMS_SLUG_MAP: { [key: string]: string } = {
  "reguli-de-pariere": "reguli-de-pariere",
  "regulamente-jocuri-casino": "regulamente-jocuri-casino",
};

import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
//@todo refactor
import {
  ClearCampaignsAction,
  GetCampaignsFailureAction,
  GetCampaignsRequestAction,
  GetCampaignsSuccessAction,
  ICampaignViewModel,
  ICampaignsViewModel,
  ISetCampaignRequestPayload,
  SetCampaignFailureAction,
  SetCampaignRequestAction,
  SetCampaignSuccessAction,
} from "@/fe-core/meta/interfaces/user/affiliate/campaigns";
import { userActionTypes } from "@/fe-core/meta/types/user";

export const getCampaignsRequest = (): GetCampaignsRequestAction => ({
  type: userActionTypes.GET_CAMPAIGNS_REQUEST,
});

export const getCampaignsSuccess = (
  payload: ICampaignsViewModel
): GetCampaignsSuccessAction => ({
  type: userActionTypes.GET_CAMPAIGNS_SUCCESS,
  payload,
});

export const getCampaignsFailure = (
  payload: IErrorPayload
): GetCampaignsFailureAction => ({
  type: userActionTypes.GET_CAMPAIGNS_FAILURE,
  payload,
});

export const setCampaignRequest = (
  payload: ISetCampaignRequestPayload
): SetCampaignRequestAction => ({
  type: userActionTypes.SET_CAMPAIGN_REQUEST,
  payload,
});

export const setCampaignSuccess = (
  payload: ICampaignViewModel
): SetCampaignSuccessAction => ({
  type: userActionTypes.SET_CAMPAIGN_SUCCESS,
  payload,
});

export const setCampaignFailure = (
  payload: IErrorPayload
): SetCampaignFailureAction => ({
  type: userActionTypes.SET_CAMPAIGN_FAILURE,
  payload,
});

export const clearCampaigns = (): ClearCampaignsAction => ({
  type: userActionTypes.CLEAR_CAMPAIGNS,
});

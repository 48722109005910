import { combineReducers } from 'redux'
import signupReducer from './signupReducer'
import signupSmsReducer from './signupSmsReducer'
import validateReducer from './validateReducer'
import userConsentsReducer from './userConsentsReducer'
import currenciesReducer from './currenciesReducer'
import validCountriesReducer from './validCountriesReducer'
import signupEmailConfirmationReducer from './signupEmailConfirmationReducer'

const registerReducer = combineReducers({
  signup: signupReducer,
  signupSms: signupSmsReducer,
  signupEmailConfirmation: signupEmailConfirmationReducer,
  validate: validateReducer,
  userConsents: userConsentsReducer,
  currencies: currenciesReducer,
  validCountries: validCountriesReducer
})

export default registerReducer

import {
  all,
  AllEffect,
  ForkEffect,
  takeLatest,
  put,
  select,
} from "redux-saga/effects";
import { IJsonResponse } from "@/fe-core/meta/interfaces/root";
import {
  ICasinoVendorMoreGamesPayload,
  ICasinoVendorsPayload,
  ICasinoVendorViewModel,
  IVendorDetailsViewModel,
} from "@/fe-core/meta/interfaces/casinoVendors";
import {
  casinoVendorsActionTypes,
  GetCasinoVendorMoreGamesRequestAction,
  GetSingleCasinoVendorRequestAction,
  GetVendorDetailsRequestAction,
  GetVendorsRequestAction,
  GetSubCasinoVendorMoreGamesRequestAction,
  GetSubSingleCasinoVendorRequestAction,
  GetSubVendorsRequestAction,
} from "@/fe-core/meta/types/casinoVendors";
import {
  getCasinoVendorMoreGamesFailure,
  getCasinoVendorMoreGamesSuccess,
  getSingleCasinoVendorFailure,
  getSingleCasinoVendorSuccess,
  getVendorDetailsSuccess,
  getVendorsFailure,
  getVendorsSuccess,
  getSubCasinoVendorMoreGamesFailure,
  getSubCasinoVendorMoreGamesSuccess,
  getSubSingleCasinoVendorFailure,
  getSubSingleCasinoVendorSuccess,
  getSubVendorsSuccess,
  getSubVendorsFailure,
} from "@/fe-core/_redux/actions/casinoVendorsActions";
import {
  CASINO_URL,
  gamesExpand,
  groupExpand,
  groupGameFields,
  MAX_GAMES_PER_PAGE,
  singleSubVendorFields,
  singleVendorExpandFields,
  singleVendorFields,
  subVendorFields,
  vendorExpand,
  vendorFields,
} from "@/config/casino";
import {
  buildCasinoGroupsQueryString,
  buildCasinoQueryString,
  buildCasinoSingleVendorQueryString,
  getGameDesktopSupportFilterString,
  getGameMobileSupportFilterString,
} from "@/fe-core/helpers/casino";
import { parseFrontendError } from "@/fe-core/helpers/general";
import { localeSelector } from "../selectors/localeSelectors";
import { buildVendorQueryString, slugifyURL } from "@/fe-core/helpers/vendor";
import { methodTypes } from "@/fe-core/meta/types/root";
import { headerKeys } from "@/config/general";
import { GetVendorDetailFailure } from "../actions/casinoVendorsActions";
import {
  ICasinoGamesPayload,
  ICasinoGroupViewModel,
} from "@/fe-core/meta/interfaces/casino";

function* getVendorsSaga({ payload }: GetVendorsRequestAction) {
  try {
    const { platform } = payload;
    const queryString: string = buildCasinoQueryString({
      fields: vendorFields,
      expand: vendorExpand,
      filters: platform
        ? [
            `games(${
              platform === "Android"
                ? getGameMobileSupportFilterString(true)
                : getGameDesktopSupportFilterString(true)
            })`,
          ]
        : [],
      sortedField: "rank",
      orderBy: "ACS",
    });
    const response: IJsonResponse<ICasinoVendorsPayload> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/vendors${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoVendorsPayload = yield response.json();

        yield put(getVendorsSuccess(payload));
        break;
      default:
        throw new Error("A problem has occurred while retrieving vendors list");
    }
  } catch (error) {
    yield put(getVendorsFailure(parseFrontendError(error)));
  }
}
function* getSubVendorsSaga({ payload }: GetSubVendorsRequestAction) {
  try {
    const queryString: string = buildCasinoQueryString({
      fields: subVendorFields,
      // dataSource: payload,
      expand: vendorExpand,
      pagination: { limit: 1000, offset: 0 },
    });
    const response: IJsonResponse<ICasinoVendorsPayload> = yield fetch(
      `${CASINO_URL}/subVendors${queryString}`,
      {
        method: "GET",
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const payload: ICasinoVendorsPayload = yield response.json();

        // Filter out vendors without games
        const filteredVendors = payload.items.filter(
          (vendor) => vendor.games && vendor.games.total > 0
        );

        for (const vendor of filteredVendors) {
          vendor.slug = slugifyURL(vendor.name);
          vendor.displayName = slugifyURL(vendor.identity);
        }

        yield put(getSubVendorsSuccess({ ...payload, items: filteredVendors }));
        break;
      default:
        throw new Error("A problem has occurred while retrieving sub vendors");
    }
  } catch (error) {
    yield put(getSubVendorsFailure(parseFrontendError(error)));
  }
}

function* getVendorDetailsSaga({ payload }: GetVendorDetailsRequestAction) {
  try {
    const { locale } = yield select(localeSelector);

    const filters: string[] = [
      `games(${
        payload.platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true)
      })`,
    ];

    const queryString: string = buildCasinoQueryString({
      fields: singleVendorFields,
      expand: singleVendorExpandFields,
      filters,
      pagination: {
        offset: 0,
        limit: MAX_GAMES_PER_PAGE,
      },
    });

    const response: IJsonResponse<ICasinoVendorsPayload> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/vendors/${payload?.slug}${queryString}`,
      {
        method: methodTypes.GET,
        headers: new Headers({
          [headerKeys.LOCALE]: locale,
        }),
      }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const viewModel: IVendorDetailsViewModel = yield response.json();
        yield put(getVendorDetailsSuccess(viewModel));
        break;
      default:
        throw new Error("Vendors couldn't be fetched");
    }
  } catch (error) {
    yield put(GetVendorDetailFailure(parseFrontendError(error)));
  }
}

function* getSingleCasinoVendorSaga({
  payload,
}: GetSingleCasinoVendorRequestAction) {
  try {
    const { dataSource, id, platform } = payload;
    const { locale } = yield select(localeSelector);

    const queryString: string = buildCasinoSingleVendorQueryString(
      groupExpand,
      singleVendorFields,
      platform ? platform : undefined,
      locale,
      groupGameFields
    );

    const response: IJsonResponse<ICasinoVendorViewModel> = yield fetch(
      `${CASINO_URL}/vendors/${id}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const vendor: ICasinoVendorViewModel = yield response.json();
        yield put(getSingleCasinoVendorSuccess(vendor));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getSingleCasinoVendorFailure(parseFrontendError(error)));
  }
}

function* getSubSingleCasinoVendorSaga({
  payload,
}: GetSubSingleCasinoVendorRequestAction) {
  try {
    const { dataSource, id, platform } = payload;
    const { locale } = yield select(localeSelector);

    const queryString: string = buildCasinoSingleVendorQueryString(
      groupExpand,
      singleSubVendorFields,
      platform ? platform : undefined,
      locale,
      groupGameFields
    );

    const response: IJsonResponse<ICasinoVendorViewModel> = yield fetch(
      `${CASINO_URL}/subVendors/${id}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const vendor: ICasinoVendorViewModel = yield response.json();
        // vendor.slug = slugifyURL(vendor.name);
        // vendor.displayName = slugifyURL(vendor.identity);
        yield put(getSubSingleCasinoVendorSuccess(vendor));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getSubSingleCasinoVendorFailure(parseFrontendError(error)));
  }
}

function* getCasinoVendorMoreGamesSaga({
  payload,
}: GetCasinoVendorMoreGamesRequestAction) {
  try {
    const { slug, pagination } = payload;
    const filters: string[] = [
      `games(${
        payload.platform === "Android"
          ? getGameMobileSupportFilterString(true)
          : getGameDesktopSupportFilterString(true)
      })`,
    ];

    const queryString: string = buildCasinoQueryString({
      fields: singleVendorFields,
      expand: singleVendorExpandFields,
      filters,
      pagination,
    });

    const response: IJsonResponse<ICasinoVendorMoreGamesPayload> = yield fetch(
      `${process.env.NEXT_PUBLIC_LOBBY_API_URL}/vendors/${slug}${queryString}`,
      { method: methodTypes.GET }
    );
    const { status } = response;
    switch (status) {
      case 200:
        const vendor: ICasinoVendorMoreGamesPayload = yield response.json();
        yield put(
          getCasinoVendorMoreGamesSuccess({ slug, games: vendor?.games })
        );
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getCasinoVendorMoreGamesFailure(parseFrontendError(error)));
  }
}

function* getSubCasinoVendorMoreGamesSaga({
  payload,
}: GetSubCasinoVendorMoreGamesRequestAction) {
  try {
    const { locale } = yield select(localeSelector);
    const { dataSource, pagination, id, platform } = payload;

    const queryString: string = buildCasinoGroupsQueryString(
      groupExpand,
      singleSubVendorFields,
      platform ? platform : undefined,
      groupGameFields,
      locale,
      [],
      null,
      pagination
    );

    const response: IJsonResponse<ICasinoVendorViewModel> = yield fetch(
      `${CASINO_URL}/subVendors/${id}${queryString}`,
      {
        method: "GET",
      }
    );

    const { status } = response;
    switch (status) {
      case 200:
        const vendor: ICasinoVendorViewModel = yield response.json();

        yield put(getSubCasinoVendorMoreGamesSuccess(vendor));
        break;
      default:
        throw new Error("A problem has occurred while retrieving navigation");
    }
  } catch (error) {
    yield put(getSubCasinoVendorMoreGamesFailure(parseFrontendError(error)));
  }
}

function* casinoVendorsSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    takeLatest(casinoVendorsActionTypes.GET_VENDORS_REQUEST, getVendorsSaga),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_VENDOR_DETAILS_REQUEST,
      getVendorDetailsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_SINGLE_CASINO_VENDOR_REQUEST,
      getSingleCasinoVendorSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_CASINO_VENDOR_MORE_GAMES_REQUEST,
      getCasinoVendorMoreGamesSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_SUB_VENDORS_REQUEST,
      getSubVendorsSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_SUB_SINGLE_CASINO_VENDOR_REQUEST,
      getSubSingleCasinoVendorSaga
    ),
  ]);
  yield all([
    takeLatest(
      casinoVendorsActionTypes.GET_SUB_CASINO_VENDOR_MORE_GAMES_REQUEST,
      getSubCasinoVendorMoreGamesSaga
    ),
  ]);
}

export default casinoVendorsSaga;

import { Session } from "autobahn";
import {
  webPubSubActionTypes,
  InitializeWebPubSubAction,
  WebPubSubConnectedAction,
  ReconnectWebPubSubAction,
  WebPubSubConnectionFailedAction,
  WebPubSubSubscribeSessionStateAction,
  WebPubSubSessionStateChangedAction,
  WebPubSubSubscribeWatchBalanceAction,
  WebPubSubSessionBalanceChangedAction,
  WebPubSubBalanceChangedPayload,
  WebPubSubSessionBalanceUpdateAction,
} from "@/fe-core/meta/types/webPubSub";

export const webPubSubConnected = (
  session: Session
): WebPubSubConnectedAction => ({
  type: webPubSubActionTypes.WEBPUBSUB_CONNECTED,
  payload: session,
});

export const initializeWebPubSub = (): InitializeWebPubSubAction => ({
  type: webPubSubActionTypes.INITIALIZE_WEBPUBSUB,
});

export const reconnectWebPubSub = (): ReconnectWebPubSubAction => ({
  type: webPubSubActionTypes.RECONNECT_WEBPUBSUB,
});

export const webPubSubConnectionFailed =
  (): WebPubSubConnectionFailedAction => ({
    type: webPubSubActionTypes.WEBPUBSUB_CONNECTION_FAILED,
  });

export const socketSubscribeSessionState =
  (): WebPubSubSubscribeSessionStateAction => ({
    type: webPubSubActionTypes.SUBSCRIBE_SESSION_STATE,
  });

export const webPubSubSessionStateChanged = (
  code: number
): WebPubSubSessionStateChangedAction => ({
  type: webPubSubActionTypes.SESSION_STATE_CHANGED,
  payload: code,
});

export const socketSubscribeWatchBalance =
  (): WebPubSubSubscribeWatchBalanceAction => ({
    type: webPubSubActionTypes.SUBSCRIBE_WATCH_BALANCE,
  });

export const webPubSubSessionBalanceChanged = (
  balance: WebPubSubBalanceChangedPayload
): WebPubSubSessionBalanceChangedAction => ({
  type: webPubSubActionTypes.SESSION_BALANCE_CHANGED,
  payload: balance,
});

export const webPubSubSessionBalanceUpdate = (
  balance: WebPubSubBalanceChangedPayload
): WebPubSubSessionBalanceUpdateAction => ({
  type: webPubSubActionTypes.SESSION_BALANCE_UPDATE,
  payload: balance,
});
